import { Info } from '@material-ui/icons';
import React from 'react';

import strings from 'common/strings';

import './Banner.scss';

const Banner: React.FC = () => {
  return (
    <div className={`Banner-container Banner-container-inventory`}>
      <div className="Banner drop-shadow">
        <Info className="Banner-icon" />
        <div>
          <div className="Banner-title">{strings.ONBOARDING_BANNER_TITLE}</div>
          <div className="Banner-subtitle">
            {strings.ONBOARDING_BANNER_SUBTITLE}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
