import { workflowStatusesDownMap } from 'models';

import './StepVehicleCounts.scss';

interface StepVehicleCountsProps {
  counts: any;
  status: string;
  stepId: string;
  stepDefinitionId: string;
  handleSelected: (value: string) => void;
}

const StepVehicleCounts = ({
  status,
  stepId,
  stepDefinitionId,
  counts,
  handleSelected,
}: StepVehicleCountsProps) => {
  function isSelected(value: string) {
    return (
      stepId === stepDefinitionId &&
      (workflowStatusesDownMap[status] === value ||
        (value === 'ALL' && status === 'ALL'))
    );
  }

  if (!counts) {
    return <span />;
  }

  return (
    <div className="StepVehicleCounts">
      {Object.keys(workflowStatusesDownMap).map((key: string) => {
        const status = workflowStatusesDownMap[key];
        key = status === 'UNKNOWN' ? 'ALL' : status;
        const className = [
          `${key.toLowerCase()}-status-text`,
          'status-block',
          isSelected(key) ? 'selected' : '',
          counts[key] ? '' : 'zero',
        ].join(' ');
        return (
          <div
            className={className}
            key={key}
            role="link"
            tabIndex={-1}
            onClick={(e) => {
              e.stopPropagation();
              handleSelected(key);
            }}
          >
            {counts[key] ?? counts.TOTAL}
          </div>
        );
      })}
    </div>
  );
};

export default StepVehicleCounts;
