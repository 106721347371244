export enum VehicleImageShots {
  CENTER_STORAGE_CONSOLE = 'CENTER_STORAGE_CONSOLE',
  DRIVE_DOOR_INTERIOR_CONTROLS = 'DRIVE_DOOR_INTERIOR_CONTROLS',
  DRIVER_DOOR_OPEN_FRONT = 'DRIVER_DOOR_OPEN_FRONT',
  DRIVERS_DASH = 'DRIVERS_DASH',
  ENGINE = 'ENGINE',
  FRONT = 'FRONT',
  FRONT_FACING_LEFT = 'FRONT_FACING_LEFT',
  FRONT_FACING_RIGHT = 'FRONT_FACING_RIGHT',
  FULL_DASH = 'FULL_DASH',
  NAVIGATION_SYSTEM = 'NAVIGATION_SYSTEM',
  OTHER = 'OTHER',
  PASSENGER_DASH = 'PASSENGER_DASH',
  PROFILE_FACING_LEFT = 'PROFILE_FACING_LEFT',
  REAR = 'REAR',
  REAR_FACING_LEFT = 'REAR_FACING_LEFT',
  REAR_SEATS = 'REAR_SEATS',
  STEREO_SYSTEM = 'STEREO_SYSTEM',
  TRUNK = 'TRUNK',
  UNKNOWN = 'UNKNOWN',
  WHEEL = 'WHEEL',
}

export default interface VehicleImageShot {
  name: string;
  sortOrder: number;
  value: VehicleImageShots;
}
