/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const BriefcaseCheck: React.FC<IconProps> = ({
  color = defaultColor,
  className,
}) => (
  <svg width={20} height={19} viewBox="0 0 20 19" fill="none">
    <path
      className={className}
      d="M8 0H12C12.5304 0 13.0391 0.210714 13.4142 0.585786C13.7893 0.960859 14 1.46957 14 2V4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V17C20 17.5304 19.7893 18.0391 19.4142 18.4142C19.0391 18.7893 18.5304 19 18 19H2C1.46957 19 0.960859 18.7893 0.585786 18.4142C0.210714 18.0391 0 17.5304 0 17V6C0 5.46957 0.210714 4.96086 0.585786 4.58579C0.960859 4.21071 1.46957 4 2 4H6V2C6 1.46957 6.21071 0.960859 6.58579 0.585786C6.96086 0.210714 7.46957 0 8 0ZM12 4V2H8V4H12ZM8.5 15.5L15.09 8.91L13.68 7.5L8.5 12.67L6.41 10.59L5 12L8.5 15.5Z"
      fill={color}
    />
  </svg>
);

export default BriefcaseCheck;
