import { useState } from 'react';

export const createFileSelector = (
  onchange: (event: Event) => void,
  onerror: (event: string | Event) => void,
  accept?: string
) => {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('multiple', 'multiple');
  fileSelector.onchange = onchange;
  fileSelector.onerror = onerror;

  if (accept) {
    fileSelector.setAttribute('accept', accept);
  }

  return fileSelector;
};

/**
 * @description Creates an HTMLInputElement
 * that can be used to select files when clicked
 *
 * @param onchange - function called after selecting files
 * @param onerror - function called if error occurs
 * */
export const useFileSelector = (
  onchange: (event: Event) => void,
  onerror: (event: string | Event) => void,
  accept?: string
) => useState(createFileSelector(onchange, onerror, accept));
