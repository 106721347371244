import { navigate, useLocation } from '@reach/router';
import { parse } from 'query-string';
import { FC, useEffect, useState } from 'react';

import { setItem } from 'api/lib/typedLocalStorage';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useCarfax } from 'hooks/useCarfax';

export interface HandleRedirectProps {
  path?: string;
}

const HandleRedirect: FC<HandleRedirectProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pathname, setPathname] = useState<string>('');

  const { code } = parse(useLocation().search);
  const { REDIRECT_ROOT, handleLogin, getVdpPath } = useCarfax();

  useEffect(() => {
    const authenticate = async () => {
      setIsLoading(true);
      await handleLogin();
      setIsLoading(false);
    };
    setItem('carfaxAuthorizationCode', code as string);
    setPathname(getVdpPath());

    authenticate();
  }, [code, getVdpPath, handleLogin]);

  if (isLoading) {
    return <LoadingIndicator />;
  } else {
    navigate(`${REDIRECT_ROOT}${pathname}`);
  }
  return <></>;
};

export default HandleRedirect;
