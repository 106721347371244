import { useMemo } from 'react';

import { useInventoryFilters } from 'api';
import { downMaps, filterIdsDownMap, UIFilter, UIFilterOption } from 'models';

export const useFilterColumn = () => {
  const { data: inventoryData = [] } = useInventoryFilters();

  const getOptions = (filter: UIFilter) => {
    const { id, options } = filter;
    const filterId = filterIdsDownMap[id] || id.toLowerCase();
    return options.map((option: UIFilterOption) => {
      const { level } = option;
      let { id, label } = option;
      id = id.replace(/^\w+::/, '');
      const mappedId = downMaps[filterId]?.[id];
      if (mappedId) {
        id = mappedId;
        label = option.label;
      }
      return { id, label, level };
    });
  };

  const filters = useMemo(
    () =>
      inventoryData?.map((filter: UIFilter) => {
        return {
          id: filter.id,
          label: filter.label,
          options: getOptions(filter),
          behavior: filter.behavior,
        };
      }) ?? [],
    [inventoryData]
  );

  return {
    filters,
  };
};
