import Edit from '@material-ui/icons/Edit';
import { useContext, useState } from 'react';
import TruncatedList from 'react-truncate-list';

import { useVehicleTags } from 'api';
import permissions from 'common/permissions';
import EditTagsModal from 'components/shared/EditTagsModal';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PermissionsGate from 'components/shared/PermissionsGate';
import { VehicleTag } from 'models';

import { VehicleCardContext } from '../../../VehicleCard';
import TagComponent from './TagSrp';

import 'react-truncate-list/dist/styles.css';
import './TagListSrp.scss';

/**
 * TODO: to fully adapt this component to react-query we must add optimistic updates to the mutations used by EditTagsModal and drop `reloadTags` and `loadingTags` props
 */

interface TagListSrpProps {
  showEditButton?: boolean;
  initialTags?: VehicleTag[];
  vehicleIsSold: boolean;
  vehicleId: string;
}

const TagListSrp = (props: TagListSrpProps) => {
  const { inExtensionCardMode } = useContext(VehicleCardContext);
  const { initialTags, vehicleId } = props;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [tagsAreDirty, setTagsAreDirty] = useState(false);
  const readTagsFromQuery = tagsAreDirty || initialTags === undefined;

  const tagsQuery = useVehicleTags(vehicleId, {
    enabled: readTagsFromQuery,
  });

  if (readTagsFromQuery && tagsQuery.isLoading) {
    return <LoadingIndicator size={30} />;
  }

  const tags = readTagsFromQuery ? tagsQuery.data?.data ?? [] : initialTags;

  const openModal = () => {
    if (!inExtensionCardMode) {
      setIsEditModalOpen(true);
    }
  };
  const closeModal = () => {
    setIsEditModalOpen(false);
    setTagsAreDirty(true);
  };

  return (
    <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
      {tags && tags.length === 0 && (
        <PermissionsGate permissions={[permissions.TAGS_UPDATE]}>
          {!inExtensionCardMode ? (
            <button
              type="button"
              className="TagListSrp-empty-container"
              onClick={openModal}
            >
              Add tags...
            </button>
          ) : (
            <div className="TagListSrp-empty-container-no-button" />
          )}
        </PermissionsGate>
      )}
      {tags && tags.length > 0 && (
        <TruncatedList
          className="TagListSrp"
          alwaysShowTruncator
          renderTruncator={({ hiddenItemsCount }) => (
            <div
              className="TagList-truncate-message"
              role="link"
              tabIndex={-1}
              onClick={openModal}
            >
              <div>{hiddenItemsCount > 0 && `+${hiddenItemsCount}`}</div>
              <PermissionsGate permissions={[permissions.TAGS_UPDATE]}>
                {!inExtensionCardMode && (
                  <button
                    type="button"
                    className="TagListSrp-icon-button"
                    onClick={openModal}
                  >
                    <Edit className="TagListSrp-icon" />
                  </button>
                )}
              </PermissionsGate>
            </div>
          )}
        >
          {tags?.map((tag: VehicleTag) => (
            <TagComponent key={`tag-${tag.tagId}`} tag={tag} />
          ))}
        </TruncatedList>
      )}
      {isEditModalOpen && (
        <EditTagsModal tags={tags} onClose={closeModal} vehicleId={vehicleId} />
      )}
    </PermissionsGate>
  );
};

export default TagListSrp;
