import { FC } from 'react';

import images from 'common/images';

import { IconProps } from './types';

const CarfaxLogo: FC<IconProps> = ({ className }) => {
  return (
    <img
      src={images.CarfaxLogo}
      className={className}
      width="40"
      height="30"
      alt="carfax-logo"
    />
  );
};

export default CarfaxLogo;
