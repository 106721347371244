import { useMutation, UseMutationResult } from 'react-query';

import { defaultMutationFn } from 'api/useAPI';
import { Invoice } from 'models/invoicing';

type UpdateInvoiceVariables = {
  invoiceId: string;
};

const usePayInvoiceMutation = () => {
  return useMutation((data: UpdateInvoiceVariables) => {
    return defaultMutationFn(
      `/vendor-invoice/${data.invoiceId}/statusPaid`,
      'PUT'
    ) as Promise<UseMutationResult<Invoice>>;
  });
};

export default usePayInvoiceMutation;
