import { strings } from 'common';
import { getFormattedDurationFromSeconds } from 'utils/time';

import LoadingIndicator from './LoadingIndicator';

interface EngagementActionsTableProps {
  isLoading: boolean;
  data?: Record<
    string,
    {
      displayLabel: string;
      count: number;
      durationSeconds: number;
    }
  >;
  durationLabel: string;
}

const renderDuration = (dur?: number) =>
  dur ? getFormattedDurationFromSeconds(dur, 3, true) : strings.EMPTY_VALUE;

const EngagementActionsTable: React.FC<EngagementActionsTableProps> = ({
  isLoading,
  data,
  durationLabel,
}) => (
  <div className="engage-table-custom">
    <div className="engage-header engage-row sub-row">
      <div className="cell">{strings.ENGAGE_HEATMAP_ITEM_TYPE}</div>
      <div className="cell">{strings.OPENS}</div>
      <div className="cell">{durationLabel}</div>
    </div>
    {isLoading || !data ? (
      <div style={{ height: 100 }}>
        <LoadingIndicator />
      </div>
    ) : (
      Object.keys(data).map((key) => (
        <div className="engage-row sub-row" key={`heatmap-data-${key}`}>
          <div className="cell">{data[key].displayLabel}</div>
          <div className="cell">{data[key].count}</div>
          <div className="cell">
            {renderDuration(data[key].durationSeconds)}
          </div>
        </div>
      ))
    )}
  </div>
);

export default EngagementActionsTable;
