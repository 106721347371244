import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import {
  useCreateVehicle,
  useDecodeVin,
  useGetVehicleColors,
  useUpdateVehicle,
  useVehicleList,
  useVehicleMakesByYear,
  useVehicleModelsByYearAndMake,
  useVehicleYears,
} from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import Alert from 'components/shared/Alert';
import CheckBox from 'components/shared/Checkbox';
import { Select, TextInput } from 'components/shared/Inputs';
import Modal from 'components/shared/Modal';
import { usePermissions } from 'hooks';
import { VehicleSummary } from 'models';
import { Currency } from 'models/money';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { replaceUrlSearchParams } from 'navigation/util/ParamHelpers';
import { cleanNumber, parseToFloat } from 'utils/formatter';

import './AddVehicleModal.scss';

export interface AddVehicleModalProps {
  onClose: any;
  setSearchInputValue?: (searchString: string) => void;
  vehicle?: VehicleSummary;
}

function formatNumber(numberString: string) {
  if (numberString === '') return numberString;

  return parseInt(numberString, 10).toLocaleString();
}

function formatCurrency(numberString: string) {
  if (numberString === '') return numberString;

  const formattedNumberString = formatNumber(numberString);

  return `$ ${formattedNumberString}`;
}

const AddVehicleModal: React.FC<AddVehicleModalProps> = ({
  onClose,
  setSearchInputValue,
  vehicle,
}) => {
  const { hasPermission } = usePermissions();
  const hasReconVdpCreate = hasPermission(permissions.RECON_VDP_CREATE);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [appraisedValue, setAppraisedValue] = useState('');
  const [color, setColor] = useState('');
  const [enableVinDecode, setEnableVinDecode] = useState(false);
  const [estimateReconCost, setEstimateReconCost] = useState('');
  const [existingVehicleId, setExistingVehicleId] = useState('');
  const [isInRecon, setIsInRecon] = useState(hasReconVdpCreate);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [make, setMake] = useState({ id: '', name: '' });
  const [mileage, setMileage] = useState('');
  const [model, setModel] = useState({ id: '', name: '' });
  const [stockNumber, setStockNumber] = useState('');
  const [vin, setVin] = useState('');
  const [year, setYear] = useState<number | undefined>();
  const { data: vehicleListData, isLoading: isLoadingVehicleList } =
    useVehicleList();
  const {
    data: createVehicleResponseData,
    error: errorCreateVehicle,
    isError: isErrorCreateVehicle,
    isLoading: isLoadingCreateVehicle,
    mutateAsync,
  } = useCreateVehicle(isInRecon);

  const {
    data: editVehicleResponseData,
    isError: isErrorEditVehicle,
    error: errorEditVehicle,
    isLoading: isLoadingEditVehicle,
    mutateAsync: updateMutateAsync,
  } = useUpdateVehicle(vehicle?.vehicleCard?.id);

  const {
    data: vinDecodeData,
    isLoading: isLoadingVinDecode,
    isError: isErrorVinDecode,
  } = useDecodeVin(vin, enableVinDecode);
  const { data: yearOptions = [], isError: isErrorYearOptions } =
    useVehicleYears();
  const { data: makeOptions = [], isError: isErrorMakeOptions } =
    useVehicleMakesByYear(year);
  const { data: modelOptions = [], isError: isErrorModelOptions } =
    useVehicleModelsByYearAndMake(year, make.id);
  const { data: colorOptions = [], isError: isErrorColorOptions } =
    useGetVehicleColors();

  useEffect(() => {
    if (
      !isLoadingEditVehicle &&
      !isErrorEditVehicle &&
      editVehicleResponseData
    ) {
      onClose();
    } else if (!isLoadingEditVehicle && isErrorEditVehicle) {
      console.log(errorEditVehicle);
      setApiErrorMessage(strings.API_MESSAGE);
    }
  }, [
    isLoadingEditVehicle,
    editVehicleResponseData,
    isErrorEditVehicle,
    errorEditVehicle,
    onClose,
    stockNumber,
    vin,
  ]);

  useEffect(() => {
    if (vehicle?.vehicleCard === undefined) {
      return;
    }
    const card = vehicle.vehicleCard;
    setVin(card.vin);
    setStockNumber(card.stockNumber);
    setModel({ id: '', name: card.model });
    setYear(card.year);
    setIsInRecon(card.inRecon);
    setMileage(card.mileage?.toString() ?? '');
    setColor(card.color ?? '');
    setAppraisedValue(card.metaData?.appraisedValue ?? '');
    setEstimateReconCost(card.metaData?.reconditioningCost ?? '');
  }, [vehicle?.vehicleCard]);

  useEffect(() => {
    if (
      !isLoadingVehicleList &&
      vehicleListData?.pages?.[0]?.meta?.show_onboarding_banner
    ) {
      setIsOnboarding(true);
    }
  }, [vehicleListData, isLoadingVehicleList]);

  useEffect(() => {
    if (
      !isLoadingCreateVehicle &&
      !isErrorCreateVehicle &&
      createVehicleResponseData
    ) {
      replaceUrlSearchParams({ s: vin });
      setSearchInputValue?.(vin);
      onClose();
    } else if (
      !isLoadingCreateVehicle &&
      isErrorCreateVehicle &&
      typeof errorCreateVehicle?.message === 'string'
    ) {
      if (errorCreateVehicle?.message.includes(`Stock number ${stockNumber}`)) {
        const regexp = /\(Vehicle Id ([A-Za-z0-9]+)\)/;
        const vehicleId = errorCreateVehicle.message.match(regexp)?.[1];
        if (vehicleId) {
          setExistingVehicleId(vehicleId);
        }
        setApiErrorMessage(strings.EXISTING_STOCK_ERROR(stockNumber));
      } else {
        setApiErrorMessage(strings.API_MESSAGE);
      }
    }
  }, [
    createVehicleResponseData,
    errorCreateVehicle,
    isErrorCreateVehicle,
    isLoadingCreateVehicle,
    onClose,
    setSearchInputValue,
    stockNumber,
    vin,
  ]);

  useEffect(() => {
    if (vin?.length === 17) {
      setEnableVinDecode(true);
    }
  }, [vin]);

  useEffect(() => {
    if (!isLoadingVinDecode && (isErrorVinDecode || !vinDecodeData)) {
      setEnableVinDecode(false);
    } else if (!isLoadingVinDecode && vinDecodeData) {
      setColor('');
      setMake(vinDecodeData.make);
      setModel(vinDecodeData.model);
      setYear(vinDecodeData.year);
    }
  }, [isErrorVinDecode, isLoadingVinDecode, vinDecodeData]);

  const onSubmit = () => {
    const body = {
      appraisedValue:
        appraisedValue === ''
          ? null
          : {
              amount: parseToFloat(appraisedValue),
              currency: Currency.USD,
            },
      color: color || null,
      make: make.name || null,
      mileage: parseInt(mileage.toString(), 10) || null,
      model: model.name || null,
      reconCost:
        estimateReconCost === ''
          ? null
          : {
              amount: parseToFloat(estimateReconCost),
              currency: Currency.USD,
            },
      stockNumber: stockNumber || null,
      vin: vin,
      year: year ? parseInt(year.toString(), 10) : null,
    };
    return vehicle === undefined ? mutateAsync(body) : updateMutateAsync(body);
  };

  const viewExistingVehicle = async () => {
    if (existingVehicleId) {
      navigate(vehicleDetailsBuilder(existingVehicleId, true));
      onClose();
    }
  };

  const renderAddVehicleForm = () => (
    <Container>
      <Row>
        <Col md={6}>
          <TextInput
            className="add-vehicle-stock-number"
            containerClassName="border-primary"
            placeholderClassName="text-primary"
            value={stockNumber}
            onChange={(changeEvent) => setStockNumber(changeEvent.target.value)}
            placeholder={strings.STOCK_NUMBER}
            disabled={isLoadingCreateVehicle}
          />
        </Col>
        <Col md={6}>
          <TextInput
            className="add-vehicle-vin"
            containerClassName="border-primary"
            placeholderClassName="text-primary"
            value={vin}
            onChange={(changeEvent) => setVin(changeEvent.target.value)}
            placeholder={strings.VIN}
            showLoadingIndicator={isLoadingVinDecode}
            disabled={isLoadingCreateVehicle}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {enableVinDecode ? (
            <TextInput
              className="add-vehicle-year"
              containerClassName="border-primary"
              placeholderClassName="text-primary"
              value={year ? year.toString() : ''}
              showLoadingIndicator={isLoadingVinDecode}
              disabled
            />
          ) : (
            <Select
              className="border-light add-vehicle-year"
              disabled={enableVinDecode || isLoadingCreateVehicle}
              placeholder={strings.YEAR}
              options={yearOptions.map((y) => {
                return { label: y.toString(), value: y.toString() };
              })}
              onChange={(selectedOption) =>
                setYear(parseInt(selectedOption.label, 10))
              }
              value={
                year
                  ? { label: year.toString(), value: year.toString() }
                  : undefined
              }
              isSearchable
            />
          )}
        </Col>
        <Col md={6}>
          {enableVinDecode ? (
            <TextInput
              className="add-vehicle-make"
              containerClassName="border-primary"
              placeholderClassName="text-primary"
              value={make ? make.name.toString() : ''}
              showLoadingIndicator={isLoadingVinDecode}
              disabled
            />
          ) : (
            <Select
              className="add-vehicle-make"
              disabled={
                enableVinDecode ||
                makeOptions.length === 0 ||
                isLoadingCreateVehicle
              }
              placeholder={strings.MAKE}
              options={makeOptions.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onChange={(selectedOption) =>
                setMake({
                  id: selectedOption.value,
                  name: selectedOption.label,
                })
              }
              value={make ? { label: make.name, value: make.id } : undefined}
              isSearchable
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {enableVinDecode ? (
            <TextInput
              className="add-vehicle-model"
              containerClassName="border-primary"
              placeholderClassName="text-primary"
              value={model ? model.name.toString() : ''}
              showLoadingIndicator={isLoadingVinDecode}
              disabled
            />
          ) : (
            <Select
              className="add-vehicle-model"
              disabled={
                enableVinDecode ||
                modelOptions.length === 0 ||
                isLoadingCreateVehicle
              }
              placeholder={strings.MODEL}
              options={modelOptions.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onChange={(selectedOption) =>
                setModel({
                  id: selectedOption.value,
                  name: selectedOption.label,
                })
              }
              value={model ? { label: model.name, value: model.id } : undefined}
              isSearchable
            />
          )}
        </Col>
        <Col md={6}>
          <Select
            className="add-vehicle-color"
            disabled={
              colorOptions.length === 0 ||
              isLoadingCreateVehicle ||
              isLoadingEditVehicle
            }
            placeholder={strings.COLOR}
            options={colorOptions.map((c) => ({
              label: c,
              value: c,
            }))}
            defaultValue={
              vehicle?.vehicleCard.color
                ? {
                    label: vehicle?.vehicleCard.color,
                    value: vehicle?.vehicleCard.color,
                  }
                : undefined
            }
            onChange={(selectedOption) => setColor(selectedOption.value)}
            value={color ? { label: color, value: color } : undefined}
            isSearchable
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TextInput
            className="add-vehicle-mileage"
            containerClassName="border-primary"
            placeholderClassName="text-primary"
            value={formatNumber(mileage)}
            onChange={(changeEvent) =>
              setMileage(cleanNumber(changeEvent.target.value))
            }
            placeholder={strings.MILEAGE}
            disabled={isLoadingCreateVehicle}
          />
        </Col>
        <Col md={6}>
          <TextInput
            className="add-vehicle-appraised-value"
            containerClassName="border-primary"
            placeholderClassName="text-primary"
            value={formatCurrency(appraisedValue)}
            onChange={(changeEvent) =>
              setAppraisedValue(cleanNumber(changeEvent.target.value))
            }
            placeholder={strings.APPRAISED_VALUE}
            disabled={isLoadingCreateVehicle}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {hasReconVdpCreate &&
            (vehicle === undefined || vehicle?.vehicleCard?.inRecon) && (
              <TextInput
                className="add-vehicle-recon-cost"
                containerClassName="border-primary"
                placeholderClassName="text-primary"
                value={formatCurrency(estimateReconCost)}
                onChange={(changeEvent) =>
                  setEstimateReconCost(cleanNumber(changeEvent.target.value))
                }
                placeholder={strings.RECON_COST}
                disabled={isLoadingCreateVehicle}
              />
            )}
        </Col>
        <Col md={6}>
          {hasReconVdpCreate && vehicle === undefined && (
            <div className="AddVehicleModal-body-checkbox">
              <div className="AddVehicleModal-body-checkbox-text">
                Is In Recon
              </div>
              <CheckBox
                checked={isInRecon}
                className="add-vehicle-is-in-recon AddVehicleModal-body-checkbox-box"
                onClick={() => setIsInRecon(!isInRecon)}
                disabled={isLoadingCreateVehicle || isOnboarding}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );

  return (
    <Modal
      title={(vehicle !== undefined ? 'Edit' : 'Add') + ' Vehicle'}
      open
      onClose={() => {
        onClose();
      }}
      primaryButtonLoading={isLoadingCreateVehicle || isLoadingEditVehicle}
      primaryButtonDisabled={
        isLoadingCreateVehicle ||
        !vin ||
        !(vin?.length === 17) ||
        isLoadingVinDecode ||
        isLoadingEditVehicle
      }
      onPrimaryButtonClick={() => onSubmit()}
      primaryButtonLabel={strings.SAVE}
      secondaryButtonLabel={strings.CANCEL}
      onSecondaryButtonClick={() => {
        onClose();
      }}
      className="modal-90w AddVehicleModal"
      backdrop="static"
    >
      <div className="AddVehicleModal-body">
        <Alert
          open={
            isErrorCreateVehicle ||
            isErrorYearOptions ||
            isErrorMakeOptions ||
            isErrorModelOptions ||
            isErrorColorOptions
          }
          contentProps={{
            message: (
              <div>
                {apiErrorMessage}
                {existingVehicleId && (
                  <button
                    className="add-vehicle-view-existing AddVehicleModal-body-view-button"
                    type="button"
                    onClick={() => viewExistingVehicle()}
                  >
                    {strings.VIEW_VEHICLE}
                  </button>
                )}
              </div>
            ),
            variant: 'error',
            onClose: () => setApiErrorMessage(''),
          }}
        />
        {renderAddVehicleForm()}
      </div>
    </Modal>
  );
};

export default AddVehicleModal;
