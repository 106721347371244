const copyStringToClipboard = (content: string) => {
  // If no clipboard is available, the app is probably running
  // on http protocol instead of https.
  if (content && navigator?.clipboard) {
    navigator.clipboard.writeText(content).catch(() => {
      console.error('unable to copy');
    });
  }
};

export default copyStringToClipboard;
