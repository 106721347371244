import React from 'react';

import ApiError from 'components/shared/ApiError';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import './ReportContainer.scss';

const ReportContainer: React.FC<{
  className?: string;
  error?: any;
  loading?: boolean;
}> = ({ children, className, error, loading }) => {
  if (error) {
    return <ApiError error={error} />;
  }

  if (loading) {
    return (
      <div className="ReportContainer-loadingContainer">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="ReportContainer">
      <div className="ReportContainer-panel-container">
        <div className={'ReportContainer-overview ' + className ?? ''}>
          {children}
        </div>
      </div>
    </div>
  );
};
export default ReportContainer;
