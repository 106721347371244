import { ContentCopy } from 'components/shared/icons';
import { copyToClipboard } from 'utils/vehicle';

import './TextCopy.scss';

interface TextCopyProps {
  value: string;
  displayValue?: string;
}
const TextCopy = ({ value, displayValue }: TextCopyProps) => {
  const text = displayValue || value;

  const onClick = (event: any) => {
    event?.stopPropagation?.();
    copyToClipboard(value);
  };

  return (
    <div className="TextCopy" onClick={onClick}>
      <div className="TextCopy-value">{text}</div>
      {text && (
        <div className="TextCopy-icon">
          <ContentCopy />
        </div>
      )}
    </div>
  );
};

export default TextCopy;
