import IconButton from '@material-ui/core/IconButton';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import DownloadIcon from '@material-ui/icons/GetApp';
import React, { useEffect, useRef } from 'react';

import LoadingIndicator from 'components/shared/LoadingIndicator';

import './MultiDownloadMenu.scss';

export var XLSIcon = () => {
  return <div className="XLSIcon">X</div>;
};

interface MultiDownloadMenuProps {
  showMenu: boolean;
  isFetchingDownload: boolean;
  setShow: (value: boolean) => void;
  onClick: () => void;
}

interface DownloadOptionProps {
  onClick: () => void;
  title: string;
  Icon?: React.ComponentType<SvgIconProps>;
  divider?: boolean;
}

var Divider = () => {
  return <div className="MultiDownloadMenu-divider" />;
};

export const DownloadOption: React.FC<DownloadOptionProps> = ({
  onClick,
  title,
  Icon,
  divider,
}) => (
  <>
    <button
      className="MultiDownloadMenu-option"
      type="button"
      onClick={onClick}
    >
      {Icon && <Icon className="MultiDownloadMenu-option-icon" />}
      <div className="MultiDownloadMenu-option-text">{title}</div>
    </button>
    {divider && <Divider />}
  </>
);

const MultiDownloadMenu: React.FC<
  React.PropsWithChildren<MultiDownloadMenuProps>
> = ({ children, showMenu, isFetchingDownload, setShow, onClick }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);
  const handleClickOutside = (event: any) => {
    if (iconRef.current && iconRef.current.contains(event.target)) {
      // let button handle
      return;
    }
    if (
      showMenu &&
      containerRef.current &&
      !containerRef.current?.contains(event.target)
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div>
      <IconButton
        ref={iconRef}
        className="MultiDownloadMenu-download-button"
        size="small"
        onClick={onClick}
      >
        <DownloadIcon />
      </IconButton>
      {showMenu && (
        <div className="MultiDownloadMenu-relative">
          <div ref={containerRef} className="MultiDownloadMenu">
            {isFetchingDownload ? (
              <LoadingIndicator size={25} />
            ) : (
              <>{children}</>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiDownloadMenu;
