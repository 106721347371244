export interface VehicleAttachment {
  contentType: string;
  createdDate?: string;
  id?: string;
  name: string;
  path: string;
  uri: string;
}

export const emptyAttachment: VehicleAttachment = {
  id: '',
  name: '',
  path: '',
  uri: '',
  contentType: '',
  createdDate: '',
};
