import { merge } from 'lodash';
import { useMutation } from 'react-query';

import { defaultMutationFn, getAPIHeaders } from 'api';

export function useResetPassword({
  passwordToken,
  newPassword,
}: {
  passwordToken: string;
  newPassword: string;
}) {
  return useMutation(async () => {
    const headers = await getAPIHeaders('POST');
    const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
      headers?.options,
      {
        body: JSON.stringify({
          passwordToken,
          newPassword,
        }),
      }
    );

    const response = await fetch(
      `${headers.host}/authenticate/resetPassword`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
  });
}

export function useChangePassword({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) {
  return useMutation({
    mutationFn: () => {
      return defaultMutationFn('/authenticate/resetPassword', 'POST', {
        oldPassword,
        newPassword,
      });
    },
  });
}
