import React from 'react';
import { Image, Navbar } from 'react-bootstrap';

import images from 'common/images';

import './ExtensionHeader.scss';

const ExtensionHeader: React.FC = () => (
  <div className="ExtensionHeader">
    <Navbar
      fixed="top"
      className="ExtensionHeader-Navbar flex-column align-center justify-center"
      variant="dark"
    >
      <Navbar.Brand>
        <Image fluid src={images.ReconLogo} />
      </Navbar.Brand>
      <div className="flex-grow" />
    </Navbar>
  </div>
);

export default ExtensionHeader;
