import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Modal } from 'react-bootstrap';

interface CarfaxData {
  scriptURL: string;
  htmlContent: string;
}
const Context = React.createContext<(data: CarfaxData) => void>((x) => {});

export const useShowCarfaxModal = () => {
  const realSetter = useContext(Context);
  return (value: any) => {
    if (
      typeof value === 'object' &&
      value !== null &&
      'data' in value &&
      typeof value.data === 'object' &&
      typeof value.data.scriptURL === 'string' &&
      typeof value.data.htmlContent === 'string'
    ) {
      realSetter(value.data);
    }
  };
};

const CarfaxModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [carfaxData, setCarfaxData] = useState<CarfaxData | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (carfaxData?.scriptURL) {
      const script = document.createElement('script');
      script.src = carfaxData?.scriptURL;
      document.body.append(script);
      return () => {
        script.remove();
      };
    }
  }, [carfaxData?.scriptURL, modalOpen]);
  const setCarfaxDivRef = (element: HTMLDivElement | null) => {
    if (element === null) return;
    const inserted = element.querySelector('#snapshot-parent');
    if (inserted instanceof HTMLElement) {
      inserted.style.width = 'auto';
    }
  };
  return (
    <>
      <Context.Provider
        value={(data) => {
          setCarfaxData(data);
          setModalOpen(true);
        }}
      >
        {children}
      </Context.Provider>
      <Modal centered show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Carfax</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{ __html: carfaxData?.htmlContent ?? '' }}
            ref={setCarfaxDivRef}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CarfaxModalProvider;
