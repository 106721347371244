import React from 'react';
import { Spinner } from 'react-bootstrap';

import strings from 'common/strings';

const LoadingSpinner: React.FC = () => (
  <div
    style={{
      overflow: 'hidden',
    }}
    className="w-100 mt-4 text-center full-height center-content"
  >
    <Spinner
      animation="border"
      role="status"
      className="icon-large text-danger"
    >
      <span className="sr-only">{strings.LOADING}</span>
    </Spinner>
  </div>
);

export default LoadingSpinner;
