import { RefObject, useRef, useState } from 'react';

import { useAddNote } from 'api';
import { permissions } from 'common';
import MentionableInput from 'components/pages/Notes/MentionableInput';
import NoteAttachementInput from 'components/pages/Notes/NoteAttachmentInput';
import SendIconButton from 'components/pages/Notes/SendIconButton';
import Alert from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PermissionsGate from 'components/shared/PermissionsGate';
import { useAttachments } from 'hooks/useAttachments';

import './VehicleCardNoteInput.scss';

interface VehicleCardNotesProps {
  vehicleId: string;
}
const VehicleCardNoteInput = ({ vehicleId }: VehicleCardNotesProps) => {
  const { isLoading: isLoadingAddNote, addNoteAsync: addNote } = useAddNote();

  const [shouldFocus, setShouldFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [shouldClearContent, setShouldClearContent] = useState(false);
  const {
    createNoteAndUploadAttachments,
    isLoading: isLoadingCreateNoteAndUploadAttachments,
  } = useAttachments();
  const attachmentInputRef: RefObject<HTMLInputElement> = useRef<any>();

  const handleCloseError = () => setErrorMessage('');

  const handleNoteSubmit = async () => {
    setShouldClearContent(true);
    setShouldFocus(false);
    await addNote({ vehicleId, note: message }).catch((error) =>
      setErrorMessage(error)
    );
    setShouldClearContent(false);
    setShouldFocus(true);
  };

  const handleAttachmentsSubmit = async (event: any) => {
    const target = event.target as HTMLInputElement;
    const files = target.files ? Array.from(target.files) : [];
    event.target.value = null;

    setShouldClearContent(true);
    setShouldFocus(false);
    await createNoteAndUploadAttachments(vehicleId, files, message).catch(
      (error) => setErrorMessage(error)
    );
    setShouldClearContent(false);
    setShouldFocus(true);
  };

  const isLoading = isLoadingAddNote || isLoadingCreateNoteAndUploadAttachments;
  return (
    <div className="VehicleCardNotes-input">
      <PermissionsGate
        permissions={[permissions.INVENTORY_VDP_NOTES_ATTACHMENT_CREATE]}
      >
        {isLoading ? (
          <LoadingIndicator size={24} className={'VehicleCardNotes-loading'} />
        ) : (
          <NoteAttachementInput
            onFileSelected={handleAttachmentsSubmit}
            disabled={isLoading}
            attachmentInputRef={attachmentInputRef}
            id={vehicleId}
          />
        )}
      </PermissionsGate>
      <PermissionsGate permissions={[permissions.INVENTORY_VDP_NOTES_CREATE]}>
        <MentionableInput
          disabled={isLoading}
          shouldFocus={shouldFocus}
          onFocus={() => setShouldFocus(true)}
          onBlur={() => setShouldFocus(false)}
          existingContent={message}
          onChange={setMessage}
          submitAction={handleNoteSubmit}
          shouldClearContent={shouldClearContent}
        />
        <SendIconButton
          disabled={!message || isLoading}
          onClick={handleNoteSubmit}
        />
      </PermissionsGate>
      <Alert
        open={!!errorMessage}
        contentProps={{
          variant: 'error',
          onClose: handleCloseError,
          message: errorMessage,
        }}
      />
    </div>
  );
};

export default VehicleCardNoteInput;
