import { Build } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import { strings } from 'common';
import Chevron from 'components/shared/icons/Chevron';
import { VehicleRepairOrder, VehicleRepairOrderLine } from 'models';
import { currencyFormatter } from 'utils/formatter';
import { getCalendarDateTimeStringFromDate } from 'utils/time';

import RepairOrderLine from '../RepairOrderLine';

import './RepairOrderItem.scss';

interface StatusProps {
  status: string;
  roCloseDate: string;
}
const Status = ({ status, roCloseDate }: StatusProps) => {
  if (status.toLocaleLowerCase() === 'open') {
    return <div className="RepairOrderItem-status-open">{status}</div>;
  }
  return (
    <div className="RepairOrderItem-status">
      {getCalendarDateTimeStringFromDate(new Date(roCloseDate))}
    </div>
  );
};

const Price = ({ label, value }: { label: string; value: number }) => {
  return (
    <div className="RepairOrderItem-price">
      <div className="RepairOrderItem-label">{label}</div>
      <div className="RepairOrderItem-value">{currencyFormatter(value)}</div>
    </div>
  );
};

interface RepairOrderItemProps {
  repairOrder: VehicleRepairOrder;
  responsive: boolean;
}

const RepairOrderItem: React.FC<
  React.PropsWithChildren<RepairOrderItemProps>
> = ({ repairOrder }) => {
  const [isOpen, setToggle] = useState(false);
  const toggleIcon = () => setToggle(!isOpen);
  const {
    dmsRoNumber,
    roCloseDate,
    status,
    laborTotal,
    partsTotal,
    miscTotal,
    total,
    repairOrderLines,
  } = repairOrder;

  const lines = useMemo(
    () =>
      repairOrderLines.map((repairOrderLine: VehicleRepairOrderLine) => (
        <RepairOrderLine
          key={`repair-order-line-${repairOrderLine.id}`}
          repairOrderLine={repairOrderLine}
        />
      )),
    [repairOrderLines]
  );

  return (
    <Accordion>
      <Card className="RepairOrderItem-card">
        <Accordion.Toggle as="div" eventKey={dmsRoNumber} onClick={toggleIcon}>
          <div className="RepairOrderItem-header">
            <div className="RepairOrderItem-icon">
              <Build />
            </div>

            <div className="RepairOrderItem-details">
              <div className="RepairOrderItem-label">
                {strings.REPAIR_ORDER}
              </div>
              <div className="RepairOrderItem-header-value">
                <div className="RepairOrderItem-ro-number">{dmsRoNumber}</div>
                <Status status={status} roCloseDate={roCloseDate} />
              </div>
            </div>

            <div className="RepairOrderItem-prices">
              <Price label={strings.LABOR} value={laborTotal} />
              <Price label={strings.MISC} value={miscTotal} />
              <Price label={strings.PARTS} value={partsTotal} />
              <Price label={strings.TOTAL} value={total} />
            </div>

            <div className="RepairOrderItem-icon">
              <Chevron isOpen={isOpen} />
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={dmsRoNumber}>
          <Card.Body className="RepairOrderItem-body">{lines}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default RepairOrderItem;
