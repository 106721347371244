import { ArrowDropDown } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { DropdownButton } from 'react-bootstrap';

import Checkbox from 'components/shared/Checkbox';

import { generateTestId, testIds } from '../../../common/testIds';

import './MultipleSearchSelection.scss';

export interface Option {
  id: string;
  value: string;
  checked: boolean;
}

interface MultipleSearchSelectionProps {
  options: Option[];
  filterId: string;
  onSelect: (optionId: string) => void;
  containerClassName?: string;
  selectedOptionClassName?: string;
  allOptionsLabel?: string;
}

var MultipleSearchSelection = ({
  options,
  filterId,
  onSelect,
  containerClassName,
  selectedOptionClassName,
  allOptionsLabel,
}: MultipleSearchSelectionProps) => {
  const [optionList, setOptionList] = useState(options);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value.trim().length) {
      const result = options.filter((option: Option) =>
        option.value.toLowerCase().includes(value)
      );
      setOptionList(result);
    } else {
      setOptionList(options);
    }
  }, [options, value]);

  const onChange = (event?: React.FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget.value?.toLowerCase();

    if (value) {
      const result = options.filter((option: Option) =>
        option.value.toLowerCase().includes(value)
      );
      setOptionList(result);
      setValue(value);
      return;
    }

    setOptionList(options);
    setValue('');
  };

  const memoizedValue = useMemo(() => {
    const count = optionList.filter((option) => option.checked === true);
    let label = allOptionsLabel ?? 'All';
    let selectedClassName: string | undefined = selectedOptionClassName;

    if (count.length > 1) label = `${count.length} selected`;
    else if (count[0]?.value) label = `${count[0]?.value}`;

    if (label.toLowerCase() !== 'all') selectedClassName = undefined;

    return { selectedClassName, label };
  }, [optionList, selectedOptionClassName, allOptionsLabel]);

  return (
    <div className={`Dropdown ml-0 mr-0 ${containerClassName}`}>
      <DropdownButton
        id="multiple-search-selection"
        size="sm"
        variant="light"
        title={
          <>
            <div
              className={`DropdownButton-selectedOption ${memoizedValue.selectedClassName}`}
              data-vas-testing={generateTestId(testIds.DROPDOWN_CONTAINER, {
                optionContainerId: `${filterId}`,
              })}
            >
              {memoizedValue.label}
            </div>
            <ArrowDropDown className="Dropdown-expand-icon" />
          </>
        }
        className={`Dropdown-btn ${containerClassName}`}
      >
        <input
          type="text"
          placeholder="Find an option"
          value={value}
          className="MultipleSearchSelection-input"
          onChange={onChange}
        />
        <div className="MultipleSearchSelection-container">
          {optionList.map((option) => {
            if (
              option.value.toLowerCase() === 'all' ||
              option.value.toLowerCase() === allOptionsLabel
            )
              return undefined;

            return (
              <div
                key={option.id}
                className="MultipleSearchSelection-option"
                data-vas-testing={generateTestId(
                  testIds.FILTER_MULTI_SELECT_OPTION,
                  {
                    filterOptionId: `${filterId}:${option.id}`,
                  }
                )}
                role="none"
                onClick={() => onSelect(option.id)}
              >
                <Checkbox
                  checked={option.checked}
                  className="MultipleSearchSelection-option-checkbox"
                />
                <span
                  className={`MultipleSearchSelection-option-title ${
                    option.checked ? 'bold' : ''
                  }`}
                >
                  {option.value}
                </span>
              </div>
            );
          })}
        </div>
      </DropdownButton>
    </div>
  );
};

export default MultipleSearchSelection;
