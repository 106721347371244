import { Delete, Edit } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';

import { useUpdateFolioDocumentUrl } from 'api';
import { extractBlob, fetchMedia } from 'api/engage/fetchMedia';
import strings from 'common/strings';
import Button from 'components/shared/Button';
import Radio from 'components/shared/Inputs/Radio';
import Input from 'components/shared/Inputs/TextInput';
import Modal from 'components/shared/Modal';
import PortalAlert from 'components/shared/PortalAlert';
import { useFileSelector, useWindowSize } from 'hooks';
import { VelocityEngageFolioFolderDocument } from 'models';
import { UploadTarget, UploadType } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';

import { useShowCarfaxModal } from '../CarfaxModalProvider';
import EngageMediaPlaceholder from '../EngageMediaPlaceholder';

import './EngageMediaItem.scss';

/**
 *  Simple component that uses an anchor when it is required to open in a new tab, otherwise using fetch.
 */
const AnchorOrDiv = ({
  children,
  media,
  onMediaClick,
}: React.PropsWithChildren<{
  media: VelocityEngageFolioFolderDocument;
  onMediaClick: (mediaUrl: string) => Promise<void>;
}>) => {
  return isOpenNewTab(media) ? (
    <a href={media.url} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <div
      role="none"
      onClick={() => {
        if (isMediaAvailable(media)) {
          onMediaClick(media.url);
        }
      }}
    >
      {children}
    </div>
  );
};

interface EngageMediaItemComponentProps {
  folderId: string;
  vehicleId: string;
  media: VelocityEngageFolioFolderDocument;
  onMediaClick: (mediaUrl: string) => Promise<void>;
  isEditModalOpen: boolean;
  setIsEditModalOpen: (isEditModalOpen: boolean) => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (isEditModalOpen: boolean) => void;
}

function isMediaAvailable(media: VelocityEngageFolioFolderDocument) {
  return (
    media.url ||
    (media.type === 'DYNAMIC' &&
      ['SNAPCELL', 'AUTOCHECK', 'CARFAX'].includes(media.name))
  );
}

function isOpenNewTab(mediaItem: VelocityEngageFolioFolderDocument) {
  if (!isMediaAvailable(mediaItem)) {
    return false;
  }
  if (mediaItem.isInventoryOverride) {
    return true;
  }
  if (mediaItem.name === 'WINDOW_STICKER') {
    return (
      mediaItem?.url?.trim().length > 0 &&
      mediaItem.url.includes('https://windowsticker')
    );
  }
  if (mediaItem.name === 'CARFAX') {
    return false;
  }
  return true;
}

const EngageMediaItemOverlay: React.FC<EngageMediaItemComponentProps> = ({
  folderId,
  vehicleId,
  media,
  onMediaClick,
  isEditModalOpen,
  setIsEditModalOpen,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
}) => {
  /**
   * Inner element that is put inside a div or an anchor.
   */
  const EngageMediaInnerComponent: React.FC<{
    media: VelocityEngageFolioFolderDocument;
    folderId: string;
    vehicleId: string;
  }> = ({ media, folderId, vehicleId }) => {
    return isMediaAvailable(media) ? (
      <>
        <img
          className="EngageMediaItem-info-image"
          src={media.thumbnailUrl}
          alt="car"
        />
        <div className="EngageMediaItem-info-image-overlay" />
        <span className="EngageMediaItem-info-title">{media.displayLabel}</span>
      </>
    ) : (
      <EngageMediaPlaceholder
        title={media.displayLabel}
        onClick={() => setIsEditModalOpen(true)}
      />
    );
  };

  return (
    <div className="EngageMediaItem item">
      <div className="item-content">
        <div>
          <div className="EngageMediaItem-info" role="none">
            <AnchorOrDiv media={media} onMediaClick={onMediaClick}>
              <EngageMediaInnerComponent
                media={media}
                folderId={folderId}
                vehicleId={vehicleId}
              />
            </AnchorOrDiv>
          </div>
        </div>
        <div className="EngageMediaItem-icons">
          {media.isInventoryOverride && (
            <Delete
              className="EngageMediaItem-icon"
              onClick={() => {
                if (isEditModalOpen) {
                  setIsEditModalOpen(false);
                }
                setIsDeleteModalOpen(!isDeleteModalOpen);
              }}
            />
          )}
          <Edit
            className="EngageMediaItem-icon"
            onClick={() => {
              if (isDeleteModalOpen) {
                setIsDeleteModalOpen(false);
              }
              setIsEditModalOpen(!isEditModalOpen);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const EngageMediaItemHorizontal: React.FC<EngageMediaItemComponentProps> = ({
  folderId,
  vehicleId,
  media,
  onMediaClick,
  isEditModalOpen,
  setIsEditModalOpen,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
}) => {
  const { displayLabel } = media;
  /**
   * Inner element that is put inside a div or an anchor.
   */
  const EngageMediaInnerComponent: React.FC<{
    media: VelocityEngageFolioFolderDocument;
    folderId: string;
    vehicleId: string;
  }> = ({ media, folderId, vehicleId }) => {
    return media.url || media.type === 'DYNAMIC' ? (
      <img
        className="EngageMediaItemHorizontal-image"
        src={media.thumbnailUrl}
        alt="car"
      />
    ) : (
      <EngageMediaPlaceholder
        onClick={() => setIsEditModalOpen(true)}
        title={media.displayLabel}
      />
    );
  };

  return (
    <div className="item EngageMediaItemHorizontal-margin">
      <div className="item-content">
        <div className="EngageMediaItemHorizontal">
          <AnchorOrDiv media={media} onMediaClick={onMediaClick}>
            <EngageMediaInnerComponent
              media={media}
              folderId={folderId}
              vehicleId={vehicleId}
            />
          </AnchorOrDiv>
          <div
            className={`EngageMediaItemHorizontal-info${
              media.url ? '' : ' EngageMediaItemHorizontal-info-padding'
            }`}
          >
            <span className="EngageMediaItemHorizontal-title">
              {displayLabel}
            </span>
            <div className="EngageMediaItemHorizontal-icons">
              <>
                {media.type !== 'DYNAMIC' && (
                  <>
                    {media.isInventoryOverride && (
                      <Delete
                        className="EngageMediaItemHorizontal-icon"
                        onClick={() => {
                          if (isEditModalOpen) {
                            setIsEditModalOpen(false);
                          }
                          setIsDeleteModalOpen(!isDeleteModalOpen);
                        }}
                      />
                    )}
                    <Edit
                      className="EngageMediaItemHorizontal-icon"
                      onClick={() => {
                        if (isDeleteModalOpen) {
                          setIsDeleteModalOpen(false);
                        }
                        setIsEditModalOpen(!isEditModalOpen);
                      }}
                    />
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EngageMediaItemProps {
  media: VelocityEngageFolioFolderDocument;
  onDelete: (document: VelocityEngageFolioFolderDocument) => void;
  onEdit: (vehicleId: string, documentId: string, url: string) => Promise<void>;
  folderId: string;
  vehicleId: string;
  setErrorMessage: (errorMessage: string) => void;
}

const EngageMediaItem: React.FC<EngageMediaItemProps> = ({
  media,
  onDelete,
  onEdit,
  folderId,
  vehicleId,
  setErrorMessage,
}) => {
  const windowSize = useWindowSize();
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(media.url ? false : true);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [newDocumentUrl, setNewDocumentUrl] = useState(
    media.url ? media.url : ''
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const showCarfaxModal = useShowCarfaxModal();
  const { mutateAsync: updateFolioDocumentUrl } = useUpdateFolioDocumentUrl();
  const uploadFiles = useUploadStore((uploadStore) => uploadStore.uploadFiles);

  const onMediaClick = async (mediaUrl: string) => {
    if (mediaUrl) {
      setInfo(strings.ENGAGE_MEDIA_FETCHING);
      await fetchMedia(mediaUrl, (value: string) => {
        setInfo(value);
        return value;
      }).then(async (res) => {
        if (res.status === 200) {
          setInfo('');
          if (media.name === 'CARFAX') {
            showCarfaxModal(await res.json());
          } else {
            extractBlob(res);
          }
        } else {
          setError(strings.ENGAGE_MEDIA_FETCH_FAILED);
        }
      });
    } else {
      setError('Document has no content for this vehicle.');
    }
  };

  const handleUploadMedia = useCallback(
    async (
      files: File[],
      vehicleId: string,
      folderId: string,
      setErrorMessage: any
    ) => {
      const onUploadSuccess = async (mediaUrl?: string) => {
        if (media.id && mediaUrl && vehicleId && folderId) {
          await updateFolioDocumentUrl({
            vehicleId,
            documentId: media.id,
            url: mediaUrl,
          });
        }
      };
      const onUploadError = async () => {
        setErrorMessage(strings.API_MESSAGE);
      };
      const onUploadCancel = () => {
        setFilesToUpload([]);
      };
      const uploadTarget: UploadTarget = {
        type: UploadType.ENGAGE_MEDIA,
      };

      return uploadFiles(
        files,
        vehicleId,
        uploadTarget,
        onUploadSuccess,
        onUploadError,
        onUploadCancel
      );
    },
    [media.id, updateFolioDocumentUrl, uploadFiles]
  );

  const onFileSelectError = useCallback(() => {
    setErrorMessage(strings.API_MESSAGE);
  }, [setErrorMessage]);

  const onFileSelectChange = useCallback((event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files ? Array.from(target.files) : [];
    setFilesToUpload(files);
  }, []);

  const [fileSelector] = useFileSelector(onFileSelectChange, onFileSelectError);

  const handleOpenFileSelector = useCallback(
    (event) => {
      event.stopPropagation();
      fileSelector.click();
    },
    [fileSelector]
  );

  return (
    <>
      {windowSize.isMobileViewport() ? (
        <EngageMediaItemHorizontal
          folderId={folderId}
          vehicleId={vehicleId}
          media={media}
          onMediaClick={onMediaClick}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      ) : (
        <EngageMediaItemOverlay
          folderId={folderId}
          vehicleId={vehicleId}
          media={media}
          onMediaClick={onMediaClick}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      )}
      <PortalAlert
        open={!!error}
        handleClose={() => setError('')}
        message={error}
        variant="error"
      />
      <PortalAlert
        open={!!info}
        handleClose={() => setInfo('')}
        message={info}
        variant="warning"
      />
      {isEditModalOpen && (
        <Modal
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onPrimaryButtonClick={async () => {
            setIsUpdating(true);
            if (isFileUpload) {
              await handleUploadMedia(
                filesToUpload,
                vehicleId,
                folderId,
                setErrorMessage
              );
            } else {
              await onEdit(vehicleId, media.id, newDocumentUrl);
            }
            setIsUpdating(false);
            setIsEditModalOpen(false);
          }}
          title={strings.CHANGE_MEDIA_URL}
          primaryButtonLabel={strings.SAVE}
          secondaryButtonLabel={strings.CANCEL}
          primaryButtonVariant="danger"
          className="EngageMediaItem-edit-modal"
          primaryButtonLoading={isUpdating}
        >
          <div style={{ height: '135px' }}>
            <div
              style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}
            >
              <div>
                <Radio
                  id={`edit-media-radio-upload-${media.id}`}
                  name="upload"
                  label="Upload File"
                  onClick={() => {
                    setIsFileUpload(true);
                  }}
                  selected={isFileUpload}
                />
              </div>
              <div>
                <Radio
                  id={`edit-media-radio-url-${media.id}`}
                  name="upload"
                  label="Paste URL"
                  onClick={() => {
                    setIsFileUpload(false);
                  }}
                  selected={!isFileUpload}
                />
              </div>
            </div>
            {isFileUpload && (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  marginTop: '16px',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={(event) => handleOpenFileSelector(event)}
                    className="file-select-button"
                  >
                    Select File
                  </Button>
                </div>
                <div>
                  {filesToUpload?.[0]?.name
                    ? filesToUpload?.[0]?.name
                    : 'Select a file to upload.'}
                </div>
              </div>
            )}
            {!isFileUpload && (
              <div>
                <Input
                  value={newDocumentUrl}
                  onChange={(event) => setNewDocumentUrl(event.target.value)}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onPrimaryButtonClick={async () => {
          if (onDelete) {
            setIsDeleting(true);
            await onDelete(media);
            setIsDeleting(false);
            setIsDeleteModalOpen(false);
          }
        }}
        title={strings.ENGAGE_DELETE_CONFIRMATION_MESSAGE}
        primaryButtonLabel={strings.DELETE}
        secondaryButtonLabel={strings.CANCEL}
        primaryButtonVariant="danger"
        className="EngageMediaItem-delete-modal"
        primaryButtonLoading={isDeleting}
      >
        <div>You can upload a file or set the URL again any time.</div>
      </Modal>
    </>
  );
};

export default EngageMediaItem;
