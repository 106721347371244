import Dropdown, { DropdownOption } from 'components/shared/Dropdown';

import './ToolbarDropdown.scss';

const ToolbarDropdown: React.FC<{
  searchOptionPlaceholder: string;
  selectedOption: DropdownOption;
  options: DropdownOption[];
  disabled?: boolean;
  onSelect: (key: string) => void;
}> = ({
  searchOptionPlaceholder,
  selectedOption,
  options,
  disabled,
  onSelect,
}) => (
  <Dropdown
    enableSearchOption
    searchOptionPlaceholder={`Enter ${searchOptionPlaceholder}`}
    containerClassName="EngagementsHeader-dropdown"
    selectedOption={selectedOption}
    onSelect={onSelect}
    options={options}
    disabled={disabled}
  />
);
export default ToolbarDropdown;
