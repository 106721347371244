/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const Upload: React.FC<IconProps> = ({
  color = defaultColor,
  className,
  size = 20,
}) => (
  <svg width={size} height={size} viewBox="0 0 18 21" fill="none">
    <path
      className={className}
      fill={color}
      d="M5.39961 15.9999V8.7999H0.599609L8.99961 0.399902L17.3996 8.7999H12.5996V15.9999H5.39961ZM0.599609 20.7999V18.3999H17.3996V20.7999H0.599609Z"
    />
  </svg>
);

export default Upload;
