import { RouteComponentProps, Router } from '@reach/router';

import { NewInvoices, PaidInvoices } from '../';
import { Invoice } from '../../Invoice';
import { InvoiceList } from '../InvoiceList';
import { PayInvoice } from '../PayInvoice';

import './Invoicing.scss';

const Invoicing = ({}: RouteComponentProps) => {
  return (
    <div className="invoicing page vertical-scroll">
      <Router
        primary={false}
        style={{ flex: 1, display: 'flex', width: '100%' }}
      >
        <InvoiceList default />
        <PayInvoice path=":invoiceId/pay" />
        <NewInvoices path="new" />
        <PaidInvoices path="paid">
          <Invoice path="invoice/:invoiceId" />
        </PaidInvoices>
      </Router>
    </div>
  );
};

export default Invoicing;
