import strings from '../../../common/strings';

export const loginValidations = {
  email: {
    presence: { allowEmpty: false },
    email: { message: strings.INVALID_EMAIL_MESSAGE },
  },
  password: {
    presence: { allowEmpty: false },
  },
};
