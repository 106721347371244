import { FC, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import { VehicleRepairOrderLine } from 'models';

import RepairOrderLineItem from './RepairOrderLineItem';
import RepairOrderLinePartsUsed from './RepairOrderLinePartsUsed';

import './RepairOrderLine.scss';

interface RepairOrderLineProps {
  repairOrderLine: VehicleRepairOrderLine;
}

const RepairOrderLine: FC<RepairOrderLineProps> = ({ repairOrderLine }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { id, partsUsed } = repairOrderLine;

  const toggleOpen = () => setOpen(!isOpen);

  const hasParts = useMemo<boolean>(() => {
    if (partsUsed) {
      return partsUsed.length > 0;
    }

    return false;
  }, [partsUsed]);

  if (hasParts) {
    return (
      <Accordion>
        <Accordion.Toggle as="div" eventKey={id} onClick={toggleOpen}>
          <RepairOrderLineItem
            repairOrderLine={repairOrderLine}
            isOpen={isOpen}
            hasParts
          />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={id}>
          <RepairOrderLinePartsUsed partsUsed={partsUsed} />
        </Accordion.Collapse>
      </Accordion>
    );
  }

  return <RepairOrderLineItem repairOrderLine={repairOrderLine} />;
};

export default RepairOrderLine;
