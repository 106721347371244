import React from 'react';

export interface WrapperProps extends React.PropsWithChildren<any> {
  class: string;
}

var Wrapper = (props: WrapperProps) => {
  return <div className={props.class}>{props.children}</div>;
};

export default Wrapper;
