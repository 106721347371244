/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const Pin: React.FC<IconProps> = ({
  color = defaultColor,
  className,
  size = 40,
}) => (
  <svg
    className="PinIcon"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M24 20V12H25V10H15V12H16V20L14 22V24H19.2V30H20.8V24H26V22L24 20Z"
      fill={color}
    />
  </svg>
);

export default Pin;
