import React, { RefObject } from 'react';

import strings from 'common/strings';
import { VelocityEngageFolioFolderDocument } from 'models';

import EngageMediaItem from '../EngageMediaItem';

import './MediaContainer.scss';

interface MediaContainerProps {
  title: string;
  icon?: React.ReactElement | null;
  media: VelocityEngageFolioFolderDocument[];
  scrollRef: RefObject<HTMLDivElement>;
  folderId: string;
  vehicleId: string;
  onDelete: (document: VelocityEngageFolioFolderDocument) => void;
  onEdit: (vehicleId: string, documentId: string, url: string) => Promise<void>;
  setErrorMessage: (errorMessage: string) => void;
}

const MediaContainer: React.FC<MediaContainerProps> = ({
  title,
  icon,
  media,
  scrollRef,
  onDelete,
  onEdit,
  folderId,
  vehicleId,
  setErrorMessage,
}) => {
  const documentList = media.map(
    (item: VelocityEngageFolioFolderDocument, index) => {
      let mediaItem =
        item.name === 'WINDOW_STICKER'
          ? {
              ...item,
              url: item.url + '?source=Velocity Recon',
            }
          : item;

      return (
        <EngageMediaItem
          key={mediaItem.id}
          media={mediaItem}
          onDelete={onDelete}
          onEdit={onEdit}
          folderId={folderId}
          vehicleId={vehicleId}
          setErrorMessage={setErrorMessage}
        />
      );
    }
  );

  return (
    <div className="MediaContainer" ref={scrollRef}>
      <div className="MediaContainer-title">
        {icon && icon}
        {title}
      </div>
      {documentList.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>{documentList}</div>
      ) : (
        <div className="MediaContainer-no-media">
          {strings.NO_ENGAGE_MEDIA_CONTAINER(title)}
        </div>
      )}
    </div>
  );
};

export default MediaContainer;
