import strings from 'common/strings';
import { User } from 'models/utility/user';
import { getUsersFullName } from 'utils/user';

import { StepItemSubset } from '../types';

export const getUserList = (
  users: User[],
  currentStep?: StepItemSubset | null
) => {
  const currentStepId = currentStep?.id;
  if (!currentStepId) {
    return [];
  }

  const workingUserList = users
    .filter((item: User) => item.allowedSteps?.includes(currentStepId))
    .map((item: User) => ({
      id: item.id!,
      value: getUsersFullName(strings.EMPTY_VALUE, item),
    }));
  if (workingUserList.length >= 1 && currentStep?.assignedTo?.id) {
    workingUserList.push({ id: '', value: strings.UNASSIGN_USER });
  }
  return workingUserList;
};
