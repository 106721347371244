import { ExpandMore } from '@material-ui/icons';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React, { useReducer, useState } from 'react';

import useVelocityEngageCorporateReport, {
  useVelocityEngageCorporateReportDetails,
  useVelocityEngageReportDownloadLink,
} from 'api/reports/useVelocityEngageCorporateReport';
import { DateRange } from 'common/DateRange';
import strings from 'common/strings';
import ToolbarDropdown from 'components/pages/Dashboard/EngageTab/EngagementsTable/ToolbarDropdown';
import DownloadMenu from 'components/shared/DownloadMenu';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import { DropdownOption } from 'components/shared/Dropdown';
import EngagementActionsTable from 'components/shared/EngagementActionsTable';
import { StickyReportHeader } from 'components/shared/StickyReportHeader';
import SummaryCard from 'components/shared/SummaryCard';
import { TabProps } from 'components/shared/Tabs/Tabs';
import {
  Overview,
  Rooftop,
} from 'models/reports/VelocityEngageCorporateReportPayload';

import { startWithUpperCase } from '../../../../utils/formatter';
import ReportContainer from '../ReportContainer';
import ReportRow, { determineOptionalTimeDisplay } from '../ReportRow';
import ReportTitleRow from '../ReportTitleRow';
import TagFilter from '../TagFilter';

import './VelocityEngageCorporateReport.scss';

const conditionFilterOptions = [
  {
    value: 'All Inventory',
    id: 'ALL',
  },
  {
    value: 'New Inventory',
    id: 'N',
  },
  {
    value: 'Used Inventory',
    id: 'U',
  },
  {
    value: 'CPO Inventory',
    id: 'C',
  },
];

interface ReportsProps extends TabProps {
  dateRange: DateRange;
}

const VelocityEngageCorporateReportTenantDetails: React.FC<{
  tenantId: string;
  dateRange: DateRange;
  condition: string;
}> = ({ tenantId, dateRange, condition }) => {
  const { isLoading, data } = useVelocityEngageCorporateReportDetails(
    tenantId,
    dateRange,
    condition
  );
  const selectedEntries = Object.entries(data?.data ?? {})
    .filter(([key, value]) => value.count > 0)
    .sort(
      ([aKey, aValue], [bKey, bValue]) =>
        aValue.count * aValue.durationSeconds -
        bValue.count * bValue.durationSeconds
    )
    .reverse()
    .slice(0, 10)
    .sort(
      ([aKey, aValue], [bKey, bValue]) =>
        aValue.durationSeconds - bValue.durationSeconds
    )
    .reverse();

  return selectedEntries.length || isLoading ? (
    <EngagementActionsTable
      isLoading={isLoading}
      data={Object.fromEntries(selectedEntries)}
      durationLabel={strings.ENGAGE_HEATMAP_ITEM_AVERAGE_DURATION}
    />
  ) : (
    <div className="not-enabled">{strings.VECR_ROOFTOP_NOT_ENABLED}</div>
  );
};

const RooftopSummaryRow: React.FC<{
  rooftop: Rooftop;
  dateRange: DateRange;
  condition: string;
}> = ({ rooftop: rt, dateRange, condition }) => {
  const [open, alternateOpen] = useReducer((b: boolean) => !b, false);
  return (
    <div onClick={alternateOpen}>
      <ReportRow
        withPadding
        rowContentClassName={'evenly-spaced-container row-content-width'}
        rowTitleClassName={'ReportRow-title row-title-width'}
        showExpandArrow={false}
        columns={[
          {
            contents: (
              <div className="row-multi-value-container">
                <span className="col-header-label">
                  <span>{startWithUpperCase(strings.VECR_EMAILS)}:</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Sent</span>
                  <span>{rt.emailsSent}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Opened</span>
                  <span>{rt.emailsOpened}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Open Rate</span>
                  <span>
                    {rt.emailsSent
                      ? Math.trunc((rt.emailsOpened / rt.emailsSent) * 100)
                      : 0}
                    %
                  </span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Replied</span>
                  <span>{rt.emailReplies}</span>
                </span>
              </div>
            ),
            columnClass: 'column-flex-4-val column-background',
          },
          {
            contents: (
              <div className="row-multi-value-container">
                <span className="col-header-label">
                  <span>{startWithUpperCase(strings.VECR_ENGAGEMENTS)}:</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">CRM Email</span>
                  <span>{rt.crmEmailEngagementsSent}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Web</span>
                  <span>{rt.webEngagementsSent}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Other</span>
                  <span>{rt.otherEngagementsSent}</span>
                </span>
              </div>
            ),
            columnClass: 'column-flex-3-val',
          },
          {
            contents: (
              <div className="row-multi-value-container">
                <span className="col-header-label">
                  <span>{startWithUpperCase(strings.VECR_FOLIO)}:</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Opens</span>
                  <span>{rt.folioOpens}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">View Time</span>
                  <span>
                    {rt.averageFolioViewTime === 0
                      ? '0s'
                      : determineOptionalTimeDisplay(rt.averageFolioViewTime)}
                  </span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Actions</span>
                  <span>{rt.folioActions}</span>
                </span>
              </div>
            ),
            columnClass: 'column-flex-3-val column-background',
          },
          {
            contents: (
              <div className="row-multi-value-container">
                <span className="col-header-label">
                  <span>
                    {startWithUpperCase(strings.VECR_PRICE_CHANGE_ALERTS)}:
                  </span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Sent</span>
                  <span>{rt.priceAlertsSent}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Opened</span>
                  <span>{rt.priceAlertsOpened}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Open Rate</span>
                  <span>
                    {rt.priceAlertsSent
                      ? `${Math.trunc(
                          (rt.priceAlertsOpened / rt.priceAlertsSent) * 100
                        )}`
                      : 0}
                    %
                  </span>
                </span>
              </div>
            ),
            columnClass: 'column-flex-3-val',
          },
          {
            contents: (
              <div className="row-multi-value-container">
                <span className="col-header-label">
                  <span>{startWithUpperCase(strings.VECR_APPOINTMENTS)}:</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Set</span>
                  <span>{rt.appointmentsSet}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Shown</span>
                  <span>{rt.appointmentVisits}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Shown Rate</span>
                  <span>
                    {rt.appointmentsSet
                      ? Math.trunc(
                          (rt.appointmentVisits / rt.appointmentsSet) * 100
                        )
                      : 0}
                    %
                  </span>
                </span>
              </div>
            ),
            columnClass: 'column-flex-3-val column-background',
          },
          {
            contents: (
              <div className="row-multi-value-container">
                <span className="col-header-label">
                  <span>{startWithUpperCase(strings.VECR_SOLD)}:</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">New</span>
                  <span>{rt.vehiclesSoldNew}</span>
                </span>
                <span className="col-value-container">
                  <span className="col-value-label">Used</span>
                  <span>{rt.vehiclesSoldUsed}</span>
                </span>
              </div>
            ),
            columnClass: 'column-flex-3-val',
          },
        ]}
        title={
          <span>
            {rt.tenantName}
            {open ? <ExpandMore /> : <ChevronRight />}
          </span>
        }
        key={rt.tenantName}
      />
      {open && (
        <VelocityEngageCorporateReportTenantDetails
          tenantId={rt.tenantId}
          dateRange={dateRange}
          condition={condition}
        />
      )}
    </div>
  );
};

const OverviewRowSquares: React.FC<{ overview?: Overview }> = ({
  overview,
}) => (
  <div className="VelocityEngageCorporateReport-overviewRow">
    <div className="VelocityEngageCorporateReport-SummaryCard-container">
      <SummaryCard
        summaryItem={{
          itemType: '',
          statLabel: 'CRM Emails',
          unit: '',
          content: (
            <div className="summary-multi-value-container">
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Sent</span>
                <span>{overview?.emailsSent ?? strings.EMPTY_VALUE}</span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Opened</span>
                <span>{overview?.emailsOpened ?? strings.EMPTY_VALUE}</span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Open Rate</span>
                <span>
                  {overview?.emailsSent
                    ? `${Math.trunc(
                        ((overview?.emailsOpened ?? 0) / overview.emailsSent) *
                          100
                      )}%`
                    : strings.EMPTY_VALUE}
                </span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Replied</span>
                <span>{overview?.emailsReplied ?? strings.EMPTY_VALUE}</span>
              </span>
            </div>
          ),
        }}
      />
    </div>
    <div className="VelocityEngageCorporateReport-SummaryCard-container">
      <SummaryCard
        summaryItem={{
          itemType: '',
          statLabel: 'Engagements',
          unit: '',
          content: (
            <div className="summary-multi-value-container">
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">CRM Emails</span>
                <span>
                  {overview?.crmEmailEngagementsSent ?? strings.EMPTY_VALUE}
                </span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Web</span>
                <span>
                  {overview?.webEngagementsSent ?? strings.EMPTY_VALUE}
                </span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Other</span>
                <span>
                  {overview?.otherEngagementsSent ?? strings.EMPTY_VALUE}
                </span>
              </span>
            </div>
          ),
        }}
      />
    </div>
    <div className="VelocityEngageCorporateReport-SummaryCard-container">
      <SummaryCard
        summaryItem={{
          itemType: '',
          statLabel: 'Folio',
          unit: '',
          content: (
            <div className="summary-multi-value-container">
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Opens</span>
                <span>{overview?.folioOpens ?? strings.EMPTY_VALUE}</span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">View Time</span>
                <span>
                  {overview?.averageFolioViewTime
                    ? determineOptionalTimeDisplay(
                        overview.averageFolioViewTime
                      )
                    : strings.EMPTY_VALUE}
                </span>
              </span>
            </div>
          ),
        }}
      />
    </div>
    <div className="VelocityEngageCorporateReport-SummaryCard-container">
      <SummaryCard
        summaryItem={{
          itemType: '',
          statLabel: 'Price Changes',
          unit: '',
          content: (
            <div className="summary-multi-value-container">
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Sent</span>
                <span>{overview?.priceAlertsSent ?? strings.EMPTY_VALUE}</span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Opened</span>
                <span>
                  {overview?.priceAlertsOpened ?? strings.EMPTY_VALUE}
                </span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Open Rate</span>
                <span>
                  {overview?.priceAlertsSent
                    ? `${Math.trunc(
                        ((overview?.priceAlertsOpened || 0) /
                          overview.priceAlertsSent) *
                          100
                      )}%`
                    : strings.EMPTY_VALUE}
                </span>
              </span>
            </div>
          ),
        }}
      />
    </div>
    <div className="VelocityEngageCorporateReport-SummaryCard-container">
      <SummaryCard
        summaryItem={{
          itemType: '',
          statLabel: 'CRM Appointments',
          unit: '',
          content: (
            <div className="summary-multi-value-container">
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Set</span>
                <span>{overview?.appointmentsSet ?? strings.EMPTY_VALUE}</span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Shown</span>
                <span>
                  {overview?.appointmentVisits ?? strings.EMPTY_VALUE}
                </span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Shown Rate</span>
                <span>
                  {overview?.appointmentsSet
                    ? `${Math.trunc(
                        ((overview?.appointmentVisits ?? 0) /
                          overview.appointmentsSet) *
                          100
                      )}%`
                    : strings.EMPTY_VALUE}
                </span>
              </span>
            </div>
          ),
        }}
      />
    </div>
    <div className="VelocityEngageCorporateReport-SummaryCard-container">
      <SummaryCard
        summaryItem={{
          itemType: '',
          statLabel: 'Sold',
          unit: '',
          content: (
            <div className="summary-multi-value-container">
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">New</span>
                <span>{overview?.vehiclesSoldNew ?? 0}</span>
              </span>
              <span className="summary-col-value-container">
                <span className="summary-col-value-label">Used</span>
                <span>{overview?.vehiclesSoldUsed ?? 0}</span>
              </span>
            </div>
          ),
        }}
      />
    </div>
  </div>
);

const VelocityEngageCorporateReport: React.FC<ReportsProps> = ({
  dateRange,
}) => {
  const [tagFilters, setTagFilters] = useState<string[]>([]);
  const [selectedConditionFilter, setSelectedConditionFilter] =
    useState<DropdownOption>(conditionFilterOptions[0]);
  const { data, status, error } = useVelocityEngageCorporateReport({
    condition: selectedConditionFilter.id,
    tags: tagFilters,
    dateRange,
  });
  const rooftops = data?.data?.data ?? [];
  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);
  const { isLoading } = useVelocityEngageReportDownloadLink(
    selectedReportType,
    dateRange,
    selectedConditionFilter.id,
    tagFilters
  );
  return (
    <div className="VelocityEngageCorporateReport">
      <ReportContainer
        loading={status === 'loading'}
        error={error}
        className="engageOverview"
      >
        <div className="VelocityEngageCorporateReport-header">
          <div className="VelocityEngageCorporateReport-title">
            {strings.ENGAGE_CORPORATE_REPORT}
          </div>
          <TagFilter
            onChange={setTagFilters}
            selectedTags={tagFilters}
            allOptionsLabel={'All Tags'}
          />
          <ToolbarDropdown
            options={conditionFilterOptions}
            onSelect={(key: string) => {
              setSelectedConditionFilter(
                conditionFilterOptions.find((option) => option.id === key) ||
                  conditionFilterOptions[0]
              );
            }}
            selectedOption={selectedConditionFilter}
            searchOptionPlaceholder="Condition"
          />
          {rooftops.length > 0 && (
            <div className="action-bar">
              <DownloadMenu
                onSelectedMenuType={setSelectedReportType}
                isLoading={isLoading}
                formats={['EXCEL']}
              />
            </div>
          )}
        </div>
        <OverviewRowSquares overview={data?.data?.overview} />

        <div className="flex-rows full-width">
          <StickyReportHeader>
            <ReportTitleRow
              rowContentClassName="row-content-width evenly-spaced-container"
              rowTitleClassName="row-title-width"
              titles={[
                {
                  name: 'CRM EMAILS',
                  columnClass: 'column-flex-4-val column-background',
                  subTitles: ['Sent', 'Opened', 'Open Rate', 'Replies'],
                },
                {
                  name: strings.VECR_ENGAGEMENTS,
                  columnClass: 'column-flex-3-val',
                  subTitles: ['CRM Emails', 'Web', 'Other'],
                },
                {
                  name: strings.VECR_FOLIO,
                  columnClass: 'column-flex-3-val column-background',
                  subTitles: ['Opens', 'View Time', 'Actions'],
                },
                {
                  name: strings.VECR_PRICE_CHANGE_ALERTS,
                  columnClass: 'column-flex-3-val',
                  subTitles: ['Sent', 'Opened', 'Open Rate'],
                },
                {
                  name: 'CRM APPOINTMENTS',
                  columnClass: 'column-flex-3-val column-background',
                  subTitles: ['Set', 'Shown', 'Shown Rate'],
                },
                {
                  name: strings.VECR_SOLD,
                  columnClass: 'column-flex-1-val',
                  subTitles: ['New', 'Used'],
                },
              ]}
            />
          </StickyReportHeader>
          {rooftops.length > 0 &&
            rooftops.map((rooftop) => (
              <RooftopSummaryRow
                rooftop={rooftop}
                key={rooftop.tenantName}
                dateRange={dateRange}
                condition={selectedConditionFilter.id}
              />
            ))}
        </div>
      </ReportContainer>
    </div>
  );
};

export default VelocityEngageCorporateReport;
