import moment from 'moment';
import { FC, useMemo, useState } from 'react';

import { strings } from 'common';
import Button from 'components/shared/Button';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useWindowSize } from 'hooks';
import { useCarfax } from 'hooks/useCarfax';
import { CarfaxReport as ICarfaxReport } from 'models/carfax';

import CarfaxPurchaseReport from './CarfaxPurchaseReport';
import { CarfaxReport } from './CarfaxReport';
import HistoryBasedValue from './HistoryBasedValue';

import './CarfaxLandingPage.scss';

interface CarfaxLandingPageProps {
  onLogout: () => void;
  vin: string;
  zipCode: string;
}

const CarfaxLandingPage: FC<CarfaxLandingPageProps> = ({
  onLogout,
  vin,
  zipCode,
}) => {
  const { handleLogout, useCarfaxReport } = useCarfax();
  const isMobile = useWindowSize().isMobileViewport();
  const buttonsStyleString = isMobile ? 'buttons-mobile' : 'buttons-desktop';

  const { isLoading, data } = useCarfaxReport(vin);
  const [currentTab, setCurrentTab] = useState<'report' | 'hbv'>('report');

  const handleLogoutClick = async () => {
    await handleLogout();
    onLogout();
  };

  const isCarfaxReportAvailable = useMemo<boolean | string | undefined>(() => {
    return !isLoading && data?.carfaxLink && moment().isBefore(data?.expiresAt);
  }, [isLoading, data]);
  return (
    <>
      <FlatVDPPaneHeader className="carfax-header">
        <div>CARFAX</div>
      </FlatVDPPaneHeader>
      <div className={buttonsStyleString}>
        <div className="tabs">
          <Button
            type="button"
            variant={currentTab === 'report' ? 'info' : 'primary'}
            onClick={() => setCurrentTab('report')}
          >
            REPORT
          </Button>
          <Button
            type="button"
            variant={currentTab === 'hbv' ? 'info' : 'primary'}
            onClick={() => setCurrentTab('hbv')}
          >
            History Based Value
          </Button>
        </div>
        <div>
          <Button variant="primary" type="button" onClick={handleLogoutClick}>
            {strings.CARFAX_LOGOUT}
          </Button>
        </div>
      </div>

      {isLoading && <LoadingIndicator />}
      {currentTab === 'report' ? (
        <>
          {isCarfaxReportAvailable ? (
            <CarfaxReport data={data as ICarfaxReport} vin={vin} />
          ) : (
            !isLoading && <CarfaxPurchaseReport vin={vin} />
          )}
        </>
      ) : (
        <HistoryBasedValue vin={vin} zipCode={zipCode} />
      )}
    </>
  );
};
export default CarfaxLandingPage;
