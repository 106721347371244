import Select from 'components/shared/Inputs/Select';
import { ChecklistItem } from 'models';

import FieldLabel from './MpiFieldLabel';

interface IMpiSelect {
  field: ChecklistItem;
  answer: any;
  onChange: (key: string, value: any) => void;
  disabled: boolean;
}

var MpiSelect = ({ field, answer, onChange, disabled }: IMpiSelect) => {
  const options = field.options.map(({ label, value }) => ({
    label: label || value,
    value,
  }));
  const value = options.find((option) => option.value === answer);

  return (
    <div key={`${field.id}_select_field`} className="MpiSelect py-1">
      <FieldLabel
        className="text-muted text-uppercase font-weight-600"
        name={field.label}
        required={field.required}
      />
      <Select
        isClearable={!!value}
        disabled={disabled}
        value={value}
        options={options}
        className="MpiSelect"
        onChange={(option) => onChange(field.id, option?.value)}
      />
    </div>
  );
};

export default MpiSelect;
