import Typography from '@material-ui/core/Typography';
import { useState } from 'react';

import permissions from 'common/permissions';
import strings from 'common/strings';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { usePermissions } from 'hooks';
import { Note } from 'models';

import NoteMessage from '../NoteMessage';

import './NotesListSection.scss';

export interface Section {
  title: string;
  data: any;
}

interface NotesListSectionProps {
  vehicleId: string;
  onEditClick(note: Note): void;
  onDeleteNote(note: Note): void;
  sections?: Section[];
  isLoading: boolean;
  inPreviewPanel?: boolean;
}

interface NotesSectionsProps {
  vehicleId: string;
  onEditClick(note: Note): void;
  onDeleteNote(note: Note): void;
  sections: any;
  inPreviewPanel?: boolean;
}

interface SectionTitleProps {
  title: string;
}

interface NotesMessagesProps {
  onEditClick(note: Note): void;
  onDeleteNote(note: Note): void;
  notes: any;
  inPreviewPanel?: boolean;
  vehicleId: string;
}

var NotesListSection = ({
  onEditClick,
  onDeleteNote,
  sections,
  isLoading,
  inPreviewPanel = false,
  vehicleId,
}: NotesListSectionProps) => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(permissions.INVENTORY_VDP_NOTES_VIEW)) {
    return <NoViewPermission />;
  }
  if (isLoading) {
    return (
      <div className="NotesListSection align-middle">
        <LoadingIndicator />
      </div>
    );
  }
  if (!sections?.length) {
    return (
      <div className="NotesListSection align-middle">
        <NoNotes />
      </div>
    );
  }

  return (
    <div className="flex-grow NotesListSection">
      <NotesSections
        vehicleId={vehicleId}
        sections={sections}
        onEditClick={onEditClick}
        onDeleteNote={onDeleteNote}
        inPreviewPanel={inPreviewPanel}
      />
    </div>
  );
};

const NotesSections = ({
  vehicleId,
  sections,
  onDeleteNote,
  onEditClick,
  inPreviewPanel = false,
}: NotesSectionsProps) =>
  sections.map((section: Section) => (
    <div key={section.title}>
      <SectionTitle title={section.title} />
      <NotesMessages
        vehicleId={vehicleId}
        notes={section.data}
        onEditClick={onEditClick}
        onDeleteNote={onDeleteNote}
        inPreviewPanel={inPreviewPanel}
      />
    </div>
  ));

const NotesMessages = ({
  notes,
  onEditClick,
  onDeleteNote,
  inPreviewPanel = false,
  vehicleId,
}: NotesMessagesProps) => {
  const [activeAttachmentDropdownId, setActiveAttachmentMenu] = useState('');

  const toggleActiveAttachmentMenu = (attachmentId: string) => {
    if (activeAttachmentDropdownId === attachmentId) {
      setActiveAttachmentMenu('');
      return;
    }
    setActiveAttachmentMenu(attachmentId);
  };

  return notes.map((note: Note, idx: number) => (
    <NoteMessage
      key={`Notes-message-${idx.toString()}`}
      note={note}
      vehicleId={vehicleId}
      openEditModal={onEditClick}
      onDelete={onDeleteNote}
      inPreviewPanel={inPreviewPanel}
      activeAttachmentDropdownId={activeAttachmentDropdownId}
      toggleActiveAttachmentMenu={toggleActiveAttachmentMenu}
    />
  ));
};

var NoViewPermission = () => {
  return (
    <div className="d-flex align-items-center flex-column flex-grow justify-content-center px-4">
      <Typography variant="subtitle1" className="no-note-title">
        {strings.NOTES_PERMISSION_DENIED}
      </Typography>
      <Typography variant="body2" className="no-note-message">
        {strings.NOTES_PERMISSION_DENIED_SUB}
      </Typography>
    </div>
  );
};

var NoNotes = () => {
  return (
    <div className="d-flex align-items-center flex-column flex-grow justify-content-center Notes-no-note-container">
      <Typography variant="subtitle1" className="no-note-title">
        {strings.NO_NOTES}
      </Typography>
      <Typography variant="body2" className="no-note-message">
        {strings.NO_NOTES_MESSAGE}
      </Typography>
    </div>
  );
};

var SectionTitle = ({ title }: SectionTitleProps) => {
  return (
    <div className="mt-3 NotesListSection-date-label">
      {title.toUpperCase()}
    </div>
  );
};

export default NotesListSection;
