import { useEffect, useRef, useState } from 'react';

export default function useTraceUpdate(props: any, TAG?: string) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce(
      (ps: any, [k, v]: any[]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      },
      {}
    );
    if (Object.keys(changedProps).length > 0) {
      // eslint-disable-next-line
      console.log(TAG ? `[${TAG}] Props:` : 'Props:', props);
      // eslint-disable-next-line
      console.log(TAG ? `[${TAG}] Changed props:` : 'Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export const useToggleWithAutoCondition = (
  isAutoConditionFulfilled: boolean = false
): [boolean, () => void] => {
  const [status, setStatus] = useState<boolean>(false);

  const onClick = () => setStatus(!status);

  useEffect(() => {
    if (isAutoConditionFulfilled) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [isAutoConditionFulfilled]);

  return [status, onClick];
};
