import CheckIcon from '@material-ui/icons/Check';
import PauseIcon from '@material-ui/icons/Pause';
import { MouseEvent, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useQueryClient } from 'react-query';

import shouldRenderContinueButton from 'api/inventory/nextStepWorkflow';
import { useChangeStepState } from 'api/inventory/workflows';
import permissions from 'common/permissions';
import strings from 'common/strings';
import ApiError from 'components/shared/ApiError';
import { usePermissions } from 'hooks';
import { StepItem, StepItemState } from 'models';

import './StepButtons.scss';

export type StepItemSubset = Partial<
  Pick<
    StepItem,
    | 'id'
    | 'status'
    | 'state'
    | 'terminalStep'
    | 'isTerminal'
    | 'allowCompleteRecon'
    | 'timeInStepSeconds'
    | 'enhancedStateTracking'
    | 'nextStep'
  >
>;
interface StepButtonsProps {
  onComplete?: any;
  small?: boolean;
  currentStep: StepItemSubset;
  vehicleId?: string;
  isOnboarding?: boolean;
}

export interface IListData {
  id: string;
  name: string;
}

const StepButtons = ({
  onComplete,
  small,
  currentStep,
  vehicleId,
  isOnboarding = false,
}: StepButtonsProps) => {
  const changeStepStateMutation = useChangeStepState(vehicleId);
  const { isLoading: changeStepStateMutationLoading } = changeStepStateMutation;
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissions();

  const [loadingState, setLoadingState] = useState({
    loadingPause: false,
    loadingBeginContinue: false,
  });

  const [apiError, setApiError] = useState(false);
  if (!currentStep) return null;
  const isWorkInProgress = currentStep.state === 'ACTIVE';

  const pauseWork = async (event: MouseEvent) => {
    event.stopPropagation();
    try {
      setLoadingState({
        ...loadingState,
        loadingPause: true,
      });
      setApiError(false);
      await changeStepStateMutation.mutateAsync(StepItemState.ACTIVE_PAUSED);

      setLoadingState({
        ...loadingState,
        loadingPause: false,
      });
    } catch (e) {
      setLoadingState({
        ...loadingState,
        loadingPause: false,
      });
      setApiError(true);
    }
  };

  const onCompleteWorkPress = async (event: MouseEvent) => {
    event.stopPropagation();
    try {
      setApiError(false);
      await changeStepStateMutation.mutateAsync(StepItemState.COMPLETE);
      queryClient.invalidateQueries('/inventory');
    } catch (e) {
      setApiError(true);
    }
  };

  const onBeginContinueWorkClick = async (event: MouseEvent) => {
    event.stopPropagation();
    try {
      setLoadingState({
        ...loadingState,
        loadingBeginContinue: true,
      });
      setApiError(false);
      await changeStepStateMutation.mutateAsync(StepItemState.ACTIVE);
      setLoadingState({
        ...loadingState,
        loadingBeginContinue: false,
      });
    } catch (e) {
      setLoadingState({
        ...loadingState,
        loadingBeginContinue: false,
      });
      setApiError(true);
    }
  };

  const completeWorkflow = async (event: MouseEvent) => {
    event.stopPropagation();
    try {
      setApiError(false);
      await changeStepStateMutation.mutateAsync(StepItemState.COMPLETE);
      onComplete?.();
      queryClient.invalidateQueries('/inventory');
    } catch (e) {
      setApiError(true);
    }
  };

  const renderWorkInProgressButtons = () => {
    let completeButtonLabel = strings.COMPLETE;
    if (currentStep.nextStep) {
      completeButtonLabel = (
        <>
          <span>Send to&nbsp;</span>
          <span>{`${currentStep.nextStep.name}`}</span>
        </>
      );
    }
    return (
      <>
        <Col xs="6" className="StepButtons-pause-col">
          <button
            disabled={
              isOnboarding ||
              loadingState.loadingPause ||
              changeStepStateMutationLoading ||
              !hasPermission(permissions.RECON_VDP_WORKFLOW_EFFORT_UPDATE)
            }
            type="button"
            className="white-button step-button-base text-ellipsis pause-button"
            onClick={pauseWork}
          >
            <>
              {loadingState.loadingPause ? (
                <Spinner
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  as="span"
                  animation="border"
                  variant="danger"
                />
              ) : (
                <>
                  <PauseIcon />
                  {!small && strings.PAUSE}
                </>
              )}
            </>
          </button>
        </Col>
        <Col xs="6" className="StepButtons-continue-col">
          <button
            onClick={onCompleteWorkPress}
            disabled={
              isOnboarding ||
              changeStepStateMutationLoading ||
              loadingState.loadingPause ||
              !hasPermission(permissions.RECON_VDP_WORKFLOW_EFFORT_UPDATE)
            }
            type="button"
            className="blue-button step-button-base text-ellipsis complete-button-base complete-work-button mt-0"
          >
            {changeStepStateMutationLoading ? (
              <Spinner
                size="sm"
                role="status"
                aria-hidden="true"
                as="span"
                animation="border"
                variant="danger"
              />
            ) : (
              <>
                {small && <CheckIcon />}
                {!small && completeButtonLabel}
              </>
            )}
          </button>
        </Col>
      </>
    );
  };

  // Please refactor - this is confusing
  const renderWorkNotInProgressButtons = () => {
    if (currentStep?.terminalStep || currentStep?.isTerminal) {
      if (!currentStep?.allowCompleteRecon) {
        return null;
      }
      return (
        currentStep?.allowCompleteRecon && (
          <Col md="12">
            <button
              onClick={completeWorkflow}
              disabled={
                isOnboarding ||
                changeStepStateMutationLoading ||
                !hasPermission(permissions.RECON_VDP_WORKFLOW_COMPLETE)
              }
              type="button"
              className="blue-button step-button-base text-ellipsis complete-button-base complete-workflow-button mt-0"
            >
              {changeStepStateMutationLoading ? (
                <Spinner
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  as="span"
                  animation="border"
                  variant="danger"
                />
              ) : (
                <>
                  <CheckIcon />
                  {strings.MARK_COMPLETE}
                </>
              )}
            </button>
          </Col>
        )
      );
    }
    return (
      <Col md="12">
        {shouldRenderContinueButton(currentStep) ? (
          <button
            onClick={onBeginContinueWorkClick}
            disabled={
              isOnboarding ||
              loadingState.loadingBeginContinue ||
              !hasPermission(permissions.RECON_VDP_WORKFLOW_EFFORT_UPDATE)
            }
            type="button"
            className="blue-button step-button-base continue-button text-ellipsis mt-0"
          >
            {loadingState.loadingBeginContinue ? (
              <Spinner
                size="sm"
                role="status"
                aria-hidden="true"
                as="span"
                animation="border"
                variant="danger"
              />
            ) : (
              strings[
                currentStep.timeInStepSeconds &&
                currentStep.timeInStepSeconds > 0
                  ? 'CONTINUE'
                  : 'BEGIN_WORK'
              ]
            )}
          </button>
        ) : null}
      </Col>
    );
  };

  // Please refactor - this is confusing
  const renderSimpleButton = () => {
    if (currentStep?.terminalStep || currentStep?.isTerminal) {
      if (!currentStep?.allowCompleteRecon) {
        return null;
      }
      return (
        currentStep?.allowCompleteRecon && (
          <Col md="12">
            <button
              onClick={completeWorkflow}
              disabled={
                isOnboarding ||
                changeStepStateMutationLoading ||
                !hasPermission(permissions.RECON_VDP_WORKFLOW_COMPLETE)
              }
              type="button"
              className="blue-button step-button-base text-ellipsis complete-button-base complete-workflow-button mt-0"
            >
              {changeStepStateMutationLoading ? (
                <Spinner
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  as="span"
                  animation="border"
                  variant="danger"
                />
              ) : (
                <>
                  <CheckIcon />
                  {strings.MARK_COMPLETE}
                </>
              )}
            </button>
          </Col>
        )
      );
    }
    if (!currentStep?.nextStep) {
      return null;
    }
    return (
      <button
        onClick={onCompleteWorkPress}
        disabled={
          isOnboarding ||
          changeStepStateMutationLoading ||
          loadingState.loadingPause ||
          !hasPermission(permissions.RECON_VDP_WORKFLOW_EFFORT_UPDATE)
        }
        type="button"
        className="blue-button step-button-base text-ellipsis complete-button-base simple-state-complete-button mt-0"
      >
        {changeStepStateMutationLoading ? (
          <Spinner
            size="sm"
            role="status"
            aria-hidden="true"
            as="span"
            animation="border"
            variant="danger"
          />
        ) : (
          <>
            <span>Send to&nbsp;</span>
            <span>{`${currentStep.nextStep.name}`}</span>
          </>
        )}
      </button>
    );
  };

  // Please refactor - this is confusing
  const renderEnhancedButtons = () =>
    isWorkInProgress
      ? renderWorkInProgressButtons()
      : renderWorkNotInProgressButtons();

  return (
    <Row className={`StepButtons ${small && 'StepButtons-small'}`}>
      {currentStep.enhancedStateTracking
        ? renderEnhancedButtons()
        : renderSimpleButton()}
      {apiError && <ApiError error={apiError} />}
    </Row>
  );
};

export default StepButtons;
