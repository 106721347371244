import IconButton from '@material-ui/core/IconButton';
import {
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedTwoTone,
} from '@material-ui/icons';

import './Radio.scss';

interface IRadioProps {
  label: string;
  onClick(): void;
  selected?: boolean;
  id: string;
  className?: string;
  disabled?: boolean;
  name: string;
  selectedClass?: string;
}

interface IRadioIconProps {
  selected?: boolean;
}

var RadioIcon = ({ selected }: IRadioIconProps) => {
  return selected ? (
    <RadioButtonCheckedOutlined className="radio-icon" />
  ) : (
    <RadioButtonUncheckedTwoTone className="radio-icon" />
  );
};

var RadioInput = ({
  label,
  onClick,
  selected,
  id,
  className,
  disabled,
  name,
  selectedClass,
}: IRadioProps) => {
  return (
    <div role="button" className={`RadioInput ${className}`}>
      <label
        role="none"
        onClick={!disabled ? onClick : undefined}
        className={`radio-container ${disabled ? 'text-muted no-pointer' : ''}`}
      >
        <input
          key={`${id}_${label}_select_button`}
          type="radio"
          name={name}
          value={label}
          defaultChecked={selected}
          onClick={(e) => e.stopPropagation()}
          disabled={disabled}
        />
        <IconButton
          className={`icon-button-primary p-2 ${selected ? selectedClass : ''}`}
        >
          <RadioIcon selected={selected} />
        </IconButton>
        <span className="align-middle">{label}</span>
      </label>
    </div>
  );
};

export default RadioInput;
