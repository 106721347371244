import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

import { useDashboardSummary } from 'api';
import strings from 'common/strings';
import ConfirmationDialog from 'components/shared/ConfirmationDialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import SummaryCard from 'components/shared/SummaryCard/SummaryCard';
import { DashboardItem, SummaryItem } from 'models';

import './Dashboard.scss';

const DashboardSummaryItems: React.FC = () => {
  const [isHelperDialog, setIsHelperDialog] = useState<boolean>(false);
  const [helperDialogBody, setHelperDialogBody] =
    useState<string>('Vehicles in Recon');
  const { data: { data } = {}, isLoading } = useDashboardSummary();
  const { items } = data || { items: [] };

  const dispositionModalProps = {
    onClose: () => setIsHelperDialog(false),
    onAccept: () => setIsHelperDialog(false),
    disableCancelButton: true,
    title: '',
    body: helperDialogBody || '',
    mainButtonTitle: strings.OK,
  };

  const HELPER_TEXTS = {
    'Vehicles in Recon': strings.VEHICLES_IN_RECON_HELPER_TEXT,
    'AVERAGE RETAIL-READY TIME': strings.TIME_GOAL_AVERAGE_HELPER_TEXT,
    'AVERAGE RECON TIME': strings.AVERAGE_RECON_TIME_HELPER_TEXT,
    'AVERAGE APPROVAL TIME': strings.AVERAGE_APPROVAL_TIME_HELPER_TEXT,
  };

  type helperText = keyof typeof HELPER_TEXTS;
  const showHelperDialog = (summaryItem: SummaryItem) => {
    setIsHelperDialog(true);
    setHelperDialogBody(HELPER_TEXTS[summaryItem.statLabel as helperText]);
  };

  return (
    <>
      {!isLoading && items ? (
        items.map((dashboardItem: DashboardItem) => {
          const value = dashboardItem.value || 0; // value is a string or number compare dashboard and reports APIs
          const statValue =
            dashboardItem.vehiclesInRecon ||
            dashboardItem.actualTimeAsSeconds ||
            value;
          const goalValue =
            dashboardItem.workInProgress || dashboardItem.goalAsSeconds || 0;
          const summaryItem: SummaryItem = {
            itemType: dashboardItem.itemType,
            statValue,
            statLabel: dashboardItem.label,
            unit: dashboardItem.unit ?? '',
            goalValue,
          };
          return (
            <Col
              key={summaryItem.statLabel}
              md={3}
              xs={6}
              className="Dashboard-cardContainer"
            >
              <SummaryCard
                testId={`summary-card-${summaryItem.statLabel
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
                summaryItem={summaryItem}
                callbackShowDialog={() => showHelperDialog(summaryItem)}
              />
            </Col>
          );
        })
      ) : (
        <>
          <Col xl="3" lg="6" md="6" className="Dashboard-cardContainer">
            <div className="Dashboard-summary-card-skeleton">
              <LoadingIndicator />
            </div>
          </Col>
          <Col xl="3" lg="6" md="6" className="Dashboard-cardContainer">
            <div className="Dashboard-summary-card-skeleton">
              <LoadingIndicator />
            </div>
          </Col>
          <Col xl="3" lg="6" md="6" className="Dashboard-cardContainer">
            <div className="Dashboard-summary-card-skeleton">
              <LoadingIndicator />
            </div>
          </Col>
          <Col xl="3" lg="6" md="6" className="Dashboard-cardContainer">
            <div className="Dashboard-summary-card-skeleton">
              <LoadingIndicator />
            </div>
          </Col>
        </>
      )}
      {isHelperDialog && <ConfirmationDialog {...dispositionModalProps} />}
    </>
  );
};

export default DashboardSummaryItems;
