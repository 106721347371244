import IconButton from '@material-ui/core/IconButton';
import { ArrowBack, Close } from '@material-ui/icons';
import React from 'react';
import { Appearance } from 'types';

import { generateTestId, testIds } from 'common/testIds';

import './PaneHeader.scss';

export interface PaneHeaderProps {
  title: string | React.ReactNode;
  titleLogoImgSrc?: string;
  titleLogoImgAlt?: string;
  icon?: any;
  appearance?: Appearance;
  onBackButtonClick?: (event: React.MouseEvent) => any;
  onCloseButtonClick?: (event: React.MouseEvent) => any;
  flipView?: boolean;
  children?: React.ReactNode;
  noDivider?: boolean;
}

const PaneHeader: React.FC<PaneHeaderProps> = (props: PaneHeaderProps) => {
  const {
    title,
    titleLogoImgSrc,
    titleLogoImgAlt,
    icon,
    onBackButtonClick,
    onCloseButtonClick,
    children,
    flipView,
    noDivider,
  } = props;
  const appearance = props.appearance || 'responsive';
  const flipViewState = flipView || false;
  const IconComponent = icon;
  if (!flipViewState) {
    return (
      <div className={`PaneHeader ${appearance} ${noDivider ? '' : 'divider'}`}>
        <div className={`PaneHeader-back-button ${appearance}`}>
          {onBackButtonClick && (
            <IconButton onClick={onBackButtonClick}>
              <ArrowBack color="secondary" />
            </IconButton>
          )}
          <div className={`PaneHeader-back-button ${appearance}`}>
            {onCloseButtonClick && (
              <IconButton onClick={onCloseButtonClick}>
                <Close color="secondary" />
              </IconButton>
            )}
          </div>
        </div>
        <div
          data-vas-testing={generateTestId(testIds.CONTAINER_TITLES, {
            title: `${title}`,
          })}
          className={`PaneHeader-title ${appearance}`}
        >
          {IconComponent && (
            <IconComponent
              color="secondary"
              className={`PaneHeader-icon ${appearance}`}
            />
          )}
          {title}
          {titleLogoImgSrc && titleLogoImgAlt ? (
            <img src={titleLogoImgSrc} alt={titleLogoImgAlt} />
          ) : (
            ''
          )}
        </div>
        <div className={`PaneHeader-action-buttons ${appearance}`}>
          {children}
        </div>
      </div>
    );
  }
  return (
    <div className={`PaneHeader ${appearance}`}>
      <div className={`PaneHeader-title ${appearance}`}>
        {IconComponent && (
          <IconComponent
            color="secondary"
            className={`PaneHeader-icon ${appearance}`}
          />
        )}
        {title}
        {titleLogoImgSrc && titleLogoImgAlt ? (
          <img src={titleLogoImgSrc} alt={titleLogoImgAlt} />
        ) : (
          ''
        )}
      </div>
      <div className={`PaneHeader-action-buttons ${appearance}`}>
        {children}
      </div>
      <div className={`PaneHeader-back-button ${appearance}`}>
        {onBackButtonClick && (
          <IconButton onClick={onBackButtonClick}>
            <ArrowBack color="secondary" />
          </IconButton>
        )}
      </div>
      <div className={`PaneHeader-back-button ${appearance}`}>
        {onCloseButtonClick && (
          <IconButton onClick={onCloseButtonClick}>
            <Close color="secondary" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default PaneHeader;
