import { useContext } from 'react';

import { VehicleCardConfigProp, VehicleCardContext } from './VehicleCard';
import VehicleCardMobileV2 from './VehicleCardMobileV2';
import VehicleCardShowroomMobile from './VehicleCardShowroomMobile';
import VehicleCardVendorMobile from './VehicleCardVendorMobile';

interface VehicleCardMobileProps {
  style: object;
  config: VehicleCardConfigProp;
}

const VehicleCardMobile = ({ style, config }: VehicleCardMobileProps) => {
  const { isShowroomMode, isVendorMode } = useContext(VehicleCardContext);

  if (isVendorMode) {
    return <VehicleCardVendorMobile />;
  }

  if (isShowroomMode) {
    return <VehicleCardShowroomMobile style={style} config={config} />;
  }

  return <VehicleCardMobileV2 />;
};

export default VehicleCardMobile;
