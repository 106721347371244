import fetchIntercept from 'fetch-intercept';
import { useEffect } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { Socket } from 'socket.io-client';

import { APIResponse } from 'api';
import { VehicleSummary } from 'models';

const mutationMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];

interface MessageContent {
  eventType: EventType;
  tenantId?: string;
  inventoryId?: string;
  year?: number;
  make?: string;
  model?: string;
  vin?: string;
  stockNumber?: string;
}
export interface Message extends MessageContent {
  room: string;
  event: string;
}

export enum EventType {
  assigneeChange = 'assigneeChange',
  stepChange = 'stepChange',
  update = 'update',
  create = 'create',
}

let interceptorSocket: Socket | undefined;
let interceptorTenantId: string | undefined;
let interceptorSharedStepsMap: any | undefined;
let interceptorQueryClient: QueryClient;

const getInventoryId = (url: string) => {
  const inventoryPattern =
    /(recon|inventory)\/([A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12})/;
  const inventoryMatches = url.match(inventoryPattern) ?? [];

  if (inventoryMatches && inventoryMatches?.length === 3) {
    return inventoryMatches[2];
  }
};

const getIsTagUpdate = (url: string, inventoryId?: string) => {
  const tagsPattern = /\/(tags)/;
  const tagsMatches = url.match(tagsPattern) ?? [];
  return inventoryId && tagsMatches && tagsMatches?.length === 2;
};

const getIsInventoryCreate = (method: string, url: string) => {
  return (
    method === 'POST' && (url.endsWith('recon') || url.endsWith('inventory'))
  );
};

const getInventoryEventType = (url: string) => {
  const assigneeChangePattern = /(workflow\/step\/user)/;
  const assigneeChangeMatches = url.match(assigneeChangePattern) ?? [];
  if (assigneeChangeMatches?.length === 2) {
    return EventType.assigneeChange;
  }

  const stepChangePattern = /(workflow\/step)/;
  const stepChangeMatches = url.match(stepChangePattern) ?? [];
  if (stepChangeMatches?.length === 2) {
    return EventType.stepChange;
  }

  return EventType.update;
};

export const getVehicleSummaryFromQueryCacheByInventoryId = (
  inventoryId: string
) => {
  let vehicleSummary: VehicleSummary | undefined;

  let queryData: any = interceptorQueryClient.getQueriesData({
    queryKey: '/inventory',
    active: true,
  })?.[0];
  const pages: any = queryData?.[1]?.pages;
  pages?.find(({ data: vehicles }: { data: VehicleSummary[] }) => {
    vehicleSummary = vehicles?.find((vehicle) => {
      const currentInventoryId = vehicle.vehicleCard.id;
      return currentInventoryId === inventoryId && vehicle.stepItem?.id;
    });
    return !!vehicleSummary;
  });

  if (!vehicleSummary) {
    queryData = interceptorQueryClient.getQueryData(
      `/inventory/${inventoryId}`
    );
    vehicleSummary = queryData?.data;
  }

  return vehicleSummary;
};

const getInventoryDetailsFromVehicleSummary = (
  vehicleSummary: VehicleSummary | undefined
) => {
  return {
    inventoryId: vehicleSummary?.vehicleCard.id,
    year: vehicleSummary?.vehicleCard.year,
    make: vehicleSummary?.vehicleCard.make,
    model: vehicleSummary?.vehicleCard.model,
    vin: vehicleSummary?.vehicleCard.vin,
    stockNumber: vehicleSummary?.vehicleCard.stockNumber,
  };
};

const getSharedTenantIdByStepId = (stepId: string) => {
  return interceptorSharedStepsMap?.[stepId]?.tenantId;
};

const sendInventoryEvent = (
  toTenantId: string | undefined,
  fromTenantId: string | undefined,
  message: MessageContent
) => {
  if (toTenantId) {
    const room = `tenant/${toTenantId}/inventory`;
    const event = `${room}/update`;
    interceptorSocket?.emit('reconVelocity', {
      ...message,
      room,
      event,
      tenantId: fromTenantId,
    });
  }
};

export let unRegisterFetchIntercept = () => {};

export const registerFetchIntercept = () => {
  unRegisterFetchIntercept = fetchIntercept.register({
    response: function (response: any) {
      if (!(interceptorSocket && interceptorTenantId)) {
        return response;
      }
      const method = response?.request?.method;
      const url = response.url;
      if (response.ok && mutationMethods.includes(method)) {
        const tenantId = interceptorTenantId;
        const inventoryId = getInventoryId(url);
        const isTagUpdate = getIsTagUpdate(url, inventoryId);
        const isInventoryCreate = getIsInventoryCreate(method, url);

        if (inventoryId) {
          const vehicleSummary =
            getVehicleSummaryFromQueryCacheByInventoryId(inventoryId);

          const eventType = getInventoryEventType(url);
          sendInventoryEvent(tenantId, tenantId, {
            tenantId,
            eventType,
            ...getInventoryDetailsFromVehicleSummary(vehicleSummary),
          });

          const sharedStepTenantId = getSharedTenantIdByStepId(
            vehicleSummary?.stepItem?.id ?? ''
          );

          if (sharedStepTenantId) {
            sendInventoryEvent(sharedStepTenantId, tenantId, {
              eventType,
              ...getInventoryDetailsFromVehicleSummary(vehicleSummary),
            });
          }

          if (eventType === EventType.stepChange) {
            response
              .clone()
              .json()
              .then((json: any) => {
                const responseSharedStepTenantId = getSharedTenantIdByStepId(
                  json?.data?.id
                );

                if (responseSharedStepTenantId !== sharedStepTenantId) {
                  sendInventoryEvent(responseSharedStepTenantId, tenantId, {
                    eventType,
                    ...getInventoryDetailsFromVehicleSummary(vehicleSummary),
                  });
                }
              });
          }
        }

        if (isTagUpdate) {
          const room = `tenant/${interceptorTenantId}/tags`;
          const event = `${room}/update`;
          interceptorSocket?.emit('reconVelocity', { room, event });
        }

        if (isInventoryCreate) {
          response
            .clone()
            .json()
            .then((json: APIResponse<VehicleSummary | undefined>) => {
              const vehicleSummary = json?.data;
              sendInventoryEvent(tenantId, tenantId, {
                eventType: EventType.create,
                ...getInventoryDetailsFromVehicleSummary(vehicleSummary),
              });
            });
        }
      }
      return response;
    },
  });
};

export const useSendInventoryUpdates = (
  socket: Socket | undefined,
  isLoading: boolean,
  tenantId: string | undefined,
  sharedStepsMap: any
) => {
  const isReady = socket && !isLoading && tenantId && sharedStepsMap;
  interceptorQueryClient = useQueryClient();

  useEffect(() => {
    if (isReady) {
      interceptorSocket = socket;
      interceptorTenantId = tenantId;
      interceptorSharedStepsMap = sharedStepsMap;
      return () => {
        interceptorSocket = undefined;
        interceptorTenantId = undefined;
        interceptorSharedStepsMap = undefined;
      };
    }
  }, [isReady, sharedStepsMap, socket, tenantId]);
};
