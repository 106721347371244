import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface TitleTooltipProps {
  label: string;
  title: string;
}

const TitleTooltip: React.FC<TitleTooltipProps> = ({ label, title }) => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 100, hide: 450 }}
    overlay={
      <Tooltip id="tooltip-top">
        <span className="font-weight-bold">{title}</span>
      </Tooltip>
    }
  >
    <div>{label}</div>
  </OverlayTrigger>
);

export default TitleTooltip;
