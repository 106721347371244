import {
  Dialog,
  DialogActions,
  DialogContent,
  Button as MUIButton,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { RouteComponentProps, WindowLocation } from '@reach/router';
import { ChangeEvent, useCallback, useState } from 'react';

import useSendInvoiceMutation from 'api/invoicing/useSendInvoiceMutation';
import { strings } from 'common';
import ApiError from 'components/shared/ApiError';
import Button from 'components/shared/Button';
import { Invoice as InvoiceType } from 'models/invoicing';

import { Invoice } from '../../Invoice';

import './SendInvoice.scss';

type Props = {
  location?: WindowLocation<{
    invoice: InvoiceType;
    invoiceId?: string;
    inventoryId: string;
    goBack: boolean;
  }>;
} & RouteComponentProps;

const SendInvoice = ({ navigate, location }: Props) => {
  const [invoice, setInvoice] = useState(location?.state?.invoice ?? null);
  const [invoiceNumber, setInvoiceNumber] = useState(
    invoice?.invoiceNumber?.toString() ?? ''
  );
  const [showDialog, setShowDialog] = useState(false);
  const { isLoading, isSuccess, error, mutateAsync } = useSendInvoiceMutation();

  const handleBack = useCallback(() => {
    navigate?.('../../');
  }, [navigate]);

  const handleInvoiceNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInvoiceNumber(event.target.value);
    },
    []
  );

  const handleSendInvoice = useCallback(async () => {
    if (invoice?.id) {
      try {
        const updatedInvoiceResult = await mutateAsync({
          invoiceId: invoice.id,
          invoiceNumber,
        });
        setInvoice(updatedInvoiceResult?.data as InvoiceType);
        setShowDialog(true);
      } catch (error) {}
    }
  }, [invoice?.id, invoiceNumber, mutateAsync]);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  const content = invoice ? (
    <Invoice
      invoice={invoice}
      onInvoiceNumberChange={handleInvoiceNumberChange}
    />
  ) : null;
  return (
    <div className="vendor-send-invoice">
      <header className="vendor-send-invoice-header">
        <MUIButton
          variant="text"
          color="secondary"
          style={{ fontWeight: 'bold' }}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
        >
          Back
        </MUIButton>
      </header>
      <main className="vendor-send-invoice-content">{content}</main>
      <footer className="vendor-send-invoice-footer">
        <Button
          variant="primary"
          type="button"
          onClick={handleSendInvoice}
          disabled={isLoading || isSuccess}
        >
          {strings.SEND_INVOICE}
        </Button>
      </footer>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={showDialog}
        onClose={handleCloseDialog}
      >
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '350px',
          }}
        >
          <CheckCircleOutlineIcon
            style={{ color: green[500], fontSize: 150 }}
          />
          <Typography variant="subtitle1">Invoice sent!</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" type="button" onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {error && <ApiError error={error} />}
    </div>
  );
};

export default SendInvoice;
