import { VehicleImage } from 'models';

export async function replaceShots(
  currentImage: VehicleImage,
  targetImage: VehicleImage,
  vehicleId: string,
  updateVehicleImage: ({
    vehicleId,
    shot,
    imageId,
  }: {
    vehicleId: string;
    shot: string;
    imageId: string;
  }) => void
): Promise<void> {
  if (!currentImage.shot) {
    throw new Error('Current Image does not have a shot');
  }

  if (!targetImage.shot) {
    throw new Error('Current Image does not have a shot');
  }

  const targetImageShot = targetImage.shot;
  const currentImageShot = currentImage.shot;

  if (currentImage.imageType !== 'STOCK') {
    currentImage.shot = targetImageShot;
    await updateVehicleImage({
      vehicleId,
      shot: currentImage.shot,
      imageId: currentImage.id!,
    });
  }

  if (targetImage.imageType !== 'STOCK') {
    targetImage.shot = currentImageShot;
    await updateVehicleImage({
      vehicleId,
      shot: targetImage.shot,
      imageId: targetImage.id!,
    });
  }
}
