import { isUndefined } from 'lodash';
import React, { RefObject, useCallback, useState } from 'react';

import { useAddNote } from 'api';
import { permissions } from 'common';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Alert from 'components/shared/Alert';
import UploadPlaceholder from 'components/shared/UploadPlaceholder';
import { usePermissions } from 'hooks';
import { Note } from 'models';
import { getUrl } from 'store/uploads/helpers';
import { MAX_UPLOADS, UploadItem, UploadType } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';
import humanFileSize from 'utils/fileSystem';

import MentionableInput from '../MentionableInput';
import NoteAttachmentInput from '../NoteAttachmentInput';
import SendIconButton from '../SendIconButton';
import { addMiddleElipse } from '../utils';

interface NoteInputProps {
  vehicleId: string;
  shouldFocus?: boolean;
  reloadNotes: () => {};
  addNote: (note: Note) => void;
}

var NoteInput = ({
  vehicleId,
  shouldFocus,
  reloadNotes,
  addNote,
}: NoteInputProps) => {
  const { hasPermission } = usePermissions();
  const { addNoteAsync } = useAddNote();
  const uploadStoreData = useUploadStore((uploadStore) => ({
    addNoteTargetId: uploadStore.addNoteTargetId,
    getUploadUrlData: uploadStore.getUploadUrlData,
    ajaxUppy: uploadStore.ajaxUppy,
    tusUppy: uploadStore.tusUppy,
    upload: uploadStore.upload,
    addFiles: uploadStore.addFiles,
    uploads: uploadStore.uploads,
  }));
  const attachmentInputRef: RefObject<HTMLInputElement> = React.useRef<any>();
  const [shouldClearContent, setShouldClearContent] = useState(false);
  const [isSendingNote, setIsSendingNote] = useState(false);
  const [stagedFiles, setStagedFiles] = useState<File[]>([]);
  const [noteMessage, setNoteMessage] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(
    shouldFocus || false
  );
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(strings.API_MESSAGE);

  const sendNote = async (message: string) => {
    try {
      const savedNote = await addNoteAsync({
        vehicleId: vehicleId!,
        note: message,
      });

      if (savedNote.data.id && stagedFiles) {
        uploadStoreData.addNoteTargetId(vehicleId!, savedNote.data.id);
        const { baseUrl } = await uploadStoreData.getUploadUrlData();
        const { xhrUploadState } = uploadStoreData.ajaxUppy?.getState() || {
          xhrUploadState: undefined,
        };
        uploadStoreData.ajaxUppy?.setState({
          xhrUpload: {
            ...xhrUploadState,
            endpoint: getUrl(
              baseUrl!,
              '',
              vehicleId!,
              UploadType.NOTE,
              savedNote.data.id,
              undefined,
              false
            ),
          },
        });
        uploadStoreData.upload();
      }

      addNote(savedNote?.data as Note);
    } catch {
      setErrorMessage('There was an error sending the note.');
    }
  };

  const handleNoteSubmit = async () => {
    const message = noteMessage.trim();
    if ((!!message || isSendingNote) && !stagedFiles) {
      return false;
    }

    setShouldClearContent(false);
    setIsSendingNote(true);

    await sendNote(message);

    setIsSendingNote(false);
    setNoteMessage('');
    setShouldClearContent(true);
    handleRemoveAttachment();

    return false;
  };

  const handleRemoveAttachment = () => {
    setStagedFiles([]);
    if (attachmentInputRef.current) {
      attachmentInputRef.current.value = '';
    }
  };

  const handleFileSelected = async (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const files = target.files ? Array.from(target.files) : [];

    const uppyFiles = uploadStoreData.tusUppy
      ?.getFiles()
      .concat(uploadStoreData.ajaxUppy?.getFiles() || []);

    if (uppyFiles && uppyFiles.length + files.length > MAX_UPLOADS) {
      setUploadError(true);
      setErrorMessage(strings.TOO_MANY_FILES);
      return;
    }

    setStagedFiles(files);
    if (!stagedFiles) {
      return;
    }

    setIsInputFocused(true);
    const onUploadSuccess = async () => {
      setStagedFiles([]);
      reloadNotes();
    };
    const onUploadError = (message?: string) => {
      if (message) {
        setErrorMessage(message);
      }
      setStagedFiles([]);
    };
    const onUploadCancel = () => {
      handleRemoveAttachment();
    };
    const uploadTarget = {
      type: UploadType.NOTE,
    };
    uploadStoreData.addFiles(
      files,
      vehicleId!,
      uploadTarget,
      onUploadSuccess,
      onUploadError,
      onUploadCancel
    );
  };

  const activeUploads = () =>
    uploadStoreData.uploads.filter(
      (upload: UploadItem) =>
        upload.meta.parentId === vehicleId &&
        upload.meta.target.type === UploadType.NOTE &&
        isUndefined(upload.meta.target.id)
    );

  const onFocus = useCallback(() => setIsInputFocused(true), []);
  const onBlur = useCallback(() => setIsInputFocused(false), []);
  const setChangeText = useCallback((value) => setNoteMessage(value), []);

  const isNoteSendingDisabled =
    isSendingNote ||
    (!noteMessage && (!stagedFiles || activeUploads().length === 0));

  const handleCloseError = () => {
    setUploadError(false);
    setErrorMessage(strings.API_MESSAGE);
  };

  return (
    <div
      data-vas-testing={testIds.NOTES_MENTIONABLE_INPUT_CONTAINER}
      className={`mentionable-input-container ${
        isInputFocused ? 'input-focus' : ''
      }`}
      role="none"
      onClick={onFocus}
    >
      <MentionableInput
        disabled={
          !hasPermission(permissions.INVENTORY_VDP_NOTES_CREATE) ||
          isSendingNote
        }
        submitAction={handleNoteSubmit}
        onChange={setChangeText}
        shouldClearContent={shouldClearContent}
        shouldFocus={isInputFocused}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {activeUploads().length > 0 && (
        <div className="Notes2-attachments">
          {activeUploads().map((upload: UploadItem) => (
            <div key={upload.id} className="Notes2-attachments-container">
              <UploadPlaceholder upload={upload} showOverlay={false} />
              <span className="Notes2-attachments-filename">
                {addMiddleElipse(upload.meta.name)}
              </span>
              <span className="Notes2-attachments-filesize">
                {humanFileSize(upload.meta.fileSize || 0)}
              </span>
            </div>
          ))}
        </div>
      )}
      <div
        data-vas-testing={testIds.NOTES_ATTACHMENT_INPUT_CONTAINER}
        className={`d-flex justify-content-between icons-container ${
          stagedFiles ? 'icons-container-with-image' : ''
        }`}
      >
        <div
          data-vas-testing={testIds.NOTES_ATTACHMENT_ICON_BUTTON}
          className="d-flex justify-content-between align-items-center"
        >
          <NoteAttachmentInput
            disabled={
              !hasPermission(
                permissions.INVENTORY_VDP_NOTES_ATTACHMENT_CREATE
              ) ||
              isSendingNote ||
              activeUploads().length >= 5
            }
            onFileSelected={handleFileSelected}
            attachmentInputRef={attachmentInputRef}
          />
        </div>
        <SendIconButton
          disabled={isNoteSendingDisabled}
          onClick={handleNoteSubmit}
        />
      </div>
      <Alert
        open={uploadError}
        contentProps={{
          variant: 'error',
          onClose: handleCloseError,
          message: errorMessage,
        }}
      />
    </div>
  );
};

export default NoteInput;
