import { Close } from '@material-ui/icons';
import React from 'react';

import strings from 'common/strings';
import Modal from 'components/shared/Modal';
import { useWindowSize } from 'hooks';
import { VehicleSummary } from 'models';
import { currencyFormatter } from 'utils/formatter';

import './AppraisalInfoModal.scss';

interface AppraisalInfoModalProps {
  open: boolean;
  onClose: () => void;
  vehicle: VehicleSummary;
}

interface AppraisalInfoModalContentProps {
  vehicle: VehicleSummary;
}

const getFieldValue = (amount: number | undefined | null) =>
  amount ? currencyFormatter(amount) : strings.EMPTY_VALUE;

const AppraisalInfoModalContent: React.FC<AppraisalInfoModalContentProps> = ({
  vehicle,
}) => (
  <div className="AppraisalInfoModalContent">
    <div className="AppraisalInfoModalContent-row">
      <div className="AppraisalInfoModalContent-field">
        <div className="AppraisalInfoModalContent-field-label">
          {strings.INITIAL_COST}
        </div>
        <div className="AppraisalInfoModalContent-field-value">
          {getFieldValue(vehicle.reconInfo?.initialCost?.amount)}
        </div>
      </div>
      <div className="AppraisalInfoModalContent-field">
        <div className="AppraisalInfoModalContent-field-label">
          {strings.ESTIMATED_RECON_COST}
        </div>
        <div className="AppraisalInfoModalContent-field-value">
          {getFieldValue(vehicle.reconInfo?.estimatedRecon?.amount)}
        </div>
      </div>
      <div className="AppraisalInfoModalContent-field">
        <div className="AppraisalInfoModalContent-field-label">
          {strings.COST}
        </div>
        <div className="AppraisalInfoModalContent-field-value">
          {getFieldValue(vehicle.reconInfo?.cost?.amount)}
        </div>
      </div>
      <div className="AppraisalInfoModalContent-field">
        <div className="AppraisalInfoModalContent-field-label">
          {strings.ACTUAL_RECON}
        </div>
        <div className="AppraisalInfoModalContent-field-value">
          {getFieldValue(vehicle.reconInfo?.actualRecon?.amount)}
        </div>
      </div>
    </div>
    <div className="AppraisalInfoModalContent-row">
      <div className="AppraisalInfoModalContent-field">
        <div className="AppraisalInfoModalContent-field-label">
          {strings.APPRAISED_VALUE}
        </div>
        <div className="AppraisalInfoModalContent-field-value">
          {getFieldValue(vehicle.reconInfo?.appraisedValue?.amount)}
        </div>
      </div>
      <div className="AppraisalInfoModalContent-field">
        <div className="AppraisalInfoModalContent-field-label">
          {strings.TARGET_COST}
        </div>
        <div className="AppraisalInfoModalContent-field-value">
          {getFieldValue(vehicle.reconInfo?.targetCost?.amount)}
        </div>
      </div>
      <div className="AppraisalInfoModalContent-field">
        <div className="AppraisalInfoModalContent-field-label">
          {strings.PACK}
        </div>
        <div className="AppraisalInfoModalContent-field-value">
          {getFieldValue(vehicle.reconInfo?.pack?.amount)}
        </div>
      </div>
      <div className="AppraisalInfoModalContent-field">
        <div className="AppraisalInfoModalContent-field-label">
          {strings.OVERAGE}
        </div>
        <div className="AppraisalInfoModalContent-field-value">
          {getFieldValue(vehicle.reconInfo?.overage?.amount)}
        </div>
      </div>
    </div>
  </div>
);

const AppraisalInfoModalMobile: React.FC<AppraisalInfoModalProps> = ({
  open,
  onClose,
  vehicle,
}) => {
  const visibilityClassName = open
    ? 'AppraisalInfoModalMobile-show'
    : 'AppraisalInfoModalMobile-hide';

  return (
    <div className={`AppraisalInfoModalMobile ${visibilityClassName}`}>
      <div className="AppraisalInfoModalMobile-title-row">
        <Close className="AppraisalInfoModalMobile-close" onClick={onClose} />
        <div className="AppraisalInfoModalMobile-title">
          {strings.APPRAISAL_INFO}
        </div>
      </div>
      <AppraisalInfoModalContent vehicle={vehicle} />
    </div>
  );
};
const AppraisalInfoModalDesktop: React.FC<AppraisalInfoModalProps> = ({
  open,
  onClose,
  vehicle,
}) => (
  <Modal
    className="AppraisalInfoModalDesktop"
    title={strings.APPRAISAL_INFO}
    open={open}
    onClose={onClose}
    onPrimaryButtonClick={onClose}
    primaryButtonLabel={strings.OK}
    closeButton={false}
    secondaryButton={false}
  >
    <AppraisalInfoModalContent vehicle={vehicle} />
  </Modal>
);
const AppraisalInfoModal: React.FC<AppraisalInfoModalProps> = (props) => {
  const windowSize = useWindowSize();
  return windowSize.isMobileViewport() ? (
    <AppraisalInfoModalMobile {...props} />
  ) : (
    <AppraisalInfoModalDesktop {...props} />
  );
};

export default AppraisalInfoModal;
