import { chain } from 'lodash';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';

import { useVehicleHistory } from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import FlatVDPPaneContent from 'components/shared/FlatVDPPaneContent';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import PermissionsGate from 'components/shared/PermissionsGate';

import VehicleLog from './VehicleLog';

import './FlatHistory.scss';

interface FlatHistoryProps {
  vehicleId: string;
}

var FlatHistory = ({ vehicleId }: FlatHistoryProps) => {
  const { isLoading, data: { data } = {} } = useVehicleHistory(vehicleId);
  const groupedHistory = chain(data?.items)
    .groupBy((item) => moment(new Date(item.timestamp)).format('M/D/YYYY'))
    .map((group, title) => ({ title, data: group }))
    .value();

  return (
    <Container
      fluid
      className="FlatHistory full-height"
      data-vas-testing={testIds.VDP_HISTORY_CONTAINER}
    >
      <FlatVDPPaneHeader vehicleId={vehicleId}>
        {strings.HISTORY}
      </FlatVDPPaneHeader>
      <FlatVDPPaneContent className="FlatHistory-content">
        <PermissionsGate permissions={[permissions.INVENTORY_VDP_HISTORY_VIEW]}>
          <Row noGutters>
            <Col>
              <VehicleLog historyLog={groupedHistory} isLoading={isLoading} />
            </Col>
          </Row>
        </PermissionsGate>
      </FlatVDPPaneContent>
    </Container>
  );
};

export default FlatHistory;
