import 'intl';
import 'intl/locale-data/jsonp/en';
import moment from 'moment';

import strings from 'common/strings';

const currencyDefaults = {
  currency: 'USD',
  style: 'currency',
};

export const currencyFormatter = (
  value = 0,
  options = {},
  locale = moment.locale()
) =>
  new Intl.NumberFormat(locale, { ...currencyDefaults, ...options }).format(
    value
  );

export const addDollarSymbol = (value: any) => `$${value}`;

export const deleteCommas = (value: any) => value.toString().replace(/,/g, '');

export const parseToFloat = (value: any) => {
  const result = parseFloat(deleteCommas(value));
  if (Number.isNaN(result)) return 0;
  return result;
};

export const cleanNumber = (numberString: string) =>
  numberString.replace(/[,\\$A-Za-z]/g, '').trim();

export const formatMileage = (
  mileage: number | string | undefined,
  odometerUnit: string = strings.MILES,
  includeMi: boolean | undefined = true
) => {
  if (typeof mileage === 'undefined') return strings.EMPTY_VALUE;
  if (typeof mileage === 'string') mileage = Number(mileage);
  const shortenOdometer =
    odometerUnit.toUpperCase() === strings.KILOMETERS.toUpperCase()
      ? ` ${strings.KILOMETERS_SHORT}`
      : ` ${strings.MILES_SHORT}`;
  return `${mileage.toLocaleString()} ${includeMi ? shortenOdometer : ''}`;
};

// Returns a formatted phone number with parenthesis suurounding the area code and hyphen
// separating the middle 3 and final 4 digits. Optional separator override.
export const phoneNumberFormatter = (
  phoneNumberString: string,
  separator?: string
) => {
  const phoneNumber = phoneNumberString?.replace(/[^0-9]+/g, '');
  if (phoneNumber?.length === 10) {
    if (separator) {
      return [
        phoneNumber.slice(0, 3),
        phoneNumber.slice(3, 6),
        phoneNumber.slice(6),
      ].join(separator);
    }
    const phoneJoin: string = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6)}`;
    return phoneJoin ?? strings.DEFAULT;
  }
  return phoneNumber ?? strings.DEFAULT;
};

// Returns formatted string with words starting with capital letter and separated by spaces.
export const startWithUpperCase = (unformattedString: string): string => {
  const words = unformattedString.split(/[_ ]/);
  const acronyms = ['CRM', 'SMS'];
  const formattedWords = words.map((word) => {
    if (word.length < 1) return word;
    if (acronyms.includes(word)) return word;
    return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
  });
  return formattedWords.join(' ');
};
