import Skeleton from 'react-loading-skeleton';

interface SkeletonProps {
  children: any;
  renderSkeleton: boolean;
}

var SkeletonComponent = ({ children, renderSkeleton }: SkeletonProps) => {
  if (renderSkeleton) {
    return <Skeleton />;
  }

  return <>{children}</>;
};

export default SkeletonComponent;
