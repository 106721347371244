/* eslint-disable consistent-return */
import {
  Close,
  Done,
  ErrorOutline,
  Pause,
  PlayArrow,
} from '@material-ui/icons';
import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import {
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_VIDEO_EXTENSIONS,
  UploadItem,
  UploadStatus,
} from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';

import { AttachmentIcon, ImageIcon, VideoIcon } from './Icons';

import './UploadPlaceholder.scss';

interface UploadPlaceholderProps {
  large?: boolean;
  showOverlay?: boolean;
  upload: UploadItem;
}

const UploadPlaceholder: React.FC<UploadPlaceholderProps> = ({
  large = false,
  showOverlay,
  upload,
}) => {
  const uploadStoreData = useUploadStore((uploadStore) => ({
    pauseOrResumeUpload: uploadStore.pauseOrResumeUpload,
    removeFile: uploadStore.removeFile,
  }));
  const onPauseResume = () => uploadStoreData.pauseOrResumeUpload(upload);
  const getStateIcon = () => {
    if (upload.meta.uploadMethod === 'AJAX') return;
    switch (upload.uploadStatus) {
      case UploadStatus.UPLOADING:
        return (
          <Pause
            className="UploadPlaceholder-progress-icon"
            onClick={onPauseResume}
          />
        );
      case UploadStatus.COMPLETED:
        return <Done className="UploadPlaceholder-progress-icon" />;
      case UploadStatus.PAUSED:
        return (
          <PlayArrow
            className="UploadPlaceholder-progress-icon"
            onClick={onPauseResume}
          />
        );
      case UploadStatus.ERROR:
        return (
          <ErrorOutline
            className="UploadPlaceholder-progress-icon"
            onClick={onPauseResume}
          />
        );
      default:
        return null;
    }
  };

  const getDefaultIcon = (fileExtension: string) => {
    if (ALLOWED_IMAGE_EXTENSIONS.includes(fileExtension)) {
      if (upload.meta.objectUrl) {
        return null;
      }
      return <ImageIcon />;
    }

    if (ALLOWED_VIDEO_EXTENSIONS.includes(fileExtension)) {
      return <VideoIcon />;
    }
    return <AttachmentIcon />;
  };

  const progressbarClassName = large
    ? 'UploadPlaceholder-overlay-progress-large'
    : '';
  const backgroundImageStyle =
    upload && upload.meta && upload.meta.objectUrl
      ? { backgroundImage: `url(${upload.meta.objectUrl})` }
      : {};

  return (
    <div className="UploadPlaceholder">
      <div className="UploadPlaceholder-image" style={backgroundImageStyle}>
        {getDefaultIcon(upload.fileExtension)}
      </div>
      <>
        {showOverlay && (
          <div className="UploadPlaceholder-overlay">
            <CircularProgressbarWithChildren
              className={`UploadPlaceholder-overlay-progress ${progressbarClassName}`}
              value={upload.progress}
              strokeWidth={8}
            >
              {getStateIcon()}
            </CircularProgressbarWithChildren>
          </div>
        )}
        <button
          type="button"
          className="UploadPlaceholder-cancel-button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            uploadStoreData.removeFile(upload);
          }}
        >
          <Close className="UploadPlaceholder-cancel-button-icon" />
        </button>
      </>
    </div>
  );
};
export default UploadPlaceholder;
