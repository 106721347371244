/* eslint-disable import/prefer-default-export */

export interface IconProps {
  color?: string;
  className?: string;
  size?: number;
  viewBox?: string;
}

export const defaultColor = '#9E9E9E';
