import React from 'react';

import { useWindowSize } from 'hooks';

import './FlatTasksHeader.scss';

interface FlatTasksHeaderProps {
  title: string;
  taskButtons?: React.ReactNode[];
  actionButtons?: React.ReactNode[];
}

const FlatTasksHeader: React.FC<FlatTasksHeaderProps> = ({
  actionButtons,
  taskButtons,
  title,
}) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.isMobileViewport();
  const classHeaderString = isMobile
    ? 'FlatTasksHeader-mobile'
    : 'FlatTasksHeader-desktop';

  return (
    <div className="FlatTasksHeader">
      <div className={classHeaderString}>
        {!isMobile && (
          <>
            <div className="FlatTasksHeader-title">{title}</div>
            <div className="FlatTasksHeader-task-buttons">{taskButtons}</div>
          </>
        )}
        {actionButtons && (
          <div className="FlatTasksHeader-action-buttons">{actionButtons}</div>
        )}
      </div>
    </div>
  );
};

export default FlatTasksHeader;
