/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const BriefcaseX: React.FC<IconProps> = ({
  color = defaultColor,
  className,
}) => (
  <svg width={20} height={19} viewBox="0 0 20 19" fill="none">
    <path
      className={className}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0H8C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2V4H2C1.46957 4 0.960859 4.21071 0.585786 4.58579C0.210714 4.96086 0 5.46957 0 6V17C0 17.5304 0.210714 18.0391 0.585786 18.4142C0.960859 18.7893 1.46957 19 2 19H18C18.5304 19 19.0391 18.7893 19.4142 18.4142C19.7893 18.0391 20 17.5304 20 17V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H14V2C14 1.46957 13.7893 0.960859 13.4142 0.585786C13.0391 0.210714 12.5304 0 12 0ZM12 2V4H8V2H12ZM12.59 8L10 10.59L7.41 8L6 9.41L8.59 12L6 14.59L7.41 16L10 13.41L12.59 16L14 14.59L11.41 12L14 9.41L12.59 8Z"
      fill={color}
    />
  </svg>
);

export default BriefcaseX;
