import strings from '../../../common/strings';

const pattern = /^(?=.{8,20})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/;
const format = {
  pattern,
  message: strings.CHANGE_PASSWORD_VALIDATION_MESSAGE,
};

export const changePasswordValidations = {
  password: {
    presence: { allowEmpty: false },
    format,
  },
  confirmPassword: {
    presence: { allowEmpty: false },
    equality: 'password',
    format,
  },
};
