import { FC } from 'react';

import { Arrow as IArrow, Arrows as IArrows } from 'models/carfax';

export interface ArrowProps {
  arrow: IArrow;
}

export const Arrow: FC<ArrowProps> = ({ arrow }) => (
  <div className="arrow">
    <div className="arrow-direction">
      <img src={arrow.direction} alt="arrow-direction" />
    </div>
    <div className="arrow-icon">
      <img src={arrow.iconUrl} alt="arrow-icon" />
    </div>
    <div className="arrow-text">{arrow.text}</div>
  </div>
);

export interface ArrowsProps {
  arrows: IArrows;
}

const Arrows: FC<ArrowsProps> = ({ arrows }) => {
  return (
    <div className="arrows">
      {arrows.up.map((arrow) => (
        <Arrow arrow={arrow} key={arrow.text} />
      ))}
      {arrows.down.map((arrow) => (
        <Arrow arrow={arrow} key={arrow.text} />
      ))}
    </div>
  );
};

export default Arrows;
