import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { io, Socket } from 'socket.io-client';

import { APIResponse, useSharedSteps, useToken } from 'api';
import { permissions } from 'common';
import { useCurrentURLVars, usePermissions } from 'hooks';
import {
  EventType,
  Message,
  useSendInventoryUpdates,
} from 'hooks/useInventoryUpdates';
import { VehicleSummary } from 'models';

import { useInventoryNotifications } from './useInventoryNotifications';

const IO_SERVER_URL = process.env.REACT_APP_IO_SERVER_URL;
const IO_SERVER_PATH = process.env.REACT_APP_IO_SERVER_PATH;
const NAMESPACE = `${process.env.REACT_APP_RECONVELOCITY_APPSERVICE_ENV}-rvWeb`;

interface JoinRoomResponse {
  rooms: string[];
  lastMessageTimestamp: string;
}
export const RealTimeContext = React.createContext({});

const handleRealtimeConnection = (
  socket: Socket,
  setIsSocketConnected: Function,
  setDisconnectTimeStamp: Function,
  setHasRecoveredMissedMessages: Function
) => {
  socket.on('connect', () => {
    setIsSocketConnected(true);
    // connect to any rooms here?
  });

  socket.on('disconnect', () => {
    setIsSocketConnected(false);
    setDisconnectTimeStamp(Date.now());
    setHasRecoveredMissedMessages(false);
    // https://socket.io/docs/v4/client-api/#event-disconnect
  });

  socket.on('connect_error', (error) => {
    console.error(error);
    setIsSocketConnected(false);

    // From the socket.io transports option docs: https://socket.io/docs/v4/client-options/#transports
    // We started with 'websocket' as the original config when the socket was instantiated.
    // This config will:
    // 1. check if the browser has WebSocket support.
    // 2. if not, use polling to begin with. Otherwise, attempt a socket connection.
    // 3. If (2) fails, the following line reverts to polling first in case websocket connection failed.
    // If we get to polling and it fails, there's no fallback from there.
    socket.io.opts.transports = ['polling', 'websocket'];
    // possibly handle token refresh
    // https://github.com/carpediemsolutionsllc/car-appservice-pub_sub-nodejs/blob/dev/public/index.html#L137
  });
};

const Realtime = ({ children }: React.PropsWithChildren<{}>) => {
  const { data: tokenData, isLoading: isTokenLoading } = useToken();
  const { data: sharedStepsData, isLoading: isSharedStepsLoading } =
    useSharedSteps();
  const { hasPermission } = usePermissions();
  const queryClient = useQueryClient();
  const { tenantId, page, inventoryId } = useCurrentURLVars();

  const inventoryNotifications = useInventoryNotifications();
  const [socket, setSocket] = useState<Socket>();
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [disconnectTimeStamp, setDisconnectTimeStamp] = useState<number>();
  const [hasRecoveredMissedMessages, setHasRecoveredMissedMessages] =
    useState(false);

  const isLoading = isTokenLoading || isSharedStepsLoading;
  const isRouteSrp =
    page !== 'inventory' || (page === 'inventory' && inventoryId);

  const sharedStepsMap = useMemo(() => {
    const { data: sharedSteps } = sharedStepsData ?? { data: [] };
    return sharedSteps?.reduce((previousValue: any, currentValue: any) => {
      return { ...previousValue, [currentValue.stepId]: currentValue };
    }, {});
  }, [sharedStepsData]);

  useSendInventoryUpdates(socket, isLoading, tenantId, sharedStepsMap);

  const joinRoomCallback = useCallback(
    (response: JoinRoomResponse) => {
      // parseInt will return NaN if lastMessageTimestamp is undefined or null.
      const lastMessageTimestamp = parseInt(response?.lastMessageTimestamp);
      if (
        disconnectTimeStamp &&
        lastMessageTimestamp &&
        disconnectTimeStamp < lastMessageTimestamp
      ) {
        if (!hasRecoveredMissedMessages) {
          setHasRecoveredMissedMessages(true);
        }
      }
    },
    [disconnectTimeStamp, hasRecoveredMissedMessages]
  );

  const { token, user } = tokenData ?? {};
  const userId = user?.id;

  useEffect(() => {
    if (IO_SERVER_URL) {
      const ioSocket = io(`${IO_SERVER_URL}/${NAMESPACE}`, {
        path: `${IO_SERVER_PATH}/socket.io`,
        auth: { token },
        transports: ['websocket', 'polling'],
        withCredentials: true,
      });

      handleRealtimeConnection(
        ioSocket,
        setIsSocketConnected,
        setDisconnectTimeStamp,
        setHasRecoveredMissedMessages
      );
      setSocket(ioSocket);

      return () => {
        ioSocket.disconnect();
      };
    }
  }, [token]);

  useEffect(() => {
    const room = `user/${userId}/notification`;
    const event = `${room}/update`;
    const handler = () => {
      queryClient.invalidateQueries('/notifications/unreadCount');
    };
    if (isSocketConnected) {
      socket?.emit('joinRoom', room, joinRoomCallback);
      socket?.on(event, handler);

      return () => {
        socket?.off(event);
        socket?.emit('leaveRoom', room);
      };
    }
  }, [isSocketConnected, joinRoomCallback, queryClient, socket, userId]);

  useEffect(() => {
    const room = `tenant/${tenantId}/notification`;
    const event = `${room}/update`;
    const handler = () => {
      queryClient.invalidateQueries('/notifications');
    };
    if (isSocketConnected) {
      socket?.emit('joinRoom', room, joinRoomCallback);
      socket?.on(event, handler);

      return () => {
        socket?.off(event);
        socket?.emit('leaveRoom', room);
      };
    }
  }, [isSocketConnected, joinRoomCallback, queryClient, socket, tenantId]);

  useEffect(() => {
    const room = `user/${userId}/notification`;
    const event = `${room}/update`;
    const handler = () => {
      queryClient.invalidateQueries('/notifications');
    };
    if (isSocketConnected) {
      socket?.emit('joinRoom', room, joinRoomCallback);
      socket?.on(event, handler);

      return () => {
        socket?.off(event);
        socket?.emit('leaveRoom', room);
      };
    }
  }, [isSocketConnected, joinRoomCallback, queryClient, socket, userId]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const room = `tenant/${tenantId}/inventory`;
    const event = `${room}/update`;
    const handler = (message: Message) => {
      const { eventType, inventoryId, tenantId: messageTenantId } = message;

      if (hasPermission(permissions.RECON_VIEW)) {
        if (eventType === EventType.stepChange) {
          queryClient.invalidateQueries('/dashboard/goals');

          if (messageTenantId !== tenantId) {
            inventoryNotifications.handleMessages(message);
          }
        }
      }

      if (eventType === EventType.create) {
        inventoryNotifications.handleMessages(message);
      } else {
        queryClient.invalidateQueries('/inventory', {
          // This will determine when useVehicleList Infinite Query page is reloaded
          // refetchPage function specifies if a page gets refreshed based on custom logic
          // https://react-query.tanstack.com/reference/QueryClient#queryclientinvalidatequeries
          refetchPage: (page: APIResponse<VehicleSummary[]>) => {
            //Invalidate page only if vehicle was found in collection;
            return page?.data.some((item) => {
              return inventoryId === item?.vehicleCard?.id;
            });
          },
        });
        queryClient.invalidateQueries(`/inventory/${inventoryId}`);
        queryClient.invalidateQueries(`/inventory/${inventoryId}/notes`);
      }
    };
    if (isSocketConnected) {
      socket?.emit('joinRoom', room, joinRoomCallback);
      socket?.on(event, handler);

      return () => {
        socket?.off(event);
        socket?.emit('leaveRoom', room);
      };
    }
  }, [
    hasPermission,
    inventoryNotifications,
    isLoading,
    isSocketConnected,
    joinRoomCallback,
    queryClient,
    socket,
    tenantId,
  ]);

  useEffect(() => {
    if (!isRouteSrp) {
      return;
    }

    const srpVinsToUpdate: any = {};
    const event = `tenant/${tenantId}/inventory/update`;

    const handler = (message: Message) => {
      const { inventoryId } = message;
      if (inventoryId) {
        srpVinsToUpdate[inventoryId] = true;
      }
    };

    if (isSocketConnected) {
      socket?.on(event, handler);
      return () => {
        socket?.off(event);
        queryClient.invalidateQueries('/inventory', {
          refetchPage: (page: APIResponse<VehicleSummary[]>) => {
            return page?.data.some((item) => {
              const id = item?.vehicleCard?.id ?? '';
              return srpVinsToUpdate[id];
            });
          },
        });
      };
    }
  }, [isRouteSrp, isSocketConnected, queryClient, socket, tenantId]);

  useEffect(() => {
    const room = `tenant/${tenantId}/tags`;
    const event = `${room}/update`;
    const handler = () => {
      queryClient.invalidateQueries('/utility/tags');
    };
    if (isSocketConnected) {
      socket?.emit('joinRoom', room, joinRoomCallback);
      socket?.on(event, handler);

      return () => {
        socket?.off(event);
        socket?.emit('leaveRoom', room);
      };
    }
  }, [isSocketConnected, joinRoomCallback, queryClient, socket, tenantId]);

  useEffect(() => {
    const room = `tenant/${tenantId}/taskTemplates`;
    const event = `${room}/update`;
    const handler = () => {
      queryClient.invalidateQueries('/utility/tasks/templates');
    };
    if (isSocketConnected) {
      socket?.emit('joinRoom', room, joinRoomCallback);
      socket?.on(event, handler);

      return () => {
        socket?.off(event);
        socket?.emit('leaveRoom', room);
      };
    }
  }, [isSocketConnected, joinRoomCallback, queryClient, socket, tenantId]);

  useEffect(() => {
    const room = `tenant/${tenantId}/workflowSteps`;
    const event = `${room}/update`;
    const handler = () => {
      queryClient.invalidateQueries('/utility/workflow/steps');
    };
    if (isSocketConnected) {
      socket?.emit('joinRoom', room, joinRoomCallback);
      socket?.on(event, handler);

      return () => {
        socket?.off(event);
        socket?.emit('leaveRoom', room);
      };
    }
  }, [isSocketConnected, joinRoomCallback, queryClient, socket, tenantId]);

  return (
    <RealTimeContext.Provider value={{ ...inventoryNotifications }}>
      {children}
    </RealTimeContext.Provider>
  );
};

export default Realtime;
