import { useState } from 'react';

import { permissions } from 'common/permissions';
import strings from 'common/strings';
import Modal from 'components/shared/Modal';
import { usePermissions } from 'hooks';
import { Note } from 'models';

import MentionableInput from '../MentionableInput';

import './EditNoteModal.scss';

interface EditNoteModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (selectedNote: Note) => void;
  note: Note;
}

var EditNoteModal = ({ onClose, note, onUpdate, open }: EditNoteModalProps) => {
  const [openModal, setOpenModal] = useState(open);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [text, setText] = useState('');
  const { hasPermission } = usePermissions();

  const handleModalClose = () => {
    setOpenModal(false);
    onClose();
  };

  const handleTextChange = (textBlob: string) => {
    setText(textBlob);
    setDisableSaveButton(!textBlob);
  };

  const handleSave = () => {
    setOpenModal(false);
    onUpdate({ ...note, note: text });
  };

  return (
    <Modal
      className="EditNotesModal"
      title={strings.EDIT_NOTE_TITLE}
      open={openModal}
      onClose={handleModalClose}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={
        !hasPermission(permissions.INVENTORY_VDP_NOTES_UPDATE) ||
        disableSaveButton
      }
    >
      <div>
        <p className="text-muted">Notes</p>
        <div className="border border-primary rounded p-3">
          <MentionableInput
            submitAction={() => {}}
            shouldFocus
            existingContent={note.note}
            onChange={handleTextChange}
            shouldClearContent={false}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditNoteModal;
