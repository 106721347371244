import AttachmentIcon from '@material-ui/icons/Attachment';
import React, { RefObject } from 'react';

import { ALLOWED_FILETYPES_STRING } from 'store/uploads/types';

import './NoteAttachmentInput.scss';

interface NoteAttachementInputProps {
  onFileSelected(event: React.FormEvent<HTMLInputElement>): void;
  disabled: boolean;
  attachmentInputRef: RefObject<HTMLInputElement>;
  id?: string;
}

var NoteAttachementInput = ({
  onFileSelected,
  disabled,
  attachmentInputRef,
  id,
}: NoteAttachementInputProps) => {
  const key = id ?? 'browse';
  return (
    <label htmlFor={key} className="mb-0">
      <input
        ref={attachmentInputRef}
        style={{ display: 'none' }}
        type="file"
        name="browse"
        id={key}
        onChange={onFileSelected}
        disabled={disabled}
        multiple
        accept={ALLOWED_FILETYPES_STRING}
      />
      <AttachmentIcon className={`upload-icon ${disabled ? 'disabled' : ''}`} />
    </label>
  );
};

export default NoteAttachementInput;
