import { isUndefined } from 'lodash';
import React from 'react';

import strings from 'common/strings';
import { StepItem } from 'models';
import { getFormattedDurationFromSeconds } from 'utils/time';

import './ProgressBar.scss';

interface ProgressBarProps {
  currentStep: StepItem;
  durationsVisible?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentStep,
  durationsVisible,
}) => {
  const formattedActualDuration = isUndefined(
    currentStep.reconTimeInStepSeconds
  )
    ? strings.EMPTY_VALUE
    : getFormattedDurationFromSeconds(currentStep.reconTimeInStepSeconds);
  const formattedTargetDuration = isUndefined(
    currentStep.recommendedDurationSeconds
  )
    ? strings.EMPTY_VALUE
    : getFormattedDurationFromSeconds(currentStep.recommendedDurationSeconds);

  let percentage =
    currentStep.reconTimeInStepSeconds && currentStep.recommendedDurationSeconds
      ? Number(
          (
            currentStep.reconTimeInStepSeconds /
            currentStep.recommendedDurationSeconds
          ).toFixed(4)
        ) * 100
      : 0;
  if (percentage > 100) percentage = 100;
  return (
    <div className="ProgressBar">
      <div className="ProgressBar-progress-bar">
        <div
          className={`ProgressBar-percentage ${currentStep?.status?.toLowerCase()}`}
          style={{ width: `${percentage}%` }}
        />
      </div>
      {durationsVisible && (
        <div className="ProgressBar-duration-values">
          <span
            className={`ProgressBar-actual-duration ${currentStep?.status?.toLowerCase()}-text`}
          >
            {formattedActualDuration}
          </span>
          <span className="ProgressBar-target-duration">
            {formattedTargetDuration}
          </span>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
