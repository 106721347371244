/* eslint-disable import/extensions */

import React, { useEffect, useMemo, useReducer, useState } from 'react';
import 'bootstrap/js/src/collapse.js';

import useVehicleEngagements, {
  useVehicleEngagementsSearchFacets,
} from 'api/inventory/useVehicleEngagements';
import {
  getCanGoToNextPage,
  getCanGoToPreviousPage,
  getStartIndex,
  initialState,
  reducer,
} from 'components/pages/Dashboard/EngageTab/EngagementsTable/EngagementsFilterReducer';
import EngagementsTable from 'components/pages/Dashboard/EngageTab/EngagementsTable/EngagementsTable';
import { TabProps } from 'components/shared/Tabs/Tabs';
import { getLastQueryParamWithName } from 'navigation/util/ParamHelpers';

import './VelocityEngageEngagementsTab.scss';

interface VelocityEngageEngagementsTabProps extends TabProps {
  inventoryId?: string;
}

const VelocityEngageEngagementTab: React.FC<
  VelocityEngageEngagementsTabProps
> = ({ inventoryId }) => {
  const [state, reduce] = useReducer(reducer, initialState);
  const { data: { data: facets } = {}, isLoading: isLoadingFacets } =
    useVehicleEngagementsSearchFacets(inventoryId);
  const {
    data: { data } = {},
    isLoading,
    isError,
    error,
  } = useVehicleEngagements(inventoryId, {
    pageSize: state.pageSize,
    startIndex: getStartIndex(state),
    filterFacets: state.filterBy,
    groupBy: state.groupBy,
  });

  const allEngagements = useMemo(
    () => data?.list.flatMap((group) => group.engagements) ?? [],
    [data?.list]
  );

  const engagementId = getLastQueryParamWithName('engagementId');
  const [initialFilterSetup, setInitialFilterSetup] = useState(false);

  /*
    This will only set the correct initially if the engagement being
    sought is among the first 20.
    */
  useEffect(() => {
    if (
      !initialFilterSetup &&
      typeof engagementId !== 'undefined' &&
      state.filterBy.USER !== engagementId
    ) {
      const engagement = allEngagements.find(
        (item) => item.id === engagementId
      );
      if (engagement?.prospect?.id) {
        reduce({
          type: 'setFilterBy',
          filterBy: {
            ...state.filterBy,
            SHOPPER: engagement.prospect.id,
          },
        });
        setInitialFilterSetup(true);
      }
    }
    console.warn(`Engagement Tab - engagementId ${engagementId}`);
  }, [allEngagements, engagementId, initialFilterSetup, state.filterBy]);

  useEffect(() => {
    reduce({ type: 'setTotal', total: data?.totalItems ?? 0 });
  }, [data?.totalItems]);
  return (
    <div>
      <EngagementsTable
        canGoToNextPage={getCanGoToNextPage(state)}
        canGoToPreviousPage={getCanGoToPreviousPage(state)}
        filterBy={state.filterBy}
        groupBy={state.groupBy}
        onNextPage={() => reduce({ type: 'nextPage' })}
        onPreviousPage={() => reduce({ type: 'previousPage' })}
        onSwitchOrderBy={(attribute) =>
          reduce({ type: 'switchOrderBy', attribute })
        }
        currentPage={state.page}
        pageSize={state.pageSize}
        setFilterBy={(filterBy) => {
          reduce({ type: 'setFilterBy', filterBy });
        }}
        resetFilters={() => {
          reduce({ type: 'reset' });
        }}
        setPageSize={(size: number) => {
          reduce({ type: 'setPageSize', size });
        }}
        sortBy={state.sortBy}
        sortDirection={state.sortDirection}
        setGroupBy={(groupBy) => reduce({ type: 'setGroupBy', groupBy })}
        facets={facets}
        data={data}
        totalItems={state.totalItems}
        isError={isError || !!error}
        isLoading={isLoading}
        isLoadingFacets={isLoadingFacets}
        hideVehicleColumn
        hideGroupSelector
      />
    </div>
  );
};

export default VelocityEngageEngagementTab;
