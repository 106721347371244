import React from 'react';

type StringNullUseState = [
  string | null,
  React.Dispatch<React.SetStateAction<string | null>>
];
export const EngageSelectContext = React.createContext<StringNullUseState>([
  null,
  () => {},
]);
