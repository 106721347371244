import lodash from 'lodash';

export declare type WorkflowStepStatus =
  | 'OK'
  | 'WARNING'
  | 'DANGER'
  | 'UNKNOWN'
  | 'TOTAL';

export const filterIdsUpMap: Record<string, string> = {
  stepId: 'STEP',
  status: 'STEP_STATUS',
  inventoryState: 'INVENTORY_STATE',
  color: 'INVENTORY_COLOR',
  year: 'VEHICLE_YEAR',
  mileage: 'INVENTORY_MILEAGE',
  retailPrice: 'INVENTORY_RETAIL_PRICE',
  bodyType: 'VEHICLE_BODY_TYPE',
  make: 'VEHICLE_MAKE',
  model: 'VEHICLE_MODEL',
};

// TODO: remove mapping
export const workflowStatusesDownMap: Record<string, WorkflowStepStatus> = {
  ONTIME: 'OK',
  WARNING: 'WARNING',
  LATE: 'DANGER',
  UNKNOWN: 'UNKNOWN',
};

export const filterIdsDownMap = lodash.invert(filterIdsUpMap);

export const downMaps: Record<string, Record<string, string>> = {
  status: workflowStatusesDownMap,
};
