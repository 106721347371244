export type DefinedStep = {
  id: string;
  name: string;
};

export const isStepDefined = (
  step: Partial<DefinedStep>
): step is DefinedStep => {
  return !!step.id && !!step.name;
};
