import { CSSProperties, MouseEventHandler } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { testIds } from 'common';
import VehicleCard from 'components/pages/VehicleCollectionBrowser/VehicleCard';
import { useVehicleCardInfo } from 'components/pages/VehicleCollectionBrowser/VehicleCard/VehicleCard';
import { VehicleSummary } from 'models';

import './VehicleList.scss';

type Props = {
  vehicles: VehicleSummary[];
  onClick: MouseEventHandler<HTMLDivElement>;
};

const VehicleList = ({ onClick, vehicles }: Props) => {
  const rowCount = vehicles?.length ?? 0;
  const itemSize = useVehicleCardInfo(false, true).cardHeight + 41;

  return (
    <div
      data-vas-testing={testIds.INVOICING_VEHICLE_LIST}
      className="VehicleList2 full-height full-width flex-rows"
    >
      <div className="VehicleList-list-container flex-grow full-width">
        <AutoSizer>
          {({ height }) => {
            return (
              <FixedSizeList
                height={height}
                width={'100%'}
                style={{ overflowX: 'hidden' }}
                itemCount={rowCount}
                itemSize={itemSize}
                itemData={{ onClick, vehicles, itemHeight: itemSize }}
              >
                {renderRow}
              </FixedSizeList>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

type RenderRowProps = {
  index: number;
  data: {
    onClick: MouseEventHandler<HTMLDivElement>;
    vehicles: VehicleSummary[];
    itemHeight: number;
  };
  style: CSSProperties;
};
const renderRow = ({ index, data, style }: RenderRowProps) => {
  const { itemHeight, vehicles } = data;
  const vehicle = vehicles[index];

  return (
    <div style={style}>
      <VehicleCard
        key={vehicle?.vehicleCard?.id}
        contextData={{
          vehicle: vehicle,
          tasks: vehicle?.tasks,
          forceShowEngageTab: true,
          inExtensionCardMode: false,
          isOnboarding: false,
          isShowroomMode: false,
          position: index,
          isVendorMode: true,
        }}
        height={itemHeight}
        config={{
          onVehicleCardEngageSendClick: () => {},
          onViewCVDPClick: async () => {},
        }}
        onPreviewVehicle={() => {}}
        isPreviewed={false}
      />
    </div>
  );
};

export default VehicleList;
