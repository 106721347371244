import { FC } from 'react';

import { PartUsed as IPartUsed } from 'models';
import { currencyFormatter } from 'utils/formatter';

export interface PartUsedProps {
  partUsed: IPartUsed;
}

const PartUsed: FC<PartUsedProps> = ({ partUsed }) => {
  const {
    part: { description, partNumber, listPrice },
    numberSold,
    totalPrice,
  } = partUsed;

  return (
    <div className="PartsUsed-Table-row">
      <div className="details">
        <div className="partNumber">{partNumber}</div>
        <div className="description">{description}</div>
      </div>
      <div className="breakdown">
        <div>{numberSold}</div>
        <div>{currencyFormatter(listPrice)}</div>
        <div>{currencyFormatter(totalPrice)}</div>
        <div />
      </div>
    </div>
  );
};

export default PartUsed;
