import { StepItem, Workflow } from 'models';

type ReadonlyWorkflow = ReadonlyArray<Readonly<StepItem>>;

type RecurseChildSteps = (steps: ReadonlyWorkflow, level?: number) => Workflow;

export const recurseChildSteps: RecurseChildSteps = (steps, level = 0) => {
  const result: Workflow = [];

  steps.forEach((step) => {
    const stepResult = {
      ...step,
      level,
    };

    result.push(stepResult);

    if (step?.childSteps?.length) {
      result.push(...recurseChildSteps(step.childSteps, level + 1));
    }
  });

  return result;
};
