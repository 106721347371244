import React, { useState } from 'react';

import strings from 'common/strings';
import Alert from 'components/shared/Alert';

interface ApiErrorProps {
  error?: any;
}

const ApiError: React.FC<ApiErrorProps> = ({ error }) => {
  const [isHidden, setIsHidden] = useState(false);

  const handleClose = () => setIsHidden(true);

  if (isHidden || !error) {
    return <span />;
  }

  return (
    <Alert
      open
      contentProps={{
        message: strings.API_MESSAGE,
        variant: 'error',
        onClose: handleClose,
      }}
    />
  );
};

export default ApiError;
