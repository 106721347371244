import { MoreHoriz } from '@material-ui/icons';
import { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useDeleteNote, useToken } from 'api';
import { permissions } from 'common';
import { usePermissions } from 'hooks';
import { NoteV2 } from 'models';

interface VehicleCardNoteEditDropDownProps {
  note: NoteV2;
  userId: string;
  vehicleId: string;
}
const VehicleCardNoteEditDropDown = ({
  note,
  userId,
  vehicleId,
}: VehicleCardNoteEditDropDownProps) => {
  const { data: sessionData } = useToken();
  const { hasPermission } = usePermissions();
  const { deleteNoteAsync } = useDeleteNote();
  const canUserDelete = hasPermission(permissions.INVENTORY_VDP_NOTES_DELETE);
  const isCurrentUserMessage = userId === sessionData?.user?.id;

  if (!canUserDelete || !isCurrentUserMessage) {
    return null;
  }

  const CustomToggle = forwardRef(({ onClick }: any, ref: any) => (
    <div role="button" onClick={(e: any) => onClick(e)} ref={ref} tabIndex={0}>
      <MoreHoriz />
    </div>
  ));

  const deleteNote = ({ id: noteId = '' }: NoteV2) =>
    deleteNoteAsync({ noteId, vehicleId });

  return (
    <Dropdown drop="right">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
      <Dropdown.Menu className="border-0 drop-shadow">
        {hasPermission(permissions.INVENTORY_VDP_NOTES_DELETE) && (
          <Dropdown.Item className="py-2" onClick={() => deleteNote(note)}>
            Delete Note
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default VehicleCardNoteEditDropDown;
