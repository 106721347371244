import { Message } from 'hooks/useInventoryUpdates';

import './VehicleUpdate.scss';

const VehicleComponent = ({
  message,
  onClick,
}: {
  message: Message;
  onClick: (inventoryId: string) => void;
}) => {
  const { inventoryId, year, make, model, stockNumber } = message;

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClick(inventoryId!);
  };

  return (
    <div key={inventoryId} className="VehicleUpdate-container">
      <div className="VehicleUpdate-action" onClick={handleOnClick}>
        {year} {make} {model} {stockNumber}
      </div>
    </div>
  );
};

export default VehicleComponent;
