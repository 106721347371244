import { ArrowDropDown } from '@material-ui/icons';
import React from 'react';
import { Card } from 'react-bootstrap';

import strings from 'common/strings';
import Dropdown from 'components/shared/Dropdown';
import MultipleSearchSelection from 'components/shared/MultipleSearchSelection';
import { UIFilter, UIFilterOption } from 'models';

import './FilterRow.scss';

interface FilterRowProps {
  filter: UIFilter;
  currentFilterValue?: string[] | string;
  sortAndFilter: (value?: string[] | string) => void;
}

const ALL = 'ALL';
const inventoryState = 'INVENTORY_STATE';
const retailPrice = 'retailPrice';
const bodyType = 'bodyType';

function formatTitleCase(value: string): string {
  if (value === inventoryState) {
    return 'Inventory State';
  }
  if (value === retailPrice) {
    return 'Retail Price';
  }
  if (value === bodyType) {
    return 'Body Type';
  }

  if (value.includes('INVENTORY') || value.includes('VEHICLE')) {
    const words = value.split('_');
    words.shift();
    const capitalizedWords = words.map(
      (word: string) => word[0].toUpperCase() + word.substr(1).toLowerCase()
    );
    return capitalizedWords.join(' ');
  }
  return value[0].toUpperCase() + value.substr(1).toLowerCase();
}

function formatLabelForOption(option?: UIFilterOption) {
  if (!option) return undefined;
  if (option.id === ALL) return 'All';
  return (
    option.label || (strings[option.id as keyof typeof strings] as string) || ''
  );
}

var MultiSelectOptions = ({
  options,
  filterId,
  handleMultiSelectFilterChange,
  isOptionChecked,
}: {
  options: UIFilterOption[];
  filterId: string;
  handleMultiSelectFilterChange: (optionId: any) => void;
  isOptionChecked: (id: UIFilterOption) => boolean;
}) => {
  const optionList = options.map((option) => ({
    id: option.id,
    value: option.label,
    checked: isOptionChecked(option),
  }));

  return (
    <MultipleSearchSelection
      options={optionList}
      filterId={filterId}
      onSelect={(id) => handleMultiSelectFilterChange(id)}
      containerClassName="FilterRow-select-dropdown"
      selectedOptionClassName="FilterRow-select-dropdown-selected"
    />
  );
};

var SingleSelectDropdown = ({
  options,
  filterId,
  values,
  handleSingleSelectFilterChange,
}: {
  options: UIFilterOption[];
  filterId: string;
  handleSingleSelectFilterChange: (optionId: string) => void;
  values: string | string[];
}) => {
  let value = values;
  if (Array.isArray(value)) [value] = values;
  if (!value) value = ALL;

  const selectedOption = options.find(
    (option: UIFilterOption) => option.id === value
  );
  const dropdownMenuTitle =
    formatLabelForOption(selectedOption) ||
    (filterId === inventoryState ? strings.INVENTORY_ONLY : filterId);

  return (
    <Dropdown
      optionContainerId={filterId}
      containerClassName="FilterRow-select-dropdown"
      selectedOption={{
        id: selectedOption?.id || 'no-id',
        value: dropdownMenuTitle,
      }}
      options={options.map((option) => {
        let testId = option.id;
        if (filterId === 'assignee') {
          // Redact the user id as a test attribute value on the DOM
          testId = option.label.replace(', ', '_');
        }
        return {
          id: option.id,
          value: option.label,
          testId,
        };
      })}
      onSelect={handleSingleSelectFilterChange}
      selectedOptionClassName="FilterRow-select-dropdown-selected"
      customCaretComponent={<ArrowDropDown className="Dropdown-expand-icon" />}
    />
  );
};

const FilterRow: React.FC<FilterRowProps> = ({
  filter,
  currentFilterValue,
  sortAndFilter,
}) => {
  const options: UIFilterOption[] = filter?.options || [];
  const title =
    (strings[
      `${filter.id.toUpperCase()}_FILTER_TITLE` as keyof typeof strings
    ] as string) || formatTitleCase(filter.id);

  const isOptionChecked = (option: UIFilterOption) => {
    if (!currentFilterValue) return false;
    return currentFilterValue.includes(option.id);
  };

  if (!options.length) return <span />;

  const renderOptions = (filter: UIFilter) => {
    switch (filter.behavior) {
      case 'MULTI_SELECT':
        return (
          <MultiSelectOptions
            options={filter.options}
            filterId={filter.id}
            handleMultiSelectFilterChange={handleMultiSelectFilterChange}
            isOptionChecked={isOptionChecked}
          />
        );
      case 'SINGLE_SELECT':
        return (
          <SingleSelectDropdown
            options={filter.options}
            filterId={filter.id}
            handleSingleSelectFilterChange={handleSingleSelectFilterChange}
            values={currentFilterValue || ALL}
          />
        );
      default:
        return <div>UNKNOWN FILTER BEHAVIOR</div>;
    }
  };

  const handleMultiSelectFilterChange = (optionId: any) => {
    const option = options.find((item: UIFilterOption) => item.id === optionId);

    if (Array.isArray(currentFilterValue)) {
      const isOptionInFilter = currentFilterValue.includes(optionId);
      const newValue = isOptionInFilter
        ? currentFilterValue.filter(
            (filterOption: any) => filterOption !== optionId
          )
        : [...currentFilterValue, optionId];
      sortAndFilter(newValue);
      return;
    }

    const value = !option || optionId === ALL ? [] : optionId;
    sortAndFilter(value);
  };

  const handleSingleSelectFilterChange = (optionId: string | undefined) => {
    const option = options.find((item: UIFilterOption) => item.id === optionId);
    const value = !option || optionId === ALL ? undefined : optionId;
    sortAndFilter(value);
  };

  return (
    <Card className="FilterRow-card">
      <div className="FilterRow-container">
        <div className="FilterRow-title">{title}</div>
        {renderOptions(filter)}
      </div>
    </Card>
  );
};

export default FilterRow;
