import { FC } from 'react';

import Button from 'components/shared/Button';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useCarfax } from 'hooks/useCarfax';

export interface CarfaxPurchaseReportProps {
  vin: string;
}

const CarfaxPurchaseReport: FC<CarfaxPurchaseReportProps> = ({ vin }) => {
  const { useCarfaxPurchaseReport } = useCarfax();

  const { isLoading, mutateAsync: purchaseReport } =
    useCarfaxPurchaseReport(vin);

  const handlePurchaseCarfaxReport = async () => {
    await purchaseReport();
  };
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="purchase-report">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Button
            variant="info"
            type="button"
            onClick={handlePurchaseCarfaxReport}
          >
            Click here to purchase Carfax report
          </Button>
        </>
      )}
    </div>
  );
};

export default CarfaxPurchaseReport;
