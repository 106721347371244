/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const Gavel: React.FC<IconProps> = ({ color = defaultColor, className }) => (
  <svg
    width={20}
    height={20}
    viewBox="10 10 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M10.2925 28.3825L19.8925 18.7825L18.4925 17.3625L17.7725 18.0725C17.3825 18.4625 16.7525 18.4625 16.3625 18.0725L15.6525 17.3625C15.2625 16.9725 15.2625 16.3425 15.6525 15.9525L21.3125 10.2925C21.7025 9.9025 22.3325 9.9025 22.7225 10.2925L23.4325 11.0025C23.8225 11.3925 23.8225 12.0225 23.4325 12.4125L22.7225 13.1025L24.1425 14.5325C24.5325 14.1425 25.1625 14.1425 25.5525 14.5325C25.9425 14.9225 25.9425 15.5625 25.5525 15.9525L26.9625 17.3625L27.6725 16.6525C28.0625 16.2625 28.7025 16.2625 29.0925 16.6525L29.7925 17.3625C30.1825 17.7525 30.1825 18.3925 29.7925 18.7825L24.1425 24.4325C23.7525 24.8225 23.1125 24.8225 22.7225 24.4325L22.0225 23.7325C21.6225 23.3425 21.6225 22.7025 22.0225 22.3125L22.7225 21.6025L21.3125 20.1925L11.7025 29.8025C11.3125 30.1925 10.6825 30.1925 10.2925 29.8025C9.9025 29.4125 9.9025 28.7725 10.2925 28.3825ZM27.9925 27.1025C28.5229 27.1025 29.0316 27.3132 29.4067 27.6883C29.7818 28.0634 29.9925 28.5721 29.9925 29.1025V30.1025H19.9925V29.1025C19.9925 28.5721 20.2032 28.0634 20.5783 27.6883C20.9534 27.3132 21.4621 27.1025 21.9925 27.1025H27.9925Z"
      fill={color}
    />
  </svg>
);

export default Gavel;
