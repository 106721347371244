import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { FC } from 'react';

export interface ChevronProps {
  isOpen: boolean;
}

const Chevron: FC<ChevronProps> = ({ isOpen }) => {
  return isOpen ? (
    <KeyboardArrowUp fontSize="small" />
  ) : (
    <KeyboardArrowDown fontSize="small" />
  );
};

export default Chevron;
