import { useMemo } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { useVehicleChecklist } from 'api';
import strings from 'common/strings';

interface MpiFooterProps {
  checklistId: string;
  vehicleId: string;
}

var MpiFooter = ({ checklistId, vehicleId }: MpiFooterProps) => {
  const { data: { data } = {} } = useVehicleChecklist(vehicleId, checklistId);
  const { answers = {}, checkList } = data || {};
  const answerCount = Object.values(answers).length;
  const checkListCount = checkList?.items.length;
  const percent = useMemo(() => {
    if (!answerCount || !checkListCount) {
      return 0;
    }
    return (answerCount / checkListCount) * 100;
  }, [answerCount, checkListCount]);

  if (!checklistId || !vehicleId) {
    return null;
  }

  return (
    <div className="MpiFooter px-3 d-flex align-items-center border-top border-right">
      <div>
        <span className="MpiFooter-complete-label">
          {strings.COMPLETE.toUpperCase()}
        </span>
        <div className="MpiFooter-completion-stats">
          <span className="mr-1">{answerCount}</span>
          <span className="mr-1">/</span>
          <span className="mr-1">{checkListCount}</span>
          <span>{strings.QUESTIONS}</span>
        </div>
      </div>
      <div className="MpiFooter-progress-container">
        <ProgressBar
          variant="success"
          now={percent}
          className="flex-grow rounded-0 complete-stats-progress"
        />
      </div>
    </div>
  );
};

export default MpiFooter;
