import React, { useEffect, useState } from 'react';

import permissions from 'common/permissions';
import strings from 'common/strings';
import { usePermissions, useWindowSize } from 'hooks';
import { currencyFormatter } from 'utils/formatter';

import './Totals.scss';

interface TotalsProps {
  partsPrice: number;
  laborPrice: number;
  totalPrice: number;
  totalHours: number;
  pinned?: boolean;
}

const Totals: React.FC<TotalsProps> = ({
  pinned = false,
  partsPrice,
  laborPrice,
  totalPrice,
  totalHours,
}) => {
  const { hasPermission } = usePermissions();
  const userHasTaskLaborCostViewPermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_LABOR_COST_VIEW
  );

  const userHasTaskPartsCostViewPermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_PARTS_COST_VIEW
  );

  const windowSize = useWindowSize();
  const [paddingRightClassName, setPaddingRightClassName] = useState('');

  useEffect(() => {
    const { scrollHeight, clientHeight } =
      document.getElementById('FlatTasks-tasks') ?? {};
    if (scrollHeight && clientHeight) {
      const hasVerticalScrollbar = scrollHeight > clientHeight;
      if (hasVerticalScrollbar) {
        setPaddingRightClassName('Totals-padding-right');
      } else {
        setPaddingRightClassName('');
      }
    }
  }, [windowSize.height, windowSize.width]);

  return (
    <div
      className={`Totals ${
        pinned ? 'Totals-pinned' : ''
      } ${paddingRightClassName}`}
    >
      <div className="Totals-hours-label">{strings.HOURS}</div>
      <div className="Totals-hours">
        {userHasTaskLaborCostViewPermissions ? totalHours : strings.EMPTY_VALUE}
      </div>

      <div className="Totals-labor-label">{strings.LABOR}</div>
      <div className="Totals-labor">
        {userHasTaskLaborCostViewPermissions
          ? currencyFormatter(laborPrice)
          : strings.EMPTY_VALUE}
      </div>

      <div className="Totals-parts-label">{strings.PARTS}</div>
      <div className="Totals-parts">
        {userHasTaskPartsCostViewPermissions
          ? currencyFormatter(partsPrice)
          : strings.EMPTY_VALUE}
      </div>

      <div className="Totals-label">{strings.TOTALS}</div>
      <div className="Totals-total">
        {userHasTaskLaborCostViewPermissions &&
        userHasTaskPartsCostViewPermissions
          ? currencyFormatter(totalPrice)
          : strings.EMPTY_VALUE}
      </div>
    </div>
  );
};

export default Totals;
