import { Router } from '@reach/router';
import React from 'react';

import HandleRedirect from 'components/pages/Carfax/CarfaxAuthRedirect';
import ChangePassword from 'components/pages/ChangePassword';
import ForgotPassword from 'components/pages/ForgotPassword';
import Login from 'components/pages/Login';
import VehicleCardExtension from 'components/pages/VehicleCollectionBrowser/VehicleCard/VehicleCardExtension';
import { VelocityInsightVehicleDetails } from 'components/pages/VehicleDetails/VelocityInsightVehicleDetails';
import VelocityAutomotiveVinScanner from 'components/pages/VelocityAutomotiveVinScanner';

import ProtectedRoute from './ProtectedRoute';
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  HANDLE_AUTH_REDIRECT,
  LOGIN,
  VELOCITY_AUTOMOTIVE_VIN_SCANNER,
  VELOCITY_INSIGHT,
} from './routes';
import UserSessionRouter from './UserSessionRouter';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <ForgotPassword path={FORGOT_PASSWORD} />
      <ChangePassword path={CHANGE_PASSWORD} />
      <Login path={LOGIN} />
      <VelocityAutomotiveVinScanner path={VELOCITY_AUTOMOTIVE_VIN_SCANNER} />
      <VehicleCardExtension
        path={`${VELOCITY_INSIGHT}/tenant/:tenantId/inventory/:inventoryId/card`}
      />
      <VelocityInsightVehicleDetails
        path={`${VELOCITY_INSIGHT}/tenant/:tenantId/inventory/:inventoryId/details/*`}
      />
      <HandleRedirect path={HANDLE_AUTH_REDIRECT} />
      <ProtectedRoute default component={UserSessionRouter} />
    </Router>
  );
};

export default AppRouter;
