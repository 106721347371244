import { useState } from 'react';

import { permissions, strings } from 'common';
import { testIds } from 'common/testIds';
import Button from 'components/shared/Button';
import PermissionsGate from 'components/shared/PermissionsGate';

import AddTasksModal from '../AddTasksModal';

interface AddButtonProps {
  vehicleId: string;
}

const AddButton: React.FC<AddButtonProps> = (props) => {
  const { vehicleId } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <PermissionsGate permissions={[permissions.INVENTORY_VDP_TASKS_CREATE]}>
      <div>
        <Button
          data-vas-testing={testIds.TODO_SECTION_ADD_BUTTON}
          className="FlatTasks-action-button FlatTasks-action-button-add"
          id="add-task-button"
          type="button"
          variant="primary"
          onClick={() => setIsModalOpen(true)}
        >
          {strings.ADD}
        </Button>
        <AddTasksModal
          vehicleId={vehicleId}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </PermissionsGate>
  );
};

export default AddButton;
