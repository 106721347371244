/* eslint-disable jsx-a11y/media-has-caption */
import Grid from '@material-ui/core/Grid';
import { Delete, MoreHoriz, Person } from '@material-ui/icons';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useDeleteNoteAttachment, useToken } from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import PermissionsGate from 'components/shared/PermissionsGate';
import UploadPlaceholder from 'components/shared/UploadPlaceholder';
import { AttachmentIcon } from 'components/shared/UploadPlaceholder/Icons';
import VehicleImageView from 'components/shared/VehicleImageView';
import { usePermissions, useWindowSize } from 'hooks';
import { Attachment, Note } from 'models';
import { UploadItem } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';
import humanFilesize from 'utils/fileSystem';
import { getFormattedHour } from 'utils/time';

import { addMiddleElipse, getParsedMessage } from '../utils';

import './NoteMessage.scss';

interface NoteMessageProps {
  vehicleId: string;
  toggleActiveAttachmentMenu: (attachmentId: string) => void;
  activeAttachmentDropdownId: string;
  note: Note;
  openEditModal: (editNote: Note) => void;
  onDelete: (note: Note) => void;
  inPreviewPanel?: boolean;
}

interface AttachmentProps {
  note: Note;
  vehicleId: string;
  attachments: Attachment[];
  activeAttachmentDropdownId: string;
  toggleActiveAttachmentMenu: (attachmentId: string) => void;
}

var Attachments = ({
  attachments,
  note,
  vehicleId,
  activeAttachmentDropdownId,
  toggleActiveAttachmentMenu,
}: AttachmentProps) => {
  const { deleteNoteAttachmentAsync } = useDeleteNoteAttachment();

  // display note attachment in a new tab
  const viewDocument = (attachment: Attachment) => {
    window.open(attachment.uri);
  };

  const onDeleteAttachment = async (attachment: Attachment) => {
    await deleteNoteAttachmentAsync({
      attachmentId: attachment.id,
      noteId: note.id!,
      vehicleId: vehicleId!,
    });
  };

  return (
    <div className="NotesMessage-attachments">
      {attachments.map((attachment: Attachment, idx: number) => (
        <div
          className="NotesMessage-attachments-container"
          key={`attachment-${attachment.id}`}
        >
          <NoteAttachmentView
            key={idx.toString()}
            activeAttachmentDropdownId={activeAttachmentDropdownId}
            toggleActiveAttachmentMenu={toggleActiveAttachmentMenu}
            attachment={attachment}
            viewDocument={() => viewDocument(attachment)}
            onDeleteAttachment={() => onDeleteAttachment(attachment)}
          />
        </div>
      ))}
    </div>
  );
};

var NoteMessage = ({
  vehicleId,
  note,
  openEditModal,
  onDelete,
  inPreviewPanel = false,
  toggleActiveAttachmentMenu,
  activeAttachmentDropdownId,
}: NoteMessageProps) => {
  const windowSize = useWindowSize();
  const { hasPermission } = usePermissions();
  const clearActiveAttachmentDropdown = () => {
    if (activeAttachmentDropdownId === '') return;
    toggleActiveAttachmentMenu('');
  };
  const uploads = useUploadStore((uploadStore) => uploadStore.uploads);

  const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => (
    <div role="button" onClick={(e: any) => onClick(e)} ref={ref} tabIndex={0}>
      <MoreHoriz />
    </div>
  ));

  const showUploads = () => {
    const activeUploads = uploads.filter(
      (upload: UploadItem) =>
        upload.meta.target.id === note.id && upload.meta.parentId === vehicleId
    );
    return activeUploads.length > 0 ? (
      <div className="NotesMessage-attachments">
        {activeUploads.map((upload: UploadItem) => (
          <div
            key={`upload-${upload.id}`}
            className="NotesMessage-attachments-container"
          >
            <UploadPlaceholder upload={upload} showOverlay />
          </div>
        ))}
      </div>
    ) : null;
  };
  const deleteNote = (note: Note) => {
    onDelete(note);
  };
  const { data: sessionData } = useToken();

  const renderEditIcon = () => {
    const canUserUpdateOrDelete =
      hasPermission(permissions.INVENTORY_VDP_NOTES_UPDATE) ||
      hasPermission(permissions.INVENTORY_VDP_NOTES_DELETE);
    const isCurrentUserMessage =
      note.sender && note.sender.id === sessionData?.user?.id;
    if (isCurrentUserMessage && canUserUpdateOrDelete) {
      return (
        <Dropdown onClick={clearActiveAttachmentDropdown} drop="right">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
          <Dropdown.Menu className="border-0 drop-shadow">
            <Dropdown.Item className="py-2" onClick={() => openEditModal(note)}>
              Edit Note
            </Dropdown.Item>
            {hasPermission(permissions.INVENTORY_VDP_NOTES_DELETE) && (
              <Dropdown.Item className="py-2" onClick={() => deleteNote(note)}>
                Delete Note
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return null;
  };

  const authorClassName =
    windowSize.isMobileViewport() || inPreviewPanel
      ? 'NotesMessage-truncated-text'
      : '';

  return (
    <Grid
      item
      container
      xs={12}
      direction="column"
      className="NotesMessage other-user-message-container mt-3"
      key={`${note.note}-${note.timestamp}`}
    >
      <div className="d-flex flex-row caption-container">
        <div className="info p-2 rounded mr-2 person-icon-container">
          <Person className="grayTint" />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <div className={`mr-2 alt-title author-title ${authorClassName}`}>
              {note.sender
                ? `${note.sender.firstName} ${note.sender.lastName}`
                : 'Unknown User'}
            </div>
            <div className="text-muted">
              {getFormattedHour(new Date(note.timestamp))}
            </div>
            <div>{renderEditIcon()}</div>
          </div>
          {note.note?.length !== 0 ? (
            <div
              className="NotesMessage-note-content"
              key={`message-${note.note}-${note.timestamp}`}
            >
              {getParsedMessage(note?.note)}
            </div>
          ) : null}
          {note.attachments?.length > 0 && (
            <Attachments
              vehicleId={vehicleId}
              note={note}
              attachments={note.attachments}
              activeAttachmentDropdownId={activeAttachmentDropdownId}
              toggleActiveAttachmentMenu={toggleActiveAttachmentMenu}
            />
          )}
          {showUploads()}
        </div>
      </div>
    </Grid>
  );
};

interface ThreeDotMenuProps {
  highlight: boolean;
  onClickMenuItem: () => void;
  activeAttachmentDropdownId: string;
  attachmentId: string;
  toggleActiveAttachmentMenu: (attachmentId: string) => void;
}

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({
  highlight,
  onClickMenuItem,
  attachmentId,
  activeAttachmentDropdownId,
  toggleActiveAttachmentMenu,
}) => {
  const attachmentsRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        activeAttachmentDropdownId === attachmentId &&
        attachmentsRef.current &&
        event.target instanceof HTMLElement &&
        !attachmentsRef.current.contains(event.target)
      ) {
        toggleActiveAttachmentMenu(attachmentId);
      }
    },
    [activeAttachmentDropdownId, attachmentId, toggleActiveAttachmentMenu]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [attachmentsRef, handleClickOutside]);

  return (
    <div ref={attachmentsRef}>
      <div
        className={`ThreeDotMenu ${highlight ? 'ThreeDotMenu-highlight' : ''}`}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          toggleActiveAttachmentMenu(attachmentId);
        }}
        role="none"
      >
        <MoreHoriz />
      </div>
      {activeAttachmentDropdownId === attachmentId && (
        <div className="ThreeDotMenu-menu">
          <div
            className="ThreeDotMenu-menu-item"
            role="none"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onClickMenuItem();
            }}
          >
            <Delete className="ThreeDotMenu-icon" />
            <div className="ThreeDotMenu-text">{strings.DELETE_ATTACHMENT}</div>
          </div>
        </div>
      )}
    </div>
  );
};

interface NoteAttachmentViewProps {
  attachment: Attachment;
  viewDocument: any;
  onDeleteAttachment: () => void;
  activeAttachmentDropdownId: string;
  toggleActiveAttachmentMenu: (attachmentId: string) => void;
}

const NoteAttachmentView = ({
  attachment,
  viewDocument,
  onDeleteAttachment,
  activeAttachmentDropdownId,
  toggleActiveAttachmentMenu,
}: NoteAttachmentViewProps) => {
  const [highlightThreeDotMenu, setHighlightThreeDotMenu] = useState(false);

  const size = parseInt(attachment.size, 10);
  switch (attachment.contentType) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'jpg':
    case 'image/png':
    case 'png':
      return (
        <div key={attachment.uri}>
          <div
            onClick={viewDocument}
            role="link"
            tabIndex={-1}
            className="NoteAttachmentView-image-container mb-1"
            onMouseEnter={() => setHighlightThreeDotMenu(true)}
            onMouseLeave={() => setHighlightThreeDotMenu(false)}
          >
            <VehicleImageView size="sm" imageUrl={attachment.uri} />
            <PermissionsGate
              permissions={[permissions.INVENTORY_VDP_NOTES_ATTACHMENT_DELETE]}
            >
              <ThreeDotMenu
                highlight={highlightThreeDotMenu}
                onClickMenuItem={onDeleteAttachment}
                activeAttachmentDropdownId={activeAttachmentDropdownId}
                attachmentId={attachment.id}
                toggleActiveAttachmentMenu={toggleActiveAttachmentMenu}
              />
            </PermissionsGate>
          </div>
          <span className="NotesMessage-attachments-filename">
            {addMiddleElipse(attachment.name)}
          </span>
          {size > 0 && (
            <span className="NotesMessage-attachments-filesize">
              {humanFilesize(size)}
            </span>
          )}
        </div>
      );
    case 'video/mp4':
    case 'video/webm':
      return (
        <div key={attachment.uri}>
          <div
            onClick={viewDocument}
            role="link"
            tabIndex={-1}
            className="NoteMessage-video NoteAttachmentView-image-container"
          >
            <video src={attachment.uri} />
            <PermissionsGate
              permissions={[permissions.INVENTORY_VDP_NOTES_ATTACHMENT_DELETE]}
            >
              <ThreeDotMenu
                highlight={highlightThreeDotMenu}
                onClickMenuItem={onDeleteAttachment}
                activeAttachmentDropdownId={activeAttachmentDropdownId}
                attachmentId={attachment.id}
                toggleActiveAttachmentMenu={toggleActiveAttachmentMenu}
              />
            </PermissionsGate>
          </div>
          <span className="NotesMessage-attachments-filename">
            {addMiddleElipse(attachment.name)}
          </span>
          <span className="NotesMessage-attachments-filesize">
            {size > 0 && (
              <span className="NotesMessage-attachments-filesize">
                {humanFilesize(size)}
              </span>
            )}
          </span>
        </div>
      );

    case 'application/pdf':
      return (
        <div key={attachment.uri}>
          <div
            onClick={viewDocument}
            role="link"
            tabIndex={-1}
            className="NoteMessage-video NoteAttachmentView-image-container"
          >
            <AttachmentIcon />
            <PermissionsGate
              permissions={[permissions.INVENTORY_VDP_NOTES_ATTACHMENT_DELETE]}
            >
              <ThreeDotMenu
                highlight={highlightThreeDotMenu}
                onClickMenuItem={onDeleteAttachment}
                activeAttachmentDropdownId={activeAttachmentDropdownId}
                attachmentId={attachment.id}
                toggleActiveAttachmentMenu={toggleActiveAttachmentMenu}
              />
            </PermissionsGate>
          </div>
          <span className="NotesMessage-attachments-filename">
            {addMiddleElipse(attachment.name)}
          </span>
          <span className="NotesMessage-attachments-filesize">
            {size > 0 && (
              <span className="NotesMessage-attachments-filesize">
                {humanFilesize(size)}
              </span>
            )}
          </span>
        </div>
      );
    default: {
      return (
        <div
          key={attachment.uri}
          onClick={viewDocument}
          role="link"
          tabIndex={-1}
          className="display-note-document mb-1"
        >
          <InsertDriveFileOutlinedIcon color="secondary" fontSize="default" />
          {addMiddleElipse(attachment.name)}
        </div>
      );
    }
  }
};

export default NoteMessage;
