import { FC } from 'react';

import { currencyFormatter } from 'utils/formatter';

export interface PriceProps {
  amount: number;
}

const Price: FC<PriceProps> = ({ amount }) => (
  <div className="price">{currencyFormatter(amount)}</div>
);

export default Price;
