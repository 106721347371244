import create from 'zustand';

interface VdpMenuState {
  collapsed: boolean;
  collapse: () => void;
  toggle: () => void;
}
export const useVdpMenuState = create<VdpMenuState>((set) => ({
  collapsed: false,
  collapse: () => set({ collapsed: true }),
  toggle: () =>
    set((state) => ({
      collapsed: !state.collapsed,
    })),
}));
