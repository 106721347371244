import Typography from '@material-ui/core/Typography';
import { useMatch } from '@reach/router';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { isUndefined } from 'util';

import images from 'common/images';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Gallery from 'components/pages/Photos/Gallery';
import Syndication from 'components/pages/Photos/Syndication';
import Alert from 'components/shared/Alert';
import FlatVDPPaneContent from 'components/shared/FlatVDPPaneContent';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import PermissionsGate from 'components/shared/PermissionsGate';
import Tabs from 'components/shared/Tabs';
import { useFileSelector, usePermissions, useWindowSize } from 'hooks';
import { VehicleSummary } from 'models';
import {
  ALLOWED_MEDIA_FILETYPES_STRING,
  UploadTarget,
  UploadType,
} from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';

import FlatDocumentsSection from '../FlatDocumentsSection';
import { FlatMediaContext } from '../FlatMedia';

import './FlatMediaSection.scss';

interface FlatMediaSectionProps {
  vehicle: VehicleSummary;
}

const FlatMediaSection: React.FC<FlatMediaSectionProps> = ({ vehicle }) => {
  const { hasPermission } = usePermissions();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(strings.API_MESSAGE);
  const uploadStoreData = useUploadStore((uploadStore) => ({
    uploads: uploadStore.uploads,
    uploadFiles: uploadStore.uploadFiles,
  }));
  const isGalleryRoute = useMatch('gallery');
  const windowSize = useWindowSize();
  const { images: data, refetch } = useContext(FlatMediaContext);

  const syndicationCount = useMemo(
    () =>
      data.reduce((acc, image) => {
        if (
          image.shot !== undefined &&
          !isUndefined(image.shot) &&
          image.shot !== strings.UNKNOWN_OPTION
        ) {
          return acc + 1;
        }
        return acc;
      }, 0),
    [data]
  );

  const galleryTitle = windowSize.isMobileViewport()
    ? strings.GALLERY
    : strings.GALLERY_WITH_COUNT(data.length);
  const syndicationTitle = windowSize.isMobileViewport()
    ? strings.SYNDICATION
    : strings.SYNDICATION_WITH_COUNT(syndicationCount);

  const handleErrorClose = useCallback(() => {
    setShowErrorAlert(false);
    setErrorMessage(strings.API_MESSAGE);
  }, []);

  const onChange = useCallback(
    (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files ? Array.from(target.files) : [];

      if (files) {
        const onUploadSuccess = async () => {
          refetch();
        };
        const onUploadError = async (message?: string) => {
          setErrorMessage(message || strings.API_MESSAGE);
          setShowErrorAlert(true);
        };
        const onUploadCancel = () => {
          const element = event?.target; //retrieve fileSelector
          if (element instanceof HTMLInputElement) {
            element.value = '';
          }
        };
        const uploadTarget: UploadTarget = {
          type: UploadType.MEDIA,
        };

        uploadStoreData.uploadFiles(
          files,
          vehicle?.vehicleCard?.id!,
          uploadTarget,
          onUploadSuccess,
          onUploadError,
          onUploadCancel
        );
      }
    },
    [refetch, uploadStoreData, vehicle?.vehicleCard?.id]
  );

  const onError = useCallback((event) => {
    setErrorMessage(event || strings.API_MESSAGE);
    setShowErrorAlert(true);
  }, []);

  const [fileSelector] = useFileSelector(
    onChange,
    onError,
    ALLOWED_MEDIA_FILETYPES_STRING
  );

  return (
    <div className="FlatMediaSection full-height">
      <FlatVDPPaneHeader
        vehicleId={vehicle?.vehicleCard?.id}
        className="FlatMedia-header"
      >
        <div
          data-vas-testing={testIds.MEDIA_AND_DOCS_CONTAINER_TITLE}
          className="Section-title"
        >
          {strings.MEDIA}
        </div>
        <PermissionsGate
          permissions={[permissions.INVENTORY_VDP_PHOTOS_CREATE]}
        >
          <button
            type="button"
            className="FlatMedia-upload-button"
            onClick={() => fileSelector.click()}
          >
            <img
              data-vas-testing={testIds.MEDIA_UPLOAD_BUTTON_ICON}
              className="FlatMedia-upload-button-icon"
              src={images.UploadIcon}
              alt="Upload button"
            />
          </button>
        </PermissionsGate>
      </FlatVDPPaneHeader>
      <FlatVDPPaneContent className="FlatMedia-media-content">
        {windowSize.isMobileViewport() && isGalleryRoute && (
          <PermissionsGate
            permissions={[permissions.INVENTORY_VDP_PHOTOS_CREATE]}
          >
            <button
              type="button"
              className="FlatMedia-upload-button FlatMedia-upload-button-mobile"
              onClick={() => fileSelector.click()}
            >
              <img
                className="FlatMedia-upload-button-icon"
                src={images.UploadIcon}
                alt="Upload button"
              />
            </button>
          </PermissionsGate>
        )}
        {hasPermission(permissions.INVENTORY_VDP_PHOTOS_VIEW) ? (
          <Tabs
            defaultPath="gallery"
            basePath="media"
            tabContainerClassName="FlatMedia-tab-container"
            tabContentClassName="FlatMedia-tab-content vertical-scroll"
            className="FlatMedia-tabs"
          >
            <Gallery title={galleryTitle} path="gallery" vehicle={vehicle} />
            <Syndication
              reload={refetch}
              title={syndicationTitle}
              path="syndication"
              vehicle={vehicle}
              images={data}
            />
            {windowSize.isMobileViewport() && (
              <FlatDocumentsSection
                vehicle={vehicle}
                title={strings.DOCUMENTS}
                path="documents"
              />
            )}
          </Tabs>
        ) : (
          <div className="d-flex align-items-center flex-column flex-grow justify-content-center px-4 pt-5">
            <Typography variant="subtitle1" className="no-note-title">
              {strings.PHOTOS_PERMISSION_DENIED}
            </Typography>
            <Typography variant="body2" className="no-note-message">
              {strings.PHOTOS_PERMISSION_DENIED_SUB}
            </Typography>
          </div>
        )}
      </FlatVDPPaneContent>
      <Alert
        open={showErrorAlert}
        contentProps={{
          message: errorMessage,
          onClose: handleErrorClose,
          variant: 'error',
        }}
      />
    </div>
  );
};

export default FlatMediaSection;
