import { MentionPluginConfig } from '@draft-js-plugins/mention';
import { ContentState, convertToRaw } from 'draft-js';

const getEditorEntities = (content: ContentState) => {
  const entityType = null;

  const entities: any = [];
  content.getBlocksAsArray().forEach((block: any) => {
    let selectedEntity: any = null;
    block.findEntityRanges(
      (character: any) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start: any, end: any) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });
  return entities;
};

export function getRawContent(contentState: ContentState) {
  const entities = getEditorEntities(contentState);
  const raw = convertToRaw(contentState);

  const { blocks }: any = raw;

  const content = blocks.map(({ text }: any) => text);

  let textBlob = content.join('/n');

  entities.forEach((entityObj: any) => {
    const { type } = entityObj?.entity;

    if (type === 'LINK') {
      return false;
    }

    const { name, id } = entityObj?.entity?.data?.mention;
    const replaceWith = `@[${id}:${name}]`;
    textBlob = textBlob.replace(`@${name}`, replaceWith);
    return false;
  });

  return textBlob;
}

export const MentionConfig: MentionPluginConfig = {
  theme: {
    mention: 'text-danger font-weight-bold',
    mentionSuggestions: 'suggestions-container mr-3 rounded py-2 shadow-sm',
    mentionSuggestionsEntry: 'py-3 pl-2 pr-5 bg-white',
    mentionSuggestionsEntryFocused:
      'py-3 pl-2 pr-5 info pointer text-dark border-info',
  },
  popperOptions: {
    placement: 'top-start',
  },
  mentionPrefix: '@',
};
