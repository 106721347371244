import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import strings from 'common/strings';
import { DashboardItem } from 'models';
import { getFormattedDurationFromSeconds } from 'utils/time';

import './MonthlyReviewCard.scss';

interface MonthlyReviewCardProps {
  cardTitle: string;
  reviewCard: DashboardItem[];
}

const MonthlyReviewCard: React.FC<
  React.PropsWithChildren<MonthlyReviewCardProps>
> = ({ reviewCard, cardTitle }) => {
  const second = reviewCard[1];
  const secondValue = second.value || 0; // value is a string or number compare dashboard and reports APIs
  const secondMonthValue = getFormattedDurationFromSeconds(secondValue);

  const third = reviewCard[2];
  const thirdValue = third.value || 0; // value is a string or number compare dashboard and reports APIs
  const thirdMonthValue = getFormattedDurationFromSeconds(thirdValue);

  const latestStatus = () => {
    if (secondValue < thirdValue) {
      return 'bad';
    }
    if (secondValue > thirdValue) {
      return 'good';
    }
    return 'neutral';
  };

  const getDifferenceSign = () => {
    if (latestStatus() === 'good') {
      return '-';
    }
    if (latestStatus() === 'bad') {
      return '+';
    }
    return '';
  };

  const latestDifference = () => {
    const differenceValue =
      secondValue && thirdValue ? Math.abs(thirdValue - secondValue) : 0;
    return `${getDifferenceSign()} ${getFormattedDurationFromSeconds(
      differenceValue
    )}`;
  };

  return (
    <>
      <div className="monthly-review-title-container">
        <span className="monthly-review-title-text">{cardTitle}</span>
      </div>
      <Container className="monthly-review-card-container">
        <Row className="monthly-review-row">
          <Col xl={4} xs={4} className="previous-value-col">
            <div className="previous-value-container center-content">
              <div className="value">{secondMonthValue}</div>
              <div className="metric">{second.label}</div>
            </div>
          </Col>
          <Col xl={4} xs={4} className="current-value-col">
            <div className="current-value-container center-content">
              <div className="value">{thirdMonthValue}</div>
              <div className="metric">{third.label}</div>
            </div>
          </Col>
          <Col xl={4} xs={4} className="difference-value-col">
            <div className="difference-value-container center-content">
              <div className={'value difference-value-' + latestStatus()}>
                {latestDifference()}
              </div>
              <div className="metric">{strings.HIGHLIGHTS_VARIANCE}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MonthlyReviewCard;
