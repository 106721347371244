import { getAPIHeaders } from 'api/lib';
import { getLastQueryParamWithName } from 'navigation/util/ParamHelpers';

import { getCurrentFilterParamsFromUrl } from './useInventory';

export const vehicleListExport = async (reportType: 'PDF' | 'EXCEL') => {
  const headers = await getAPIHeaders();

  const path = `/inventory/export/${reportType}`;
  const searchParams = new URLSearchParams();
  const filterIds = getCurrentFilterParamsFromUrl();
  if (filterIds) {
    searchParams.set('filterIds', filterIds);
  }
  const searchValue = getLastQueryParamWithName('s') || '';
  if (searchValue) {
    searchParams.set('s', searchValue);
  }
  searchParams.set('sortId', getLastQueryParamWithName('sort') || 'AGE');

  const response = await fetch(
    `${headers?.host}${path}?${searchParams.toString()}`,
    headers?.options
  );

  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.status}`);
  }
  return response.json();
};
