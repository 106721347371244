const humanFileSize = (size: number) => {
  const i: number = Math.floor(Math.log(size) / Math.log(1024));
  return (
    parseInt((size / 1024 ** i).toFixed(2), 10) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
};

export default humanFileSize;
