import React, { useState } from 'react';
import ReactSelect, { ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import DropdownIndicator from './Components/DropdownIndicator';
import SelectPlaceholder from './Components/SelectPlaceholder';
import { customColors, customStyles } from './selectTheme';
import { Option } from './types';

import './Select.scss';

interface SelectProps {
  onChange: (option: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  options: Option[];
  isSearchable?: boolean;
  className?: string;
  containerClassName?: string;
  placeholderClassName?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: Option;
  defaultValue?: ValueType<Option, false>;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  isCreatable?: boolean;
  onCreateOption?: (inputValue: string) => void;
  validator?: (inputValue: string) => boolean;
  // , value: ValueType<Option, false>, options: OptionsType<Option>) => boolean;
  controlShouldRenderValue?: boolean;
  closeMenuOnSelect?: boolean;
  customComponents?: any;
  isClearable?: boolean;
}

const Select = ({
  className = '',
  containerClassName = '',
  placeholderClassName = '',
  disabled,
  onChange,
  options,
  placeholder,
  value,
  defaultValue = undefined,
  onFocus,
  onBlur,
  isSearchable = false,
  formatCreateLabel,
  isCreatable,
  onCreateOption,
  validator,
  controlShouldRenderValue,
  closeMenuOnSelect,
  customComponents,
  isClearable,
}: SelectProps) => {
  const [selectedValue, setSelectedValue] = useState<Option | undefined>(value);
  const [isFocused, setIsFocused] = useState(false);

  const getLabelClassName = (): string => {
    if (value || selectedValue || isFocused) {
      return 'transition';
    }
    return '';
  };

  const handleOnFocus = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleChange = (option: any) => {
    // Added !option for the case when a user clears the input
    setSelectedValue(isCreatable || !option ? undefined : option);
    setIsFocused(!!(isCreatable || !option));
    onChange(option);
  };

  const sharedProps = {
    value,
    options,
    isSearchable,
    styles: customStyles,
    className: `Select ${className}`,
    classNamePrefix: 'Select',
    components: {
      IndicatorSeparator: () => null,
      Placeholder: () => null,
      DropdownIndicator,
      ...customComponents,
    },
    onChange: handleChange,
    isDisabled: disabled,
    theme: (baseTheme: any) => ({
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        ...customColors,
      },
    }),
    onFocus: handleOnFocus,
    onBlur: handleBlur,
    defaultValue,
    isClearable,
  };

  const SharedSelectPlaceHolder = () => (
    <SelectPlaceholder
      defaultValue={defaultValue}
      labelClassName={getLabelClassName()}
      disabled={disabled}
      selectedValue={selectedValue}
      value={value}
      placeholderClassName={placeholderClassName}
      placeholder={placeholder}
    />
  );

  if (isCreatable) {
    return (
      <div className={`SelectContainer ${containerClassName}`}>
        <CreatableSelect
          {...sharedProps}
          formatCreateLabel={formatCreateLabel}
          closeMenuOnSelect={closeMenuOnSelect}
          onCreateOption={onCreateOption}
          isValidNewOption={(inputValue: string) =>
            validator ? validator(inputValue) : true
          }
          controlShouldRenderValue={controlShouldRenderValue}
        />
        <SharedSelectPlaceHolder />
      </div>
    );
  }

  return (
    <div className={`SelectContainer ${containerClassName}`}>
      <ReactSelect {...sharedProps} />
      <SharedSelectPlaceHolder />
    </div>
  );
};

export default Select;
