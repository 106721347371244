import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import React, { useEffect, useRef } from 'react';

import LoadingIndicator from 'components/shared/LoadingIndicator';

import './StatelessDownloadMenu.scss';

var XLSIcon = () => {
  return <div className="XLSIcon">X</div>;
};
var CSVIcon = () => {
  return <div className="XLSIcon">C</div>;
};

interface StatelessDownloadMenuProps {
  showMenu: boolean;
  isFetchingDownload: boolean;
  onPDFClick: (() => void) | null;
  onXLSClick: (() => void) | null;
  onCSVClick: (() => void) | null;
  setShow: (value: boolean) => void;
  onClick: () => void;
}

const StatelessDownloadMenu: React.FC<
  React.PropsWithChildren<StatelessDownloadMenuProps>
> = ({
  showMenu,
  onPDFClick,
  onXLSClick,
  onCSVClick,
  isFetchingDownload,
  setShow,
  onClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);
  const handleClickOutside = (event: any) => {
    if (iconRef.current && iconRef.current.contains(event.target)) {
      // let button handle
      return;
    }
    if (
      showMenu &&
      containerRef.current &&
      !containerRef.current?.contains(event.target)
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div>
      <IconButton
        ref={iconRef}
        className="StatelessDownloadMenu-download-button"
        size="small"
        onClick={onClick}
      >
        <DownloadIcon />
      </IconButton>
      {showMenu && (
        <div className="StatelessDownloadMenu StatelessDownloadMenu-relative">
          <div ref={containerRef} className="DownloadMenu">
            {isFetchingDownload ? (
              <LoadingIndicator size={25} />
            ) : (
              <>
                {onPDFClick && (
                  <button
                    className="StatelessDownloadMenu-option"
                    type="button"
                    onClick={onPDFClick}
                  >
                    <PDFIcon className="StatelessDownloadMenu-option-icon" />
                    <div className="StatelessDownloadMenu-option-text">
                      Download PDF
                    </div>
                  </button>
                )}
                {onXLSClick && (
                  <button
                    className="StatelessDownloadMenu-option"
                    type="button"
                    onClick={onXLSClick}
                  >
                    <XLSIcon />
                    <div className="StatelessDownloadMenu-option-text">
                      Download XLS
                    </div>
                  </button>
                )}
                {onCSVClick && (
                  <button
                    className="StatelessDownloadMenu-option"
                    type="button"
                    onClick={onCSVClick}
                  >
                    <CSVIcon />
                    <div className="StatelessDownloadMenu-option-text">
                      Download CSV
                    </div>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StatelessDownloadMenu;
