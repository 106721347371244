import { permissions, strings } from 'common';
import Button from 'components/shared/Button';
import PermissionsGate from 'components/shared/PermissionsGate';

interface ViewAppraisalButtonProps {
  onClick: () => void;
}

const ViewAppraisalButton: React.FC<ViewAppraisalButtonProps> = (props) => {
  const { onClick } = props;

  return (
    <PermissionsGate permissions={[permissions.INVENTORY_VDP_RECON_INFO_VIEW]}>
      <Button
        className="FlatTasks-appraisal-button"
        id="tasks-view-appraisal-info-button"
        type="button"
        variant="secondary"
        onClick={onClick}
      >
        {strings.VIEW_APPRAISAL}
      </Button>
    </PermissionsGate>
  );
};

export default ViewAppraisalButton;
