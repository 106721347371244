import ClockIcon from '@material-ui/icons/AccessTime';
import CurrencyIcon from '@material-ui/icons/AttachMoney';
import BatteryIcon from '@material-ui/icons/BatteryAlert';
import GasIcon from '@material-ui/icons/LocalGasStation';
import TimerIcon from '@material-ui/icons/Timer';

import images from 'common/images';
import strings from 'common/strings';

export var ReconIcon = () => {
  return (
    <div className="VehicleNeedsAttentionCard-icon-hover">
      <img
        className="VehicleNeedsAttentionCard-icon"
        src={images.ReconBadgeIcon}
        alt="ReconVelocity logo"
      />
    </div>
  );
};

export const noticeTitleAndIconMap: any = {
  RECON_INFO: {
    title: strings.RECON_ISSUE,
    Icon: <ReconIcon />,
  },
  EXCEEDED_GOAL_TIME: {
    title: strings.EXCEEDED_GOAL_TIME,
    Icon: <TimerIcon className="VehicleNeedsAttentionCard-icon" />,
  },
  BATTERY_LOW: {
    title: strings.BATTERY_LOW,
    Icon: <BatteryIcon className="VehicleNeedsAttentionCard-icon" />,
  },
  GAS_LOW: {
    title: strings.GAS_LOW,
    Icon: <GasIcon className="VehicleNeedsAttentionCard-icon" />,
  },
  EXCEEDED_GOAL_COST: {
    title: strings.EXCEEDED_GOAL_COST,
    Icon: <CurrencyIcon className="VehicleNeedsAttentionCard-icon" />,
  },
  EXCEEDED_IDLE_TIME: {
    title: strings.EXCEEDED_IDLE_TIME,
    Icon: <ClockIcon className="VehicleNeedsAttentionCard-icon" />,
  },
};

export default noticeTitleAndIconMap;
