import Check from '@material-ui/icons/Check';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Dropdown as BootstrapDropdown,
  DropdownButton,
  Spinner,
} from 'react-bootstrap';

import { generateTestId, testIds } from 'common/testIds';
import { useWindowSize } from 'hooks';
import { truncateEllipsis } from 'utils/string';

import './Dropdown.scss';

export interface DropdownOption {
  id: string;
  value: string;
  level?: number;
  isHeader?: boolean;
  testId?: string;
}

interface DropdownProps {
  userCanChange?: boolean;
  selectedOption: DropdownOption;
  onSelect: (key: string) => void;
  disabled?: boolean;
  optionContainerId?: string;
  options: DropdownOption[];
  isSubmitting?: boolean;
  containerClassName?: string;
  selectedOptionClassName?: string;
  customCaretComponent?: React.ReactNode;
  truncateOptions?: boolean;
  loaderType?: 'default' | 'srp' | 'srpGradient';
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  enableSearchOption?: boolean;
  searchOptionPlaceholder?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  containerClassName = '',
  disabled = false,
  isSubmitting = false,
  onSelect,
  optionContainerId = '',
  options,
  selectedOption,
  selectedOptionClassName = '',
  userCanChange = true,
  customCaretComponent,
  truncateOptions = true,
  enableSearchOption = false,
  searchOptionPlaceholder = 'Find an option',
  loaderType = 'default',
  onClick,
}) => {
  const windowSize = useWindowSize();
  const stopPropagation = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const Caret = customCaretComponent || null;
  const caretClass = customCaretComponent ? '' : 'caret-show';

  const selectedClassName = useMemo(() => {
    let selectedClassName: string | undefined = selectedOptionClassName;

    if (selectedOption?.value?.toLowerCase?.() !== 'all')
      selectedClassName = undefined;

    return selectedClassName;
  }, [selectedOption?.value, selectedOptionClassName]);

  const [searchValue, setSearchValue] = useState('');
  const [optionList, setOptionList] = useState<DropdownOption[]>([]);

  useEffect(() => {
    setOptionList(options);
  }, [options]);

  if (loaderType === 'srp' && isSubmitting) {
    return (
      <div className="SrpLoader">
        <Spinner
          animation="border"
          size="sm"
          className="Dropdown-loading-indicator"
          role="status"
        />
      </div>
    );
  }

  if (loaderType === 'srpGradient' && isSubmitting) {
    return <div className="GradientLoader gradient-animated-background" />;
  }

  const onChange = (event?: React.FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget.value?.toLowerCase();

    if (value) {
      const result = options.filter((option: DropdownOption) =>
        option.value.toLowerCase().includes(value)
      );
      setOptionList(result);
      setSearchValue(value);
      return;
    }

    setOptionList(options);
    setSearchValue('');
  };

  return (
    <div className={`Dropdown ml-0 mr-0 ${containerClassName}`}>
      <DropdownButton
        {...(optionContainerId
          ? {
              'data-vas-testing': generateTestId(testIds.DROPDOWN_CONTAINER, {
                optionContainerId,
              }),
            }
          : {})}
        size="sm"
        variant="light"
        menuAlign="right"
        key="users-button"
        id="users-dropdown"
        title={
          <div className={`DropdownButton-selectedOption ${selectedClassName}`}>
            <div>{selectedOption?.value}</div>
            {!isSubmitting && userCanChange && Caret && Caret}
          </div>
        }
        className={`Dropdown-btn ${containerClassName} ${caretClass}`}
        onSelect={(evt) => {
          if (typeof evt !== 'string') return;
          onSelect(evt);
        }}
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (onClick) {
            onClick(event);
          }
          stopPropagation(event);
        }}
        disabled={disabled || options.length === 0}
      >
        {enableSearchOption && (
          <div className={'SearchContainer'}>
            <input
              type="text"
              placeholder={searchOptionPlaceholder}
              value={searchValue}
              className="Dropdown-input"
              onChange={onChange}
            />
            <BootstrapDropdown.Divider />
          </div>
        )}
        {optionList.map(({ id, value, level = 0, isHeader = false, testId }) =>
          isHeader ? (
            <BootstrapDropdown.Header
              key={id}
              className="Dropdown-option"
              style={{
                paddingLeft: `${level + 1}.8rem`,
                cursor: 'not-allowed',
                color: '#acacac',
              }}
            >
              {value}
            </BootstrapDropdown.Header>
          ) : (
            <BootstrapDropdown.Item
              {...(optionContainerId
                ? {
                    'data-vas-testing': generateTestId(
                      testIds.DROPDOWN_OPTION_ITEM,
                      {
                        optionItemId: `${optionContainerId}:${testId}`,
                      }
                    ),
                  }
                : {})}
              key={id}
              eventKey={id}
              className={`Dropdown-option ${
                selectedOption?.id === id ? 'bold' : ''
              }`}
              style={{ paddingLeft: `${level}.8rem` }}
            >
              <div className="Dropdown-option-text">
                {selectedOption?.id === id && (
                  <Check className="Dropdown-option-check-icon" />
                )}
                {windowSize.isMobileViewport() && truncateOptions
                  ? truncateEllipsis(value, 22, '...')
                  : value}
              </div>
            </BootstrapDropdown.Item>
          )
        )}
      </DropdownButton>
      {isSubmitting && (
        <Spinner
          animation="border"
          size="sm"
          className="ml-2 mt-1 Dropdown-loading-indicator"
          role="status"
        />
      )}
    </div>
  );
};

export default Dropdown;
