import { chain, isUndefined } from 'lodash';
import moment from 'moment';
import React, {
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { useDeleteNote, useUpdateNote, useVehicleNotes } from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import Alert from 'components/shared/Alert';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import { Pin } from 'components/shared/icons';
import Pane from 'components/shared/Pane';
import PermissionsGate from 'components/shared/PermissionsGate';
import { useWindowSize } from 'hooks';
import { Note } from 'models';
import { UploadItem, UploadType } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';
import { getCalendarDateStringFromDate } from 'utils/time';

import { PinnableComponentType } from '../VehicleDetails/PinnedComponent';
import EditNoteModal from './EditNoteModal';
import NoteInput from './NoteInput/NoteInput';
import NotesListSection from './NotesListSection';

import './Notes.scss';

interface NotesProps {
  path?: string;
  vehicleId: string;
  shouldFocus?: boolean;
  pinned?: boolean;
  inPreviewPanel?: boolean;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

const useNoteDataSource = (vehicleId: string) => {
  const { data, refetch: reloadNotes, isLoading } = useVehicleNotes(vehicleId);
  const [notes, setNotes] = useState<Note[]>([]);
  const { mutate: updateNote } = useUpdateNote(vehicleId);

  const { deleteNoteAsync } = useDeleteNote();

  useEffect(() => {
    setNotes(data?.data ?? []);
  }, [data]);

  const notesSections = useMemo(
    () =>
      chain(notes)
        .sortBy('timestamp')
        .groupBy((item) => moment(new Date(item.timestamp)).format('M/D/YYYY'))
        .map((notes, title) => {
          const currentMilli = Date.now();
          const todayMoment = moment(currentMilli);
          const today = todayMoment.format('M/D/YYYY');

          const yesterdayMoment = moment(currentMilli).subtract('1', 'd');
          const yesterday = yesterdayMoment.format('M/D/YYYY');

          let sectionTitle;

          if (title === today) {
            sectionTitle = strings.TODAY;
          } else if (title === yesterday) {
            sectionTitle = strings.YESTERDAY;
          } else {
            sectionTitle = getCalendarDateStringFromDate(new Date(title));
          }

          return {
            title: sectionTitle,
            data: notes,
          };
        })
        .value(),
    [notes]
  );

  const addNote = (note: Note) => {
    const newNoteList = [...notes, note];
    setNotes(newNoteList);
  };

  return {
    isLoading,
    notesSections,
    reloadNotes,
    addNote,
    deleteNoteAsync,
    updateNote,
  };
};

const Notes: React.FC<NotesProps> = ({
  vehicleId,
  shouldFocus,
  pinned = false,
  inPreviewPanel = false,
  openPinnedComponent,
}) => {
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(strings.API_MESSAGE);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(
    shouldFocus || false
  );
  const [selectedNote, setSelectedNote] = useState<Note | undefined>(undefined);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const {
    notesSections,
    reloadNotes,
    addNote,
    deleteNoteAsync,
    updateNote,
    isLoading,
  } = useNoteDataSource(vehicleId!);
  const windowSize = useWindowSize();
  const uploadStoreData = useUploadStore((uploadStore) => ({
    uploads: uploadStore.uploads,
  }));

  const activeUploads = uploadStoreData.uploads.filter(
    (upload: UploadItem) =>
      upload.meta.parentId === vehicleId &&
      upload.meta.target.type === UploadType.NOTE &&
      isUndefined(upload.meta.target.id)
  );

  const sectionListRef: RefObject<HTMLDivElement> = React.useRef<any>();
  const messageEndRef = React.useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (messageEndRef && messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [notesSections, messageEndRef]);

  const handleUpdateSubmit = async (note?: Note) => {
    if (!note) {
      return false;
    }

    updateNote({ noteId: note.id, note: note.note });

    handleEditModalClose();
    return false;
  };

  const handleDeleteNote = async (note: Note) => {
    await deleteNoteAsync({ noteId: note.id, vehicleId: vehicleId });
  };

  const handleEditClick = (note: Note) => {
    setSelectedNote(note);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedNote(undefined);
    setIsInputFocused(true);
  };

  var PinButton = () => {
    if (!windowSize.isVdpLargeViewport() || !openPinnedComponent) {
      return null;
    }
    return (
      <button
        type="button"
        className="Notes2-Header-pin-button"
        onClick={() => openPinnedComponent('notes')}
      >
        <Pin size={35} />
      </button>
    );
  };

  const handleCloseError = () => {
    setUploadError(false);
    setErrorMessage(strings.API_MESSAGE);
  };

  return (
    <Pane
      bodyRef={sectionListRef}
      className={`Notes2 ${
        activeUploads.length > 0 ? 'Notes2-staged-file-padding' : ''
      }`}
      header={
        <>
          {!pinned && (
            <FlatVDPPaneHeader className="Notes2-Header" vehicleId={vehicleId}>
              {strings.NOTES}
              <PinButton />
            </FlatVDPPaneHeader>
          )}
        </>
      }
      footer={
        <PermissionsGate permissions={[permissions.INVENTORY_VDP_NOTES_CREATE]}>
          <NoteInput
            vehicleId={vehicleId}
            shouldFocus={isInputFocused}
            reloadNotes={reloadNotes}
            addNote={addNote}
          />
        </PermissionsGate>
      }
    >
      <>
        <NotesListSection
          vehicleId={vehicleId}
          onEditClick={handleEditClick}
          onDeleteNote={handleDeleteNote}
          sections={notesSections}
          isLoading={isLoading}
          inPreviewPanel={inPreviewPanel}
        />
        <div ref={messageEndRef} />
        {selectedNote && (
          <EditNoteModal
            note={selectedNote}
            open={isEditModalOpen && !!selectedNote}
            onClose={handleEditModalClose}
            onUpdate={handleUpdateSubmit}
          />
        )}
        <Alert
          open={uploadError}
          contentProps={{
            variant: 'error',
            onClose: handleCloseError,
            message: errorMessage,
          }}
        />
      </>
    </Pane>
  );
};

export default Notes;
