import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useLayoutEffect } from 'react';

import strings from 'common/strings';
import { testIds } from 'common/testIds';
import { DashboardItem } from 'models';

import './MonthlyReviewGraph.scss';

interface MonthlyReviewGraphProps {
  data: DashboardItem[];
}

const MonthlyReviewGraph: React.FC<
  React.PropsWithChildren<MonthlyReviewGraphProps>
> = ({ data }: MonthlyReviewGraphProps) => {
  useLayoutEffect(() => {
    if (process.env.REACT_APP_AMCHARTS_LICENSE_KEY) {
      am4core.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY);
    }
    const chart = am4core.create('chartdiv', am4charts.XYChart);
    chart.data = data;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'label';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.fontSize = 14;
    categoryAxis.fontWeight = '600';
    categoryAxis.renderer.labels.template.fill = am4core.color('#999999');

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.fontWeight = '600';
    valueAxis.fontSize = 14;
    valueAxis.renderer.labels.template.fill = am4core.color('#999999');

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = 'Recon Jobs';
    series.columns.template.tooltipText = '{valueY}';
    series.columns.template.fill = am4core.color('#BBE1F4');
    series.columns.template.stroke = am4core.color('#BBE1F4');
    series.columns.template.width = am4core.percent(50);
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'label';

    const label = series.columns.template.createChild(am4core.Label);
    label.text = '{valueY}';
    label.align = 'center';
    label.valign = 'bottom';
    label.fontWeight = '600';
    label.fill = am4core.color('#000000');
    label.zIndex = 2;
    label.strokeWidth = 0;
    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      <div className="monthly-review-title-container">
        <span className="monthly-review-title-text">
          {strings.VEHICLES_IN_RECON}
        </span>
      </div>
      <div
        id="chartdiv"
        className="monthly-review-graph-container"
        data-vas-testing={testIds.HIGHLIGHTS_VEHICLES_IN_RECON_GRAPH_CONTAINER}
      />
    </>
  );
};

export default MonthlyReviewGraph;
