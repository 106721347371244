import strings from 'common/strings';

const pattern =
  /^(?=.{8,20})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/;
const format = {
  pattern,
  message: strings.CHANGE_PASSWORD_VALIDATION_MESSAGE,
};

export const changePasswordModalValidations = {
  newPassword: {
    presence: { allowEmpty: false, message: strings.PROVIDE_NEW_PASSWORD },
    format,
  },
  currentPassword: {
    presence: { allowEmpty: false, message: strings.PROVIDE_CURRENT_PASSWORD },
  },
  confirmNewPassword: {
    presence: { allowEmpty: false, message: strings.CONFIRM_NEW_PASSWORD },
    equality: {
      attribute: 'newPassword',
      message: strings.PASSWORDS_DONT_MATCH,
    },
    format,
  },
};
