import { useEffect, useState } from 'react';

export default function useOnChange<T>(
  callback: ((x: T) => void) | (() => void),
  value: T,
  callOnMount?: boolean
): void {
  const [previousValue, setPreviousValue] = useState(value);
  const [firstCallPending, setFirstCallPending] = useState(
    callOnMount ?? false
  );
  useEffect(() => {
    if (firstCallPending) {
      callback(value);
      setFirstCallPending(false);
      setPreviousValue(value);
    } else if (value !== previousValue) {
      callback(value);
      setPreviousValue(value);
    }
  }, [callback, firstCallPending, previousValue, value]);
}
