import { Badge } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';

import { useUnreadNotificationsCount } from 'api';
import { permissions } from 'common';
import PermissionsGate from 'components/shared/PermissionsGate';

import './NotificationBell.scss';

interface NotificationBellProps {
  onClick: () => void;
}
const NotificationBell = ({ onClick }: NotificationBellProps) => {
  const { data } = useUnreadNotificationsCount();

  const unreadNotifications = data?.data;

  return (
    <PermissionsGate permissions={[permissions.NOTIFICATIONS_VIEW]}>
      <div className="notifications-link">
        <Badge
          badgeContent={unreadNotifications}
          className="notifications-badge"
          onClick={onClick}
        >
          <Notifications className="notifications-icon" />
        </Badge>
      </div>
    </PermissionsGate>
  );
};
export default NotificationBell;
