import { Warning } from '@material-ui/icons';
import { navigate } from '@reach/router';

import strings from 'common/strings';
import {
  BriefcaseAccount,
  BriefcaseCheck,
  BriefcaseQuestion,
  BriefcaseX,
  NoteMention,
} from 'components/shared/icons';
import { Authentication, Location } from 'models';
import {
  engageEngagementBuilder,
  locationBuilder,
  notesBuilder,
  taskDetailsBuilder,
  vehicleInfoBuilder,
} from 'navigation/routes';

export const iconColor = '#9E9E9E';
export const icons: Record<string, any> = {
  RECON_TASK_APPROVAL: BriefcaseQuestion,
  RECON_TASK_DENIED: BriefcaseX,
  RECON_TASK_ASSIGNED: BriefcaseAccount,
  RECON_TASK_COMPLETE: BriefcaseCheck,
  RECON_VEHICLE_GOAL_WARNING: Warning,
  RECON_VEHICLE_GOAL_DANGER: Warning,
  RECON_NOTE_MENTION: NoteMention,
} as const;

interface RequiredNotificationAttributes {
  parentType: string;
  parentId: string;
  locationId?: string;
  tenantId?: string;
  targetType: string;
  targetId: string;
}

const findRoute = (notification: RequiredNotificationAttributes) => {
  switch (notification.targetType) {
    case strings.TARGET_TYPE_TASK:
      return taskDetailsBuilder(
        notification.parentId,
        undefined,
        notification.locationId
      );
    case strings.TARGET_TYPE_NOTE:
      return notesBuilder(
        notification.parentId,
        undefined,
        notification.locationId
      );
    case strings.TARGET_TYPE_ENGAGEMENT:
      return engageEngagementBuilder(
        notification.parentId,
        false,
        notification.targetId
      );
    case strings.TARGET_TYPE_LOCATION:
      return locationBuilder(
        notification.parentId,
        undefined,
        notification.locationId
      );
    default:
      return vehicleInfoBuilder(
        notification.parentId,
        undefined,
        notification.locationId
      );
  }
};

export const routeToNotification = async (
  notification: RequiredNotificationAttributes,
  onError: () => void,
  user: Authentication,
  currentLocation: Location,
  locationStatePageTitle = '',
  rooftopList: Location[],
  changeLocation: any
) => {
  if (
    notification.parentType !== 'VEHICLE' ||
    (!notification.locationId && !notification.tenantId)
  )
    return;

  if (!user) {
    onError();
    return;
  }

  const notificationRooftopId =
    notification.locationId || notification.tenantId;

  const route = findRoute(notification);

  if (notificationRooftopId !== currentLocation.id) {
    const notificationRooftop = rooftopList.find(
      (rooftop) => rooftop.id === notificationRooftopId
    );
    if (!notificationRooftop) {
      onError();
      return;
    }
    await changeLocation({
      locationId: notificationRooftop.id,
      routePath: route,
    });
  } else {
    navigate(route);
  }
};
