import { ArrowDropDown } from '@material-ui/icons';
import {
  NavigateFn,
  Redirect,
  RouteComponentProps,
  Router,
  useLocation,
  useMatch,
  useNavigate,
} from '@reach/router';
import React, { ReactNode } from 'react';

import { generateTestId, testIds } from 'common/testIds';
import Dropdown from 'components/shared/Dropdown';
import { useWindowSize } from 'hooks';

import './Tabs.scss';

// TODO: Type appropriately
const mapChildren = (children: any, func: any) =>
  React.Children.map(children, (child) =>
    React.isValidElement(child) ? func(child) : child
  );

const findChild = (
  children: React.ReactNode,
  func: any
): React.ReactElement<any> | undefined =>
  React.Children.toArray(children).find(func);

export interface TabProps {
  title: ReactNode;
  path: string;
  tabClassName?: string;
  navigate?: NavigateFn;
  disabled?: boolean;
}

interface TabsProps {
  defaultPath: string;
  className?: string;
  tabClassName?: string;
  basePath?: string;
  tabContentClassName?: string;
  tabContainerClassName?: string;
}

export const Tab: React.FC<TabProps> = ({
  navigate,
  title,
  path,
  tabClassName,
  disabled,
}) => {
  const match = useMatch(path || 'nothing');
  const activeClassName = match ? 'active' : '';
  const disabledClass = disabled ? 'disabled' : '';

  return (
    <div
      data-vas-testing={generateTestId(testIds.MEDIA_TABS, {
        title: `${title}`.replace(/[(0-9)]/g, '').trim(),
      })}
      key={path}
      className={`Tabs-tab-container ${tabClassName}`}
    >
      <button
        type="button"
        onClick={() => {
          if (navigate) {
            navigate(`./${path}`, { replace: true });
          }
        }}
        className={`Tabs-tab ${activeClassName} ${disabledClass}`}
        disabled={disabled}
      >
        {title}
      </button>
      <div className={`Tabs-underline ${activeClassName}`} />
    </div>
  );
};

interface RelativeRedirectProps extends RouteComponentProps {
  to: string;
  from: string;
  basePath: string;
  noThrow: boolean;
}
const RelativeRedirect: React.FC<RelativeRedirectProps> = (props) => {
  const { to, basePath, location, ...rest } = props;
  const actualTo = to.replace('/', '');
  const pathname = location && location.pathname ? location.pathname : '';
  const actualPathname = pathname.substring(
    0,
    pathname.indexOf(basePath) + basePath.length
  );

  return <Redirect {...rest} to={`${actualPathname}/${actualTo}`} />;
};

const Tabs: React.FC<TabsProps> = (props) => {
  const {
    children,
    className = '',
    defaultPath,
    tabClassName = '',
    basePath = '',
    tabContentClassName = '',
    tabContainerClassName = '',
  } = props;

  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const location = useLocation();

  const renderTab = (child: any) => {
    const { title, path, disabled } = child.props;

    return (
      <Tab
        key={title}
        title={title}
        path={path}
        navigate={navigate}
        tabClassName={tabClassName}
        disabled={disabled}
      />
    );
  };

  const renderDesktopTabs = () => (
    <div
      data-vas-testing={testIds.MEDIA_TABS_CONTAINER}
      className={`Tabs-tabs-container ${tabContainerClassName}`}
    >
      {mapChildren(children, renderTab)}
    </div>
  );

  const getChildDropdownData = (child: any) => {
    const { title, path } = child.props;
    return {
      id: path,
      value: title,
    };
  };

  const renderMobileDropdown = () => {
    const pathArr = location.pathname.split('/');
    const activeRoute = pathArr[pathArr.length - 1];
    const activeChild = findChild(
      children,
      (child: any) => child.props.path === activeRoute
    );
    if (!activeChild) return null;
    const { title: selectedTitle, path: selectedId } = activeChild.props;
    const queryParams = location.search;

    return (
      <div className={`Tabs-tabs-container ${tabContainerClassName}`}>
        <Dropdown
          selectedOption={{ id: selectedId, value: selectedTitle }}
          onSelect={(optionId) => {
            navigate(`./${optionId}${queryParams}`, {
              replace: true,
            });
          }}
          options={mapChildren(children, getChildDropdownData)}
          customCaretComponent={
            <ArrowDropDown className="Dropdown-expand-icon" />
          }
          selectedOptionClassName="Tabs-dropdown"
        />
      </div>
    );
  };

  return (
    <div className={`Tabs full-height ${className}`}>
      {windowSize.isMobileViewport()
        ? renderMobileDropdown()
        : renderDesktopTabs()}
      <Router
        primary={false}
        className={`Tabs-content full-height ${tabContentClassName}`}
      >
        {children}
        <RelativeRedirect
          basePath={basePath}
          from="/"
          to={defaultPath}
          default
          noThrow
        />
      </Router>
    </div>
  );
};

export default Tabs;
