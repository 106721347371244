import { navigate } from '@reach/router';
import React, { ReactElement } from 'react';

import { EngageVehicleCard } from 'models/inventory/vehicleCard';
import { engageEngagementBuilder } from 'navigation/routes';

interface IVehicleTagProps {
  item?: Pick<
    EngageVehicleCard,
    'stockNumber' | 'make' | 'model' | 'year' | 'id'
  > &
    Partial<Pick<EngageVehicleCard, 'vin'>>;
  engagementId?: string;
  children?: ReactElement;
}

export const EngageItemVehicle: React.FunctionComponent<IVehicleTagProps> = ({
  item = {},
  engagementId,
  children,
}) => {
  const { stockNumber, make, model, year, id, vin } = item;

  // @todo - refactor engagementBuild into a more robust hook for useAPI
  const permalink = id && engageEngagementBuilder(id, false, engagementId);

  // @todo - gross dude.
  const sty = {
    display: 'flex',
    verticalAlign: 'center',
    alignItems: 'center',
    gap: 10,
  };

  /**
   * @description Navigation should always be accompanied by a matching
   * audit event. We can refelct this business logic in csode by pairing these
   * functions in a shared namespace.
   *
   * @param route - URL path to navigate to. If undefined no action is taken.
   * @todo - integrate this into navigation/routes
   * */
  function navigateWithAudit(route?: string) {
    if (!route) {
      return;
    }
    navigate(route);
  }

  const handleClick = () => navigateWithAudit(permalink);
  return (
    <div role="button" tabIndex={-1} style={sty} onClick={handleClick}>
      {children ?? (
        <div className="item-eng-non-anchor-link">
          <div>
            <small>{stockNumber}</small>
            {vin && <small> - VIN: {vin}</small>}
          </div>
          <div>
            {year} {make}
          </div>
          <div>{model}</div>
        </div>
      )}
    </div>
  );
};

export default EngageItemVehicle;
