import { Button } from 'react-bootstrap';

import { strings } from 'common';
import Radio from 'components/shared/Inputs/Radio';
import { ChecklistItem, ChecklistOption } from 'models';

import FieldLabel from './MpiFieldLabel';

interface IMpiRadioGroup {
  field: ChecklistItem;
  answer: string;
  onChange: (key: string, value: any) => void;
  disabled?: boolean;
}

var MpiRadioGroup = ({ field, answer, onChange, disabled }: IMpiRadioGroup) => {
  const wrapClassName = field.options.length > 3 ? 'wrap' : 'no-wrap';
  const className = `field-container flex-column ${wrapClassName} full-width`;

  const renderRadioInputs = () =>
    field.options.map((option: ChecklistOption) => {
      const label = option.label || option.value;
      const selected = answer === option.value;

      const onSelect = () => onChange(field.id, option.value);

      let selectedClass = '';
      if (option.label === 'Yes' || option.label === 'No') {
        selectedClass =
          option.label === 'Yes' ? 'icon-button-success' : 'icon-button-danger';
      }

      return (
        <Radio
          key={`radio-${label}`}
          label={label}
          name={field.id}
          onClick={onSelect}
          selected={selected}
          id={field.id}
          disabled={disabled}
          selectedClass={selectedClass}
        />
      );
    });

  return (
    <div
      key={`${field.id}_container`}
      className={`${className} py-1 Mpi-radio-group-container`}
    >
      <div className="MpiRadioGroup d-flex justify-content-between align-items-center">
        <FieldLabel
          className="text-muted text-uppercase font-weight-600"
          name={field.label}
          required={field.required}
        />
        {!!answer && (
          <Button
            size="sm"
            className="Mpi-clear-button"
            variant="light"
            onClick={() => onChange(field.id, '')}
          >
            {strings.CLEAR.toUpperCase()}
          </Button>
        )}
      </div>

      <div className="d-flex flex-row">{renderRadioInputs()}</div>
    </div>
  );
};

export default MpiRadioGroup;
