import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  APIResponse,
  defaultMetaQueryFn,
  defaultMutationFn,
  makeQueryString,
  queryCacheStrategies,
} from 'api';
import { EngagementProspect, ShopperForm } from 'models';
import EngagementsPayload from 'models/engage/engagementsPayload';

const baseEngagementPath = '/plugins/vdp/velocityengage/engagements';

/**
 * @name useEngagementsByFilter
 *
 * @param {string} filterFacet - Filter facet attribute, eg. 'SHOPPER'
 * @param {string} id - ID of the entity to filter by as UUID. eg. a prospect ID for SHOPPER filter.
 * @param {boolean} enabled - true if useQuery should be enabled, otherwise false. Defaults to false.
 * @returns {EngagementsPayload | Error}
 */
export function useEngagementsByFilter(
  filterFacet: string,
  id: string,
  enabled: boolean = false
) {
  const path = `${baseEngagementPath}?filterFacets=${filterFacet}::${id}&pageSize=100&startIndex=0`;
  return useQuery<APIResponse<EngagementsPayload>, Error>(path, {
    enabled,
    ...queryCacheStrategies.basic,
  });
}

type useEngagementsRequestParams = {
  startIndex: any;
  pageSize: any;
  sortBy: any;
  sortDirection: any;
  groupBy: any;
  filterBy: any;
  facets: any;
};
export function useEngagements({
  startIndex,
  pageSize,
  sortBy,
  sortDirection,
  groupBy,
  filterBy,
  facets,
}: useEngagementsRequestParams) {
  return useQuery<APIResponse<EngagementsPayload>, Error>(
    [
      startIndex,
      pageSize,
      sortBy,
      sortDirection,
      groupBy,
      ...Object.values(filterBy),
    ],
    async () => {
      const sortFacets =
        sortBy && sortDirection ? `${sortBy}::${sortDirection}` : '';
      const parts = () =>
        [
          'filterFacets=',
          makeQueryString(filterBy) + '&',
          `sortFacets=${sortFacets}&`,
          groupBy !== null ? `groupFacets=${groupBy}&` : '',
          `pageSize=${pageSize}&`,
          `startIndex=${startIndex}&`,
        ].join('');
      const path = baseEngagementPath + '?' + parts();
      return defaultMetaQueryFn(path);
    },
    {
      enabled: !!facets,
      ...queryCacheStrategies.basic,
    }
  );
}

export function useEngageProspects() {
  const path = `/plugins/vdp/velocityengage/prospects`;

  return useQuery<APIResponse<EngagementProspect[]>>(path);
}

export function useCreateEngagement() {
  return useMutation(
    ({
      vehicleId,
      prospectIdList,
      senderId,
      showPrice,
      engagementType,
    }: {
      vehicleId: string;
      prospectIdList: string[];
      senderId: string;
      showPrice: boolean;
      engagementType?: string;
    }) => {
      if (!vehicleId) {
        throw new Error('Must pass a Vehicle to create engagement');
      }

      if (!prospectIdList) {
        throw new Error('Must pass a list of prospect to create engagement');
      }

      if (!senderId) {
        throw new Error('Must pass a sender to create engagement');
      }

      let body;
      if (prospectIdList.length > 0) {
        body = prospectIdList.map((item) => ({
          prospectId: item,
          dealerUserId: senderId,
          showPrice: showPrice,
          engagementType,
        }));
      } else {
        body = [
          {
            dealerUserId: senderId,
            showPrice: showPrice,
            engagementType,
          },
        ];
      }

      const path = `/inventory/${vehicleId}/plugins/vdp/velocityengage/engagements`;
      return defaultMutationFn(path, 'POST', body);
    }
  );
}

export function useSubmitForm() {
  return useMutation(
    ({
      vehicleId,
      engagementType,
      message,
      engagementIds,
    }: {
      vehicleId: string;
      engagementType: any;
      message: string;
      engagementIds: string[];
    }) => {
      if (!vehicleId) {
        throw new Error('Must pass a vehicle to send folio');
      }
      if (!engagementType) {
        throw new Error('Must pass a engagementType to send folio');
      }
      if (!message) {
        throw new Error('Must pass a message to send folio');
      }
      if (!engagementIds) {
        throw new Error('Must pass a list of engagements to send folio');
      }

      const path = `/inventory/${vehicleId}/plugins/vdp/velocityengage/folio/send`;
      const body = {
        engagementType,
        message,
        engagementIds,
      };
      return defaultMutationFn(path, 'POST', body);
    }
  );
}

export function useCreateProspect() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ shopperForm }: { shopperForm: ShopperForm }) => {
      const path = `/plugins/vdp/velocityengage/prospects`;
      const body = {
        person: {
          lastName: shopperForm.lastName,
          firstName: shopperForm.firstName,
          middleName: '',
          fullName: `${shopperForm.firstName} ${shopperForm.lastName}`,
          dateOfBirth: '',
        },
        contact: {
          contactType: '',
          profilePictureURL: '',
          mentionDisplayName: `${shopperForm.firstName} ${shopperForm.lastName}`,
          addressContacts: [
            {
              addressType: 'HOME',
              physicalAddress: {
                addressLine1: shopperForm.address1,
                addressLine2: shopperForm.address2,
                city: shopperForm.city,
                state: shopperForm?.state?.value,
                postalCode: shopperForm.zip,
                postalCodePlus4: '',
                country: 'US',
                latitude: '',
                longitude: '',
              },
            },
          ],
          emailContacts: [
            {
              emailAddressType: 'PERSONAL',
              emailAddress: shopperForm.email,
            },
          ],
          phoneContacts: [
            {
              phoneType: 'MOBILE',
              phoneNumber: shopperForm.phone,
            },
          ],
        },
      };

      return defaultMutationFn(path, 'POST', body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          `/plugins/vdp/velocityengage/prospects`
        );
      },
    }
  );
}
