import React, { useEffect, useMemo, useState } from 'react';

import permissions from 'common/permissions';
import strings from 'common/strings';
import { usePermissions, useWindowSize } from 'hooks';
import { Task } from 'models';
import { currencyFormatter } from 'utils/formatter';

import './SelectedTotals.scss';

interface SelectedTotalsProps {
  totalTaskCount: number;
  selectedTasks: Task[];
  pinned?: boolean;
}

const SelectedTotals: React.FC<SelectedTotalsProps> = ({
  totalTaskCount,
  selectedTasks,
  pinned = false,
}) => {
  const { hasPermission } = usePermissions();
  const userHasTaskLaborCostViewPermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_LABOR_COST_VIEW
  );

  const userHasTaskPartsCostViewPermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_PARTS_COST_VIEW
  );

  const windowSize = useWindowSize();
  const [paddingRightClassName, setPaddingRightClassName] = useState('');

  useEffect(() => {
    const { scrollHeight, clientHeight } =
      document.getElementById('FlatTasks-tasks') ?? {};
    if (scrollHeight && clientHeight) {
      const hasVerticalScrollbar = scrollHeight > clientHeight;
      if (hasVerticalScrollbar) {
        setPaddingRightClassName('SelectedTotals-padding-right');
      } else {
        setPaddingRightClassName('');
      }
    }
  }, [windowSize.height, windowSize.width]);

  const selectedTaskTotals = useMemo(() => {
    return selectedTasks.reduce(
      (previousValue, currentValue) => {
        return {
          hours: (previousValue.hours += currentValue.laborHours ?? 0),
          labor: (previousValue.labor += currentValue?.laborPrice?.amount ?? 0),
          parts: (previousValue.parts += currentValue?.partsPrice?.amount ?? 0),
          total: (previousValue.total += currentValue?.price?.amount ?? 0),
        };
      },
      { labor: 0, parts: 0, total: 0, hours: 0 }
    );
  }, [selectedTasks]);

  return (
    <div
      className={`SelectedTotals ${
        pinned ? 'SelectedTotals-pinned' : ''
      } ${paddingRightClassName}`}
    >
      <div className="SelectedTotals-hours-label">{strings.HOURS}</div>
      <div className="SelectedTotals-hours">
        {userHasTaskLaborCostViewPermissions
          ? selectedTaskTotals.hours
          : strings.EMPTY_VALUE}
      </div>

      <div className="SelectedTotals-labor-label">{strings.LABOR}</div>
      <div className="SelectedTotals-labor">
        {userHasTaskLaborCostViewPermissions
          ? currencyFormatter(selectedTaskTotals.labor)
          : strings.EMPTY_VALUE}
      </div>

      <div className="SelectedTotals-parts-label">{strings.PARTS}</div>
      <div className="SelectedTotals-parts">
        {userHasTaskPartsCostViewPermissions
          ? currencyFormatter(selectedTaskTotals.parts)
          : strings.EMPTY_VALUE}
      </div>

      <div className="SelectedTotals-label">{`${totalTaskCount} Total, ${selectedTasks?.length} Checked`}</div>
      <div className="SelectedTotals-total">
        {userHasTaskLaborCostViewPermissions &&
        userHasTaskPartsCostViewPermissions
          ? currencyFormatter(selectedTaskTotals.total)
          : strings.EMPTY_VALUE}
      </div>
    </div>
  );
};

export default SelectedTotals;
