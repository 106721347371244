import { Cancel, Done, Pause, PlayArrow } from '@material-ui/icons';
import { navigate } from '@reach/router';
import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import { UploadItem, UploadStatus } from 'store/uploads/types';
import humanFileSize from 'utils/fileSystem';
import { truncateEllipsisMiddle } from 'utils/string';

import './UploadRow.scss';
import 'react-circular-progressbar/dist/styles.css';

interface UploadRowProps {
  upload: UploadItem;
  removeUpload: (event: React.MouseEvent) => void;
  onPauseResume: (event: React.MouseEvent) => void;
}

const UploadRow: React.FC<UploadRowProps> = ({
  upload,
  removeUpload,
  onPauseResume,
}) => {
  const getIcon = () => {
    switch (upload.uploadStatus) {
      case UploadStatus.COMPLETED:
        return <Done className="UploadRow-progress-icon" />;
      case UploadStatus.PAUSED:
        return (
          <PlayArrow
            className="UploadRow-progress-icon"
            onClick={onPauseResume}
          />
        );
      case UploadStatus.UPLOADING:
        return (
          <Pause className="UploadRow-progress-icon" onClick={onPauseResume} />
        );
      case UploadStatus.ERROR:
        return null;
      default:
        return null;
    }
  };

  return (
    <div className="UploadRow">
      <div
        role="none"
        className="UploadRow-upload-data"
        onClick={() => navigate(upload.meta.url)}
      >
        <div className="UploadRow-upload-data-filename">
          {truncateEllipsisMiddle(upload.meta.name)}
        </div>
        <div className="UploadRow-upload-data-filesize">
          {humanFileSize(upload.uploadOptions.data.size)}
        </div>
      </div>
      <div className="UploadRow-icons">
        <div className="UploadRow-progressbar-container">
          <CircularProgressbarWithChildren
            value={upload.progress}
            strokeWidth={8}
          >
            {getIcon()}
          </CircularProgressbarWithChildren>
        </div>
        <Cancel onClick={removeUpload} className="UploadRow-delete-icon" />
      </div>
    </div>
  );
};

export default UploadRow;
