import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useToken } from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import { VelocityEngageIcon } from 'components/shared/icons';
import PermissionsGate from 'components/shared/PermissionsGate';
import Tabs from 'components/shared/Tabs';
import { usePermissions } from 'hooks';

import AssignmentsTab from './AssignmentsTab';
import DashboardSummaryItems from './DashboardSummary';
import EngageTab from './EngageTab';
import HighlightsTab from './HighlightsTab';
import NeedsAttentionTab from './NeedsAttentionTab';

import './Dashboard.scss';

interface DashboardProps {
  default?: boolean;
  path: string;
}

const Dashboard: React.FC<React.PropsWithChildren<DashboardProps>> = () => {
  const { data: sessionData } = useToken();
  const name = sessionData?.user.firstName;
  const { hasPermission } = usePermissions();

  const defaultPath = () => {
    if (hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW_DASHBOARD)) {
      return 'engage';
    }
    if (
      hasPermission(permissions.DASHBOARD_INVENTORY_NEEDING_ATTENTION_VIEW) &&
      !hasPermission(permissions.DASHBOARD_SUMMARY_VIEW)
    ) {
      return 'attention';
    }
    return 'highlights';
  };

  const greeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return strings.GREETING_MORNING;
    }
    if (currentHour < 18) {
      return strings.GREETING_AFTERNOON;
    }
    return strings.GREETING_EVENING;
  };

  return (
    <div className="Dashboard page grey-background vertical-scroll ">
      <div
        data-vas-testing={testIds.DASHBOARD_CONTAINER}
        className="Dashboard-panel-container"
      >
        <Container className="Dashboard-overview-container">
          <Row className="Dashboard-welcome-container centered">
            <Col xl="12" className="Dashboard-salutation-container">
              <span>{`${greeting()} ${name}`}</span>
            </Col>
            <Col xl="12" className="Dashboard-SOTU-container">
              <b>Here&apos;s what&apos;s happening today.</b>
            </Col>
          </Row>
          <Row>
            <PermissionsGate permissions={[permissions.DASHBOARD_SUMMARY_VIEW]}>
              <DashboardSummaryItems />
            </PermissionsGate>
          </Row>
        </Container>
      </div>
      <Container
        fluid
        className="Dashboard-content-container position-relative flex-grow flex-rows"
      >
        <Tabs
          defaultPath={defaultPath()}
          basePath="dashboard"
          className="Dashboard-tabs-container"
          tabClassName="Dashboard-tab"
        >
          {hasPermission(permissions.DASHBOARD_SUMMARY_VIEW) && (
            <HighlightsTab title="Highlights" path="highlights" />
          )}
          {hasPermission(permissions.DASHBOARD_SUMMARY_VIEW) && (
            <AssignmentsTab title="Assignments" path="assignments" />
          )}
          {hasPermission(
            permissions.DASHBOARD_INVENTORY_NEEDING_ATTENTION_VIEW
          ) && (
            <NeedsAttentionTab
              title="Vehicles Needing Attention"
              path="attention"
            />
          )}
          {hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW_DASHBOARD) && (
            <EngageTab
              title={
                <>
                  VelocityEngage{' '}
                  <VelocityEngageIcon className="dashboard-tab-icon" />
                </>
              }
              path="engage"
            />
          )}
        </Tabs>
      </Container>
    </div>
  );
};

export default Dashboard;
