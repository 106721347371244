import { VehicleCard } from 'models';

export enum YesNoEnum {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
}

export enum RecallType {
  NHTSA = 'NHTSA',
  OEM_VOLUNTARY = 'OEM_VOLUNTARY',
  OEM_WARRANTY = 'OEM_WARRANTY',
  UNKNOWN = 'UNKNOWN',
  OTHER = 'OTHER',
}

export enum RiskLevel {
  ZERO_RISK = 'ZERO_RISK',
  LOW_RISK = 'LOW_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
  HIGH_RISK = 'HIGH_RISK',
  EXTREME_RISK = 'EXTREME_RISK',
  UNKNOWN = 'UNKNOWN',
}

export enum RiskType {
  COLLISION = 'COLLISION',
  FIRE = 'FIRE',
  SAFETY = 'SAFETY',
  EMISSIONS = 'EMISSIONS',
  UNKNOWN = 'UNKNOWN',
}

export interface VehicleInfo {
  make: VehicleCard['make'];
  model: VehicleCard['model'];
  vin: VehicleCard['vin'];
  year: VehicleCard['year'];
}

export interface Recall {
  canBeDriven: YesNoEnum;
  description: string;
  expirationDate: Date;
  id: string;
  issuedDate: Date;
  name: string;
  nhtsaId: string;
  oemId: string;
  recallSource: string;
  recallType: RecallType;
  rememdy: string;
  risk: string;
  riskLevel: RiskLevel;
  riskType: RiskType;
  stopSale: YesNoEnum;
  vehicleInfo: VehicleInfo;
}
