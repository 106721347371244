import { Typography } from '@material-ui/core';
import React from 'react';
import Modal from 'react-bootstrap/Modal';

import strings from 'common/strings';

import './ConfirmationDialog.scss';

interface ConfirmationDialogProps {
  onClose: () => void;
  onAccept: () => void;
  title: string;
  body: string;
  mainButtonTitle: string;
  disableCancelButton?: boolean;
  disabled?: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const {
    onClose,
    onAccept,
    title,
    body,
    mainButtonTitle,
    disableCancelButton,
    disabled,
  } = props;
  return (
    <Modal className="ConfirmationDialog" show centered onHide={onClose}>
      <Modal.Header
        className={`ConfirmationDialog__${
          title ? 'header' : 'no-header'
        } modal-header-container`}
      >
        <span className="ConfirmationDialog__modal-title">{title}</span>
      </Modal.Header>
      <Modal.Body className="ConfirmationDialog__modal_body flex-grow">
        <Typography className="full-height flex-rows justify-center">
          {body}
        </Typography>
        <div className="footer">
          <div className="ConfirmationDialog-footer">
            {!disableCancelButton && (
              <button
                type="button"
                onClick={onClose}
                className="ConfirmationDialog-cancel-button"
              >
                {strings.CANCEL}
              </button>
            )}
            <button
              type="button"
              className="blue-button ConfirmationDialog-create-button"
              onClick={onAccept}
              disabled={disabled}
            >
              <div>{mainButtonTitle}</div>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationDialog;
