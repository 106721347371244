import { FC } from 'react';

import { useChangeStep, useUtilityWorkflow } from 'api/inventory/workflows';
import permissions from 'common/permissions';
import strings from 'common/strings';
import BaseStepDropdown from 'components/shared/BaseStepDropdown';
import { usePermissions } from 'hooks';
import { StepItem } from 'models';

interface StepDropdownProps {
  containerClassName?: string;
  loaderType?: 'srp' | 'default' | 'srpGradient';
  currentStep?: Pick<StepItem, 'id' | 'name'>;
  inventoryId?: string;
}

const StepDropdown: FC<StepDropdownProps> = ({
  inventoryId,
  containerClassName,
  loaderType,
  currentStep,
}) => {
  const { hasPermission } = usePermissions();

  const { data: queryWorkflowData } = useUtilityWorkflow();
  const changeStepMutation = useChangeStep(inventoryId);

  const workflow = queryWorkflowData?.data;

  const handleStepSelect = async (newSelectedStepId: string) => {
    await changeStepMutation.mutateAsync(newSelectedStepId);
  };

  if (hasPermission(permissions.RECON_VDP_WORKFLOW_STEP_UPDATE)) {
    return (
      <BaseStepDropdown
        containerClassName={containerClassName}
        workflow={workflow}
        isLoading={changeStepMutation.isLoading}
        loaderType={loaderType}
        currentStep={currentStep}
        onStepChange={handleStepSelect}
      />
    );
  }

  return (
    <span className="stepName">{currentStep?.name || strings.EMPTY_VALUE}</span>
  );
};

export default StepDropdown;
