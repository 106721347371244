import { navigate } from '@reach/router';
import queryStringLib from 'query-string';

import { FILTER_PARAM_NAMES } from 'api';

export type QueryParamValue = string | number | string[] | null | undefined;

type FilterParams = typeof FILTER_PARAM_NAMES[number];

type PairsFromUnionKeyAndValue<KeyT extends PropertyKey, ValueT> = {
  [Key in KeyT]: { [Key2 in Key]: ValueT };
}[KeyT];

type CustomFilterQueryParam = PairsFromUnionKeyAndValue<FilterParams, string>;

export const getLastQueryParamWithName = (
  name: string,
  search: string | undefined = undefined,
  defaultValue?: string
): string | undefined => {
  const paramValues = queryStringLib.parse(search || window.location.search)[
    name
  ];
  if (!paramValues) return defaultValue;
  return typeof paramValues === 'string'
    ? paramValues
    : paramValues[paramValues.length - 1];
};

export const getAllQueryParamsWithName = (
  name: string,
  search: string | undefined = undefined,
  defaultValues: string[] = []
): string[] | undefined => {
  const paramValues = queryStringLib.parse(search || window.location.search)[
    name
  ];
  if (!paramValues) return defaultValues;
  return typeof paramValues === 'string' ? [paramValues] : paramValues;
};

export const updateUrlSearchParam = (
  params: Record<string, QueryParamValue>,
  replace: boolean = false
) => {
  const url = new URL(window.location.href);
  url.search = queryStringLib.stringify(
    removeUndefinedValueFromObject({
      ...queryStringLib.parse(window.location.search),
      ...params,
    })
  );
  navigate(url.href, { replace });
};

export const replaceUrlSearchParams = (
  params: Record<string, QueryParamValue>
) => {
  const url = new URL(window.location.href);
  url.search = queryStringLib.stringify(
    removeUndefinedValueFromObject({
      ...params,
    })
  );
  navigate(url.href);
};

export const createCustomFilterQuery = (params: CustomFilterQueryParam) =>
  queryStringLib.stringify({ ...params });

function removeUndefinedValueFromObject<T extends Record<string, any>>(
  obj: T
): T {
  Object.keys(obj).forEach((key: string) => {
    if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
}
