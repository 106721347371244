import { useQuery } from 'react-query';

export const isValidVin = (vin: string) => {
  const vinpattern = new RegExp(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/g);
  return vinpattern.test(vin);
};

/**
 * Get window sticker pdf.
 * @param {string} vin
 * Endpoint: GET /useWindowSticker/{vin}
 */
export function useWindowSticker(vin: string) {
  const path = `${process.env.REACT_APP_WINDOW_STICKER_URL}/vin/${vin}?source=Velocity Recon`;
  const method = () => path;
  return useQuery(path, method, {
    enabled: isValidVin(vin),
    retry: 3,
  });
}
