import TitleTooltip from '../TitleTooltip';

import './LabelValue.scss';

interface LabelValueProps {
  label: string;
  children: React.ReactNode | string;
  direction?: 'row' | 'column';
  labelCaps?: boolean;
  tooltip?: string;
}
const LabelValue = ({
  label,
  labelCaps = false,
  direction = 'row',
  children,
  tooltip = '',
}: LabelValueProps) => {
  const shownLabel = labelCaps ? label?.toUpperCase() : label;
  const shownDirection = `LabelValue-container-${direction}`;

  return (
    <div className={`LabelValue-container ${shownDirection}`}>
      <div className="LabelValue-label">
        {tooltip ? (
          <TitleTooltip label={shownLabel} title={tooltip} />
        ) : (
          shownLabel
        )}
      </div>
      <div className="LabelValue-value">{children}</div>
    </div>
  );
};

export default LabelValue;
