/* eslint-disable jsx-a11y/media-has-caption */
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';
import Carousel, { CommonProps, Modal, ModalGateway } from 'react-images';

import { isImage } from 'common/images';
import permissions from 'common/permissions';
import { VehicleImage } from 'models';

import CustomHeader from './CustomHeader';

import './ModalCarousel.scss';

const CustomNextButton: React.FC<CommonProps> = ({ innerProps }) => (
  <button
    type="button"
    className="ModalCarousel-nav-button ModalCarousel-nav-button-next"
    {...innerProps}
  >
    <ChevronRight />
  </button>
);

const CustomPreviousButton: React.FC<CommonProps> = ({ innerProps }) => (
  <button
    type="button"
    className="ModalCarousel-nav-button ModalCarousel-nav-button-previous"
    {...innerProps}
  >
    <ChevronLeft />
  </button>
);

var CustomFooter = () => {
  return null;
};

const CustomView: React.FC<CommonProps> = (props) => {
  const { innerProps, views } = props;
  let { currentView } = props;
  if (!currentView && views) {
    [currentView] = views;
  }
  const source: string = (currentView?.source as string) || '';

  return (
    <div className="ModalCarousel-view">
      {isImage(`${source}`) ? (
        <img
          src={source}
          alt="media"
          className="ModalCarousel-view-image"
          {...innerProps}
        />
      ) : (
        <video
          src={source as string}
          controls
          className="ModalCarousel-view-image"
          {...innerProps}
        />
      )}
    </div>
  );
};

interface ModalCarouselProps {
  images: VehicleImage[];
  modalIsOpen: boolean;
  currentIndex: number;
  onClose: () => void;
  onDeleteImage?: (id: string) => void;
  onAssignImage?: (id: string, shot: string) => void;
  deletePermission?: string;
  assignPermission?: string;
}

const ModalCarousel: React.FC<ModalCarouselProps> = (props) => {
  const {
    images,
    modalIsOpen,
    currentIndex,
    onClose,
    onDeleteImage,
    onAssignImage,
    deletePermission = permissions.INVENTORY_VDP_PHOTOS_DELETE,
    assignPermission = permissions.INVENTORY_VDP_PHOTOS_CREATE,
  } = props;
  const views = images.map((image: VehicleImage) => ({
    source: image.uri,
    name: image.name,
    createdDate: image.createdAt,
    id: image.id,
    type: image.imageType,
  }));

  return (
    <ModalGateway>
      {modalIsOpen && (
        <Modal
          styles={{
            blanket: (base: any) => ({ ...base, zIndex: 10000 }),
            positioner: (base: any) => ({ ...base, zIndex: 10000 }),
            dialog: (base: any) => ({ ...base, zIndex: 10000 }),
          }}
          onClose={onClose}
        >
          <Carousel
            views={views}
            currentIndex={currentIndex}
            components={{
              Header: (props) => (
                <CustomHeader
                  {...props}
                  innerProps={{
                    onDeleteImage,
                    onAssignImage,
                    deletePermission,
                    assignPermission,
                    ...props.innerProps,
                  }}
                />
              ),
              Footer: CustomFooter,
              NavigationNext: CustomNextButton,
              NavigationPrev: CustomPreviousButton,
              View: CustomView,
            }}
            trackProps={{
              contain: true,
              instant: true,
            }}
            frameProps={{
              autoSize: 'height',
            }}
          />
        </Modal>
      )}
    </ModalGateway>
  );
};

export default ModalCarousel;
