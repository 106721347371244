import { RouteComponentProps } from '@reach/router';

import useGetDealerInvoiceInventoryQuery from 'api/invoicing/useGetDealerInvoiceInventoryQuery';
import ApiError from 'components/shared/ApiError';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import VehicleList from '../../VehicleList/VehicleList';
import Header from './Header';

import './InvoiceList.scss';

type Props = RouteComponentProps;

const InvoiceList = ({ navigate, ...props }: Props) => {
  const getDealerInvoiceInventoryQuery = useGetDealerInvoiceInventoryQuery();
  const handleListClick = () => {};
  const vehicles = getDealerInvoiceInventoryQuery.data?.data ?? [];
  const noVehicles = (
    <span className="vendor-no-vehicles full-height full-width flex-rows">
      No vehicles found!
    </span>
  );

  if (getDealerInvoiceInventoryQuery.isSuccess && vehicles.length === 0) {
    return noVehicles;
  }

  return (
    <div className="vendor-invoice-list">
      <main className="vendor-invoice-list-content">
        {getDealerInvoiceInventoryQuery.isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="vendor-invoice-list-container">
            <Header />
            <VehicleList vehicles={vehicles} onClick={handleListClick} />
            {getDealerInvoiceInventoryQuery.error && (
              <ApiError error={getDealerInvoiceInventoryQuery.error} />
            )}
          </div>
        )}
      </main>
    </div>
  );
};

export default InvoiceList;
