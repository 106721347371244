import { useLocation } from '@reach/router';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useVehicleSummary } from 'api';
import permissions from 'common/permissions';
import VehicleDetails2 from 'components/pages/VehicleDetails';
import ExtensionHeader from 'components/shared/ExtensionHeader';
import NotificationCenter from 'components/shared/NotificationCenter';
import { usePermissions } from 'hooks';
import { getExtensionModeInfo } from 'utils/extension';

interface VelocityInsightVehicleDetailsProps {
  path?: string;
  inventoryId?: string;
  tenantId?: string;
}

export const VelocityInsightVehicleDetails = ({
  path,
  inventoryId,
  tenantId,
}: VelocityInsightVehicleDetailsProps) => {
  const { hasPermission } = usePermissions();
  const queryClient = useQueryClient();
  const routerLocation = useLocation();
  const [isNotificationCenterOpen, setIsNotificationCenterOpen] =
    useState(false);
  const { data: vehicleResponse } = useVehicleSummary(
    inventoryId === 'background' ? undefined : inventoryId
  );

  const receiveMessageHandler = useCallback(
    (event: MessageEvent<{ action: string; vehicleId: string }>) => {
      const { action, vehicleId } = event?.data ?? {};
      if (action === 'refresh_vehicle_details') {
        queryClient.invalidateQueries(`/inventory/${vehicleId}`);
      }
    },
    [queryClient]
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessageHandler, false);
    return () => {
      window.removeEventListener('message', receiveMessageHandler);
    };
  }, [receiveMessageHandler]);

  const isAuthorized =
    hasPermission(permissions.RECON_VIEW) ||
    hasPermission(permissions.INVENTORY_VIEW);

  if (!getExtensionModeInfo().enabled) {
    return null;
  }

  return (
    <div className={`page flex-rows mobile-viewport overflow-hidden`}>
      <ExtensionHeader />
      <div className="flex-grow relative header-margin">
        {hasPermission(permissions.NOTIFICATIONS_VIEW) && (
          <NotificationCenter
            isOpen={isNotificationCenterOpen}
            onClose={() => setIsNotificationCenterOpen(false)}
          />
        )}
        {isAuthorized && vehicleResponse?.data && (
          <VehicleDetails2
            path={path}
            vehicle={vehicleResponse.data}
            location={routerLocation}
            showCloseButton={false}
          />
        )}
      </div>
    </div>
  );
};
