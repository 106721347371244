import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';

import AlertContent, { AlertContentProps } from './AlertContent/AlertContent';

export interface AlertProps {
  anchor?: SnackbarOrigin;
  open: boolean;
  handleClose?: () => void;
  contentProps: AlertContentProps;
  duration?: number;
  className?: string;
}

var Alert = (props: AlertProps) => {
  const {
    anchor,
    open,
    handleClose,
    contentProps,
    duration,
    className,
    ...other
  } = props;
  const defaultAnchor: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };
  const anchorOrigin = anchor || defaultAnchor;
  const hideDuration = duration || null;

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={hideDuration}
      className={className}
      {...other}
    >
      <AlertContent {...contentProps} />
    </Snackbar>
  );
};

export default Alert;
