import { navigate } from '@reach/router';
import React from 'react';

import { Section, VehicleSummary } from 'models';

import { MobileHeaderData } from '../MobileVDPHeader';

import './MenuItem.scss';

interface MenuItemProps {
  sectionData: MobileHeaderData;
  section: Section;
  vehicle: VehicleSummary;
  onClick: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  sectionData,
  section,
  vehicle,
  onClick,
}) => {
  const getVelocityLocateUrl = (links: string) => {
    const linkIndex = links.lastIndexOf('https');
    return links.slice(linkIndex);
  };

  return (
    <div
      role="none"
      className="MenuItem"
      onClick={() => {
        if (section.id === 'velocityLocate') {
          const velocityLocateUrl = getVelocityLocateUrl(section.links!.self);
          window.open(velocityLocateUrl, '_newtab');
          onClick();
          return;
        }
        navigate(sectionData.navigateTo(vehicle.vehicleCard.id!), {
          replace: true,
        }).then(onClick);
      }}
    >
      <div className="MenuItem-title">
        <div className="MenuItem-title-icon">{sectionData?.icon}</div>
        <div className="MenuItem-title-label">{sectionData?.title}</div>
      </div>
      {section.alertCount > 0 && (
        <div className="MenuItem-chip">
          <div className="MenuItem-chip-text">{section.alertCount}</div>
        </div>
      )}
    </div>
  );
};

export default MenuItem;
