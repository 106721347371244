import { ArrowDropDown } from '@material-ui/icons';
import React, { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

import { strings } from 'common';
import { Price } from 'models/reports/corporatePayload';
import { currencyFormatter } from 'utils/formatter';
import { getFormattedDurationFromSeconds } from 'utils/time';

import { ratings } from './ReportsState';

import './ReportRow.scss';

const DEFAULT_MAX_TERMS = 2;
const INCLUDE_SECONDS = false;

interface ReportRowProps {
  columns: Array<{
    contents?: ReactNode;
    columnClass?: string;
    contentClass?: string;
    seconds?: number | null;
    secondsGoal?: number | null;
    money?: Price | null;
    cellName?: string;
  }>;
  title?: ReactNode;
  rowContentClassName?: string;
  rowTitleClassName?: string;
  showExpandArrow?: boolean;
  withPadding?: boolean;
}

const formatDurationFromSeconds = (timeInSeconds: number): string =>
  getFormattedDurationFromSeconds(
    timeInSeconds,
    DEFAULT_MAX_TERMS,
    INCLUDE_SECONDS,
    {
      trim: true,
      trunc: true,
    }
  );

// returns '--' or '6d 21h'
export const determineOptionalTimeDisplay = (
  timeInSeconds: number | null
): string =>
  timeInSeconds
    ? formatDurationFromSeconds(timeInSeconds)
    : strings.EMPTY_VALUE;

const displayOptionalGoalTime = (
  timeInSeconds?: number | null,
  goalTimeInSeconds?: number | null
): string =>
  timeInSeconds && goalTimeInSeconds
    ? ' / ' + formatDurationFromSeconds(goalTimeInSeconds) + ' GOAL'
    : '';

// returns '--' or '73.05' or '+$90.65' or '-$89.48'
const displayOptionalDollarAmount = (
  price: Price | null,
  unshiftPlusSymbol = true
): string =>
  price && price?.amount
    ? `${unshiftPlusSymbol && price?.amount > 0 ? '+' : ''}${currencyFormatter(
        price?.amount
      )}`
    : strings.EMPTY_VALUE;

function selectContent(
  contents?: ReactNode,
  seconds?: number | null,
  money?: Price | null
) {
  if (contents !== undefined) return contents ?? strings.EMPTY_VALUE;
  if (seconds) return getFormattedDurationFromSeconds(seconds);
  if (money !== undefined) return displayOptionalDollarAmount(money);
  return strings.EMPTY_VALUE;
}
// return '' or 'bad' or 'good' style
const optionalDollarStyling = (price: Price | null) =>
  price && price.amount ? (price.amount > 0 ? ratings.BAD : ratings.GOOD) : '';
// return '' or 'bad' or 'good' style
const getOptionalExceededGoalStatusStyle = (
  timeValue: number | null,
  goalValue: number | null
) =>
  timeValue && goalValue
    ? timeValue > goalValue
      ? ratings.BAD
      : ratings.GOOD
    : '';
function computeCellClassName(
  time: { seconds?: number | null; secondsGoal?: number | null },
  money?: Price | null
) {
  if (money !== undefined) return optionalDollarStyling(money);
  if (time.seconds !== undefined && time.secondsGoal !== undefined) {
    return getOptionalExceededGoalStatusStyle(time.seconds, time.secondsGoal);
  }
  return '';
}

const ConditionalCard: React.FC<{ wrapWithCard: boolean }> = ({
  children,
  wrapWithCard,
}) => (wrapWithCard ? <Card>{children}</Card> : <>{children}</>);
const ReportRow: React.FC<ReportRowProps> = ({
  title,
  columns,
  rowContentClassName,
  rowTitleClassName,
  showExpandArrow = true,
  withPadding = false,
}) => (
  <ConditionalCard wrapWithCard={withPadding}>
    <div className={`ReportRow ${withPadding ? 'WithPadding' : ''}`}>
      {title && (
        <>
          {showExpandArrow && <ArrowDropDown fontSize="small" />}
          <div
            className={
              rowTitleClassName ? rowTitleClassName : 'w-25 ReportRow-title'
            }
          >
            {title}
          </div>
        </>
      )}
      <div
        className={
          rowContentClassName
            ? rowContentClassName
            : `${title ? 'w-75' : 'w-100'} evenly-spaced-container`
        }
      >
        {columns.map(
          (
            {
              contents,
              columnClass,
              contentClass,
              seconds,
              money,
              secondsGoal,
              cellName: nameDataForStyling,
            },
            index
          ) => (
            <div
              key={`report-row-${index}`}
              className={
                columnClass
                  ? columnClass
                  : 'd-flex flex-column justify-content-center evenly-spaced-item'
              }
              data-name={nameDataForStyling}
            >
              <span
                className={`font-bold ${computeCellClassName(
                  { seconds, secondsGoal },
                  money
                )} ${contentClass}`}
              >
                {selectContent(contents, seconds, money)}
              </span>
              <span className="CircularProgressChart-goal-time">
                {displayOptionalGoalTime(seconds, secondsGoal)}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  </ConditionalCard>
);

export default ReportRow;
