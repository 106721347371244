import { useState } from 'react';

import TextInput from 'components/shared/Inputs/TextInput';
import { ChecklistItem } from 'models';

import FieldLabel from './MpiFieldLabel';

interface IMpiTextInput {
  field: ChecklistItem;
  answer: any;
  onChange: (key: string, value: any) => void;
  disabled: boolean;
}

var MpiTextInput = ({ field, answer, onChange, disabled }: IMpiTextInput) => {
  const [value, setValue] = useState(answer);

  return (
    <div
      key={`${field.id}_text_input_container TextInput`}
      className="MpiTextInput py-1"
    >
      <FieldLabel
        className="text-muted text-uppercase font-weight-600"
        name={field.label}
        required={field.required}
      />
      <TextInput
        disabled={disabled}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={() => onChange(field.id, value)}
      />
    </div>
  );
};

export default MpiTextInput;
