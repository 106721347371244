import { FC, useMemo } from 'react';

import SharedStepDropdown from 'components/shared/SharedStepDropdown';
import { StepItem } from 'models';

import { isStepDefined } from './utils';

interface VehicleCardSharedStepDropdownProps {
  stepItem: StepItem | undefined;
  inventoryId: string | undefined;
}

export const VehicleCardSharedStepDropdown: FC<
  VehicleCardSharedStepDropdownProps
> = ({ stepItem, inventoryId }) => {
  const currentStepPart = useMemo(
    () => ({
      id: stepItem?.sharedStepId,
      name: stepItem?.sharedStep,
    }),
    [stepItem?.sharedStep, stepItem?.sharedStepId]
  );

  const tenantId = stepItem?.sharedTenantId;

  if (!isStepDefined(currentStepPart) || !tenantId) {
    return currentStepPart.name ? <div>{currentStepPart.name}</div> : null;
  }

  return (
    <SharedStepDropdown
      tenantId={tenantId}
      inventoryId={inventoryId}
      currentStep={currentStepPart}
      containerClassName="UsersDropdown"
      loaderType="srpGradient"
    />
  );
};
