/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';

import { BOOTSTRAP_BREAKPOINT } from 'context/Breakpoints';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktopViewport = () =>
    windowSize.width ? windowSize.width >= BOOTSTRAP_BREAKPOINT.sm : false;

  const isMobileViewport = () =>
    windowSize.width && windowSize.height
      ? windowSize.width < BOOTSTRAP_BREAKPOINT.sm ||
        windowSize.height < BOOTSTRAP_BREAKPOINT.sm
      : false;

  const isTabletLandscape = () =>
    windowSize.width ? windowSize.width <= BOOTSTRAP_BREAKPOINT.lg : false;

  const isWideViewport = () =>
    windowSize.width ? windowSize.width >= 1550 : false;

  const isMediumViewport = () =>
    windowSize.width
      ? windowSize.width > BOOTSTRAP_BREAKPOINT.sm &&
        windowSize.width <= BOOTSTRAP_BREAKPOINT.md
      : false;

  const isVdpLargeViewport = () =>
    windowSize.width ? windowSize.width > 1200 : false;

  const isSidebarViewport = () =>
    windowSize.width ? windowSize.width >= 1650 : false;

  return {
    ...windowSize,
    isDesktopViewport,
    isMobileViewport,
    isTabletLandscape,
    isWideViewport,
    isMediumViewport,
    isVdpLargeViewport,
    isSidebarViewport,
  };
}
