import { DateRange as DateRangeIcon } from '@material-ui/icons';
import moment from 'moment';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { strings } from 'common';
import { DateRange } from 'common/DateRange';
import CustomDatePickerModal from 'components/shared/CustomDatePickerModal';

import './ReportsDateFilter.scss';

type RangeType =
  | 'TODAY'
  | 'YESTERDAY'
  | 'LAST_7_DAYS'
  | 'LAST_30_DAYS'
  | 'THIS_MONTH'
  | 'LAST_MONTH'
  | 'CUSTOM_RANGE';

const basicFilterTypes: RangeType[] = [
  'TODAY',
  'YESTERDAY',
  'LAST_7_DAYS',
  'LAST_30_DAYS',
  'THIS_MONTH',
  'LAST_MONTH',
];

export interface ReportsDateFilterProps {
  allowCustomRange: boolean;
  onChange: (r: DateRange) => void;
}

function getRangeFromType(type: RangeType): DateRange | null {
  const now = moment(new Date());
  const today = moment(now);
  today.set('hours', 0);
  today.set('minutes', 0);
  today.set('seconds', 0);
  let start = moment();
  let end = moment();
  switch (type) {
    case 'TODAY':
      start.set('date', today.get('date'));
      end.set('date', now.get('date'));
      break;
    case 'YESTERDAY':
      start = moment().subtract(1, 'day');
      end = moment(start);
      break;
    case 'LAST_7_DAYS':
      start.set('date', today.get('date') - 6);
      end.set('date', today.get('date'));
      break;
    case 'LAST_30_DAYS':
      start.set('date', today.get('date') - 29);
      end.set('date', today.get('date'));
      break;
    case 'THIS_MONTH':
      start.set('month', today.get('month'));
      start.set('date', 1);
      end.set('date', today.get('date'));
      break;
    case 'LAST_MONTH':
      start.set('month', today.get('month') - 1);
      start.set('date', 1);
      end.set('date', 0);
      break;
    case 'CUSTOM_RANGE':
      return null;
    default:
      return null;
  }
  start.set('hours', 0);
  start.set('minutes', 0);
  start.set('seconds', 0);
  end.set('hours', 23);
  end.set('minutes', 59);
  end.set('seconds', 59);
  return { start, end };
}

function getSimpleDate(date?: moment.Moment) {
  if (date) {
    return moment(date).format('MMMM D, YYYY');
  }
  return '--';
}

function getLabelFromRange(type: RangeType, range: DateRange): string {
  const { start, end } = range;
  switch (type) {
    case 'TODAY':
    case 'YESTERDAY':
      return `${strings[type]}: ${moment(end).format('MMM D')}`;
    case 'LAST_7_DAYS':
    case 'LAST_30_DAYS':
    case 'THIS_MONTH':
    case 'LAST_MONTH':
      return strings[type];
    case 'CUSTOM_RANGE':
      return `${getSimpleDate(start)} - ${getSimpleDate(end)}`;
    default:
      return '-';
  }
}

export function getDefaultDateRange(): DateRange {
  const ret = getRangeFromType('THIS_MONTH');
  if (ret == null) {
    return { start: moment(), end: moment() };
  }
  return ret;
}

const ReportsDateFilter = ({
  allowCustomRange,
  onChange,
}: ReportsDateFilterProps) => {
  const [rangeType, setRangeType] = useState<RangeType>('THIS_MONTH');
  const filterItems: RangeType[] = allowCustomRange
    ? [...basicFilterTypes, 'CUSTOM_RANGE']
    : basicFilterTypes;
  const [showCustomRangeModal, setShowCustomRangeModal] = useState(false);
  const [customDateRange, setCustomDateRange] = useState<DateRange | null>(
    null
  );
  const labelDateRange =
    (rangeType === 'CUSTOM_RANGE'
      ? customDateRange
      : getRangeFromType(rangeType)) ?? getDefaultDateRange();
  const label = getLabelFromRange(rangeType, labelDateRange);

  return (
    <div className="ReportsDateFilter">
      <Dropdown>
        <Dropdown.Toggle variant="light" id="Reports-filter-toggle">
          <DateRangeIcon />
          {label}
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          {filterItems.map((filterItem) => (
            <Dropdown.Item
              eventKey={filterItem}
              key={filterItem}
              onSelect={(key) => {
                if (key === null) return;
                setRangeType(key as RangeType);
                if (key === 'CUSTOM_RANGE') {
                  setShowCustomRangeModal(true);
                } else {
                  const range = getRangeFromType(key as RangeType);
                  if (range != null) {
                    onChange(range);
                  }
                }
              }}
            >
              {strings[filterItem]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <CustomDatePickerModal
        showModal={showCustomRangeModal}
        onAccept={(s) => {
          const range: DateRange = {
            end: moment(s.endDate),
            start: moment(s.startDate),
          };
          onChange(range);
          setShowCustomRangeModal(false);
          setCustomDateRange(range);
        }}
        onClose={() => setShowCustomRangeModal(false)}
      />
    </div>
  );
};

export default ReportsDateFilter;
