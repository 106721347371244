import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import Alert from 'components/shared/Alert';

import './PortalAlert.scss';

interface PortalAlertProps {
  open: boolean;
  handleClose: () => void;
  message: string;
  variant: 'error' | 'success' | 'warning' | 'info';
}

const PortalAlert: React.FC<PortalAlertProps> = ({
  open,
  handleClose,
  message,
  variant,
}) => {
  const [portalDivElement, setPortalDivElement] =
    useState<HTMLDivElement | null>(null);
  useEffect(() => {
    const elem = document.createElement('div');
    elem.id = 'alert-root';
    elem.className = 'PortalAlert';
    document.body.appendChild(elem);
    setPortalDivElement(elem);
  }, []);

  return portalDivElement
    ? ReactDOM.createPortal(
        <Alert
          open={open}
          duration={3000}
          handleClose={handleClose}
          contentProps={{
            variant,
            message,
            onClose: handleClose,
          }}
        />,
        portalDivElement
      )
    : null;
};

export default PortalAlert;
