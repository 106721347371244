import { useMemo } from 'react';

import { permissions, strings } from 'common';
import { testIds } from 'common/testIds';
import Button from 'components/shared/Button';
import PermissionsGate from 'components/shared/PermissionsGate';
import { Task, TaskStatus } from 'models';

interface ApproveButtonProps {
  selectedIds: string[];
  tasks: Task[];
  loading: boolean;
  onClick: (status: TaskStatus) => void;
}

const ApproveButton: React.FC<ApproveButtonProps> = (props) => {
  const { selectedIds, tasks, loading, onClick } = props;

  const disabled = useMemo(() => {
    if (!selectedIds.length) {
      return true;
    }
    return !selectedIds.every((id) => {
      const task = tasks.find((task) => task.id === id);
      return (
        task?.status === 'DRAFT' ||
        task?.status === 'PENDING_APPROVAL' ||
        task?.status === 'COMPLETE' ||
        task?.status === 'DENIED'
      );
    });
  }, [selectedIds, tasks]);

  return (
    <PermissionsGate permissions={[permissions.INVENTORY_VDP_TASKS_APPROVE]}>
      <Button
        data-vas-testing={testIds.REQUESTS_SECTION_APPROVE_BUTTON}
        className="FlatTasks-action-button FlatTasks-action-button-approve"
        id="approve-task-button"
        type="button"
        variant="success"
        onClick={() => onClick('APPROVED_PENDING_COMPLETE')}
        disabled={disabled}
        loading={loading}
      >
        {strings.APPROVE}
      </Button>
    </PermissionsGate>
  );
};

export default ApproveButton;
