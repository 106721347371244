export const customStyles = {
  option: (provided: any) => ({
    ...provided,
    color: 'black',
    paddingTop: 16,
    paddingBottom: 16,
  }),
  control: (provided: any) => ({
    ...provided,
    color: 'black',
    padding: 0,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  menu: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export const customColors = {
  primary: '#DDDDDD',
  primary25: '#EFF1F1',
  primary50: '#C7C7C7',
};
