/* eslint-disable max-len */
// @ts-nocheck
import React from 'react';

import { IconProps } from './types';

const GreenDownArrow: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.13 0.120001L8.63 0.12L8.63 6.12L12.26 6.12L6.38 12L0.5 6.12L4.13 6.12L4.13 0.120001Z"
      fill="#1AB245"
    />
  </svg>
);

export default GreenDownArrow;
