import { DriveEta, InfoOutlined } from '@material-ui/icons';
import React, { useState } from 'react';

import strings from 'common/strings';
import { VehicleSummary } from 'models';

import VehicleDetailsModal from '../VehicleDetailsModal';

import './MobileVehicleDetails.scss';

interface MobileVehicleDetailsProps {
  height: number;
  vehicle: VehicleSummary;
}

const MobileVehicleDetails: React.FC<MobileVehicleDetailsProps> = ({
  height,
  vehicle,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hasImageError, setHasImageError] = useState(false);
  return (
    <div className="MobileVehicleDetails" style={{ height }}>
      <div
        role="none"
        className="MobileVehicleDetails-info"
        onClick={() => setModalOpen(true)}
      >
        {vehicle?.vehicleCard?.vehicleImage && !hasImageError ? (
          <img
            className="MobileVehicleDetails-info-image"
            src={vehicle?.vehicleCard?.vehicleImage}
            alt="vehicle"
            onError={() => setHasImageError(true)}
          />
        ) : (
          <DriveEta className="MobileVehicleDetails-info-image" />
        )}
        <div className="MobileVehicleDetails-info-car-details">
          <div className="MobileVehicleDetails-info-car-details-title">
            {!vehicle?.vehicleCard?.year &&
            !vehicle?.vehicleCard?.make &&
            !vehicle?.vehicleCard?.model ? (
              <span className="MobileVehicleDetails-info-car-details-year-make">
                {strings.EMPTY_VALUE}
              </span>
            ) : (
              <>
                <span className="MobileVehicleDetails-info-car-details-year-make">
                  {`${vehicle?.vehicleCard?.year} ${vehicle?.vehicleCard?.make}`}
                </span>
                <span>
                  {`${
                    vehicle?.vehicleCard?.model
                      ? ` - ${vehicle?.vehicleCard?.model}`
                      : ''
                  }`}
                </span>
              </>
            )}
          </div>
          <span className="MobileVehicleDetails-info-car-details-stock-number">
            {`STK: ${vehicle?.vehicleCard?.stockNumber}`}
          </span>
        </div>
        <InfoOutlined className="MobileVehicleDetails-info-icon" />
      </div>
      <VehicleDetailsModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        vehicle={vehicle}
      />
    </div>
  );
};

export default MobileVehicleDetails;
