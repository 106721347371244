import { useContext } from 'react';

import strings from 'common/strings';
import { generateTestId, testIds } from 'common/testIds';
import TextCopy from 'components/shared/Inputs/TextCopy/TextCopy';
import Skeleton from 'components/shared/Skeleton';
import TitleTooltip from 'components/shared/TitleTooltip';
import { formatMileage } from 'utils/formatter';

import { VehicleCardContext } from '../../VehicleCard';

import './VehicleCardDetailsTable.scss';

interface VehicleDetailsTableProps {
  showVin?: boolean;
}

const VehicleDetailsTable = ({ showVin = true }: VehicleDetailsTableProps) => {
  const { vehicle, position } = useContext(VehicleCardContext);
  const disposition =
    vehicle?.vehicleCard?.disposition?.toLowerCase() ?? strings.EMPTY_VALUE;
  const color =
    vehicle?.vehicleCard?.exteriorColor?.toLowerCase() ?? strings.EMPTY_VALUE;
  const trim = vehicle?.vehicleCard?.trim?.toLowerCase() ?? strings.EMPTY_VALUE;

  return (
    <div className={'VehicleCard-details-table'}>
      {showVin && (
        <div className="VehicleCard-details-item">
          <div className="VehicleCard-details-label">
            <TitleTooltip label={strings.VIN} title={strings.VIN_TOOLTIP} />
          </div>
          <div
            data-vas-testing={generateTestId(testIds.INVENTORY_SRP_CARD_VIN, {
              position: position.toString(),
            })}
            className={'VehicleCard-detail-value'}
          >
            <Skeleton renderSkeleton={!vehicle}>
              {vehicle?.vehicleCard.vin ? (
                <TextCopy value={vehicle?.vehicleCard.vin} />
              ) : (
                strings.EMPTY_VALUE
              )}
            </Skeleton>
          </div>
        </div>
      )}
      <div className={'VehicleCard-details-columns'}>
        <div className="VehicleCard-details-columnOne">
          <div className="VehicleCard-details-item">
            <div className="VehicleCard-details-label">
              <TitleTooltip label={strings.STK} title={strings.STK_TOOLTIP} />
            </div>
            <div className="VehicleCard-details-value StockNumber">
              <Skeleton renderSkeleton={!vehicle}>
                <TextCopy value={vehicle?.vehicleCard.stockNumber!} />
              </Skeleton>
            </div>
          </div>

          <div className="VehicleCard-details-item">
            <div className="VehicleCard-details-label">
              <TitleTooltip
                label={strings.MIL_SRP_CARD}
                title={strings.MIL_TOOLTIP}
              />
            </div>
            <div className="VehicleCard-details-value">
              <Skeleton renderSkeleton={!vehicle}>
                {formatMileage(
                  vehicle?.vehicleCard.mileage,
                  vehicle?.vehicleCard.odometerUnit,
                  false
                )}
              </Skeleton>
            </div>
          </div>

          {disposition && (
            <div className="VehicleCard-details-item">
              <div className="VehicleCard-details-label">
                <TitleTooltip label={strings.DIS} title={strings.DIS_TOOLTIP} />
              </div>
              <div className="VehicleCard-details-value">
                <Skeleton renderSkeleton={!vehicle}>{disposition}</Skeleton>
              </div>
            </div>
          )}
        </div>

        <div className="VehicleCard-details-columnTwo">
          <div className="VehicleCard-details-item">
            <div className="VehicleCard-details-label">
              <TitleTooltip label={strings.EXT} title={strings.EXT_TOOLTIP} />
            </div>
            <div className="VehicleCard-details-value">
              <Skeleton renderSkeleton={!vehicle}>{color}</Skeleton>
            </div>
          </div>

          {trim && (
            <div className="VehicleCard-details-item">
              <div className="VehicleCard-details-label">
                <TitleTooltip
                  label={strings.TRIM_LABEL}
                  title={strings.TRIM_TOOLTIP}
                />
              </div>
              <div className="VehicleCard-details-value">
                <Skeleton renderSkeleton={!vehicle}>{trim}</Skeleton>
              </div>
            </div>
          )}

          <div className="VehicleCard-details-item">
            <div className="VehicleCard-details-label">
              <TitleTooltip label={strings.DLR} title={strings.DLR_TOOLTIP} />
            </div>
            <div className="VehicleCard-details-value">
              <Skeleton renderSkeleton={!vehicle}>
                {vehicle?.vehicleCard.location.name}
              </Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetailsTable;
