import React, { useCallback, useState } from 'react';

import { useDealerStepReport, useDealerStepReportDownloadLink } from 'api';
import { DateRange } from 'common/DateRange';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import { TabProps } from 'components/shared/Tabs/Tabs';
import { useFeatures } from 'hooks';
import { DealerStepReportSummaryItem } from 'models/reports/DealerStepReportPayload';

import ReportContainer from '../ReportContainer';
import VehicleStepReportModal from '../VehicleStepReportModal';
import VehicleStepReportModalV2 from '../VehicleStepReportModalV2';
import { GridOverview } from './GridOverview';
import { StepSummary } from './StepSummary';

import './DealerStepReport.scss';

interface ReportsProps extends TabProps {
  dateRange: DateRange;
  corporateTenantId?: string;
  displayOverView?: Boolean;
}
interface VehicleStepReportModalState {
  open: boolean;
  step?: DealerStepReportSummaryItem;
}

const DealerStepReport: React.FC<ReportsProps> = ({
  dateRange,
  corporateTenantId,
  displayOverView = true,
}) => {
  const { hasFeature } = useFeatures();
  const queryResult = useDealerStepReport(dateRange, corporateTenantId);
  const dealerStepReport = queryResult.data?.data;

  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);

  const { isLoading, downloadFileUrl = '' } = useDealerStepReportDownloadLink(
    selectedReportType,
    dateRange,
    corporateTenantId
  );

  const [vehicleStepReportModalState, setVehicleStepReportModalState] =
    useState<VehicleStepReportModalState>({
      open: false,
      step: undefined,
    });

  const onCloseStepModal = useCallback(
    () =>
      setVehicleStepReportModalState({
        open: false,
        step: vehicleStepReportModalState.step,
      }),
    [vehicleStepReportModalState.step]
  );

  const onOpenStepModal = useCallback(
    (step) =>
      setVehicleStepReportModalState({
        open: true,
        step,
      }),
    []
  );

  return (
    <ReportContainer
      className="DealerStepReport"
      loading={queryResult.isLoading}
      error={queryResult.error}
    >
      {displayOverView && (
        <GridOverview
          dealerStepReport={dealerStepReport}
          isLoading={isLoading}
          openStepModal={onOpenStepModal}
        />
      )}

      <StepSummary
        dealerStepReport={dealerStepReport}
        isDownloading={isLoading}
        downloadFileUrl={downloadFileUrl}
        setSelectedReportType={setSelectedReportType}
        openStepModal={onOpenStepModal}
      />

      {vehicleStepReportModalState.step &&
        (hasFeature('STEP_REPORT_VERSION_2') ? (
          <VehicleStepReportModalV2
            onClose={onCloseStepModal}
            showModal={vehicleStepReportModalState.open}
            step={vehicleStepReportModalState.step}
            dateRange={dateRange}
            tenantId={corporateTenantId}
          />
        ) : (
          <VehicleStepReportModal
            onClose={onCloseStepModal}
            showModal={vehicleStepReportModalState.open}
            step={vehicleStepReportModalState.step}
            dateRange={dateRange}
            tenantId={corporateTenantId}
          />
        ))}
    </ReportContainer>
  );
};

export default DealerStepReport;
