import {
  navigate,
  RouteComponentProps,
  Router,
  useLocation,
} from '@reach/router';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import { useToken } from 'api';
import { removeUUIDs } from 'api/authenticate/urlManagement';
import { permissions } from 'common';
import NotificationSystem from 'components/shared/NotificationSystem';
import Realtime from 'components/shared/Realtime/Realtime';
import { useCurrentLocationId, usePermissions, useVendor } from 'hooks';

import RooftopPage from './RooftopPage';
import { INVENTORY_RELATIVE, INVOICING_RELATIVE } from './routes';
import { createCustomFilterQuery } from './util/ParamHelpers';

function useNavigateToDefaultLocation() {
  const { data: userData } = useToken();
  const { isVendor } = useVendor();
  const locationId = useCurrentLocationId();
  const location = useLocation();
  const currentPathname = location?.pathname ?? '';
  const currentSearch = location?.search ?? '';

  const { hasPermission } = usePermissions();

  const userLocationId = userData?.location?.id;

  if (currentPathname === '/' && userLocationId) {
    if (isVendor) {
      navigate(`/${userLocationId}/${INVOICING_RELATIVE}`);
    } else {
      navigate(`/${userLocationId}/${INVENTORY_RELATIVE}`);
    }
  } else if (
    hasPermission(permissions.RECON_VIEW) &&
    currentPathname.endsWith(INVENTORY_RELATIVE) &&
    !currentSearch
  ) {
    const onlyReconVehiclesQuery = `?${createCustomFilterQuery({
      stepId: 'ALL',
      status: 'ALL',
      inventoryState: 'IN_RECON',
    })}`;
    navigate(
      `/${userLocationId}/${INVENTORY_RELATIVE}${onlyReconVehiclesQuery}`
    );
  } else if (userLocationId && userLocationId !== locationId) {
    const currentRoute = removeUUIDs(window.location.pathname, false);
    const route = currentRoute.endsWith(INVENTORY_RELATIVE)
      ? `/${INVENTORY_RELATIVE}`
      : currentRoute;
    navigate(`/${userLocationId}${route}`);
  }
}

function useSendPageviews() {
  const { pathname } = useLocation();
  useEffect(() => {
    // Send pageview without ids to analytics
    const pageView = removeUUIDs(pathname, true);
    if (window.recon.gtmInit && !!pageView) {
      ReactGA.pageview(pageView);
    }
  }, [pathname]);
}

const UserSessionRouter: React.FC<RouteComponentProps> = () => {
  useNavigateToDefaultLocation();

  useSendPageviews();

  return (
    <div className="page flex-rows">
      <NotificationSystem>
        <Realtime>
          <Router>
            <RooftopPage path=":rooftopId/*" />
          </Router>
        </Realtime>
      </NotificationSystem>
    </div>
  );
};

export default UserSessionRouter;
