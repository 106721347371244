import { merge } from 'lodash';
import { useMutation } from 'react-query';

import { getAPIHeaders } from 'api';

export function useSendResetPassword(email: string) {
  return useMutation(async () => {
    const headers = await getAPIHeaders('POST');
    const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
      headers?.options,
      {
        body: JSON.stringify({
          email,
        }),
      }
    );

    const response = await fetch(
      `${headers?.host}/authenticate/sendResetPassword`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
  });
}
