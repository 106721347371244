const VinScanner: React.FC<React.PropsWithChildren<any>> = () => {
  return (
    <div
      style={{
        height: '100vh',
      }}
    >
      <iframe
        title="Velocity Automotive Landing Page"
        width={'100%'}
        height={'100%'}
        style={{ overflow: 'auto' }}
        frameBorder={0}
        src={`${process.env.REACT_APP_WINDOW_STICKER_URL}/velocityAutomotive`}
      />
    </div>
  );
};

export default VinScanner;
