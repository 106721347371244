import React, { useEffect, useState } from 'react';

import StatelessDownloadMenu from './StatelessDownloadMenu';

export type ReportType = 'PDF' | 'EXCEL' | 'CSV';
interface DownloadMenuProps {
  downloadFileUrl?: string;
  onSelectedMenuType: (type: ReportType | null) => void;
  isLoading: boolean;
  formats: ReportType[];
}
const DownloadMenu: React.FC<React.PropsWithChildren<DownloadMenuProps>> = ({
  downloadFileUrl,
  onSelectedMenuType,
  isLoading,
  formats,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (downloadFileUrl) {
      window.open(downloadFileUrl, '_newtab');
      setOpen(false);
      onSelectedMenuType(null);
    } else {
      if (!isLoading) {
        setOpen(false);
        onSelectedMenuType(null);
      }
    }
  }, [downloadFileUrl, isLoading, setOpen, onSelectedMenuType]);
  const onDownloadClick = async (type: ReportType) => {
    onSelectedMenuType(type);
  };
  return (
    <StatelessDownloadMenu
      isFetchingDownload={isLoading}
      onClick={() => {
        setOpen(!open);
      }}
      onPDFClick={formats.includes('PDF') ? () => onDownloadClick('PDF') : null}
      onXLSClick={
        formats.includes('EXCEL') ? () => onDownloadClick('EXCEL') : null
      }
      onCSVClick={formats.includes('CSV') ? () => onDownloadClick('CSV') : null}
      setShow={(value: boolean) => {
        setOpen(value);
      }}
      showMenu={open}
    />
  );
};

export default DownloadMenu;
