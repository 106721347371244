import React from 'react';
import { Card } from 'react-bootstrap';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Task, TaskStatus, VehicleSummary } from 'models';

import FlatTask from '../FlatTask';

import './TasksContainer.scss';

interface TasksContainerProps {
  tasks: Task[];
  loading?: boolean;
  selectedIds: string[];
  pinned?: boolean;
  vehicle: VehicleSummary;
  onDeleteTask: (task: Task) => void;
  onTaskCheckboxClick: (task: Task) => void;
  onApproveClick: (task: Task) => void;
  onApproveAllClick: (status: TaskStatus) => void;
  printPressed: boolean;
}

const TasksContainer: React.FC<TasksContainerProps> = (props) => {
  const {
    tasks,
    loading = false,
    selectedIds,
    pinned = false,
    vehicle,
    onDeleteTask,
    onTaskCheckboxClick,
    onApproveClick,
    onApproveAllClick,
    printPressed,
  } = props;

  return (
    <Card className="TasksContainer-card">
      <Card.Body className="TasksContainer-card-body">
        {loading ? (
          <LoadingIndicator />
        ) : (
          tasks.map((task, index) => {
            return (
              <FlatTask
                key={task.id}
                position={index}
                taskId={task.id}
                checked={selectedIds.includes(task.id!)}
                pinned={pinned}
                vehicle={vehicle}
                onDeleteTask={onDeleteTask}
                onTaskCheckboxClick={onTaskCheckboxClick}
                onApproveClick={onApproveClick}
                onApproveAllClick={onApproveAllClick}
                printPressed={printPressed}
              />
            );
          })
        )}
      </Card.Body>
    </Card>
  );
};

export default TasksContainer;
