import React from 'react';

import { defaultColor, IconProps } from './types';

const Alert: React.FC<IconProps> = ({
  color = defaultColor,
  className,
  size = 20,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d={
        'M14.0008 0.199951L0.800781 23H27.2008L14.0008 0.199951ZM14.0008 4.99995L23.0368 ' +
        '20.6H4.96478L14.0008 4.99995ZM12.8008 9.79995V14.6H15.2008V9.79995H12.8008ZM12.8008 17V19.4H15.2008V17'
      }
      fill={color}
    />
  </svg>
);

export default Alert;
