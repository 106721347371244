import VehicleCardExtensionV2 from './VehicleCardExtensionV2';

import './VehicleCard.scss';

interface VehicleCardExtensionProps {
  path?: string;
  inventoryId?: string;
}

const VehicleCardExtension = ({ inventoryId }: VehicleCardExtensionProps) => {
  return <VehicleCardExtensionV2 inventoryId={inventoryId} />;
};

export default VehicleCardExtension;
