import { FilterBy, SortAttribute, SortDirection } from './EngagementsTable';

function reverseSort(direction: SortDirection): SortDirection {
  return direction === 'ASC' ? 'DESC' : 'ASC';
}

export interface EngagementsFilterState {
  page: number;
  pageSize: number;
  totalItems: number | null;
  filterBy: FilterBy;
  sortBy: SortAttribute | null;
  sortDirection: SortDirection;
  groupBy: string | null;
}

export type EngagementsFilterAction =
  | {
      type: 'nextPage';
    }
  | {
      type: 'previousPage';
    }
  | {
      type: 'setTotal';
      total: number;
    }
  | {
      type: 'switchOrderBy';
      attribute: SortAttribute;
    }
  | { type: 'setFilterBy'; filterBy: FilterBy }
  | { type: 'reset' }
  | { type: 'setPageSize'; size: number }
  | { type: 'setGroupBy'; groupBy: string | null };

function beginningOfTime(): string {
  const d = new Date();
  d.setDate(d.getDate() - 14);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
}
export const initialState: EngagementsFilterState = {
  page: 0,
  pageSize: 20,
  totalItems: 0,
  filterBy: {
    TIMESTAMP_BEGIN: beginningOfTime(),
    TIMESTAMP_END: null,
    SHOPPER: null,
    USER: null,
    METHOD: null,
  },
  sortBy: 'LAST_OPEN',
  sortDirection: 'DESC',
  groupBy: null,
};
export function reducer(
  state: EngagementsFilterState,
  action: EngagementsFilterAction
): EngagementsFilterState {
  switch (action.type) {
    case 'nextPage':
      return getCanGoToNextPage(state)
        ? {
            ...state,
            page: state.page + 1,
          }
        : state;
    case 'previousPage':
      return getCanGoToPreviousPage(state)
        ? {
            ...state,
            page: state.page - 1,
          }
        : state;
    case 'reset':
      return initialState;
    case 'setFilterBy':
      return {
        ...state,
        page: 0,
        filterBy: action.filterBy,
        totalItems: null,
      };
    case 'setGroupBy':
      if (action.groupBy === null) {
        return initialState;
      } else {
        return {
          ...initialState,
          sortBy: null,
          groupBy: action.groupBy,
        };
      }
    case 'setPageSize':
      return {
        ...state,
        page: 0,
        pageSize: action.size,
      };
    case 'setTotal':
      return {
        ...state,
        totalItems: action.total,
      };
    case 'switchOrderBy':
      if (state.groupBy !== null) {
        return state;
      }
      return {
        ...state,
        sortBy: action.attribute,
        sortDirection:
          state.sortBy === action.attribute
            ? reverseSort(state.sortDirection)
            : 'DESC',
      };
  }
}
export function getCanGoToNextPage(state: EngagementsFilterState): boolean {
  return (
    state.totalItems !== null &&
    (state.page + 1) * state.pageSize < state.totalItems
  );
}
export function getCanGoToPreviousPage(state: EngagementsFilterState): boolean {
  return state.page > 0;
}
export function getStartIndex(state: EngagementsFilterState) {
  return state.page * state.pageSize;
}
