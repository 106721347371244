export const parseKey = (key: string) =>
  key
    .charAt(0)
    .toUpperCase()
    .concat(key.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2'));

export const isPriceFormat = (value: string) =>
  new RegExp(/^\d*\.?\d*$/).test(value);

export const removeMoneySign = (value: string) => value.replace('$', '');

export const isCorrectNumberLength = (value: string) => {
  const arrayOfValues = value.split('.');
  const integer = arrayOfValues[0];
  const decimals = arrayOfValues[1];

  if ((decimals && decimals.length > 2) || (integer && integer.length > 9)) {
    return false;
  }
  return true;
};

export const generateId = () => {
  // Implementation taken from http://stackoverflow.com/a/2117523
  let id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

  id = id.replace(/[xy]/g, (c) => {
    const r = Math.floor(Math.random() * 16);

    let v;
    if (c === 'x') {
      v = r;
    } else {
      v = (r & 0x3) | 0x8; // eslint-disable-line no-bitwise
    }

    return v.toString(16);
  });

  return 'uid' + id;
};
