import { useMutation, useQuery, useQueryClient } from 'react-query';

import { APIResponse, defaultMutationFn } from 'api';
import { Folio, VelocityEngageFolioFolderDocument } from 'models';

export function useFolio(vehicleId: string) {
  const path = `/inventory/${vehicleId}/plugins/vdp/velocityengage/folio`;
  return useQuery<APIResponse<Folio>>(path, {
    enabled: vehicleId !== undefined,
  });
}

export function useDeleteFolioDocument() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      vehicleId,
      document,
    }: {
      vehicleId: string;
      document: VelocityEngageFolioFolderDocument;
    }) => {
      const path = `/inventory/${vehicleId}/plugins/vdp/velocityengage/folio/document/${document.id}`;
      return defaultMutationFn(path, 'DELETE');
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          `/inventory/${variables.vehicleId}/plugins/vdp/velocityengage/folio`,
          data
        );
      },
    }
  );
}

export function useUpdateFolioDocumentUrl() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      vehicleId,
      documentId,
      url,
    }: {
      vehicleId: string;
      documentId: string;
      url: string;
    }) => {
      const path = `/inventory/${vehicleId}/plugins/vdp/velocityengage/folio/document/${documentId}`;
      const data = { url: url };
      return defaultMutationFn(path, 'PUT', data);
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          `/inventory/${variables.vehicleId}/plugins/vdp/velocityengage/folio`,
          data
        );
      },
    }
  );
}
