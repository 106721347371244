import Edit from '@material-ui/icons/Edit';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import TruncatedList from 'react-truncate-list';

import permissions from 'common/permissions';
import EditTagsModal from 'components/shared/EditTagsModal';
import PermissionsGate from 'components/shared/PermissionsGate';
import { VehicleSummary, VehicleTag } from 'models';

import TagComponent from './Tag';

import './TagList.scss';
import 'react-truncate-list/dist/styles.css';

interface TagListProps {
  vehicle: VehicleSummary;
  tags: VehicleTag[];
  showEditButton?: boolean;
  truncate?: boolean;
  onEditClick?: () => void;
  onCloseModal?: () => void;
}

const TagList = (props: TagListProps) => {
  const { vehicle, truncate = false } = props;
  const { tags } = vehicle;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const openModal = () => {
    if (props.onEditClick) {
      props.onEditClick();
    }
    setIsEditModalOpen(true);
  };
  const closeModal = () => {
    if (props.onCloseModal) {
      props.onCloseModal();
    }
    setIsEditModalOpen(false);
    queryClient.invalidateQueries(`/inventory/${vehicle.vehicleCard.id}`);
  };

  const tagsRowClassName =
    truncate && tags?.length !== 0 ? 'TagList-tags-row-truncate' : '';

  return (
    <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
      <div className={`TagList-Container ${tagsRowClassName}`}>
        {tags?.length === 0 && (
          <PermissionsGate permissions={[permissions.TAGS_UPDATE]}>
            <button
              type="button"
              className="TagList-empty-container"
              onClick={openModal}
            >
              Add tags...
            </button>
          </PermissionsGate>
        )}
        <TruncatedList
          className="TagList"
          alwaysShowTruncator
          renderTruncator={({ hiddenItemsCount }) => (
            <div
              className="TagList-truncate-message"
              role="link"
              tabIndex={-1}
              onClick={openModal}
            >
              <div>{hiddenItemsCount > 0 && `+${hiddenItemsCount}`}</div>
              <PermissionsGate permissions={[permissions.TAGS_UPDATE]}>
                <button
                  type="button"
                  className="TagList-icon-button"
                  onClick={openModal}
                >
                  <Edit className="TagList-icon" />
                </button>
              </PermissionsGate>
            </div>
          )}
        >
          {tags?.map((tag: VehicleTag) => (
            <TagComponent key={`tag-component-${tag.tagId}`} tag={tag} />
          ))}
        </TruncatedList>
      </div>
      {isEditModalOpen && (
        <EditTagsModal
          tags={vehicle.tags}
          onClose={closeModal}
          vehicleId={vehicle.vehicleCard.id}
        />
      )}
    </PermissionsGate>
  );
};

export default TagList;
