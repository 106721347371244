import { useQuery } from 'react-query';

import { APIResponse, defaultMetaQueryFn, useDownload } from 'api';

const requestPath: string = '/reports/engage/module';

interface VelocityEngageModuleReportParameters {
  page: number;
  pageSize: number;
  condition: 'N' | 'C' | 'U';
}

interface VelocityEngageModuleReportPayload {
  content: Array<{
    condition: null | 'U' | 'C' | 'N';
    dmsStockStatus: string | null;
    folioFolderId: string;
    make: string;
    model: string;
    presentDocuments: string[] | null;
    startDate: string;
    stockNumber: string;
    vin: string;
    year: number;
  }>;
  numberOfElements: number;
  pageNumber: number;
  totalElements: number;
  totalPages: number;
}

type FolioTemplateTypesPayload = Array<{
  label: string;
  id: string;
}>;

export function useFolioTemplateTypes(condition: 'U' | 'C' | 'N') {
  const url = `/reports/engage/module/moduleDetails?condition=${condition}`;
  return useQuery<APIResponse<FolioTemplateTypesPayload>>(
    [url, condition],
    () => defaultMetaQueryFn(url)
  );
}

export function useVelocityEngageModuleReport({
  page,
  pageSize,
  condition,
}: VelocityEngageModuleReportParameters) {
  return useQuery<APIResponse<VelocityEngageModuleReportPayload>>(
    [requestPath, page, pageSize, condition],
    () =>
      defaultMetaQueryFn(
        `${requestPath}?page=${page}&pageSize=${pageSize}&condition=${condition}`
      )
  );
}

export function useVelocityEngageModuleReportDownload({
  condition,
  enabled,
}: {
  condition: string;
  enabled: boolean;
}) {
  return useDownload({
    enabled,
    fileName: 'Engage Module Report.csv',
    url: `/reports/engage/moduleCsv?condition=${condition}`,
  });
}
