import { ArrowBack } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { Container } from 'react-bootstrap';

import { useWindowSize } from 'hooks';
import { vehicleDetailsBuilder } from 'navigation/routes';

import './FlatVDPPaneHeader.scss';

interface FlatVDPPaneHeaderProps {
  className?: string;
  vehicleId?: string;
  shouldNavOnBackClick?: () => boolean;
  shouldShowBackButton?: boolean;
}

const FlatVDPPaneHeader: React.FC<FlatVDPPaneHeaderProps> = ({
  children,
  className = '',
  vehicleId,
  shouldNavOnBackClick = () => true,
  shouldShowBackButton = true,
}) => {
  const windowSize = useWindowSize();

  if (windowSize.isMobileViewport()) return null;

  const handleVDPNavigation = async () => {
    if (shouldNavOnBackClick) {
      const shouldNavToVDP = shouldNavOnBackClick();
      if (shouldNavToVDP) {
        await navigate(vehicleDetailsBuilder(vehicleId!), {
          replace: true,
        });
      }
    }
  };

  return (
    <Container fluid className={`FlatVDPPaneHeader ${className}`}>
      {windowSize.isMobileViewport() && shouldShowBackButton && (
        <button
          type="button"
          className="FlatVDPPaneHeader-back-button"
          onClick={handleVDPNavigation}
        >
          <ArrowBack color="secondary" />
        </button>
      )}
      {children}
    </Container>
  );
};

export default FlatVDPPaneHeader;
