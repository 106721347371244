import { RouteComponentProps, Router } from '@reach/router';

import VehicleDetailsRouter from 'navigation/VehicleDetailsRouter';

import { InvoiceList } from '../InvoiceList';
import { SendInvoice } from '../SendInvoice';
import { ViewInvoice } from '../ViewInvoice';

import './Invoicing.scss';

type Props = RouteComponentProps;

const Invoicing = ({ path, navigate }: Props) => {
  return (
    <div className="vendor-invoicing page vertical-scroll">
      <Router
        primary={false}
        style={{ flex: 1, display: 'flex', width: '100%' }}
      >
        <InvoiceList default />
        <ViewInvoice path=":invoiceId" />
        <SendInvoice path=":invoiceId/send" />
        <VehicleDetailsRouter path="inventory/:vehicleId/*" />
      </Router>
    </div>
  );
};

export default Invoicing;
