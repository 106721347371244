interface IFieldLabel {
  name: string;
  required: boolean;
  className?: string;
}

var FieldLabel = ({ className, name, required }: IFieldLabel) => {
  const text = `${name}${required ? '*' : ''}`;

  return <div className={`${className} Mpi-item-label`}>{text}</div>;
};

export default FieldLabel;
