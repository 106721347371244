import { RouteComponentProps } from '@reach/router';

import { useCreateInvoiceMutation } from 'api/invoicing';

import Invoice from '../../Invoice/Invoice';

type Props = RouteComponentProps;

const NewInvoices = (props: Props) => {
  const { isLoading, data, isError, error } = useCreateInvoiceMutation();

  let content = null;
  if (isLoading) {
    content = 'is loading';
  } else if (isError) {
    content = error;
  } else {
    console.log({ data });
    content = <Invoice />;
  }

  return <div className="new-invoices">{content}</div>;
};

export default NewInvoices;
