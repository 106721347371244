export const StickyReportHeader: React.FC<{}> = ({ children }) => {
  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 20,
        backgroundColor: 'white',
      }}
    >
      {children}
    </div>
  );
};
