import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { FC } from 'react';

import permissions from 'common/permissions';
import strings from 'common/strings';
import { usePermissions } from 'hooks';
import { DealerStepReportVehicleV2 } from 'models';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { currencyFormatter } from 'utils/formatter';
import { getFormattedDurationFromSeconds } from 'utils/time';

import { compareItemOverage, ratings } from '../ReportsState';

interface StepReportTableRowProps {
  item: DealerStepReportVehicleV2;
}

export const StepReportTableRow: FC<StepReportTableRowProps> = ({ item }) => {
  const { hasPermission } = usePermissions();
  const userHasReconInfoPermission = hasPermission(
    permissions.INVENTORY_VDP_RECON_INFO_VIEW
  );

  const openVDPScreenTabForVehicleItem = (item: DealerStepReportVehicleV2) => {
    const vehicleId = item?.vehicle?.id;
    if (!vehicleId) return;
    navigate(`${vehicleDetailsBuilder(vehicleId)}/overview`);
  };

  const renderItemOverage = () => {
    if (!userHasReconInfoPermission) {
      return <>{strings.EMPTY_VALUE}</>;
    }

    return (
      <>
        {compareItemOverage(item) === ratings.GOOD ? (
          <ArrowUpward />
        ) : (
          <ArrowDownward />
        )}
        {`${
          item?.overage?.amount
            ? currencyFormatter(Math.abs(item?.overage?.amount) || 0)
            : strings.EMPTY_VALUE
        }`}
      </>
    );
  };

  const itemOverageClassName =
    'VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight ' +
    (userHasReconInfoPermission
      ? `VehicleStepReportModal-${compareItemOverage(item)}`
      : '');

  return (
    <tr className="VehicleStepReportModal-tableDataRow">
      <td className="VehicleStepReportModal-stockNumber">
        <div role="none" onClick={() => openVDPScreenTabForVehicleItem(item)}>
          {item?.vehicle?.stockNumber || ''}
        </div>
      </td>
      <td className="VehicleStepReportModal-alignLeft">
        {`${item?.vehicle?.year || ''} ${item?.vehicle?.make || ''} ${
          item?.vehicle?.model || ''
        }`}
      </td>
      <td className="VehicleStepReportModal-darkGreyCell VehicleStepReportModal-alignCenter">
        {getFormattedDurationFromSeconds(item.retailReadyTimeInStepAsSeconds)}
      </td>
      <td className="VehicleStepReportModal-darkGreyCell VehicleStepReportModal-alignCenter">
        {getFormattedDurationFromSeconds(item.reconTimeInStepAsSeconds)}
      </td>
      <td className="VehicleStepReportModal-lightGreyCell VehicleStepReportModal-alignCenter">
        {getFormattedDurationFromSeconds(item?.retailReadyTimeAsSeconds) || ''}
      </td>
      <td className="VehicleStepReportModal-lightGreyCell VehicleStepReportModal-alignCenter">
        {getFormattedDurationFromSeconds(item?.reconTimeAsSeconds) || ''}
      </td>
      <td className="VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight">
        {item?.estimatedRecon && userHasReconInfoPermission
          ? currencyFormatter(item?.estimatedRecon?.amount || 0)
          : strings.EMPTY_VALUE}
      </td>
      <td className="VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight">
        {item?.actualRecon && userHasReconInfoPermission
          ? currencyFormatter(item?.actualRecon?.amount || 0)
          : strings.EMPTY_VALUE}
      </td>
      <td className={itemOverageClassName}>{renderItemOverage()}</td>
    </tr>
  );
};
