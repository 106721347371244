import { useMemo } from 'react';

import { strings } from 'common';
import { testIds } from 'common/testIds';
import Button from 'components/shared/Button';
import { Task } from 'models';

interface CreateInvoiceProps {
  disabled: boolean;
  onClick: () => void;
  selectedIds: string[];
  tasks: Task[];
}

const CreateInvoice: React.FC<CreateInvoiceProps> = ({
  disabled,
  onClick,
  selectedIds,
  tasks,
}) => {
  const disabledNoCompleteTasks = useMemo(() => {
    if (!selectedIds.length) {
      return true;
    }
    return !selectedIds.every((id) => {
      const task = tasks.find((task) => task.id === id);
      return task?.status === 'COMPLETE'; // TODO: add logic to make sure user doesn't select a task that is already assigned to an invoice
    });
  }, [selectedIds, tasks]);

  return (
    <Button
      data-vas-testing={testIds.CREATE_INVOICE_BUTTON}
      className="FlatTasks-action-button FlatTasks-action-button-approve"
      id="create-invoice-button"
      type="button"
      variant="dark"
      onClick={onClick}
      disabled={disabled || disabledNoCompleteTasks}
    >
      {strings.CREATE_INVOICE}
    </Button>
  );
};

export default CreateInvoice;
