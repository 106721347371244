import TextField from '@material-ui/core/TextField';

import Wrapper from 'components/shared/Wrapper';

const LoginFormTextField: React.FC<{
  errorMessage?: string;
  value: string;
  onChange: (v: string) => void;
  label: string;
  disabled: boolean;
  onSubmit: () => void;
  name: string;
  margin?: boolean;
  password?: boolean;
  autoFocus?: boolean;
}> = ({
  errorMessage,
  value,
  onChange,
  label,
  disabled,
  onSubmit,
  name,
  margin,
  password,
  autoFocus,
}) => (
  <Wrapper class={errorMessage ? 'input-error' : 'input'}>
    <TextField
      variant="filled"
      margin={margin ? 'normal' : 'none'}
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
      fullWidth
      id={name}
      label={label}
      type={password ? 'password' : 'text'}
      error={!!errorMessage}
      helperText={errorMessage}
      name={name}
      autoComplete={name}
      disabled={disabled}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          onSubmit();
          ev.preventDefault();
        }
      }}
      autoFocus={autoFocus}
    />
  </Wrapper>
);

export default LoginFormTextField;
