import { createContext, PropsWithChildren, useRef } from 'react';
import NotificationSystem from 'react-notification-system';

import './NotificationSystem.scss';

export const NotificationSystemContext = createContext({});

const VANotificationSystem = ({ children }: PropsWithChildren<{}>) => {
  const notificationSystemRef = useRef<NotificationSystem>(null);

  return (
    <NotificationSystemContext.Provider value={{ ref: notificationSystemRef }}>
      <div className="VANotificationSystem-container">
        <NotificationSystem ref={notificationSystemRef} />
      </div>
      {children}
    </NotificationSystemContext.Provider>
  );
};

export default VANotificationSystem;
