import { useMutation, UseMutationResult } from 'react-query';

import { defaultTextPlainMutationFn } from 'api/useAPI';
import { Invoice } from 'models/invoicing';

type UpdateInvoiceVariables = {
  invoiceId: string;
  invoiceNumber: string;
};

const useSendInvoiceMutation = () => {
  return useMutation((data: UpdateInvoiceVariables) => {
    return defaultTextPlainMutationFn(
      `/vendor-invoice/${data.invoiceId}`,
      'PUT',
      data.invoiceNumber
    ) as Promise<UseMutationResult<Invoice>>;
  });
};

export default useSendInvoiceMutation;
