import { useMatch } from '@reach/router';

export function useCurrentLocationId(): string | undefined {
  const { locationId } = useMatch('/:locationId/*') || {
    locationId: undefined,
  };
  return locationId;
}

export function useCurrentURLVars(): any {
  const { tenantId, page } = useMatch('/:tenantId/:page/*') ?? {
    tenantId: undefined,
    page: undefined,
  };
  const { inventoryId } = useMatch('/:tenantId/inventory/:inventoryId/*') ?? {
    inventoryId: undefined,
  };
  return { tenantId, page, inventoryId };
}
