/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const NoteMention: React.FC<IconProps> = ({
  color = defaultColor,
  className,
}) => (
  <svg width={20} height={19} viewBox="0 0 20 20" fill="none">
    <path
      className={className}
      d="M18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V20L4 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM4 7H16V9H4V7ZM12 12H4V10H12V12ZM16 6H4V4H16"
      fill={color}
    />
  </svg>
);

export default NoteMention;
