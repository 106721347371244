import { useQuery, UseQueryOptions } from 'react-query';

import { APIResponse, defaultMetaQueryFn } from 'api';
import { DateRange } from 'common/DateRange';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import CorporatePayload, {
  CorporateReportFilters,
} from 'models/reports/corporatePayload';
import { getHyphenatedApiDateStringFromDate } from 'utils/time';

const requestPath: string = '/reports/corporate';

interface UseCorporateReportParams {
  dateRange: DateRange;
  tagIds?: string[];
  condition?: string;
}

export default function useCorporateReport({
  dateRange,
  tagIds,
  condition,
}: UseCorporateReportParams) {
  let params = `startDate=${getHyphenatedApiDateStringFromDate(
    dateRange.start
  )}&endDate=${getHyphenatedApiDateStringFromDate(dateRange.end)}`;

  if (tagIds && tagIds.length > 0) {
    const tagIdParam = tagIds.join(',');
    params = `${params}&tagIds=${tagIdParam}`;
  }

  if (condition) {
    params = `${params}&condition=${condition}`;
  }

  const url = `${requestPath}?${params}`;
  const key = [requestPath, dateRange.start, dateRange.end, tagIds, condition];

  return useQuery<APIResponse<CorporatePayload>>(key, () =>
    defaultMetaQueryFn(url)
  );
}

export function useCorporateReportFilters(
  options?: UseQueryOptions<APIResponse<CorporateReportFilters>>
) {
  const url = `${requestPath}/filters`;

  return useQuery<APIResponse<CorporateReportFilters>>(
    url,
    () => defaultMetaQueryFn(url),
    options
  );
}

const getDownloadUrl = (exportType: ReportType, dateRange: DateRange) => {
  const url = '/reports/export/corporate?';
  const params = {
    startDate: getHyphenatedApiDateStringFromDate(dateRange.start),
    endDate: getHyphenatedApiDateStringFromDate(dateRange.end),
    exportType,
    reportType: 'CORPORATE',
  };
  return (
    url +
    Object.entries(params)
      .filter((entry) => entry[1] !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
  );
};

export function useCorporateReportDownloadLink(
  reportType: ReportType | null,
  dateRange: DateRange
) {
  const downloadUrl =
    reportType !== null ? getDownloadUrl(reportType, dateRange) : '';
  const result = useQuery<{ uri: string }>(downloadUrl, {
    enabled: reportType !== null,
  });
  return {
    isLoadingExport: result.isLoading,
    downloadFileUrl: result?.data?.uri,
  };
}
