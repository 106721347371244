import React from 'react';
import { Container } from 'react-bootstrap';

import './FlatVDPPaneContent.scss';

interface FlatVDPPaneContentProps {
  className?: string;
}

const FlatVDPPaneContent: React.FC<FlatVDPPaneContentProps> = ({
  children,
  className = '',
}) => (
  <Container fluid className={`FlatVDPPaneContent full-height ${className}`}>
    {children}
  </Container>
);

export default FlatVDPPaneContent;
