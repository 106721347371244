import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React from 'react';

import strings from 'common/strings';
import { VehicleSummary } from 'models';

import './VehicleTitle.scss';

interface VehicleTitleProps {
  vehicle: VehicleSummary;
  isDesktop: boolean;
  miles?: number;
  navigateBack: () => void;
  className?: string;
}

const VehicleTitle: React.FC<VehicleTitleProps> = (props) => {
  const { vehicle, isDesktop, navigateBack, className } = props;
  const { year, make, trim, model } = vehicle.vehicleCard;

  const subTitle = trim ? `${model} ${trim}` : model;
  return (
    <div className="VehicleTitle full-width space-between">
      {!isDesktop && (
        <IconButton
          className="vehicle-title-back-button"
          onClick={navigateBack}
        >
          <ArrowBack color="secondary" />
        </IconButton>
      )}
      <div className={`flex-rows year-make-model text-ellipsis ${className}`}>
        <div className="year-make">
          {`${year || strings.EMPTY_VALUE} ${make || strings.EMPTY_VALUE}`}
        </div>
        <div className="model">{subTitle || strings.EMPTY_VALUE}</div>
      </div>
    </div>
  );
};

export default VehicleTitle;
