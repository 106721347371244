import CarIcon from '@material-ui/icons/DriveEta';
import { navigate } from '@reach/router';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import strings from 'common/strings';
import FitImage from 'components/shared/FitImage';
import { InventoryNeedingAttentionItem } from 'models';
import { vehicleDetailsBuilder } from 'navigation/routes';
import {
  STOCK_LAST_LENGTH,
  truncateEndOfStringByLength,
  VIN_LAST_LENGTH,
} from 'utils/string';
import { getFormattedDurationFromSeconds } from 'utils/time';

import VehicleNeedsAttentionIcons from './VehicleNeedsAttentionIcons';

import './VehicleNeedsAttentionCard.scss';

interface Props {
  inventoryNeedingAttentionItem: InventoryNeedingAttentionItem;
}

const VehicleNeedsAttentionCard: React.FC<Props> = ({
  inventoryNeedingAttentionItem,
}: Props) => {
  const { vehicleCard, stepItem } = inventoryNeedingAttentionItem;
  const { id, vehicleImage, make, model, vin, year, stockNumber } = vehicleCard;

  function handleCardClick() {
    navigate(`${vehicleDetailsBuilder(id!, false)}?fromVDP=dashboard`);
  }

  function getStepStatus() {
    if (
      stepItem?.timeInStepSeconds &&
      stepItem?.safeDurationSeconds &&
      stepItem.recommendedDurationSeconds
    ) {
      if (stepItem.timeInStepSeconds < stepItem.safeDurationSeconds) {
        return 'ok';
      }
      if (stepItem.timeInStepSeconds < stepItem.recommendedDurationSeconds) {
        return 'warning';
      }
      return 'danger';
    }
    return '';
  }

  function getProgressBarPercent() {
    if (stepItem?.timeInStepSeconds && stepItem.recommendedDurationSeconds) {
      return (
        (stepItem.timeInStepSeconds / stepItem.recommendedDurationSeconds) * 100
      );
    }
    return 0;
  }

  var VehicleThumb = () => {
    if (!vehicleImage) {
      return (
        <div className="VehicleNeedsAttentionCard-car-icon-container">
          <CarIcon className="VehicleNeedsAttentionCard-car-icon" />
        </div>
      );
    }
    return (
      <FitImage
        fitWidth="80px"
        fitHeight="80px"
        alt={make}
        imageUrl={vehicleImage}
      />
    );
  };

  var VehicleDescription = () => {
    return (
      <div className="vehicle-attention-description-container">
        <div className="year-make">
          {year || make ? `${year} ${make || ''}` : strings.EMPTY_VALUE}
        </div>
        <div className="model">{model || strings.EMPTY_VALUE}</div>
      </div>
    );
  };

  var VehicleVinStk = ({ label, val, length }: any) => {
    return (
      <div>
        <div className="VehicleNeedsAttentionCard-label">{label}</div>
        <span className="stock-number">
          {truncateEndOfStringByLength(val, length)}
        </span>
      </div>
    );
  };

  var VehicleProgressBar = () => {
    return (
      <div className="ProgressBar">
        <div className="ProgressBar-progress-bar">
          <div
            className={`ProgressBar-percentage ${getStepStatus()}`}
            style={{ width: `${getProgressBarPercent()}%` }}
          />
        </div>
      </div>
    );
  };

  var VehicleStepName = () => {
    return (
      <span className="VehicleNeedsAttentionCard-step-name-text">
        {stepItem?.name ?? strings.EMPTY_VALUE}
      </span>
    );
  };

  var VehicleTime = () => {
    return (
      <span className="VehicleNeedsAttentionCard-time-name-text text-primary">
        {stepItem?.timeInStepSeconds && stepItem.recommendedDurationSeconds
          ? `${getFormattedDurationFromSeconds(stepItem?.timeInStepSeconds)} /
          ${getFormattedDurationFromSeconds(
            stepItem.recommendedDurationSeconds
          )}`
          : strings.EMPTY_VALUE}
      </span>
    );
  };

  return (
    <Card className="VehicleNeedsAttentionCard h-100" onClick={handleCardClick}>
      <Row className="VehicleNeedsAttentionCard-step-info-row">
        <Col className="VehicleNeedsAttentionCard-step-name-col">
          <VehicleStepName />
        </Col>
        <Col className="VehicleNeedsAttentionCard-step-progress-text-col">
          <VehicleTime />
        </Col>
        <Col
          xl={12}
          xs={12}
          className="VehicleNeedsAttentionCard-step-progress-col"
        >
          <VehicleProgressBar />
        </Col>
      </Row>
      <Row className="VehicleNeedsAttentionCard-row">
        <Col className="align-items-center d-flex">
          <VehicleThumb />
          <VehicleDescription />
        </Col>
      </Row>
      <Row className="VehicleNeedsAttentionCard-row">
        <Col xl={4}>
          <VehicleVinStk
            label={strings.STK}
            val={stockNumber || '--'}
            length={STOCK_LAST_LENGTH}
          />
        </Col>
        <Col xl={8}>
          <VehicleVinStk
            label={strings.VIN}
            val={vin}
            length={VIN_LAST_LENGTH}
          />
        </Col>
      </Row>
      <Row className="VehicleNeedsAttentionCard-row">
        <Col className="VehicleNeedsAttentionCard-label">
          {strings.NEEDS_ATTENTION.toUpperCase()}
        </Col>
      </Row>
      <Row>
        <Col className="d-flex VehicleNeedsAttentionCard-icons-container">
          <VehicleNeedsAttentionIcons
            inventoryNeedingAttentionItem={inventoryNeedingAttentionItem}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default VehicleNeedsAttentionCard;
