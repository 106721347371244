import IconButton from '@material-ui/core/IconButton';
import Send from '@material-ui/icons/Send';

interface SendIconButtonProps {
  disabled?: boolean;
  onClick(): void;
}

var SendIconButton = ({ disabled, onClick }: SendIconButtonProps) => {
  return (
    <IconButton
      disabled={disabled}
      onMouseDown={onClick}
      className={`send-icon ${disabled ? 'disabled' : ''}`}
    >
      <Send />
    </IconButton>
  );
};

export default SendIconButton;
