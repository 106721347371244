import { navigate, useLocation } from '@reach/router';
import { FC, useEffect, useState } from 'react';

import { setItem } from 'api/lib/typedLocalStorage';
import strings from 'common/strings';
import Button from 'components/shared/Button';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import { useCarfax } from 'hooks/useCarfax';
import { VehicleSummary } from 'models';

import CarfaxLandingPage from './CarfaxLandingPage';

import './CarfaxLogin.scss';

interface CarfaxLoginProps {
  vehicle: VehicleSummary;
}

const CarfaxLogin: FC<CarfaxLoginProps> = ({ vehicle }) => {
  const { pathname } = useLocation();

  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const { validateSession, LOGIN_REDIRECT_URI } = useCarfax();

  useEffect(() => {
    const checkIsAuthorized = async () => {
      setIsAuthorized(await validateSession());
    };

    checkIsAuthorized();

    setItem('carfaxVdpPath', pathname);
  }, [pathname, validateSession]);

  const onLogout = () => setIsAuthorized(false);
  const onLoginClick = () => navigate(LOGIN_REDIRECT_URI);

  return !isAuthorized ? (
    <>
      <div>
        <FlatVDPPaneHeader className="carfax-header">
          <div>{strings.CARFAX}</div>
        </FlatVDPPaneHeader>
        <div>
          <Button variant="info" type="button" onClick={onLoginClick}>
            Login to Carfax
          </Button>
        </div>
      </div>
    </>
  ) : (
    <CarfaxLandingPage
      onLogout={onLogout}
      vin={vehicle.vehicleCard.vin}
      zipCode={vehicle.vehicleCard.location.zipCode}
    />
  );
};

export default CarfaxLogin;
