import { useQuery } from 'react-query';

import { APIResponse } from 'api/useAPI';
import { VehicleSummary } from 'models';

export type Vehicle = {
  id: number;
};

/**
 * @package api/vendor-inventory
 * @description - Get summaries for vehicles with vendor tasks
 */
export function useGetInvoiceInventoryQuery() {
  const path = `/vendor-inventory`;
  return useQuery<APIResponse<VehicleSummary[]>>(path);
}

export default useGetInvoiceInventoryQuery;
