import { useMemo } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { SortItem, useVehicleList } from 'api';
import {
  getLastQueryParamWithName,
  updateUrlSearchParam,
} from 'navigation/util/ParamHelpers';

import './SortSelector.scss';

const SortSelector = () => {
  const { data, isLoading } = useVehicleList();

  const selectedSortValue = getLastQueryParamWithName('sort') || 'AGE';

  const sortOptions = useMemo(() => {
    const responseMeta = data?.pages?.[0]?.meta;
    if (!isLoading && responseMeta?.sortOptions) {
      return responseMeta.sortOptions;
    }
    return [];
  }, [data?.pages, isLoading]);

  const sortLabelValue = useMemo(() => {
    if (sortOptions.length > 0) {
      const selectedSortOption = sortOptions?.find(
        (item: SortItem) => item.id === selectedSortValue
      ) || { label: '' };
      return selectedSortOption.label;
    }
    return '';
  }, [selectedSortValue, sortOptions]);

  function handleSortChange(eventKey: string | null) {
    if (typeof eventKey !== 'string') return;
    updateUrlSearchParam({ sort: eventKey });
  }

  return (
    <div className="SortSelector">
      <DropdownButton
        alignRight
        onSelect={handleSortChange}
        className="sort-select"
        size="sm"
        variant="light"
        key="sort-button"
        id="sort-dropdown"
        title={sortLabelValue}
      >
        {sortOptions.map((sortOption: SortItem) => (
          <Dropdown.Item key={sortOption.id} eventKey={sortOption.id}>
            {sortOption.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default SortSelector;
