import React from 'react';

import { Permission, usePermissions } from 'hooks';

interface PermissionsGateProps {
  permissions: Permission[];
  [key: string]: any;
}

var PermissionsGate = (
  props: React.PropsWithChildren<PermissionsGateProps>
) => {
  const { children, permissions, fallback } = props;
  const { hasPermissions } = usePermissions();

  if (hasPermissions(permissions)) {
    return children;
  }

  // TODO: `fallback` isn't used in the web app and should probably be removed.
  // TODO: We should using ternary {hasPermissions([...]) ? ... : ...} instead.
  if (fallback) {
    return fallback;
  }
  return null;
};

export default PermissionsGate;
