/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useDeleteVehicleTask, useToken } from 'api';
import { permissions } from 'common';
import { usePermissions } from 'hooks';
import { Task, TaskStatus } from 'models';

import './ActionMenu.scss';

const CustomToggle = React.forwardRef((props: any, ref) => {
  return (
    //@ts-ignore need a ref here but typescript acting up...
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      ref={ref}
      size="small"
    >
      <MoreVert />
    </IconButton>
  );
});

interface ActionMenuProps {
  task: Task;
  vehicleId?: string;
  status: TaskStatus;
  onApproveClick: (task: Task) => void;
  onApproveAllClick: (status: TaskStatus) => void;
}

const ActionMenu: React.FunctionComponent<ActionMenuProps> = (props) => {
  const { hasPermission } = usePermissions();
  const { task, vehicleId, onApproveClick, status, onApproveAllClick } = props;

  const { data: auth } = useToken();
  const { user } = auth ?? {};

  const deleteVehicleTaskQuery = useDeleteVehicleTask(vehicleId, task.id);

  const userHasTaskDeletePermission = useMemo(
    () => hasPermission(permissions.INVENTORY_VDP_TASKS_DELETE),
    [hasPermission]
  );

  const userHasTaskApprovePermission = useMemo(
    () => hasPermission(permissions.INVENTORY_VDP_TASKS_APPROVE),
    [hasPermission]
  );

  const canViewApprove =
    userHasTaskApprovePermission &&
    (task.status === 'DRAFT' ||
      task.status === 'PENDING_APPROVAL' ||
      task.status === 'COMPLETE' ||
      task.status === 'DENIED');

  const canViewApproveAll =
    userHasTaskApprovePermission &&
    (task.status === 'DRAFT' || task.status === 'PENDING_APPROVAL');

  const canDelete =
    userHasTaskDeletePermission || task.createdByUserId === user?.id;

  return (
    <Dropdown className={'Task-ActionMenu-drowdown'}>
      <Dropdown.Toggle
        as={CustomToggle}
        id={`Task-dropdown-id-${task.id}`}
      ></Dropdown.Toggle>
      <Dropdown.Menu>
        {canViewApprove && (
          <Dropdown.Item onClick={() => onApproveClick(task)}>
            Approve
          </Dropdown.Item>
        )}
        {canViewApproveAll && (
          <Dropdown.Item onClick={() => onApproveAllClick(task.status)}>
            Approve All {status}
          </Dropdown.Item>
        )}
        {canDelete && (
          <Dropdown.Item onClick={() => deleteVehicleTaskQuery.mutate()}>
            Delete
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionMenu;
