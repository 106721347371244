/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const BriefcaseAccount: React.FC<IconProps> = ({
  color = defaultColor,
  className,
}) => (
  <svg width={20} height={19} viewBox="0 0 20 19" fill="none">
    <path
      className={className}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0H8C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2V4H2C1.46957 4 0.960859 4.21071 0.585786 4.58579C0.210714 4.96086 0 5.46957 0 6V17C0 17.5304 0.210714 18.0391 0.585786 18.4142C0.960859 18.7893 1.46957 19 2 19H18C18.5304 19 19.0391 18.7893 19.4142 18.4142C19.7893 18.0391 20 17.5304 20 17V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H14V2C14 1.46957 13.7893 0.960859 13.4142 0.585786C13.0391 0.210714 12.5304 0 12 0ZM12 2V4H8V2H12ZM11.2728 8.42721C10.9352 8.08964 10.4774 7.9 10 7.9C9.52261 7.9 9.06477 8.08964 8.72721 8.42721C8.38964 8.76477 8.2 9.22261 8.2 9.7C8.2 10.1774 8.38964 10.6352 8.72721 10.9728C9.06477 11.3104 9.52261 11.5 10 11.5C10.4774 11.5 10.9352 11.3104 11.2728 10.9728C11.6104 10.6352 11.8 10.1774 11.8 9.7C11.8 9.22261 11.6104 8.76477 11.2728 8.42721ZM13.6 14.2C13.6 13.2055 11.989 12.4 10 12.4C8.011 12.4 6.4 13.2055 6.4 14.2V15.1H13.6V14.2Z"
      fill={color}
    />
  </svg>
);

export default BriefcaseAccount;
