import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { StringNullableChain } from 'lodash';
import React, { useEffect, useState } from 'react';

import { useEngagementsByFilter } from 'api';
import { EngagementDetail, EngagementGroup } from 'models';
import { engageEngagementBuilder } from 'navigation/routes';
import { getFullName } from 'utils/user';

import strings from '../../../../common/strings';
import { phoneNumberFormatter } from '../../../../utils/formatter';
import { getFormattedDurationFromSeconds } from '../../../../utils/time';
import EngageItem from './EngageItem';
import { Thead } from './EngagementsTable/EngagementsTable';

import './EngageGroup.scss';

interface IEngageGroupHeadingProps {
  id?: string;
  detail: EngagementDetail;
  group: EngagementGroup;
}

export const EngageGroupHeading: React.FC<IEngageGroupHeadingProps> = ({
  detail,
  group,
}) => {
  const { groupFacet } = group;
  const { attribute } = groupFacet;
  const { actions, totalDurationSeconds, opens, lastOpen, crmEmailSentCount } =
    detail?.engagementMetrics;
  const [isExpanded, setIsExpanded] = useState(false);
  const [groupEntityId, setGroupEntityId] = useState('');
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const {
    data: { data } = {},
    isLoading,
    error,
  } = useEngagementsByFilter(attribute, groupEntityId, isQueryEnabled);

  useEffect(() => {
    let id;
    if (attribute === 'SHOPPER') {
      id = detail?.prospect?.id;
    } else if (attribute === 'USER') {
      id = detail?.dealerUser?.id;
    } else if (attribute === 'INVENTORY') {
      id = detail?.vehicleCard?.id;
    }

    if (id) {
      setGroupEntityId(id);
    }
  }, [attribute, detail]);

  useEffect(() => {
    if (isExpanded && groupEntityId) {
      setIsQueryEnabled(true);
    }
  }, [isExpanded, groupEntityId]);

  const getGroupInfo = () => {
    if (attribute === 'SHOPPER' && detail?.prospect) {
      const { prospect } = detail;
      const { person, contact } = prospect;
      const phoneNumber = contact?.phoneContacts[0]?.phoneNumber;
      const emailAddress = contact?.emailContacts[0]?.emailAddress;
      return !person && !contact ? (
        <b>Anyonmous shopper</b>
      ) : (
        <span>
          <b>{person?.fullName}</b>
          <span className="group-label-shopper-contact">
            {phoneNumber && (
              <span>
                {phoneNumber ? (
                  <a
                    className="item-eng-contact-link"
                    href={`tel:${phoneNumber}`}
                  >
                    {phoneNumberFormatter(phoneNumber, '.')}
                  </a>
                ) : (
                  strings.EMPTY_VALUE
                )}
              </span>
            )}
            {phoneNumber && emailAddress && (
              <span className="group-label-shopper-contact-divider">|</span>
            )}
            {emailAddress && <EmailLink addr={emailAddress} />}
          </span>
        </span>
      );
    }
    if (attribute === 'USER' && detail?.dealerUser) {
      const { dealerUser } = detail;
      return !dealerUser ? (
        <b>No salesperson</b>
      ) : (
        <b>{getFullName(dealerUser)}</b>
      );
    }
    if (attribute === 'INVENTORY' && detail?.vehicleCard) {
      const { vehicleCard } = detail;
      const { id, stockNumber, year, make, model, trim } = vehicleCard;
      return (
        <span>
          <b>{stockNumber}</b>
          <span className="group-label-vehicle-year-make-model">
            {year} {make} {model} {trim}
          </span>
          {id && (
            <span
              className="group-label-vehicle-link"
              onClick={() => navigate(engageEngagementBuilder(id))}
            >
              View Vehicle Details
            </span>
          )}
        </span>
      );
    }
    return <b>Group label unavailable.</b>;
  };

  const handleClick = () => {
    // Toggle isExpanded.
    setIsExpanded(!isExpanded);
  };

  if (!group.groupFacet) return null;

  return (
    <>
      <div
        className="engage-group-header-row"
        style={{ backgroundColor: '#eee' }}
        onClick={() => handleClick()}
      >
        <div>
          {isExpanded ? <ExpandMore /> : <ChevronRight />}
          {getGroupInfo()}
        </div>
        {attribute === 'USER' ? (
          <div className="cell centered">
            <b>{crmEmailSentCount ?? 0}</b>
          </div>
        ) : (
          <div className="cell" />
        )}
        <div className="cell centered">
          <b>{opens}</b>
        </div>
        <div className="cell centered">
          <b>
            {totalDurationSeconds
              ? getFormattedDurationFromSeconds(totalDurationSeconds, 3, true)
              : strings.EMPTY_VALUE}
          </b>
          <span className="view-duration-total-text">Total</span>
        </div>
        <div className="cell centered">
          <b>{actions}</b>
        </div>
        <div className="cell centered">
          <b>{lastOpen}</b>
        </div>
      </div>
      {isExpanded && (isLoading || !data || error) && (
        <b>Loading engagements...</b>
      )}
      {isExpanded && !isLoading && data && (
        <>
          <Thead />
          {data?.list[0]?.engagements?.map((item: EngagementDetail) => (
            <>
              <EngageItem
                id={item.id}
                item={item}
                groupBy={attribute}
                key={`engage-item-${item.id}`}
              />
            </>
          ))}
        </>
      )}
    </>
  );
};

export const EmailLink: React.FC<{ addr?: string | StringNullableChain }> = ({
  addr,
}) => {
  if (!addr) return null;
  return (
    <a className="item-eng-contact-link" href={`mailto:${addr}`}>
      {`${addr}`}
    </a>
  );
};

export default EngageGroupHeading;
