import { TextareaAutosize } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';

import {
  useCreateOrUpdateVehicleTask,
  useTaskTemplates,
  useUsers,
  useVehicleTask,
} from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import Checkbox from 'components/shared/Inputs/Checkbox';
import { usePermissions, useWindowSize } from 'hooks';
import { Task, TaskStatus, VehicleSummary } from 'models';
import { addDollarSymbol } from 'utils/formatter';
import { getUsersFullName } from 'utils/user';

import TaskPhotos from '../TaskPhotos';
import { getTaskRowTestIds, taskFromTaskTemplate } from '../taskUtils';
import ActionMenu from './ActionMenu';
import FlatTaskInput from './FlatTaskInput';
import {
  customDropdownComponents,
  customDropdownStyles,
  getDropdownTheme,
} from './utils';

import './FlatTask.scss';

export const STATUS = {
  DRAFT: strings.STATUS_ESTIMATED,
  PENDING_APPROVAL: strings.STATUS_REQUESTED,
  APPROVED_PENDING_COMPLETE: strings.STATUS_APPROVED,
  ACCEPTED: strings.STATUS_APPROVED,
  COMPLETE: strings.STATUS_COMPLETED,
  COMPLETED: strings.STATUS_COMPLETED,
  DENIED: strings.STATUS_DENIED,
  DELETED: strings.STATUS_DELETED,
  INVOICED: strings.INVOICED,
  PAID: strings.PAID,
};

interface FlatTaskProps {
  position: number;
  taskId?: string;
  vehicle: VehicleSummary;
  checked?: boolean;
  pinned?: boolean;
  printPressed: boolean;
  onDeleteTask: (task: Task) => void;
  onTaskCheckboxClick: (task: Task) => void;
  onApproveClick: (task: Task) => void;
  onApproveAllClick: (status: TaskStatus) => void;
}

const FlatTask: React.FC<FlatTaskProps> = (props) => {
  const {
    position,
    taskId,
    checked = false,
    pinned = false,
    vehicle,
    printPressed,
    onTaskCheckboxClick,
    onApproveClick,
    onApproveAllClick,
  } = props;
  const { hasPermission } = usePermissions();

  // Hooks
  const { data: taskTemplateData } = useTaskTemplates();
  const { data: taskTemplates = [] } = taskTemplateData ?? {};

  const { data: userData } = useUsers();
  const { data: users = [] } = userData ?? {};

  const { data: taskData } = useVehicleTask(vehicle?.vehicleCard?.id, taskId);
  const { data: task = {} as Task } = taskData ?? {};

  const createOrUpdateVehicleTaskQuery = useCreateOrUpdateVehicleTask(
    vehicle?.vehicleCard?.id
  );

  const windowSize = useWindowSize();
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const [isAddingCustomTaskType, setIsAddingCustomTaskType] = useState(false);
  const [customTypeText, setCustomTypeText] = useState('');

  // Classes
  const pinnedClassName = pinned ? 'FlatTask-pinned' : '';

  // Permissions
  const userHasTaskLaborCostViewPermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_LABOR_COST_VIEW
  );
  const userHasTaskLaborCostUpdatePermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_LABOR_COST_UPDATE
  );
  const userHasTaskPartsCostViewPermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_PARTS_COST_VIEW
  );
  const userHasTaskPartsCostUpdatePermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_PARTS_COST_UPDATE
  );
  const userHasTaskUpdatePermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_UPDATE
  );
  const userHasTaskAttachmentViewPermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_ATTACHMENT_VIEW
  );

  const taskHasInvoice = !!task.invoiceId;

  const disabled =
    task.status === 'APPROVED_PENDING_COMPLETE' ||
    task.status === 'COMPLETE' ||
    task.status === 'DENIED' ||
    !userHasTaskUpdatePermissions ||
    taskHasInvoice;

  const assigneeDisabled =
    task.status === 'COMPLETE' ||
    task.status === 'DENIED' ||
    !userHasTaskUpdatePermissions ||
    taskHasInvoice;

  // helpers
  const focusDescription = useCallback(() => {
    if (descriptionRef && descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, []);

  const handleDescriptionColPrint = (task: Task) => {
    return task.notes ? task.notes : strings.ENTER_DESCRIPTION;
  };

  // Set up dropdowns
  const taskTypeDropdownOptions = useMemo(
    () =>
      taskTemplates.map((taskTemplate) => ({
        value: taskTemplate.id,
        label: taskTemplate.label,
      })),
    [taskTemplates]
  );
  const taskTypeSelectedDropdownOption = useMemo(
    () => ({
      value: task.taskTemplateId || 'no-id',
      label: task.label || 'Select Task Type',
    }),
    [task]
  );

  const userDropdownOptions = useMemo(
    () =>
      users.map((user) => ({
        value: user.id || strings.EMPTY_VALUE,
        label: getUsersFullName(strings.EMPTY_VALUE, user),
      })),
    [users]
  );

  const userSelectedDropdownOption = useMemo(
    () => ({
      value: task?.assignedTo?.id || 'no-user-id',
      label: getUsersFullName('Select Assignee', task.assignedTo),
    }),
    [task.assignedTo]
  );

  const taskRowTestIds = useMemo(
    () => getTaskRowTestIds('', position),
    [position]
  );

  const updateRateOrHours = useCallback(
    (rate: string | number, hours: string | number) => {
      const rateFloat = parseFloat(Number(rate).toFixed(2));
      const hoursFloat = parseFloat(Number(hours).toFixed(2));
      task.laborRate = {
        amount: rateFloat,
        currency: task.laborRate?.currency,
      };
      task.laborHours = hoursFloat;
      task.laborPrice = {
        amount: parseFloat(Number(rateFloat * hoursFloat).toFixed(2)),
        currency: task.laborPrice?.currency,
      };
      createOrUpdateVehicleTaskQuery.mutate(task);
    },
    [createOrUpdateVehicleTaskQuery, task]
  );

  const createOrUpdateCustomTaskType = useCallback(async () => {
    if (isAddingCustomTaskType && customTypeText.trim().length > 0) {
      task.label = customTypeText;
      createOrUpdateVehicleTaskQuery.mutateAsync(task);
      focusDescription();
    }
  }, [
    createOrUpdateVehicleTaskQuery,
    customTypeText,
    focusDescription,
    isAddingCustomTaskType,
    task,
  ]);

  const ephemeral = !taskId;

  return (
    <div
      id={`task-container-view-${position}`}
      data-vas-testing={taskRowTestIds.containerId}
      role="none"
      className={`FlatTask ${pinnedClassName}`}
    >
      <div className="FlatTask-checkbox-col">
        {createOrUpdateVehicleTaskQuery.isLoading ? (
          <div className="Loading-centered">
            <Spinner size="sm" animation="border" />
          </div>
        ) : (
          <Checkbox
            testId={taskRowTestIds.checkboxId}
            onClick={() => onTaskCheckboxClick(task)}
            label=""
            id={`task-checkbox-${position}`}
            name={`${task.id}-checkbox`}
            selected={checked}
            disabled={!userHasTaskUpdatePermissions}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-status-label">
        {strings.STATUS}
      </div>
      <div
        className={`FlatTask-status-col FlatTask-status-${STATUS[task.status]}`}
        id={`task-status-${position}`}
      >
        {STATUS[task.status]}
      </div>

      <div className="FlatTask-sm-label FlatTask-task-label">
        {strings.TASK}
      </div>
      <div
        data-vas-testing={taskRowTestIds.taskTypeId}
        id={`task-select-task-input-${position}`}
        className="FlatTask-task-col"
      >
        {printPressed && taskTypeSelectedDropdownOption.label}
        {isAddingCustomTaskType && (
          <div className="FlatTask-custom-type-input">
            <input
              data-vas-testing={taskRowTestIds.customTaskId}
              id={`task-custom-input-${position}`}
              placeholder={strings.CUSTOM_PLACEHOLDER}
              type="text"
              value={customTypeText}
              autoFocus
              onChange={(event) => setCustomTypeText(event.target.value)}
              onBlur={() => {
                task.label = customTypeText;
                if (ephemeral && task.status === 'APPROVED_PENDING_COMPLETE') {
                  return;
                }
                createOrUpdateCustomTaskType();
              }}
              disabled={disabled}
            />
            <div
              role="none"
              onMouseDown={(event) => {
                event.stopPropagation();
                setIsAddingCustomTaskType(false);
              }}
            >
              <Close className="FlatTask-close-icon" />
            </div>
          </div>
        )}
        {!printPressed && !isAddingCustomTaskType && (
          <Select
            classNamePrefix={taskRowTestIds.taskTypeClass}
            placeholder={strings.TASK_TYPE_PLACEHOLDER}
            value={taskTypeSelectedDropdownOption}
            options={taskTypeDropdownOptions}
            isDisabled={disabled}
            theme={getDropdownTheme}
            components={customDropdownComponents(() => {})}
            styles={customDropdownStyles(windowSize.isMobileViewport())}
            onChange={async ({ value: id }: any) => {
              const taskTemplate =
                id === 'custom'
                  ? taskTemplates[0]
                  : taskTemplates.find((template) => template.id === id);

              const updatedTask = taskFromTaskTemplate(task, taskTemplate);

              if (id === 'custom') {
                setIsAddingCustomTaskType(true);
                return;
              }

              createOrUpdateVehicleTaskQuery.mutateAsync(updatedTask);
              focusDescription();
            }}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-description-label">
        {strings.DESCRIPTION}
      </div>
      <div className="FlatTask-description-col">
        {printPressed ? (
          handleDescriptionColPrint(task)
        ) : (
          <TextareaAutosize
            data-vas-testing={taskRowTestIds.descriptionId}
            id={`task-description-input-${position}`}
            ref={descriptionRef}
            className={`FlatTask-description-col`} //TODO
            defaultValue={task.notes}
            placeholder={strings.ENTER_DESCRIPTION}
            onBlur={(event) => {
              task.notes = event.target.value;
              createOrUpdateVehicleTaskQuery.mutate(task);
            }}
            disabled={disabled}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-assignee-label">
        {strings.ASSIGNEE}
      </div>
      <div
        data-vas-testing={taskRowTestIds.assigneeId}
        className="FlatTask-assignee-col"
      >
        {printPressed ? (
          userSelectedDropdownOption.label
        ) : (
          <Select
            id={`task-assignee-input-${position}`}
            classNamePrefix={taskRowTestIds.assigneeClass}
            placeholder={strings.TASK_TYPE_PLACEHOLDER}
            value={userSelectedDropdownOption}
            options={userDropdownOptions}
            isDisabled={assigneeDisabled}
            theme={getDropdownTheme}
            components={customDropdownComponents(() => {})}
            styles={customDropdownStyles(windowSize.isMobileViewport())}
            onChange={({ value: id }: any) => {
              const user = users.find((user) => user.id === id);
              task.assignedTo = user;
              task.assignedToId = user?.id;
              createOrUpdateVehicleTaskQuery.mutate(task);
            }}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-photos-label">
        {strings.PHOTOS}
      </div>
      <div className="FlatTask-photos-col">
        {userHasTaskAttachmentViewPermissions && (
          <TaskPhotos
            task={task}
            disabled={disabled || ephemeral}
            vehicle={vehicle}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-costs-label">
        {strings.COSTS}
      </div>

      <div className="FlatTask-rate-col" id={`task-rate-price-${position}`}>
        <FlatTaskInput
          testId={''}
          testData={`task-rate-input-${position}`}
          disabled={disabled || !userHasTaskLaborCostUpdatePermissions}
          value={task.laborRate?.amount}
          onSave={(value) => updateRateOrHours(value, `${task.laborHours}`)}
          hasViewPermission={userHasTaskLaborCostViewPermissions}
          formatter={addDollarSymbol}
        />
      </div>
      <div className="FlatTask-hours-col" id={`task-hours-price-${position}`}>
        <FlatTaskInput
          testData={`task-hours-input-${position}`}
          testId={''} //TODO
          disabled={disabled || !userHasTaskLaborCostUpdatePermissions}
          value={task.laborHours}
          onSave={(value) =>
            updateRateOrHours(`${task.laborRate?.amount}`, value)
          }
          hasViewPermission={userHasTaskLaborCostViewPermissions}
          formatter={(value) => value}
        />
      </div>

      <div className="FlatTask-sm-label FlatTask-labor-label">
        {strings.LABOR}
      </div>
      <div
        className={`FlatTask-labor-price-col`}
        id={`task-labor-price-${position}`}
      >
        <FlatTaskInput
          testData={`task-labor-input-${position}`}
          testId={''} //TODO
          disabled={disabled || !userHasTaskLaborCostUpdatePermissions}
          value={task.laborPrice?.amount}
          onSave={function (value: any): void {
            task.laborRate = {
              amount: 0,
              currency: task.laborRate?.currency,
            };
            task.laborHours = 0;
            task.laborPrice = {
              amount: parseFloat(value),
              currency: task.laborPrice?.currency,
            };
            createOrUpdateVehicleTaskQuery.mutate(task);
          }}
          hasViewPermission={userHasTaskLaborCostViewPermissions}
          formatter={addDollarSymbol}
        />
      </div>

      <div className="FlatTask-sm-label FlatTask-parts-label">
        {strings.PARTS}
      </div>
      <div
        className={`FlatTask-parts-price-col`}
        id={`task-parts-price-${position}`}
      >
        <FlatTaskInput
          testData={`task-parts-input-${position}`}
          testId={taskRowTestIds.partsCostId}
          disabled={disabled || !userHasTaskPartsCostUpdatePermissions}
          value={task.partsPrice?.amount}
          onSave={(value) => {
            task.partsPrice = {
              amount: parseFloat(value),
              currency: task.partsPrice?.currency,
            };
            createOrUpdateVehicleTaskQuery.mutate(task);
          }}
          hasViewPermission={userHasTaskPartsCostViewPermissions}
          formatter={addDollarSymbol}
        />
      </div>

      <div className="FlatTask-sm-label FlatTask-total-label">
        {strings.TOTAL}
      </div>
      <div
        id={`tasks-total-price-${position}`}
        data-vas-testing={taskRowTestIds.totalCostId}
        className={`FlatTask-total-price-col`}
      >
        {userHasTaskLaborCostViewPermissions &&
        userHasTaskPartsCostViewPermissions
          ? addDollarSymbol(
              parseFloat(Number(task.price?.amount ?? 0).toFixed(2))
            )
          : strings.EMPTY_VALUE}
      </div>
      <div
        id={`task-action-menu-dropdown-${position}`}
        data-vas-testing={taskRowTestIds.deleteId}
        className={`FlatTask-delete-col`}
      >
        <ActionMenu
          task={task}
          vehicleId={vehicle?.vehicleCard?.id}
          status={STATUS[task.status]}
          onApproveClick={onApproveClick}
          onApproveAllClick={onApproveAllClick}
        />
      </div>
    </div>
  );
};

export default FlatTask;
