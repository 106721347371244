/* eslint-disable no-alert */
export interface MockUser {
  id: string;
  label: string;
  value?: string;
  isSelected: boolean;
}

export interface MockShopper {
  id: string;
  label: string;
  value?: string;
  isSelected: boolean;
}

export interface MockHtmlTemplate {
  id: string;
  name: string;
  value: string;
  isSelected: boolean;
}

export interface MockModule {
  id: string;
  label: string;
  isSelected: boolean;
  value: string;
}

export const mockUserList: MockUser[] = [
  {
    id: '000',
    label: 'Schwartz Auto Sales',
    isSelected: true,
    value: 'user1',
  },
  {
    id: '001',
    label: 'Spaceballs, The Sales Team',
    isSelected: false,
    value: 'user2',
  },
  {
    id: '002',
    label: "Willy Wonka's Gobstopper Factory",
    isSelected: false,
    value: 'user3',
  },
];

export const mockShopperList: MockShopper[] = [
  {
    id: '010',
    isSelected: false,
    label: 'Tyler Durden',
    value: 'shopper01',
  },
  {
    id: '011',
    isSelected: true,
    label: 'Marla Singer',
    value: 'shopper02',
  },
  {
    id: '012',
    isSelected: true,
    label: 'Robert Paulson',
    value: 'shopper03',
  },
];

export const mockTemplateList: MockHtmlTemplate[] = [
  {
    id: '110',
    name: 'Thank You 01',
    isSelected: true,
    value:
      '<h1>Thank You 01</h1><p>Lorem <strong>ipsum</strong> <em>dolor</em> <ins>sit</ins> <del>amet</del></p>',
  },
  {
    id: '111',
    name: 'Thank You 02',
    isSelected: false,
    value:
      '<h1>Thank You 02</h1><p>Lorem <strong>ipsum</strong> <em>dolor</em> <ins>sit</ins> <del>amet</del></p>',
  },
  {
    id: '112',
    name: 'Thank You 03',
    isSelected: false,
    value:
      '<h1>Thank You 03</h1><p>Lorem <strong>ipsum</strong> <em>dolor</em> <ins>sit</ins> <del>amet</del></p>',
  },
];

export const mockModuleList: MockModule[] = [
  {
    id: '004',
    label: 'CARFAX',
    isSelected: false,
    value: 'module01',
  },
  {
    id: '005',
    label: 'WindowSticker',
    isSelected: true,
    value: 'module02',
  },
  {
    id: '006',
    label: 'Warranty',
    isSelected: true,
    value: 'module03',
  },
];

export const mockShareUrl: string =
  'https://vadar.reconvelocity.com/123-456DER-232';
