import { useQuery } from 'react-query';

import { APIResponse } from 'api';
import { permissions } from 'common';
import { usePermissions } from 'hooks';
import {
  DashboardGoals,
  DashboardHighlights,
  DashboardSummary,
  InventoryNeedingAttention,
} from 'models';
import InventoryVehicleSummary from 'models/inventory/inventoryVehicleSummary';

export function useDashboardSummary() {
  const path = '/dashboard/summary';
  return useQuery<APIResponse<DashboardSummary>>(path);
}

export function useDashboardAssignments() {
  const path = '/assignments';
  return useQuery<APIResponse<InventoryVehicleSummary[]>>(path);
}

export function useDashboardHighlights() {
  const path = '/dashboard/highlights';
  return useQuery<APIResponse<DashboardHighlights>>(path);
}

export function useVehicleNeedsAttention() {
  const path = '/dashboard/inventoryNeedingAttention/';
  return useQuery<APIResponse<InventoryNeedingAttention>>(path);
}

export function useDashboardGoals() {
  const path = '/dashboard/goals';
  const { hasPermission } = usePermissions();
  const enabled = hasPermission(permissions.RECON_VIEW);
  return useQuery<APIResponse<DashboardGoals[]>>(path, { enabled });
}
