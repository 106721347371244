import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './CircularProgressChart.scss';

interface CircularProgressChartProps {
  id: string;
  title?: string;
  goalTime?: string;
  timeLogged?: string;
  percentage: number;
  size?: number;
  strokeWidth?: number;
  variant: 'danger' | 'warning' | 'ok' | 'ontime';
}

const CircularProgressChart: React.FC<CircularProgressChartProps> = ({
  title = '',
  goalTime,
  timeLogged = 0,
  percentage,
  size = 60,
  strokeWidth = 21.5,
  variant = 'ok',
}) => {
  if (percentage > 100) {
    percentage = 100;
  }
  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <Container fluid className="CircularProgressChart">
      {/* TODO: Keep text from wrapping to new line */}
      <Row noGutters>
        {goalTime && (
          <Col xs="auto">
            <svg width={size} height={size} viewBox={viewBox}>
              <circle
                className="circle-chart-background"
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`}
              />
              <circle
                className={`circle-chart-progress ${variant}`}
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`}
                transform={`rotate(-90 ${size / 2} ${size / 2})`}
                style={{
                  strokeDasharray: dashArray,
                  strokeDashoffset: dashOffset,
                }}
              />
              <circle
                className="circle-chart-border"
                cx={size / 2}
                cy={size / 2}
                r={(size - 1) / 2}
              />
              <circle
                className="circle-chart-border"
                cx={size / 2}
                cy={size / 2}
                r={(size - strokeWidth * 2) / 2}
              />
            </svg>
          </Col>
        )}
        <Col
          xs="auto"
          className="CircularProgressChart-text d-flex flex-column align-items-start justify-content-center"
        >
          <div className="CircularProgressChart-title">{title}</div>
          <div className="CircularProgressChart-time-info">
            <span className="CircularProgressChart-time-logged">
              {timeLogged}
            </span>
            {goalTime && (
              <span className="CircularProgressChart-goal-time">{` / ${goalTime} GOAL`}</span>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CircularProgressChart;
