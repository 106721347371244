import { Redirect } from '@reach/router';
import { useReducer } from 'react';
import validateJs from 'validate.js';

import { useLoginMutation } from 'api';
import { getCredentialValue } from 'api/lib/credentialsPersistence';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Alert from 'components/shared/Alert';
import Button from 'components/shared/Button';

import ForgotPasswordLink from './ForgotPasswordLink';
import LoginFormTextField from './LoginFormTextField';
import LoginScreenLayout from './LoginScreenLayout';
import { loginValidations } from './validations';

interface LoginState {
  emailAddress: string;
  password: string;
  emailAddressError: string | null;
  passwordError: string | null;
  apiError: any | null;
}

type LoginAction =
  | {
      type: 'setEmail' | 'setPassword';
      value: string;
    }
  | {
      type: 'preSubmit' | 'clearApiError';
    }
  | {
      type: 'setError';
      error: any;
    };

function loginValidationErrors({
  emailAddress,
  password,
}: {
  emailAddress: string;
  password: string;
}) {
  const results = validateJs(
    {
      email: emailAddress,
      password: password,
    },
    loginValidations
  );
  const emailAddressError = results?.email?.[0] ?? null;
  const passwordError = results?.password?.[0] ?? null;
  return {
    emailAddressError,
    passwordError,
    valid: !emailAddressError && !passwordError,
  };
}

function loginReducer(state: LoginState, action: LoginAction): LoginState {
  switch (action.type) {
    case 'setEmail':
      return {
        ...state,
        emailAddress: action.value,
        apiError: null,
      };
    case 'setPassword':
      return {
        ...state,
        password: action.value,
        apiError: null,
      };
    case 'preSubmit': {
      const { emailAddressError, passwordError } = loginValidationErrors(state);
      return {
        ...state,
        apiError: null,
        emailAddressError,
        passwordError,
      };
    }
    case 'clearApiError': {
      return {
        ...state,
        apiError: null,
      };
    }
    case 'setError': {
      return {
        ...state,
        apiError: action.error,
      };
    }
  }
}

function getErrorMessage(error: any): string {
  return error?.message?.includes(401)
    ? strings.INVALID_LOGIN_MESSAGE
    : strings.API_MESSAGE;
}

interface LoginProps {
  path?: string;
}
const Login = ({ path }: LoginProps) => {
  const emailAddressFromUrl = new URLSearchParams(window.location.search).get(
    'username'
  );

  const [state, dispatch] = useReducer<typeof loginReducer>(loginReducer, {
    emailAddress: emailAddressFromUrl || '',
    password: '',
    emailAddressError: null,
    passwordError: null,
    apiError: null,
  });

  const { mutateAsync: loginMutation, isLoading: isSubmittingLogin } =
    useLoginMutation();

  const authBearerToken = getCredentialValue('authBearerToken');
  if (authBearerToken) {
    return <Redirect noThrow to="/" />;
  }

  const onSubmit = async () => {
    dispatch({ type: 'preSubmit' });
    const { valid } = loginValidationErrors(state);
    if (valid) {
      try {
        await loginMutation({
          emailAddress: state.emailAddress,
          password: state.password,
        });
      } catch (error: any) {
        if (error?.constructor?.name !== 'CancelledError') {
          dispatch({ type: 'setError', error });
        }
      }
    }
  };

  return (
    <LoginScreenLayout>
      <Alert
        open={!!state.apiError}
        data-vas-testing={testIds.LOGIN_ERROR_MESSAGE_ALERT}
        contentProps={{
          message: state.apiError && getErrorMessage(state.apiError),
          variant: 'error',
          onClose: () => dispatch({ type: 'clearApiError' }),
        }}
      />
      <form>
        <LoginFormTextField
          disabled={isSubmittingLogin}
          label={strings.EMAIL_FIELD}
          name="email"
          onChange={(value) => {
            if (emailAddressFromUrl === null) {
              dispatch({ type: 'setEmail', value });
            }
          }}
          onSubmit={onSubmit}
          value={state.emailAddress}
          errorMessage={state.emailAddressError || undefined}
          margin
          autoFocus
        />
        <LoginFormTextField
          disabled={isSubmittingLogin}
          label={strings.PASSWORD_FIELD}
          name="password"
          onChange={(value) => dispatch({ type: 'setPassword', value })}
          onSubmit={onSubmit}
          value={state.password}
          errorMessage={state.passwordError || undefined}
          password
        />
      </form>
      <ForgotPasswordLink />
      <Button
        id="login"
        type="submit"
        variant="primary"
        className="btn-block"
        onClick={onSubmit}
        disabled={isSubmittingLogin}
        loading={isSubmittingLogin}
      >
        {strings.LOGIN_BUTTON_TEXT}
      </Button>
    </LoginScreenLayout>
  );
};

export default Login;
