import { useEffect, useState } from 'react';

import { useCurrentLocation } from 'api';
import { features } from 'common';
import { useFeatures } from 'hooks';

export const useVendor = () => {
  const roofTopLocation = useCurrentLocation();
  const { hasFeature } = useFeatures();

  const [isVendor, setIsVendor] = useState<boolean>(false);

  useEffect(() => {
    if (roofTopLocation) {
      const isVelocityVendor = hasFeature(features.VELOCITY_VENDOR);
      const locationType = roofTopLocation?.type;
      setIsVendor(isVelocityVendor && locationType === 'VENDOR');
    }
  }, [hasFeature, roofTopLocation, roofTopLocation?.type]);

  return { isVendor };
};
