import { FC, useMemo } from 'react';

import StepDropdown from 'components/shared/StepDropdown';
import { StepItem } from 'models';

import { isStepDefined } from './utils';

interface VehicleCardStepDropdownProps {
  stepItem: StepItem | undefined;
  inventoryId: string | undefined;
}

export const VehicleCardStepDropdown: FC<VehicleCardStepDropdownProps> = ({
  stepItem,
  inventoryId,
}) => {
  const currentStepPart = useMemo(
    () => ({
      id: stepItem?.id,
      name: stepItem?.name,
    }),
    [stepItem?.id, stepItem?.name]
  );

  if (!isStepDefined(currentStepPart)) {
    return null;
  }

  return (
    <StepDropdown
      inventoryId={inventoryId}
      currentStep={currentStepPart}
      containerClassName="UsersDropdown"
      loaderType="srpGradient"
    />
  );
};
