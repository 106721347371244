import Grid from '@material-ui/core/Grid';
import { MoreVert, Send, Visibility } from '@material-ui/icons';
import { navigate } from '@reach/router';
import React, { useState } from 'react';

import useEngageOverview from 'api/inventory/useEngageOverview';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Alert from 'components/shared/Alert';
import Button from 'components/shared/Button';
import PaneHeader from 'components/shared/PaneHeader';
import PermissionsGate from 'components/shared/PermissionsGate';
import SendVehicleModal from 'components/shared/SendVehicleModal';
import Tabs from 'components/shared/Tabs';
import { useWindowSize } from 'hooks';
import { VehicleSummary } from 'models';
import { ENGAGE_ENGAGEMENTS, vehicleDetailsBuilder } from 'navigation/routes';
import { useVdpMenuState } from 'store/useVdpMenuCollapsedState';

import VelocityEngageEngagementTab from './VelocityEngageEngagementsTab';
import VelocityEngageMediaTab from './VelocityEngageMediaTab';
import VelocityEngageOverviewTab from './VelocityEngageOverviewTab';

import './VelocityEngage.scss';

interface VelocityEngageProps {
  path?: string;
  vehicle: VehicleSummary;
}

export const VelocityEngage: React.FC<
  React.PropsWithChildren<VelocityEngageProps>
> = ({ vehicle }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [sendToShopperModalOpen, setSendToShopperModalOpen] =
    useState<boolean>(false);
  const { data: { data: overview } = {} } = useEngageOverview(
    vehicle?.vehicleCard?.id
  );
  const windowSize = useWindowSize();

  const handleVDPNavigation = () => {
    navigate(vehicleDetailsBuilder(vehicle?.vehicleCard?.id!, true), {
      replace: true,
    });
  };

  const openModal = () => {
    setSendToShopperModalOpen(true);
  };

  const closeModal = () => {
    setSendToShopperModalOpen(false);
  };

  const handleHeatmapCVDPClick = async () => {
    const shareURL = overview?.shareURL;
    if (shareURL) {
      window.open(`${shareURL}?heatmap=true`, '_newtab');
    }
  };
  const vdpMenuCollapsed = useVdpMenuState((state) => state.collapsed);

  const tabsMarginClassName =
    !vdpMenuCollapsed &&
    !windowSize.isMobileViewport() &&
    windowSize.width < 850
      ? 'VelocityEngage-tabs-margin'
      : '';

  return (
    <>
      <Grid
        item
        container
        xs={12}
        direction="column"
        wrap="nowrap"
        className="VelocityEngage flex-rows"
        data-vas-testing="rv-vdp-velocityEngage-section"
      >
        {!windowSize.isMobileViewport() && (
          <PaneHeader
            title={strings.VELOCITY_ENGAGE}
            onBackButtonClick={handleVDPNavigation}
          >
            {windowSize.width <= 1000 ? (
              <>
                <MoreVert
                  className="VelocityEngage-more"
                  onClick={() => setMenuOpen(!menuOpen)}
                />
                {menuOpen && (
                  <div className="VelocityEngage-more-menu">
                    <div
                      className="VelocityEngage-more-menu-option"
                      role="none"
                      onClick={() => handleHeatmapCVDPClick()}
                    >
                      {strings.VIEW_HEATMAP}
                    </div>
                    <PermissionsGate
                      permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}
                    >
                      <div
                        className="VelocityEngage-more-menu-option"
                        role="none"
                        onClick={openModal}
                      >
                        {strings.SEND_TO_SHOPPER}
                      </div>
                    </PermissionsGate>
                  </div>
                )}
              </>
            ) : (
              <>
                <Button
                  data-vas-testing={testIds.VDP_ENGAGE_VEHICLE_VIEW_BUTTON}
                  className="VelocityEngage-header-button"
                  id="engage-view-button"
                  type="button"
                  variant="secondary"
                  onClick={() => handleHeatmapCVDPClick()}
                >
                  {strings.HEATMAP}
                  <Visibility className="VelocityEngage-header-button-icon" />
                </Button>
                <PermissionsGate
                  permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}
                >
                  <Button
                    data-vas-testing={testIds.VDP_ENGAGE_VEHICLE_SEND_BUTTON}
                    className="VelocityEngage-header-button"
                    type="button"
                    variant="secondary"
                    onClick={openModal}
                  >
                    {strings.BUTTON_TITLE_SEND}
                    <Send className="VelocityEngage-header-button-icon" />
                  </Button>
                </PermissionsGate>
              </>
            )}
          </PaneHeader>
        )}
        {windowSize.isMobileViewport() && (
          <div className="VelocityEngage-mobile-action-buttons">
            <Visibility
              className="VelocityEngage-mobile-action-buttons-button"
              onClick={() => handleHeatmapCVDPClick()}
            />
            <PermissionsGate
              permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}
            >
              <Send
                className="VelocityEngage-mobile-action-buttons-button"
                onClick={openModal}
              />
            </PermissionsGate>
          </div>
        )}
        <Tabs
          defaultPath="overview"
          basePath="engage"
          className={`VelocityEngage-tabs ${tabsMarginClassName || ''}`}
        >
          <VelocityEngageOverviewTab
            title={strings.OVERVIEW}
            path="overview"
            inventoryId={vehicle?.vehicleCard?.id}
          />
          <VelocityEngageEngagementTab
            title={strings.ENGAGEMENTS}
            path={ENGAGE_ENGAGEMENTS}
            inventoryId={vehicle?.vehicleCard?.id}
          />
          {vehicle?.vehicleCard?.id ? (
            <VelocityEngageMediaTab
              title={strings.MEDIA}
              path="media"
              vehicleId={vehicle?.vehicleCard?.id}
            />
          ) : null}
        </Tabs>
        <SendVehicleModal
          selectedVehicleList={[vehicle]}
          open={sendToShopperModalOpen}
          onClose={closeModal}
        />
      </Grid>
      <Alert
        open={isErrorDisplayed}
        duration={2000}
        contentProps={{
          message: strings.VIEW_CVDP_ERROR_MESSAGE,
          variant: 'error',
          onClose: () => setIsErrorDisplayed(false),
        }}
      />
    </>
  );
};

export default VelocityEngage;
