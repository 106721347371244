import { Send, Visibility } from '@material-ui/icons';
import { noop } from 'lodash';
import { useContext, useState } from 'react';

import { permissions, strings } from 'common';
import ModalCarousel from 'components/shared/ModalCarousel';
import PermissionsGate from 'components/shared/PermissionsGate/PermissionsGate';
import VehicleImageView from 'components/shared/VehicleImageView';
import { currencyFormatter } from 'utils/formatter';
import { getIsCertifiedPreOwned } from 'utils/vehicle';

import VehicleDetailsTable from '../components/VehicleCardDetailsTable';
import VehicleCardYearMakeModel from '../components/VehicleCardYearMakeModel';
import {
  VehicleCardConfigProp,
  VehicleCardContext,
  WrapImagePreviewForShowroom,
} from '../VehicleCard';

import './VehicleCardShowroomMobile.scss';

interface VehicleCardShowroomMobileProps {
  style: object;
  config: VehicleCardConfigProp;
}
const VehicleCardShowroomMobile = ({
  style,
  config,
}: VehicleCardShowroomMobileProps) => {
  const { onVehicleCardEngageSendClick, onViewCVDPClick } = config;
  const { vehicle } = useContext(VehicleCardContext);
  const vehicleId = vehicle?.vehicleCard.id;
  const [isModalGalleryOpen, setIsModalGalleryOpen] = useState(false);

  const toggleIsModalGalleryOpen = () => {
    setIsModalGalleryOpen((currentVal) => !currentVal);
  };

  const handleViewCVDPClick = () => {
    if (vehicle && vehicleId) {
      onViewCVDPClick();
    }
  };

  const inventoryPrice = currencyFormatter(vehicle?.price?.amount);
  const isCertifiedPreOwned = getIsCertifiedPreOwned(vehicle!);

  return (
    <div className="VehicleCardShowroomMobile">
      <div className="VehicleCardShowroomMobile-vehicleInfo">
        <div className="cover-size-image-container">
          <WrapImagePreviewForShowroom
            toggleIsModalGalleryOpen={toggleIsModalGalleryOpen}
          >
            <VehicleImageView
              imageUrl={vehicle?.vehicleCard.vehicleImage}
              skeleton={!vehicle}
              className="VehicleImageView-showroom-size"
            />
          </WrapImagePreviewForShowroom>
        </div>
        <VehicleCardYearMakeModel />
      </div>

      <div className="showroom-priceChip">
        <div className="showroom-price">{`Price ${inventoryPrice}`}</div>
        {isCertifiedPreOwned && (
          <div className="showroom-chip">{strings.CERTIFIED_PREOWNED_CHIP}</div>
        )}
      </div>

      <VehicleDetailsTable showVin={false} />

      <div className="showroom-buttons">
        <div className="showroom-badge" onClick={handleViewCVDPClick}>
          <div>{strings.VIEW}</div>
          <Visibility className="engage-btn-icon" />
        </div>
        <PermissionsGate permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}>
          <div
            className="showroom-badge"
            onClick={onVehicleCardEngageSendClick}
          >
            <div>{strings.SEND}</div>
            <Send className="engage-btn-icon" />
          </div>
        </PermissionsGate>
      </div>

      {vehicle && (
        <ModalCarousel
          modalIsOpen={isModalGalleryOpen}
          currentIndex={0}
          images={vehicle?.vehicleCard.imageCarousel ?? []}
          onClose={toggleIsModalGalleryOpen}
          onDeleteImage={noop}
          onAssignImage={noop}
        />
      )}
    </div>
  );
};

export default VehicleCardShowroomMobile;
