import CircularProgress from '@material-ui/core/CircularProgress';
import Delete from '@material-ui/icons/Delete';
import { useLocation } from '@reach/router';
import React, { MouseEvent, useState } from 'react';

import {
  useChangeLocation,
  useCurrentLocation,
  useDeleteNotification,
  useLocations,
  useToken,
  useUpdateNotificationReadStatus,
} from 'api';
import permissions from 'common/permissions';
import PermissionsGate from 'components/shared/PermissionsGate';
import { Notification } from 'models';

import NotificationIcon from '../NotificationIcon';
import { iconColor, routeToNotification } from '../notificationUtils';

import './NotificationListItem.scss';

interface NotificationListItemProps {
  notification: Notification;
  toggleShowAlert: () => void;
  onReloadNotifications: () => void;
  closeNotificationCenter: () => void;
}

const NotificationListItem: React.FC<NotificationListItemProps> = ({
  notification,
  toggleShowAlert,
  onReloadNotifications,
  closeNotificationCenter,
}) => {
  const locationsQuery = useLocations();
  const rooftopList = locationsQuery?.data ?? [];

  const changeLocationQuery = useChangeLocation();
  const deleteNotificationMutation = useDeleteNotification();
  const updateReadStatusMutation = useUpdateNotificationReadStatus();
  const location = useLocation();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteButtonClick = async (e: MouseEvent) => {
    e.stopPropagation();
    setIsDeleting(true);
    await deleteNotificationMutation.mutateAsync(notification.id);
  };

  const { data: currentUser } = useToken();
  const currentLocation = useCurrentLocation();

  const handleNotificationClick = async () => {
    if (!notification.read) {
      await updateReadStatusMutation.mutateAsync(notification.id);
    }
    closeNotificationCenter();
    if (currentUser !== undefined && currentLocation !== undefined) {
      routeToNotification(
        notification,
        toggleShowAlert,
        currentUser,
        currentLocation,
        location.pathname.split('/')?.slice(1)?.join('/') || 'Dashboard',
        rooftopList,
        changeLocationQuery.mutateAsync
      );
    }
  };

  return (
    <div
      className={`NotificationListItem-notification ${
        isDeleting ? 'is-deleting' : ''
      }`}
      onClick={handleNotificationClick}
      role="button"
      tabIndex={-1}
    >
      <NotificationIcon type={notification.notificationType} />
      {isDeleting ? (
        <div className="NotificationListItem-status NotificationListItem-progress">
          <CircularProgress color="primary" size={24} />
        </div>
      ) : (
        <>
          <div className="NotificationListItem-status">
            {!notification.read && (
              <div className="NotificationListItem-status-dot" />
            )}
          </div>
          <PermissionsGate permissions={[permissions.NOTIFICATIONS_DELETE]}>
            <div
              role="button"
              tabIndex={-1}
              onClick={handleDeleteButtonClick}
              className="NotificationListItem-actions"
            >
              <Delete style={{ color: iconColor }} />
            </div>
          </PermissionsGate>
        </>
      )}
      <div className="NotificationListItem-message">{notification.message}</div>
    </div>
  );
};

export default NotificationListItem;
