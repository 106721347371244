import IconButton from '@material-ui/core/IconButton';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';

import { features } from 'common';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useFeatures } from 'hooks';
import { Section, VehicleSummary } from 'models';
import { useVdpMenuState } from 'store/useVdpMenuCollapsedState';

import VehicleMenuRow from './VehicleMenuRow/VehicleMenuRow';

import './VehicleMenu.scss';

interface VehicleMenuProps {
  sections: Section[] | undefined;
  vehicle: VehicleSummary;
  selected?: string;
  isDesktop: boolean;
}

const VehicleMenu: React.FC<VehicleMenuProps> = ({
  sections,
  vehicle,
  selected,
  isDesktop,
}) => {
  const { hasFeature } = useFeatures();
  const toggleVdpMenu = useVdpMenuState((state) => state.toggle);
  const vdpMenuCollapsed = useVdpMenuState((state) => state.collapsed);
  const collapseState = vdpMenuCollapsed ? 'collapsed' : 'notCollapsed';
  return (
    <div className={`VehicleMenu ${collapseState}`}>
      {!sections ? (
        <LoadingIndicator />
      ) : (
        sections.map((item) => {
          if (item.id === 'carfax') {
            if (hasFeature(features.CARFAX_DEALER)) {
              return (
                <div key={`vehicle-menu-row-${item.id}`}>
                  <VehicleMenuRow
                    vehicle={vehicle}
                    selected={isDesktop && selected === item.label}
                    section={item}
                    showLabel={!vdpMenuCollapsed}
                  />
                </div>
              );
            } else {
              return null;
            }
          }
          return (
            <div key={`vehicle-menu-row-${item.id}`}>
              <VehicleMenuRow
                vehicle={vehicle}
                selected={isDesktop && selected === item.label}
                section={item}
                showLabel={!vdpMenuCollapsed}
              />
            </div>
          );
        })
      )}
      <div className="text-right menu-chevron-icon">
        <IconButton disabled={false} onClick={toggleVdpMenu}>
          {vdpMenuCollapsed ? (
            <ChevronRight className="menu-chevron-icon" />
          ) : (
            <ChevronLeft className="menu-chevron-icon" />
          )}
        </IconButton>
      </div>
    </div>
  );
};

export default VehicleMenu;
