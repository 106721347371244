import { Print } from '@material-ui/icons';
import { useNavigate } from '@reach/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useToken, useUpdateVehicleTasksStatus, useVehicleTasks } from 'api';
import { useCreateInvoiceMutation } from 'api/invoicing';
import strings from 'common/strings';
import Alert from 'components/shared/Alert';
import IconButton from 'components/shared/IconButton';
import { Pin } from 'components/shared/icons';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useVendor, useWindowSize } from 'hooks';
import { Task, TaskStatus, VehicleSummary } from 'models';

import { PinnableComponentType } from '../VehicleDetails/PinnedComponent';
import AddButton from './ActionButtons/AddButton';
import ApproveButton from './ActionButtons/ApproveButton';
import CompleteButton from './ActionButtons/CompleteButton';
import CreateInvoice from './ActionButtons/CreateInvoice';
import DenyButton from './ActionButtons/DenyButton';
import RequestApprovalButton from './ActionButtons/RequestApprovalButton';
import ViewAppraisalButton from './ActionButtons/ViewAppraisalButton';
import AppraisalInfoModal from './AppraisalInfoModal';
import FlatTasksHeader from './FlatTasksHeader';
import SelectedTotals from './SelectedTotals';
import TasksContainer from './TasksContainer';
import TaskTableHeader from './TaskTableHeader';
import { buildSortedTasks } from './taskUtils';
import Totals from './Totals';

import './FlatTasks.scss';

interface FlatTasksProps {
  vehicle: VehicleSummary;
  pinned?: boolean;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

const FlatTasks: React.FC<FlatTasksProps> = ({
  vehicle,
  pinned = false,
  openPinnedComponent,
}) => {
  const navigate = useNavigate();
  const { isVendor } = useVendor();

  const createInvoiceMutation = useCreateInvoiceMutation();
  const {
    data: vehicleTasksData,
    isLoading: isVehicleTasksLoading,
    isError: isVehicleTasksError,
  } = useVehicleTasks(vehicle?.vehicleCard?.id ?? '');
  const { data: tasks = [], meta } = vehicleTasksData ?? {};
  const {
    mutate: updateVehicleTasksStatus,
    isLoading: isVehicleTasksStatusLoading,
    isError: isVehicleTasksStatusError,
  } = useUpdateVehicleTasksStatus(vehicle?.vehicleCard?.id);

  const sortedTasks = useMemo(() => buildSortedTasks(tasks), [tasks]);
  const queryClient = useQueryClient();
  const windowSize = useWindowSize();
  const isMobile = windowSize.isMobileViewport();

  const [showAppraisalInfoModal, setShowAppraisalInfoModal] = useState(false);
  const [printPressed, setPrintPressed] = useState(false);
  const [apiError, setApiError] = useState('');
  const [selectedIds, setSelectedIds] = useState([] as string[]);

  // Check if labor and parts are required
  const { data } = useToken();
  const requireLaborAndParts =
    data?.location.settings.ENABLE_REQUIRE_LABOR_AND_PARTS_ON_TASKS ?? '0';

  const handlePrint = useCallback(() => {
    setPrintPressed(true);
  }, []);

  const handleAfterPrint = useCallback(() => {
    setPrintPressed(false);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeprint', handlePrint);
    return () => {
      window.removeEventListener('beforeprint', handlePrint);
    };
  }, [handlePrint]);

  useEffect(() => {
    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [handleAfterPrint]);

  const handleDeleteTask = useCallback(
    (task: Task) => {
      const key = `/inventory/${vehicle?.vehicleCard?.id}/tasks`;
      queryClient.setQueryData(key, (data: any) => {
        if (data?.data) {
          const tasks = data.data.filter((x: any) => !!x.id);
          data.data = tasks;
        }
        return data;
      });
    },
    [queryClient, vehicle?.vehicleCard?.id]
  );

  const handleUpdateVehicleTasksStatus = useCallback(
    (tasks: Task[], status: TaskStatus) => {
      if (tasks.length) {
        updateVehicleTasksStatus({
          taskIds: tasks.map((task) => task.id ?? ''),
          status,
        });
        setSelectedIds([]);
      }
    },
    [updateVehicleTasksStatus]
  );

  const handleTaskCheckboxClick = useCallback(
    (task: Task) => {
      const taskIsSelected = selectedIds.includes(task.id ?? '');
      if (taskIsSelected) {
        setSelectedIds((ids) => ids.filter((id) => id !== task.id));
      } else {
        setSelectedIds((ids) => [...ids, task.id ?? '']);
      }
    },
    [selectedIds]
  );

  const getSelectedTasks = useCallback(() => {
    const selectedTasks: Task[] = [];
    selectedIds.forEach((id) => {
      const task = tasks.find((task) => task.id === id);
      if (task) {
        selectedTasks.push(task);
      }
    });
    return selectedTasks;
  }, [selectedIds, tasks]);

  const processInputError = useCallback(
    () => setApiError(strings.LABOR_AND_PARTS_REQUIRED),
    []
  );

  const isLaborOrPartsEmpty = (task: Task) =>
    !task.partsPrice?.amount || !task.laborPrice?.amount;

  const handelCreateInvoice = useCallback(async () => {
    // create mutation here
    try {
      const { data: invoice } = await createInvoiceMutation.mutateAsync({
        inventoryId: vehicle?.vehicleCard?.id!,
        taskIds: selectedIds,
      });

      if (invoice) {
        navigate?.(`../../${invoice.id}/send`, {
          state: {
            invoice,
            inventoryId: vehicle?.vehicleCard?.id!,
          },
        });
      }
    } catch (error) {
      setApiError(strings.API_MESSAGE);
    }
  }, [createInvoiceMutation, navigate, selectedIds, vehicle?.vehicleCard?.id]);

  const handleUpdateTasksStatus = useCallback(
    (status: TaskStatus) => {
      const selectedTasks = getSelectedTasks();
      if (selectedTasks.length) {
        // Labor and Parts validation
        if (
          requireLaborAndParts === '1' &&
          selectedTasks.some((task) => isLaborOrPartsEmpty(task)) &&
          (status === 'PENDING_APPROVAL' ||
            status === 'APPROVED_PENDING_COMPLETE')
        ) {
          processInputError();
        } else {
          handleUpdateVehicleTasksStatus(selectedTasks, status);
        }
      }
    },
    [
      getSelectedTasks,
      handleUpdateVehicleTasksStatus,
      processInputError,
      requireLaborAndParts,
    ]
  );

  const handleApproveTaskClick = useCallback(
    (task: Task) => {
      if (
        task.id &&
        requireLaborAndParts === '1' &&
        isLaborOrPartsEmpty(task)
      ) {
        processInputError();
      } else if (task.id) {
        updateVehicleTasksStatus({
          taskIds: [task.id],
          status: 'APPROVED_PENDING_COMPLETE',
        });
      }
    },
    [updateVehicleTasksStatus, requireLaborAndParts, processInputError]
  );
  const handleApproveAllTasksClick = useCallback(
    (status: TaskStatus) => {
      if (
        requireLaborAndParts === '1' &&
        tasks
          .filter((task) => task.status === status)
          .filter((task) => isLaborOrPartsEmpty(task))
          .map((task) => task.id ?? '').length > 0
      ) {
        processInputError();
      } else {
        updateVehicleTasksStatus({
          taskIds: tasks
            .filter((task) => task.status === status)
            .map((task) => task.id ?? ''),
          status: 'APPROVED_PENDING_COMPLETE',
        });
      }
    },
    [tasks, updateVehicleTasksStatus, processInputError, requireLaborAndParts]
  );

  const allChecked = useMemo(
    () => selectedIds.length === sortedTasks.length,
    [selectedIds.length, sortedTasks.length]
  );

  const handleCheckAllTasks = useCallback(() => {
    if (allChecked) {
      setSelectedIds([]);
    } else {
      setSelectedIds(sortedTasks.map((task) => task.id ?? ''));
    }
  }, [allChecked, sortedTasks]);

  useEffect(() => {
    if (printPressed) {
      try {
        window.print();
      } catch (error) {
      } finally {
        window.onafterprint = handleAfterPrint;
      }
    }
  }, [handleAfterPrint, printPressed]);

  useEffect(() => {
    if (isVehicleTasksStatusError) {
      setApiError('Error updating Vehicle Task');
    }
    if (isVehicleTasksError) {
      setApiError('Error fetching Vehicle Tasks');
    }
  }, [isVehicleTasksStatusError, isVehicleTasksError]);

  const buttons = [
    <AddButton key="addTask" vehicleId={vehicle?.vehicleCard?.id ?? ''} />,
    <RequestApprovalButton
      key="requestTaskApproval"
      selectedIds={selectedIds}
      tasks={sortedTasks}
      loading={isVehicleTasksStatusLoading}
      onClick={handleUpdateTasksStatus}
    />,
    <ApproveButton
      key="approveTask"
      selectedIds={selectedIds}
      tasks={sortedTasks}
      loading={isVehicleTasksStatusLoading}
      onClick={handleUpdateTasksStatus}
    />,
    <DenyButton
      key="denyTask"
      selectedIds={selectedIds}
      tasks={sortedTasks}
      loading={isVehicleTasksStatusLoading}
      onClick={handleUpdateTasksStatus}
    />,
    <CompleteButton
      key="completeTask"
      selectedIds={selectedIds}
      tasks={sortedTasks}
      loading={isVehicleTasksStatusLoading}
      onClick={handleUpdateTasksStatus}
    />,
    <ViewAppraisalButton
      key="viewAppraisal"
      onClick={() => setShowAppraisalInfoModal(true)}
    />,
  ];

  return (
    <div className={`FlatTasks ${pinned ? 'FlatTasks-pinned' : ''}`}>
      {!pinned && (
        <FlatTasksHeader
          title={strings.TASKS}
          taskButtons={buttons}
          actionButtons={[
            windowSize.isVdpLargeViewport() && (
              <IconButton
                key="pinnedComponentIcon"
                iconComponent={<Pin size={30} color="rgba(38, 50, 56, 0.6)" />}
                onClick={() => openPinnedComponent?.('tasks')}
              />
            ),
            <IconButton
              key="printComponentIcon"
              iconComponent={<Print />}
              onClick={handlePrint}
            />,
          ]}
        />
      )}
      {isVehicleTasksLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <TaskTableHeader
            pinned={pinned}
            checked={allChecked}
            onClick={handleCheckAllTasks}
          />
          <div className="FlatTasks-tasks" id="FlatTasks-tasks">
            <TasksContainer
              pinned={pinned}
              selectedIds={selectedIds}
              tasks={sortedTasks}
              vehicle={vehicle}
              onDeleteTask={handleDeleteTask}
              onTaskCheckboxClick={handleTaskCheckboxClick}
              onApproveClick={handleApproveTaskClick}
              onApproveAllClick={handleApproveAllTasksClick}
              printPressed={printPressed}
            />
          </div>
          <div className="FlatTasks-footer">
            <div className="FlatTasks-footer-create-invoice">
              {isVendor && (
                <CreateInvoice
                  key="createInvoice"
                  selectedIds={selectedIds}
                  tasks={sortedTasks}
                  onClick={handelCreateInvoice}
                  disabled={
                    createInvoiceMutation.isLoading ||
                    createInvoiceMutation.isSuccess
                  }
                />
              )}
            </div>
            <div className="FlatTasks-footer-totals">
              <SelectedTotals
                totalTaskCount={tasks?.length}
                selectedTasks={getSelectedTasks()}
                pinned={pinned}
              />
              <Totals
                pinned={pinned}
                partsPrice={meta?.overallTaskTotal?.partsTotal ?? 0}
                laborPrice={meta?.overallTaskTotal?.laborTotal ?? 0}
                totalPrice={meta?.overallTaskTotal?.total ?? 0}
                totalHours={meta?.overallTaskTotal?.hoursTotal ?? 0}
              />
              {(isMobile || pinned) && (
                <div
                  className={`FlatTasks-buttons ${
                    pinned ? 'FlatTasks-buttons-pinned' : ''
                  }`}
                >
                  {buttons}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <Alert
        open={!!apiError}
        duration={3000}
        handleClose={() => setApiError('')}
        contentProps={{
          variant: 'error',
          message: apiError,
          onClose: () => setApiError(''),
        }}
      />
      <AppraisalInfoModal
        open={showAppraisalInfoModal}
        onClose={() => setShowAppraisalInfoModal(false)}
        vehicle={vehicle}
      />
    </div>
  );
};

export default FlatTasks;
