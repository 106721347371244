import 'common/DateRange';
import { isUndefined } from 'lodash';

import { permissions } from 'common';
import strings from 'common/strings';
import SummaryCard from 'components/shared/SummaryCard';
import { usePermissions } from 'hooks';
import {
  DealerStepReportPayload,
  DealerStepReportSummaryItem,
  SummaryItem,
} from 'models';
import { currencyFormatter } from 'utils/formatter';

import {
  compareAverageOverage,
  compareGoalValue,
  compareTotalOverage,
  ratings,
} from '../ReportsState';

export const GridOverview = ({
  dealerStepReport,
  isLoading,
  openStepModal,
}: {
  dealerStepReport?: DealerStepReportPayload;
  isLoading: Boolean;
  openStepModal: (step?: DealerStepReportSummaryItem) => void;
}) => {
  const { hasPermission } = usePermissions();
  const userHasReconInfoPermission = hasPermission(
    permissions.INVENTORY_VDP_RECON_INFO_VIEW
  );

  if (!dealerStepReport) {
    return <></>;
  }

  let overviewItems: SummaryItem[] = [];
  const averageOverageAmount = dealerStepReport?.averageOverage?.amount;
  const totalOverage = dealerStepReport.totalOverage;

  const averageOverageString = [
    compareAverageOverage(totalOverage) === ratings.GOOD ? '' : '+',
    averageOverageAmount ? currencyFormatter(averageOverageAmount) : '',
  ].join(' ');

  const averageOverageClassName = userHasReconInfoPermission
    ? `DealerStepReport-quadrant-${compareAverageOverage(totalOverage)}`
    : '';

  const totalOverageString = [
    `${compareAverageOverage(totalOverage) === ratings.GOOD ? '' : '+'}`,
    `${totalOverage?.amount && currencyFormatter(totalOverage.amount)}`,
  ].join(' ');

  const totalOverageClassName = userHasReconInfoPermission
    ? `DealerStepReport-quadrant-${compareTotalOverage(totalOverage)}`
    : '';

  if (!isUndefined(dealerStepReport)) {
    overviewItems = [
      {
        itemType: 'VehiclesCompleted',
        statValue: dealerStepReport.completedVehicles,
        statLabel: String(strings.VEHICLES_COMPLETED),
        unit: 'Items',
      },
      {
        itemType: 'RetailReadyTime',
        statValue: dealerStepReport.averageRetailReadyAsSeconds,
        statLabel: String(strings.AVERAGE_RETAIL_READY_TIME),
        unit: 'Seconds',
        goalValue: dealerStepReport.averageRetailReadyGoalAsSeconds,
        goalLabel: strings.GOAL,
      },
      {
        itemType: 'AverageReconTime',
        statValue: dealerStepReport.averageReconTimeAsSeconds,
        statLabel: String(strings.AVERAGE_RECON_TIME),
        unit: 'Seconds',
        goalValue: dealerStepReport.averageReconTimeGoalAsSeconds,
        goalLabel: strings.GOAL,
      },
    ];
  }

  return (
    <div className="DealerStepReport-Overview">
      <div className="DealerStepReport-title">{strings.OVERVIEW}</div>
      <div className="DealerStepReport-Overview-Items">
        {overviewItems.map((summaryItem: SummaryItem, index) => (
          <div
            key={`summary-card-key-${index}`}
            className="DealerStepReport-SummaryCard-container"
            id={`step-report-${summaryItem?.itemType}-container`}
          >
            <SummaryCard
              summaryItem={summaryItem}
              setStyle={compareGoalValue(summaryItem)}
              onStatClick={
                summaryItem.itemType === 'VehiclesCompleted' &&
                summaryItem.statValue !== 0 &&
                !isLoading
                  ? () => openStepModal()
                  : undefined
              }
              statSelectable={
                summaryItem.itemType === 'VehiclesCompleted' &&
                summaryItem.statValue !== 0
              }
            />
          </div>
        ))}
        <div className="DealerStepReport-SummaryCard-container">
          <div className="DealerStepReport-QuadrantCard">
            <div className="DealerStepReport-Quadrant border-right border-bottom">
              <h4>
                {!dealerStepReport?.averageEstimatedRecon?.amount ||
                !userHasReconInfoPermission
                  ? strings.EMPTY_VALUE
                  : currencyFormatter(
                      dealerStepReport.averageEstimatedRecon.amount
                    )}
              </h4>
              {strings.AVERAGE_ESTIMATED}
            </div>
            <div className="DealerStepReport-Quadrant border-bottom">
              <h4>
                {!dealerStepReport?.averageActualRecon?.amount ||
                !userHasReconInfoPermission
                  ? strings.EMPTY_VALUE
                  : currencyFormatter(
                      dealerStepReport.averageActualRecon?.amount
                    )}
              </h4>
              {strings.AVERAGE_ACTUAL}
            </div>
            <div className="DealerStepReport-Quadrant border-right">
              <h4 className={averageOverageClassName}>
                {!dealerStepReport?.averageOverage?.amount ||
                !userHasReconInfoPermission
                  ? strings.EMPTY_VALUE
                  : averageOverageString}
              </h4>
              {strings.AVERAGE_OVERAGE}
            </div>
            <div className="DealerStepReport-Quadrant">
              <h4 className={totalOverageClassName}>
                {!dealerStepReport?.totalOverage?.amount ||
                !userHasReconInfoPermission
                  ? strings.EMPTY_VALUE
                  : totalOverageString}
              </h4>
              {strings.TOTAL_OVERAGE}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
