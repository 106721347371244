import { useQuery } from 'react-query';

import { configuredFetch, getAuthTenantId, HttpMethod } from 'api';
import { UIFilter } from 'models';
import {
  getExtensionModeInfo,
  sendMessageToExtensionAsync,
} from 'utils/extension';

import { logoutActions } from './authenticate';
import { getCredentialValue } from './lib/credentialsPersistence';

export interface SortItem {
  id: string;
  label: string;
}

export interface APIResponse<T> {
  data: T;
  meta?: {
    returnedRecords: number;
    totalRecords: number;
    sortOptions?: SortItem[];
    filters?: UIFilter[];
    show_onboarding_banner?: boolean;
    total?: number;
    warning?: number;
    danger?: number;
    unread?: number;
  };
  links?: any;
  errors?: any;
}
// This function will be call anytime useQuery is used
type DefaultQueryFnParams = { queryKey: string[] };
export async function defaultQueryFn({ queryKey }: DefaultQueryFnParams) {
  const url = Array.isArray(queryKey) ? queryKey.join('/') : queryKey;
  if (getExtensionModeInfo().features['extension_fetch']) {
    const response = await sendMessageToExtensionAsync({
      action: 'fetch',
      url,
      headers: {
        'auth-tenant-id': `${getAuthTenantId()}`,
      },
    });
    return response;
  } else {
    return configuredFetch(url).then((res) => res.json());
  }
}

export async function defaultMetaQueryFn(url: string) {
  return configuredFetch(url).then((res) => res.json());
}

export async function defaultMutationFn(
  url: string,
  method: HttpMethod,
  data?: any
) {
  if (getExtensionModeInfo().features['extension_fetch']) {
    const response = await sendMessageToExtensionAsync({
      action: 'fetch',
      url,
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        'auth-tenant-id': `${getAuthTenantId()}`,
      },
    });
    return response;
  } else {
    return configuredFetch(url, {
      method,
      body: data ? JSON.stringify(data) : undefined,
    }).then((res) => res.json());
  }
}

export async function defaultTextPlainMutationFn(
  url: string,
  method: HttpMethod,
  data: any = {}
) {
  if (getExtensionModeInfo().features['extension_fetch_plain_text']) {
    const response = await sendMessageToExtensionAsync({
      action: 'fetch',
      url,
      method,
      body: data,
      headers: {
        'content-type': 'text/plain',
        'auth-tenant-id': `${getAuthTenantId()}`,
      },
    });
    return response;
  } else {
    const response = await configuredFetch(url, {
      method,
      body: data,
      headers: {
        'content-type': 'text/plain',
      },
    });
    return response.json();
  }
}

export const reactQueryRetry = (failureCount: number, error: any) => {
  const url = window?.location?.pathname;
  const authBearerToken = getCredentialValue('authBearerToken');
  if (!authBearerToken) {
    if (url !== '/') {
      logoutActions();
    }
    return false;
  }

  const isNotFound = error?.message?.search(/404/g) >= 0;
  if (isNotFound) {
    return false;
  }

  const isNoToken = error?.message?.search(/No Auth Token/g) >= 0;
  if (isNoToken) {
    logoutActions();
    return false;
  }

  const isUnauthorized = error?.message?.search(/401/g) >= 0;
  if (failureCount === 3) {
    if (isUnauthorized) {
      logoutActions();
    }
    return false;
  }

  return true;
};

async function downloadFile(url: string, fileName: string) {
  const response = await configuredFetch(url);
  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const anchorTag = document.createElement('a');
    document.body.appendChild(anchorTag);
    anchorTag.href = url;
    anchorTag.download = fileName;
    anchorTag.target = '_blank';
    anchorTag.click();
    anchorTag.remove();
    window.URL.revokeObjectURL(url);
  }
}

export function useDownload({
  enabled,
  fileName,
  url,
}: {
  url: string;
  fileName: string;
  enabled: boolean;
}) {
  const { isLoading } = useQuery(
    url,
    async () => {
      await downloadFile(url, fileName);
    },
    {
      staleTime: 0, // Allows the user to download file every time enabled turns true
      enabled,
    }
  );
  return { isLoading };
}
