import { useContext } from 'react';

import { VehicleCardConfigProp, VehicleCardContext } from './VehicleCard';
import VehicleCardDesktopV2 from './VehicleCardDesktopV2';
import VehicleCardShowroomDesktop from './VehicleCardShowroomDesktop';
import VehicleCardVendorDesktop from './VehicleCardVendorDesktop';

interface VehicleCardDesktopProps {
  config: VehicleCardConfigProp;
}

const VehicleCardDesktop = ({ config }: VehicleCardDesktopProps) => {
  const { isShowroomMode, isVendorMode } = useContext(VehicleCardContext);

  if (isVendorMode) {
    return <VehicleCardVendorDesktop />;
  }

  if (isShowroomMode) {
    return <VehicleCardShowroomDesktop config={config} />;
  }
  return <VehicleCardDesktopV2 />;
};

export default VehicleCardDesktop;
