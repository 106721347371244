import DirectionsCar from '@material-ui/icons/DirectionsCar';
import React from 'react';

import { iconColor, icons } from '../notificationUtils';

import './NotificationIcon.scss';

interface NotificationIconProps {
  type: keyof typeof icons;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({ type }) => {
  const Icon = icons[type] || DirectionsCar;
  return (
    <div className="NotificationIcon">
      <Icon style={{ color: iconColor }} />
    </div>
  );
};

export default NotificationIcon;
