/* eslint-disable max-len */
import React from 'react';

interface IconContainerProps {
  className?: string;
}

export const IconContainer: React.FC<IconContainerProps> = ({
  children,
  className,
}) => (
  <div className={`IconContainer ${className}`}>
    <div className="IconContainer-inner-container">{children}</div>
  </div>
);

export var VideoIcon = () => {
  return (
    <IconContainer className="IconContainer-video">
      <svg
        aria-hidden="true"
        focusable="false"
        className="uppy-c-icon"
        width="25"
        height="25"
        viewBox="0 0 25 25"
      >
        <path
          d="M16 11.834l4.486-2.691A1 1 0 0 1 22 10v6a1 1 0 0 1-1.514.857L16 14.167V17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2.834zM15 9H5v8h10V9zm1 4l5 3v-6l-5 3z"
          fill="#19AF67"
          fillRule="nonzero"
        />
      </svg>
    </IconContainer>
  );
};

export var ImageIcon = () => {
  return (
    <IconContainer className="IconContainer-image">
      <svg
        aria-hidden="true"
        focusable="false"
        width="25"
        height="25"
        viewBox="0 0 25 25"
      >
        <g fill="#686DE0" fillRule="evenodd">
          <path
            d="M5 7v10h15V7H5zm0-1h15a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1z"
            fillRule="nonzero"
          />
          <path
            d="M6.35 17.172l4.994-5.026a.5.5 0 0 1 .707 0l2.16 2.16 3.505-3.505a.5.5 0 0 1 .707 0l2.336 2.31-.707.72-1.983-1.97-3.505 3.505a.5.5 0 0 1-.707 0l-2.16-2.159-3.938 3.939-1.409.026z"
            fillRule="nonzero"
          />
          <circle cx="7.5" cy="9.5" r="1.5" />
        </g>
      </svg>
    </IconContainer>
  );
};

export var AttachmentIcon = () => {
  return (
    <IconContainer className="IconContainer-attachment">
      <svg
        aria-hidden="true"
        focusable="false"
        className="uppy-c-icon"
        width="25"
        height="25"
        viewBox="0 0 25 25"
      >
        <path
          d="M9.766 8.295c-.691-1.843-.539-3.401.747-3.726 1.643-.414 2.505.938 2.39 3.299-.039.79-.194 1.662-.537 3.148.324.49.66.967 1.055 1.51.17.231.382.488.629.757 1.866-.128 3.653.114 4.918.655 1.487.635 2.192 1.685 1.614 2.84-.566 1.133-1.839 1.084-3.416.249-1.141-.604-2.457-1.634-3.51-2.707a13.467 13.467 0 0 0-2.238.426c-1.392 4.051-4.534 6.453-5.707 4.572-.986-1.58 1.38-4.206 4.914-5.375.097-.322.185-.656.264-1.001.08-.353.306-1.31.407-1.737-.678-1.059-1.2-2.031-1.53-2.91zm2.098 4.87c-.033.144-.068.287-.104.427l.033-.01-.012.038a14.065 14.065 0 0 1 1.02-.197l-.032-.033.052-.004a7.902 7.902 0 0 1-.208-.271c-.197-.27-.38-.526-.555-.775l-.006.028-.002-.003c-.076.323-.148.632-.186.8zm5.77 2.978c1.143.605 1.832.632 2.054.187.26-.519-.087-1.034-1.113-1.473-.911-.39-2.175-.608-3.55-.608.845.766 1.787 1.459 2.609 1.894zM6.559 18.789c.14.223.693.16 1.425-.413.827-.648 1.61-1.747 2.208-3.206-2.563 1.064-4.102 2.867-3.633 3.62zm5.345-10.97c.088-1.793-.351-2.48-1.146-2.28-.473.119-.564 1.05-.056 2.405.213.566.52 1.188.908 1.859.18-.858.268-1.453.294-1.984z"
          fill="#E2514A"
          fillRule="nonzero"
        />
      </svg>
    </IconContainer>
  );
};
