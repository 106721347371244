/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const Pulse: React.FC<IconProps> = ({ color = defaultColor, className }) => (
  <svg
    width={20}
    height={19}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M0 8.24534H2.79L7.1 -3.83112e-08L8.28 8.99857L11.5 4.89096L14.83 8.24534H18V10.2539H14L11.67 7.91392L6.92 14L5.94 6.54806L4 10.2539H0V8.24534Z"
      fill={color}
    />
  </svg>
);
export default Pulse;
