import CheckIcon from '@material-ui/icons/Check';
import { useEffect, useReducer, useState } from 'react';
import { Card } from 'react-bootstrap';

import {
  useFolioTemplateTypes,
  useVelocityEngageModuleReport,
  useVelocityEngageModuleReportDownload,
} from 'api/reports/useVelocityEngageModuleReport';
import EngageItemVehicle from 'components/pages/Dashboard/EngageTab/EngageItemVehicle';
import ToolbarDropdown from 'components/pages/Dashboard/EngageTab/EngagementsTable/ToolbarDropdown';
import ToolbarSpacer from 'components/pages/Dashboard/EngageTab/EngagementsTable/ToolbarSpacer';
import ApiError from 'components/shared/ApiError';
import DownloadMenu from 'components/shared/DownloadMenu';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { SmartPagination } from 'components/shared/Pagination';
import { StickyReportHeader } from 'components/shared/StickyReportHeader';
import { TabProps } from 'components/shared/Tabs/Tabs';
import Tag from 'components/shared/TagList/Tag';

import ReportRow from '../ReportRow';

import './VelocityEngageModuleReport.scss';

const conditionOptions = [
  {
    value: 'Used inventory',
    id: 'U',
  },
  {
    value: 'New inventory',
    id: 'N',
  },
  {
    value: 'CPO inventory',
    id: 'C',
  },
];

type Condition = 'U' | 'N' | 'C';

interface PaginationState {
  page: number;
  pageSize: number;
  condition: Condition;
}

type PaginationAction =
  | {
      setPage: number;
    }
  | {
      setPageSize: number;
    }
  | {
      setCondition: Condition;
    };
function reducer(
  state: PaginationState,
  action: PaginationAction
): PaginationState {
  if ('setPage' in action) {
    return { ...state, page: action.setPage };
  }
  if ('setPageSize' in action) {
    return { ...state, pageSize: action.setPageSize };
  }
  if ('setCondition' in action) {
    return { ...state, page: 0, condition: action.setCondition };
  }
  return state;
}

const ModuleReportDownloadMenu = ({ condition }: { condition: string }) => {
  const [enabled, setEnabled] = useState(false);
  const { isLoading } = useVelocityEngageModuleReportDownload({
    condition,
    enabled,
  });
  useEffect(() => {
    if (!isLoading) {
      setEnabled(false);
    }
  }, [isLoading]);
  return (
    <DownloadMenu
      onSelectedMenuType={(type) => {
        setEnabled(type !== null);
      }}
      isLoading={isLoading || enabled}
      formats={['CSV']}
    />
  );
};

const VelocityEngageModuleReport: React.FC<TabProps> = ({ children }) => {
  const [state, reduce] = useReducer(reducer, {
    page: 0,
    pageSize: 20,
    condition: 'U',
  });
  const {
    data: reportData,
    isLoading: loadingReportData,
    error: reportDataError,
  } = useVelocityEngageModuleReport(state);
  const {
    data: templateTypesData,
    isLoading: loadingModuleDetails,
    error: moduleDetailsError,
  } = useFolioTemplateTypes(state.condition);
  return (
    <Card
      style={{ margin: '24px 0', boxShadow: '3px 3px 5px #e6e7e8' }}
      className="VelocityEngageModuleReport"
    >
      <StickyReportHeader>
        <ToolbarSpacer
          title={
            reportData
              ? `${reportData.data.totalElements} vehicles`
              : '... vehicles'
          }
        >
          <ToolbarDropdown
            options={conditionOptions}
            onSelect={(newCondition) => {
              if (
                newCondition === 'U' ||
                newCondition === 'C' ||
                newCondition === 'N'
              ) {
                reduce({ setCondition: newCondition });
              }
            }}
            searchOptionPlaceholder="Condition"
            selectedOption={
              conditionOptions.find(
                (option) => state.condition === option.id
              ) || conditionOptions[0]
            }
          />
          <div className="download-menu-container">
            <ModuleReportDownloadMenu condition={state.condition} />
          </div>
        </ToolbarSpacer>
        {reportData && templateTypesData && (
          <div className="col-12 px-0">
            <div className="ReportTitleRow report-flex-columns full-width">
              <div className="w-100 evenly-spaced-container">
                <ReportHeaderColumn label="VEHICLE" data-name="Vehicle" />
                <ReportHeaderColumn label="Age (DAYS)" data-name="age" />
                <ReportHeaderColumn label="Status" data-name="stockStatus" />
                {templateTypesData.data.map(({ label }) => (
                  <ReportHeaderColumn label={label} />
                ))}
              </div>
            </div>
          </div>
        )}
      </StickyReportHeader>
      {reportData && templateTypesData ? (
        <div className="col-12 px-0">
          {reportData.data.content.map((entry) => {
            const { stockNumber, make, model, year, vin, condition } = entry;
            return (
              <ReportRow
                withPadding
                columns={[
                  {
                    cellName: 'Vehicle',
                    columnClass: 'text-left',
                    contentClass: 'ReportCellContent',
                    contents: (
                      <EngageItemVehicle>
                        <div className="item-eng-non-anchor-link">
                          <div>
                            <span className="yearMakeModel">
                              {condition === 'C' && (
                                <div style={{ display: 'inline-flex' }}>
                                  <Tag
                                    tag={{
                                      value: 'CPO',
                                      hexColor: '#26323899',
                                    }}
                                  />
                                </div>
                              )}{' '}
                              {year} {make} {model}
                            </span>
                          </div>
                          <div className="stockNumber">{stockNumber}</div>
                          {vin && <div className="vin">{vin}</div>}
                        </div>
                      </EngageItemVehicle>
                    ),
                  },
                  {
                    cellName: 'age',
                    contentClass: 'ReportCellContent',
                    contents: Math.floor(
                      (new Date().getTime() -
                        new Date(entry.startDate).getTime()) /
                        (1000 * 60 * 60 * 24)
                    ),
                  },
                  {
                    cellName: 'stockStatus',
                    contentClass: 'ReportCellContent',
                    contents: entry.dmsStockStatus,
                  },
                  ...templateTypesData.data.map(({ id }) => {
                    return {
                      contents:
                        entry.presentDocuments &&
                        entry.presentDocuments.includes(id) ? (
                          <CheckIcon />
                        ) : (
                          <span>--</span>
                        ),
                    };
                  }),
                ]}
              />
            );
          })}
        </div>
      ) : loadingModuleDetails || loadingReportData ? (
        <div style={{ height: '200px' }}>
          <LoadingIndicator />
        </div>
      ) : (
        (reportDataError || moduleDetailsError) && (
          <ApiError error={reportDataError || moduleDetailsError} />
        )
      )}
      <div className="footer">
        <SmartPagination
          onPageChange={(setPage) => reduce({ setPage })}
          onPageSizeChange={(setPageSize) => reduce({ setPageSize })}
          page={state.page}
          pageSize={state.pageSize}
          totalItems={reportData?.data.totalElements ?? null}
        />
      </div>
    </Card>
  );
};

const ReportHeaderColumn = ({
  label,
  ...props
}: {
  label: string;
  [x: string]: any;
}) => {
  return (
    <div className="evenly-spaced-item" {...props}>
      <span>{label}</span>
    </div>
  );
};

export default VelocityEngageModuleReport;
