import { GeoLocation, User } from 'models';

export enum LocationType {
  ROOFTOP = 'ROOFTOP',
  GROUP = 'GROUP',
  CORPORATE_GROUP = 'CORPORATE_GROUP',
  VENDOR = 'VENDOR',
}

type Settings = {
  [key: string]: string;
};

// A "Location" is a tenant. In an earlier version of the ReconVelocity app,
// tenants were called locations, and the auth API still uses that name.
export interface Location {
  id: string;
  name: string;
  type: LocationType;
  settings: Settings;
  zipCode: string;
  permissions?: string[];
  featureFlags?: string[];
}

export default interface InventoryLocation {
  location: GeoLocation;
  lastSeenBy: User;
  timestamp: string;
  isRealTime: boolean;
}
