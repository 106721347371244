import Delete from '@material-ui/icons/Delete';
import { isNil, isUndefined } from 'lodash';
import React, { useState } from 'react';

import { useUpdateVehicleImage, useVehicleImageShots } from 'api';
import { isImage } from 'common/images';
import permissions from 'common/permissions';
import strings from 'common/strings';
import Alert from 'components/shared/Alert';
import { Upload } from 'components/shared/icons';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PermissionsGate from 'components/shared/PermissionsGate';
import { TabProps } from 'components/shared/Tabs/Tabs';
import UploadPlaceholder from 'components/shared/UploadPlaceholder';
import { usePermissions } from 'hooks';
import {
  VehicleImage,
  VehicleImageShot,
  VehicleImageShots,
  VehicleSummary,
} from 'models';
import { UploadItem, UploadType } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';
import { replaceShots } from 'utils/photos';

import SyndicationModal from '../SyndicationModal';

import './Syndication.scss';

interface ISyndication extends TabProps {
  vehicle: VehicleSummary;
  images: VehicleImage[];
  reload: () => void;
}

export interface IShot extends VehicleImageShot {
  url?: string;
  image?: VehicleImage;
}

const Syndication: React.FC<ISyndication> = ({ vehicle, images, reload }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedAngle, setSelectedAngle] = useState<IShot | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(strings.API_MESSAGE);
  const uploadStoreData = useUploadStore((uploadStore) => ({
    uploads: uploadStore.uploads,
    uploadFiles: uploadStore.uploadFiles,
  }));
  const { data } = useVehicleImageShots();
  const { mutateAsync: updateVehicleImage } = useUpdateVehicleImage();
  const { hasPermission } = usePermissions();

  const vehicleImages = images.filter((mediaItem: VehicleImage) =>
    isImage(mediaItem.uri)
  );

  const activeUploads = uploadStoreData.uploads.filter(
    (upload: UploadItem) =>
      upload.meta.parentId === vehicle?.vehicleCard?.id &&
      upload.meta.target.type === UploadType.MEDIA &&
      !isUndefined(upload.meta.target.shot)
  );

  const unassignedImages: VehicleImage[] = vehicleImages.filter(
    (mediaItem: VehicleImage) =>
      mediaItem.shot === strings.ANGLE_UNKNOWN &&
      mediaItem.imageType === strings.DEALER_IMAGE_TYPE
  );

  const closeAlert = () => {
    setShowErrorAlert(false);
    setErrorMessage(strings.API_MESSAGE);
  };

  const closeModal = () => {
    setOpenModal(false);
    reload();
  };

  const uploadAndAssignShotImage = async (file: File, shot: IShot) => {
    setIsSubmitting(true);
    closeModal();
    const onUploadSuccess = async () => {
      reload();
      setSelectedAngle(null);
      setIsSubmitting(false);
      setOpenModal(false);
    };

    const onUploadCancel = async () => {
      setSelectedAngle(null);
      setIsSubmitting(false);
      setOpenModal(false);
      closeModal();
    };

    const onUploadError = (message?: string) => {
      if (message) {
        setErrorMessage(message);
      }
      setShowErrorAlert(true);
      setOpenModal(false);
    };

    const uploadTarget = {
      type: UploadType.MEDIA,
      shot: shot.value,
    };

    uploadStoreData.uploadFiles(
      [file],
      vehicle?.vehicleCard?.id!,
      uploadTarget,
      onUploadSuccess,
      onUploadError,
      onUploadCancel
    );
  };

  const assignExistingShotImage = async (image: VehicleImage, shot: IShot) => {
    setIsSubmitting(true);
    if (!shot.image) {
      await updateVehicleImage({
        vehicleId: vehicle?.vehicleCard?.id!,
        imageId: image.id!,
        shot: shot.value,
      });
    } else {
      await replaceShots(
        shot.image,
        image,
        vehicle?.vehicleCard?.id ?? '',
        updateVehicleImage
      );
    }
    reload();
    setSelectedAngle(null);
    setIsSubmitting(false);
  };

  const prepareDataForAngles = () => {
    const vehicleMediaShotsDict = vehicleImages
      .slice()
      .reduce((acc: Record<string, VehicleImage>, image: VehicleImage) => {
        acc[image.shot ?? ''] = image;
        return acc;
      }, {});

    const shots = data?.map((item: VehicleImageShot) => {
      if (vehicleMediaShotsDict[item.value]) {
        return {
          ...item,
          url: vehicleMediaShotsDict[item.value].uri,
          image: vehicleMediaShotsDict[item.value],
        };
      }
      return item;
    });

    return shots ?? [];
  };

  const onPhotoAngleClicked = (angle: IShot) => {
    if (hasPermission(permissions.INVENTORY_VDP_PHOTOS_CREATE)) {
      setSelectedAngle(angle);
      setOpenModal(true);
    }
  };

  const renderAngleRow = (shot: IShot, index: number, isLast?: boolean) => {
    const { value, url, name, image } = shot;

    if (value !== strings.ANGLE_UNKNOWN) {
      const isUnassigned = value === 'Unassigned';
      const isUnset = isUnassigned || !url;
      const isUploadingShot = isSubmitting && value === selectedAngle?.value;
      const activeUpload = activeUploads.find(
        (upload: UploadItem) => upload.meta.target.shot === value
      );

      const unAssignImage = () => {
        if (isNil(image)) {
          throw new Error('Must provide image');
        }
        updateVehicleImage({
          vehicleId: vehicle?.vehicleCard?.id!,
          imageId: image.id!,
          shot: VehicleImageShots.UNKNOWN,
        });
      };

      return (
        <div
          key={`angle-row-${image?.name}-${index}`}
          className="Syndication-row flex-rows full-width"
        >
          <div className="flex-columns full-width justify-content-between align-items-center padding">
            <div
              role="none"
              tabIndex={-1}
              className="flex-columns align-items-center Syndication-clickable"
              onClick={() => onPhotoAngleClicked(shot)}
            >
              {isUploadingShot ? (
                <>
                  {activeUpload ? (
                    <div className="icon-uploading">
                      <UploadPlaceholder upload={activeUpload} showOverlay />
                    </div>
                  ) : (
                    <div className="icon-loading">
                      <LoadingIndicator />
                    </div>
                  )}
                </>
              ) : isUnset ? (
                <div className="icon-upload">
                  <Upload size={28} />
                </div>
              ) : (
                <img className="angle-img" src={url} alt={name} />
              )}
              <div>{name}</div>
            </div>
            {!isUnassigned && url && image && image.imageType !== 'STOCK' && (
              <PermissionsGate
                permissions={[permissions.INVENTORY_VDP_PHOTOS_DELETE]}
              >
                <Delete
                  className="Syndication-delete-icon"
                  onClick={unAssignImage}
                />
              </PermissionsGate>
            )}
          </div>
          {!isLast && <div className="divider" />}
        </div>
      );
    }

    return null;
  };

  const shots = prepareDataForAngles();

  return (
    <div className="Syndication full-height">
      <div className="Syndication-message padding">
        {strings.SYNDICATION_HEADER_DESCRIPTION_BEFORE_ICON}
        <Upload size={16} />
        {strings.SYNDICATION_HEADER_DESCRIPTION_AFTER_ICON}
      </div>
      {shots.map((shot: IShot, index: number) => {
        const isLast = index === shots.length - 1;
        return renderAngleRow(shot, index, isLast);
      })}
      {openModal && selectedAngle && (
        <SyndicationModal
          images={unassignedImages}
          onClose={closeModal}
          selectedVehicleMediaShot={selectedAngle}
          onUploadAndAssignImage={uploadAndAssignShotImage}
          assignExistingImage={assignExistingShotImage}
        />
      )}
      <Alert
        open={showErrorAlert}
        contentProps={{
          variant: 'error',
          onClose: closeAlert,
          message: errorMessage,
        }}
      />
    </div>
  );
};

export default Syndication;
