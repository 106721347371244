import './ToolbarSpacer.scss';

const ToolbarSpacer: React.FC<{ title?: string }> = ({ title, children }) => (
  <div className="EngagementsHeader">
    <div className="EngagementsHeader-toolbar">
      {title && <div className="EngagementsHeader-count">{title}</div>}
      <div className="filters">{children}</div>
    </div>
  </div>
);
export default ToolbarSpacer;
