/* eslint-disable */
import React, { useState, ChangeEvent, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import strings from 'common/strings';
import LoadingIndicator from '../../LoadingIndicator';
import { TextInput } from 'components/shared/Inputs';
import { isValidVin, useWindowSticker } from 'api/engage/windowSticker';

import './VINInquiryModal.scss';
import { configuredFetch, getAPIHeaders } from 'api';

interface SubmitFeedbackModalProps {
  onClose: () => void;
  onError: () => void;
}

interface ResponseError {
  message: string;
}

const VINInquiryModal: React.FC<
  React.PropsWithChildren<SubmitFeedbackModalProps>
> = ({ onClose, onError }) => {
  const [VIN, setVIN] = useState('');
  const { data, isLoading, isError, error, failureCount } = useWindowSticker(VIN);

  useEffect(() => {
    const setAuth = async () => {
      const { options } = await getAPIHeaders();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_WINDOW_STICKER_URL}/api/token`,
          {
            ...options,
            credentials: 'include',
          }
        );
      } catch (error) {
        console.log('Error setting token', error);
      }
    };
    setAuth();
  }, []);

  const onChangeVIN = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setVIN(value);
  };

  const onSubmit = () => {
    if (data) {
      window.open(data, '_newtab');
    }
  };

  const errorMessage = () => {
    if (failureCount > 0) {
      return failureCount < 4
        ? `The window sticker is taking longer than expected, please wait.`
        : `The window sticker is not currently available`;
    }
    if (VIN?.length >= 17 && !isValidVin(VIN)) {
      return strings.ERROR_INVALID_VIN;
    }
    return '';
  };

  const handleKeypress = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };


  return (
    <>
      <Modal
        show
        centered
        onHide={onClose}
        className="VINInquiryModal"
        backdrop={true}
      >
        <Modal.Header className="modal-header-container">
          <Modal.Title>{strings.VIN_INQUIRY}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="VINInquiryModal-body flex-grow">
          <TextInput
            containerClassName="VINInquiryModal-input-container"
            onChange={onChangeVIN}
            value={VIN}
            placeholder={strings.VIN}
            required={false}
            onKeyDown={handleKeypress}
          />
          <div className="VINInquiryModal-footer">
            <div>
              <h6 className="VINInquiryModal-error">{errorMessage()}</h6>
            </div>
            <div className="VINInquiryModal-buttons">
              <button
                type="button"
                onClick={onClose}
                className="VINInquiryModal-cancel-button"
              >
                {strings.CANCEL}
              </button>
              <button
                type="button"
                className="blue-button VINInquiryModal-submit-button"
                onClick={onSubmit}
                disabled={!isValidVin(VIN) || isLoading || isError}
              >
                {isLoading ? (
                  <LoadingIndicator size={12} />
                ) : (
                  <div>{strings.SUBMIT}</div>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VINInquiryModal;
