import { ChevronRight, ExpandMore, Fireplace } from '@material-ui/icons';
import React, { useContext, useMemo } from 'react';

import strings from 'common/strings';
import { EngagementDetail } from 'models/engage/engagementDetail';
import { phoneNumberFormatter, startWithUpperCase } from 'utils/formatter';
import {
  getDateAndTimeWithTimeZone,
  getFormattedDurationFromSeconds,
} from 'utils/time';
import { getFullName } from 'utils/user';

import EngageItemHeatmap from './EngageItemHeatmap';
import EngageItemVehicle from './EngageItemVehicle';
import { EngageSelectContext } from './EngageSelectContext';

import './EngageItem.scss';

interface IEngageItemProps {
  id: string;
  item: EngagementDetail;
  groupBy: string | null;
}

const EngageItem: React.FC<IEngageItemProps> = ({ id, item }) => {
  const { timestamp, vehicleCard, dealerUser, prospect, engagementMetrics } =
    item;
  const { opens, actions, lastOpen, totalDurationSeconds } = engagementMetrics;
  const person = prospect?.person;
  const phoneLink = prospect?.contact?.phoneContacts[0]?.phoneNumber;
  const emailLink = prospect?.contact?.emailContacts[0]?.emailAddress;

  const [selectedItem, setSelectedItem] = useContext(EngageSelectContext);

  const isSelected = useMemo(() => selectedItem === id, [selectedItem, id]);

  const handleClick = () => {
    if (!isSelected) {
      setSelectedItem(item.id);
    } else {
      setSelectedItem(null);
    }
  };

  const openHeatMap = () => {
    // @todo - This would always yield an error in testing. We need to do this
    // as a side-effect (useEffect) or by typechecking window !== 'undefined'
    if (!item.shareURL) return;
    window.open(`${item.shareURL}?heatmap=true`);
  };

  const renderLastOpen = (
    <div role="button" tabIndex={-1} onClick={openHeatMap}>
      <Fireplace />{' '}
      <span className="item-eng-non-anchor-link">
        {getDateAndTimeWithTimeZone(lastOpen)}
      </span>
    </div>
  );

  const shopperColumn = (
    <div className="cell">
      <div>
        <small>{person?.fullName || null}</small>
      </div>
      <div>
        {phoneLink ? (
          <a className="item-eng-contact-link" href={`tel:${phoneLink}`}>
            {phoneNumberFormatter(phoneLink, '.')}
          </a>
        ) : (
          strings.EMPTY_VALUE
        )}
      </div>
      <div>{emailLink || strings.EMPTY_VALUE}</div>
    </div>
  );

  return (
    <>
      <div
        className={`engage-row ${isSelected && 'current-row'}`}
        key={id}
        onClick={() => handleClick()}
      >
        <div className="cell">
          {isSelected ? <ExpandMore /> : <ChevronRight />}
        </div>
        <div className="cell">
          <div>{getDateAndTimeWithTimeZone(timestamp)}</div>
        </div>
        {shopperColumn}
        {vehicleCard && (
          <div className="cell">
            <EngageItemVehicle
              item={{ ...vehicleCard, vin: undefined }}
              engagementId={id}
            />
          </div>
        )}
        <div className="cell">
          {dealerUser ? getFullName(dealerUser) : strings.EMPTY_VALUE}
        </div>
        <div className="cell centered">
          {startWithUpperCase(item.engagementType.name)}
        </div>
        <div className="cell centered">{opens}</div>
        <div className="cell centered">
          {totalDurationSeconds
            ? getFormattedDurationFromSeconds(totalDurationSeconds, 3, true)
            : strings.EMPTY_VALUE}
        </div>
        <div className="cell centered">{actions}</div>
        <div className="cell centered">
          {lastOpen ? renderLastOpen : strings.EMPTY_VALUE}
        </div>
      </div>
      {isSelected && <EngageItemHeatmap id={id} />}
    </>
  );
};

export default EngageItem;
