import { navigate } from '@reach/router';
import { useContext, useState } from 'react';
import NotificationSystem from 'react-notification-system';
import { useQueryClient } from 'react-query';

import { permissions } from 'common';
import VehicleComponent from 'components/shared/Realtime/VehicleUpdate/VehicleUpdate';
import { useCurrentLocationId, usePermissions } from 'hooks';
import { EventType, Message } from 'hooks/useInventoryUpdates';
import { vehicleInfoBuilder } from 'navigation/routes';

import { NotificationSystemContext } from '../NotificationSystem/NotificationSystem';

type MessageSet = {
  [key: string]: Message;
};

interface useInventoryNotificationsInterface {
  messages: MessageSet;
  handleMessages: (message: Message) => void;
  handleClearMessages: () => void;
  handleClearMessageById: (inventoryId: string) => void;
  handleRefreshInventoryList: () => void;
  handleViewRecon: (inventoryId: string) => void;
}

export const useInventoryNotifications =
  (): useInventoryNotificationsInterface => {
    const { hasPermission } = usePermissions();
    const currentTenantId = useCurrentLocationId();
    const queryClient = useQueryClient();
    const { ref: notificationSystemRef } = useContext<any>(
      NotificationSystemContext
    );

    const [messages, setMessages] = useState<MessageSet>({});
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);

    const notification: NotificationSystem = notificationSystemRef?.current;

    const handleClearMessages = () => {
      setMessages({});
      notification.removeNotification('updated_vehicles');
    };

    const handleClearMessageById = (inventoryId: string) => {
      delete messages[inventoryId];
      const newMessages = { ...messages };
      setMessages(newMessages);
      return newMessages;
    };

    const handleRefreshInventoryList = () => {
      handleClearMessages();

      if (hasPermission(permissions.RECON_VIEW)) {
        queryClient.invalidateQueries('/dashboard/goals');
      }
      queryClient.invalidateQueries('/inventory');
    };

    const handleViewRecon = (inventoryId: string) => {
      navigate(vehicleInfoBuilder(inventoryId, false));
      const newMessages = handleClearMessageById(inventoryId);
      handleNotification(newMessages);
    };

    const createNotificationMessage = (
      updatedMessages: MessageSet
    ): NotificationSystem.Notification => {
      return {
        title: `${Object.entries(updatedMessages).length} Vehicles Updated`,
        message: (
          <div>
            {Object.entries(updatedMessages).map(
              ([key, message]: [string, Message]) => {
                return (
                  <VehicleComponent
                    key={key}
                    message={message}
                    onClick={handleViewRecon}
                  />
                );
              }
            )}
          </div>
        ),
        level: 'info',
        position: 'br',
        autoDismiss: 0,
        dismissible: 'both',
        action: {
          label: 'Refresh',
          callback: handleRefreshInventoryList,
        },
        onRemove: () => {
          setIsNotificationVisible(false);
          handleClearMessages();
        },
        uid: 'updated_vehicles',
      };
    };

    const handleNotification = (messages: MessageSet) => {
      if (!Object.entries(messages).length) {
        notification.removeNotification('updated_vehicles');
        setIsNotificationVisible(false);
        return;
      }

      const notificationMessage = createNotificationMessage(messages);
      if (isNotificationVisible) {
        notification?.editNotification('updated_vehicles', notificationMessage);
      } else {
        notification?.addNotification(notificationMessage);
        setIsNotificationVisible(true);
      }
    };

    const handleMessages = (message: Message) => {
      const { inventoryId = '', tenantId, eventType } = message;
      if (eventType === EventType.stepChange && tenantId === currentTenantId) {
        return;
      }

      if (messages[inventoryId]) {
        return;
      }

      const updatedMessages = { ...messages, [inventoryId]: message };
      setMessages(updatedMessages);
      handleNotification(updatedMessages);
    };

    return {
      messages,
      handleMessages,
      handleClearMessages,
      handleClearMessageById,
      handleRefreshInventoryList,
      handleViewRecon,
    };
  };
