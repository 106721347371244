import React from 'react';

import './IconButton.scss';

interface IconButtonProps {
  iconComponent: React.ReactNode;
  onClick: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({ iconComponent, onClick }) => (
  <button type="button" onClick={onClick} className="IconButton">
    {iconComponent}
  </button>
);

export default IconButton;
