import { FC } from 'react';

import { CarfaxReport as ICarfaxReport } from 'models/carfax';

interface CarfaxReportProps {
  data: ICarfaxReport;
  vin: string;
}

export const CarfaxReport: FC<CarfaxReportProps> = ({ data, vin }) => {
  return (
    <iframe
      className="carfax-report"
      src={data.carfaxLink}
      title={`CarfaxReport-${vin}`}
    />
  );
};
