import { useMutation } from 'react-query';

import { defaultMutationFn } from 'api/useAPI';

export async function updateDeviceToken(deviceId: string) {
  return await defaultMutationFn('/notifications/devices', 'POST', {
    deviceId,
    deviceType: 'WEB',
  });
}

export function useUpdateDeviceTokenMutation() {
  return useMutation(async (deviceId: string) => {
    updateDeviceToken(deviceId);
  });
}
