import { User } from 'models';

export declare type ChecklistAnswers = Record<
  string,
  undefined | null | string | string[]
>;

export enum ChecklistItemType {
  USER_CONTROL = 'USER_CONTROL',
  GROUP = 'GROUP',
}

export enum ChecklistUIType {
  CHECKBOX = 'CHECKBOX',
  RADIO_BUTTON = 'RADIO_BUTTON',
  YES_NO = 'YES_NO',
  TEXT_INPUT = 'TEXTBOX',
  SELECT = 'DROPDOWN',
}

export interface ChecklistOption {
  label: string;
  positionIndex: number;
  selected: boolean;
  value: string;
}

export interface ChecklistItem {
  id: string;
  itemType: ChecklistItemType;
  label: string;
  options: ChecklistOption[];
  positionIndex?: number;
  required: boolean;
  uiType: ChecklistUIType;
  value: string | string[];
}

export interface Checklist {
  id: string;
  createdTimestamp?: string;
  modifiedTimestamp?: string;
  createdBy: User;
  modifiedBy?: User;
  name: string;
  items: ChecklistItem[];
  compeletedChecklistItems: number;
  totalChecklistItems: number;
}

export interface CheckListMap {
  [id: string]: CheckListInstance;
}

export interface CheckListInstance {
  id: string;
  answers?: any;
  checkList: Checklist;
  checkListId: string;
  // checkListLink?: HrefLinks;
}
