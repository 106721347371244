import { useContext, useEffect } from 'react';

import VehicleImageView from 'components/shared/VehicleImageView';

import VehicleCardReconInventoryTabs from '../components/VehicleCardReconInventoryTabs';
import VehicleCardYearMakeModel from '../components/VehicleCardYearMakeModel';
import { VehicleCardContext } from '../VehicleCard';

import './VehicleCardVendorMobile.scss';

const VehicleCardMobile = () => {
  const { setCurrentTab, vehicle } = useContext(VehicleCardContext);

  useEffect(() => {
    setCurrentTab?.('tasks');
  }, [setCurrentTab]);
  return (
    <div className="VehicleCardMobile">
      <div className="VehicleCardMobile-vehicleInfo">
        <VehicleImageView
          imageUrl={vehicle?.vehicleCard.vehicleImage}
          skeleton={!vehicle}
          className="VehicleImageView-showroom-size"
        />
        <VehicleCardYearMakeModel />
      </div>
      <VehicleCardReconInventoryTabs showNotesTab={true} />
    </div>
  );
};

export default VehicleCardMobile;
