import { useEffect } from 'react';

import { useActiveFilters } from 'api';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import { filterIdsDownMap } from 'models';
import { updateUrlSearchParam } from 'navigation/util/ParamHelpers';

import { WorkflowMenuItem } from '../Sidebar/Sidebar';
import FilterChip from './FilterChip';
import FilterRow from './FilterRow';
import { useFilterColumn } from './useFilterColumn';

import './FilterColumn.scss';

interface FilterColumnProps {
  workflowMenuItems: WorkflowMenuItem[];
  isShowroomMode: boolean;
}

const STATUS = 'status';
const INVENTORY_STATE_KEY = 'inventoryState';
const DEFAULT_INVENTORY_STATE_VALUE = 'INVENTORY_ONLY';

const FilterColumn = ({
  workflowMenuItems,
  isShowroomMode,
}: FilterColumnProps) => {
  const { filters } = useFilterColumn();
  const activeFilters = useActiveFilters();

  const updateFilters = (filters: { [filter: string]: string } | any) => {
    updateUrlSearchParam(filters, false);
  };

  const clearAllFilters = () => {
    const clearObject: { [x: string]: string } = {};
    filters.forEach((filter) => {
      const filterId = filterIdsDownMap[filter.id]
        ? filterIdsDownMap[filter.id]
        : filter.id.toLowerCase();
      clearObject[filterId] = '';
    });
    clearObject[STATUS] = '';
    clearObject[INVENTORY_STATE_KEY] = DEFAULT_INVENTORY_STATE_VALUE;
    updateFilters(clearObject);
  };

  useEffect(() => {
    if (isShowroomMode) {
      const clearObject = {
        [INVENTORY_STATE_KEY]: DEFAULT_INVENTORY_STATE_VALUE,
      };
      updateFilters(clearObject);
    }
  }, [isShowroomMode]);

  return (
    <div className="FilterColumn">
      <div className="FilterColumn-active-filters">
        <div className="FilterColumn-active-filters-header">
          {activeFilters.length > 0 && (
            <button
              data-vas-testing={testIds.FILTER_CHIPS_CLEAR_BUTTON}
              type="button"
              className="FilterColumn-active-filters-clear-button"
              onClick={clearAllFilters}
            >
              {strings.CLEAR}
            </button>
          )}
        </div>
        <div className="FilterColumn-active-filters-chips">
          {activeFilters.map(({ filter, value }) => {
            if (Array.isArray(value)) {
              return value.map((multiFilterValue) => (
                <FilterChip
                  key={filter}
                  filter={filter}
                  title={multiFilterValue}
                  onCancelClick={() => {
                    const newFilterValue = value.filter(
                      (option: string) => option !== multiFilterValue
                    );

                    const key =
                      filterIdsDownMap[filter] || filter.toLowerCase();
                    updateFilters({
                      [key]: newFilterValue,
                    });
                  }}
                  workflowMenuItems={workflowMenuItems}
                />
              ));
            }
            return (
              <FilterChip
                key={filter}
                filter={filter}
                title={value}
                onCancelClick={() => {
                  const key = filterIdsDownMap[filter] || filter.toLowerCase();
                  updateFilters({
                    [key]: '',
                  });
                }}
                workflowMenuItems={workflowMenuItems}
              />
            );
          })}
        </div>
      </div>
      <div className="FilterColumn-container">
        {filters.map((filter) => {
          let values: string | string[] = '';
          if (filter.behavior === 'MULTI_SELECT') {
            values = [];
            activeFilters.forEach((item) => {
              if (filter.id === item.filter) {
                if (Array.isArray(item.value)) {
                  (values as string[]).push(...item.value);
                } else {
                  (values as string[]).push(item.value);
                }
              }
            });
          } else {
            values = activeFilters.find(
              (item) => item.filter === filter.id
            )?.value;
          }

          return (
            <FilterRow
              key={`${filter.label}-${filter.id}`}
              sortAndFilter={(value: any) => {
                const key =
                  filterIdsDownMap[filter.id] || filter.id.toLowerCase();
                updateFilters({
                  [key]: value,
                });
              }}
              currentFilterValue={values}
              filter={filter}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilterColumn;
