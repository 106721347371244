/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const Database: React.FC<IconProps> = ({ color = defaultColor, className }) => (
  <svg
    width={20}
    height={19}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M6.99961 0.799805C3.46361 0.799805 0.599609 2.2318 0.599609 3.9998C0.599609 5.7678 3.46361 7.1998 6.99961 7.1998C10.5356 7.1998 13.3996 5.7678 13.3996 3.9998C13.3996 2.2318 10.5356 0.799805 6.99961 0.799805ZM0.599609 5.5998V7.9998C0.599609 9.76781 3.46361 11.1998 6.99961 11.1998C10.5356 11.1998 13.3996 9.76781 13.3996 7.9998V5.5998C13.3996 7.36781 10.5356 8.7998 6.99961 8.7998C3.46361 8.7998 0.599609 7.36781 0.599609 5.5998ZM0.599609 9.5998V11.9998C0.599609 13.7678 3.46361 15.1998 6.99961 15.1998C10.5356 15.1998 13.3996 13.7678 13.3996 11.9998V9.5998C13.3996 11.3678 10.5356 12.7998 6.99961 12.7998C3.46361 12.7998 0.599609 11.3678 0.599609 9.5998Z"
      fill={color}
    />
  </svg>
);
export default Database;
