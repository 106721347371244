import { OutlinedInputProps, Paper, TextField } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import { Invoice as InvoiceType } from 'models/invoicing';
import { currencyFormatter } from 'utils/formatter';

import './Invoice.scss';

type Props = {
  invoice?: InvoiceType;
  onInvoiceNumberChange?: OutlinedInputProps['onChange'];
} & RouteComponentProps;

const Invoice = ({ invoice, onInvoiceNumberChange }: Props) => {
  let total = 0;
  const vehicle = invoice?.vehicle!;
  const invoiceComponent = invoice?.invoiceNumber ? (
    <div>
      <div className="invoice-number-label">Invoice Number</div>
      <div className="invoice-number">{invoice.invoiceNumber}</div>
    </div>
  ) : (
    <TextField
      onChange={onInvoiceNumberChange}
      variant="outlined"
      size="small"
      placeholder="Invoice Number"
      autoFocus
      inputProps={{ maxLength: 30 }}
    />
  );
  return (
    <div className="invoice">
      <Paper elevation={1} className="invoice-page">
        <header className="invoice-header">
          <div className="header-top">
            <div className="header-left">
              <div className="vendor-logo-container">
                <img
                  className="vendor-logo"
                  alt="logo"
                  src={invoice?.vendor?.logoUrl}
                />
              </div>
              {invoiceComponent}
            </div>
            <div className="header-right">
              <div className="vendor-details">
                <span className="vendor-name">{invoice?.vendor?.name}</span>
                <span className="vendor-detail">
                  {invoice?.vendor?.mailingAddress?.street1}
                </span>
                <span className="vendor-detail">
                  {invoice?.vendor?.mailingAddress?.city},{' '}
                  {invoice?.vendor?.mailingAddress?.state?.name}
                </span>
                <span className="vendor-detail">
                  {invoice?.vendor?.mailingAddress?.postalCode}
                </span>
                <span className="vendor-detail">
                  {invoice?.vendor?.serviceDepartment?.phone}
                </span>
                <span className="vendor-detail">
                  {invoice?.vendor?.websiteUrl}
                </span>
              </div>
            </div>
          </div>
          <h1 className="horizontal-line horizontal-line-margin-top horizontal-line-margin-bottom" />
          <div className="header-bottom">
            <div className="header-left">
              <div className="invoice-details-container">
                <span className="invoice-detail">
                  Date: {moment(invoice?.createdAt).format('l')}
                </span>
                <span className="invoice-detail-bold">
                  Payment Due By: {moment(invoice?.dueByDate).format('l')}
                </span>
                <span className="invoice-detail-bold">
                  Total Amount Due:{' '}
                  {currencyFormatter(invoice?.totalAmountDue ?? 0)}
                </span>
              </div>
            </div>
            <div className="header-right">
              <div className="tenant-details-container">
                <span className="tenant-detail-bold">
                  {invoice?.tenant?.name}
                </span>
                <span className="tenant-detail">
                  {invoice?.tenant?.serviceDepartment?.phone}
                </span>
                <span className="tenant-detail">
                  {invoice?.tenant?.serviceDepartment?.email}
                </span>
              </div>
            </div>
          </div>
        </header>
        <main className="invoice-main">
          <div className="item">
            <div className="vehicle">
              <div className="year-make-model">
                {vehicle?.year} {vehicle?.make} {vehicle?.model}
              </div>
              <div className="vin">VIN: {vehicle.vin}</div>
              <div className="stock">Stock #:{vehicle.stockNumber}</div>
            </div>
            <div className="task">
              <div className="task-row">
                <div className="task-item task-item-right task-item-border">
                  Task
                </div>
                <div className="task-item task-item-border">Description</div>
                <div className="task-item task-item-left task-item-border">
                  Total
                </div>
              </div>
              {vehicle?.tasks.map((task) => {
                return (
                  <div key={task.label} className="task-row">
                    <div className="task-value task-value-right task-value-background">
                      {task.label}
                    </div>
                    <div className="task-value task-value-background">
                      {task.notes}
                    </div>
                    <div className="task-value task-value-left task-value-background task-total">
                      {currencyFormatter(task?.price?.amount ?? 0)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="task">
              <div className="task-row">
                <div className="task-item task-item-right"></div>
                <div className="task-item"></div>
                <div className="task-item task-item-left task-item-border">
                  Vehicle Total
                </div>
              </div>
              <div className="task-row">
                <div className="task-value"></div>
                <div className="task-value"></div>
                <div className="task-value task-total">
                  {currencyFormatter(
                    vehicle.tasks?.reduce((acc, task) => {
                      total += task?.price?.amount;
                      return (acc += task?.price?.amount ?? 0);
                    }, 0) ?? 0
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <h1 className="horizontal-line horizontal-line-margin-top horizontal-line-margin-small-bottom" />
        <footer className="invoice-footer">
          <div className="total-price">Total: {currencyFormatter(total)}</div>
        </footer>
      </Paper>
    </div>
  );
};

export default Invoice;
