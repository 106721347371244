import { isUndefined } from 'lodash';

import strings from 'common/strings';
import { DealerStepReportVehicle, Price, SummaryItem } from 'models';

export enum ratings {
  GOOD = 'good',
  NEUTRAL = 'neutral',
  BAD = 'bad',
}

export function compareAverageOverage(
  averageEstimatedRecon?: Price,
  averageActualRecon?: Price
) {
  const averageEstimateRecon = averageEstimatedRecon
    ? averageEstimatedRecon?.amount
    : strings.EMPTY_VALUE;
  const averageActualReconAmount = averageActualRecon
    ? averageActualRecon?.amount
    : strings.EMPTY_VALUE;
  return getRatingForValue(averageActualReconAmount, averageEstimateRecon);
}

export function compareTotalOverage(totalOverage?: Price) {
  const totalOverageAmount = totalOverage
    ? totalOverage?.amount
    : strings.EMPTY_VALUE;
  return getRatingForValue(totalOverageAmount, 0);
}

export function compareGoalValue(summaryItem: SummaryItem) {
  return getRatingForValue(summaryItem.statValue, summaryItem.goalValue);
}

export function compareItemOverage(item: DealerStepReportVehicle) {
  return getRatingForValue(
    item?.overage?.amount ? item.overage.amount : strings.EMPTY_VALUE,
    0
  );
}

export function getRatingForValue(value?: number, neutralValue?: number) {
  if (isUndefined(value) || isUndefined(neutralValue)) return ratings.NEUTRAL;
  if (value < neutralValue) return ratings.GOOD;
  if (value > neutralValue) return ratings.BAD;
  return ratings.NEUTRAL;
}
