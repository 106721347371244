import { useContext } from 'react';

import permissions from 'common/permissions';
import TagList from 'components/pages/VehicleCollectionBrowser/VehicleCard/components/VehicleCardTagListAndActions/TagListSrp';
import PermissionsGate from 'components/shared/PermissionsGate/PermissionsGate';
import VehicleImageView from 'components/shared/VehicleImageView';

import VehicleDetailsTable from '../components/VehicleCardDetailsTable';
import VehicleCardProgressBar from '../components/VehicleCardProgressBar';
import VehicleCardReconInventoryTabs from '../components/VehicleCardReconInventoryTabs';
import VehicleCardYearMakeModel from '../components/VehicleCardYearMakeModel';
import { VehicleCardContext } from '../VehicleCard';

import './VehicleCardMobile.scss';

const VehicleCardMobile = () => {
  const { vehicle } = useContext(VehicleCardContext);
  const vehicleId = vehicle?.vehicleCard.id;

  return (
    <div className="VehicleCardMobile">
      <div className="VehicleCardMobile-vehicleInfo">
        <VehicleImageView
          imageUrl={vehicle?.vehicleCard.vehicleImage}
          skeleton={!vehicle}
          className="VehicleImageView-showroom-size"
        />
        <VehicleCardYearMakeModel />
      </div>
      <VehicleDetailsTable showVin={false} />
      <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
        <TagList
          showEditButton
          vehicleIsSold={vehicle?.vehicleCard?.sold ?? true}
          initialTags={vehicle?.tags}
          vehicleId={vehicleId!}
        />
      </PermissionsGate>
      <VehicleCardProgressBar />
      <VehicleCardReconInventoryTabs showNotesTab={true} />
    </div>
  );
};

export default VehicleCardMobile;
