function shouldRenderContinueButton(data: any): boolean {
  switch (data.state) {
    case 'ACTIVE_PAUSED':
      return true;
    case 'ACTIVE':
      if (data.nextStep === null) {
        return false;
      }
      return true;
    case 'COMPLETE':
      if (data.nextStep === null || data.id === data.nextStep.id) {
        return false;
      }
      return true;
    case 'NOT_STARTED':
      if (data.nextStep === null || data.id === data.nextStep.id) {
        return false;
      }
      return true;
    default:
      return true;
  }
}
export default shouldRenderContinueButton;
