import { VehicleSummary } from 'models';

export const copyToClipboard = (content: string) => {
  // current clipboard API only works in secure context (HTTPS)
  if (content && navigator?.clipboard) {
    navigator.clipboard.writeText(content).catch(() => {
      console.error('unable to copy');
    });
  }
};

export function getDaysInInventory(vehicle: VehicleSummary) {
  if (vehicle?.startDate === undefined) {
    return undefined;
  }
  const startDateAsSeconds =
    Math.floor(Date.now() / 1000) -
    new Date(vehicle?.startDate)?.getTime() / 1000;
  return Math.floor(startDateAsSeconds / (60 * 60 * 24));
}

export function getIsCertifiedPreOwned(vehicle: VehicleSummary) {
  const { condition, disposition } = vehicle?.vehicleCard ?? {};
  return (
    disposition === 'CERTIFIED_PRE_OWNED' ||
    (condition === 'CPO' && disposition === 'UNKNOWN')
  );
}
