import { Delete } from '@material-ui/icons';
import React, { useState } from 'react';
import { isUndefined } from 'util';

import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import VehicleImageView from 'components/shared/VehicleImageView';
import { VehicleImage } from 'models';
import { ALLOWED_SYNDICATION_FILETYPES_STRING } from 'store/uploads/types';
import { truncateEndOfStringByLength } from 'utils/string';

import { IShot } from '../Syndication/Syndication';

import './SyndicationModal.scss';

interface ISyndicationModal {
  images: VehicleImage[];
  onClose: () => void;
  selectedVehicleMediaShot: IShot;
  onUploadAndAssignImage: (file: File, shot: IShot) => void;
  assignExistingImage: (image: VehicleImage, shot: IShot) => Promise<void>;
}

const SyndicationModal: React.FC<ISyndicationModal> = ({
  images,
  onClose,
  selectedVehicleMediaShot,
  onUploadAndAssignImage,
  assignExistingImage,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [selectedImageId, setSelectedImageId] = useState<string | undefined>(
    undefined
  );
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('multiple', 'multiple');
  fileSelector.setAttribute('accept', ALLOWED_SYNDICATION_FILETYPES_STRING);
  const handleChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target?.files) {
      setSelectedFile(target.files[0]);
    }
  };
  fileSelector.onchange = handleChange;

  const setShotImage = async (file: File | undefined) => {
    if (file) {
      onUploadAndAssignImage(file, selectedVehicleMediaShot);
    } else {
      const image = images.find(
        (vehicleMedia: VehicleImage) => vehicleMedia.id === selectedImageId
      );
      if (image) assignExistingImage(image, selectedVehicleMediaShot);
    }
    onClose();
  };

  return (
    <Modal
      className="SyndicationModal"
      title={strings.SYNDICATION_MODAL_TITLE(selectedVehicleMediaShot.name)}
      open
      onClose={onClose}
      onPrimaryButtonClick={async () => {
        await setShotImage(selectedFile);
        onClose();
      }}
      primaryButtonDisabled={!selectedFile && !selectedImageId}
      primaryButtonLabel={strings.ASSIGN}
      primaryButtonLoading={false}
      secondaryButtonLabel={strings.CANCEL}
    >
      <div
        className="SyndicationModal-upload"
        data-vas-testing={testIds.MEDIA_SYNDICATION_MODAL_UPLOAD_CONTAINER}
      >
        <Button
          data-vas-testing={testIds.SYNDICATION_MODAL_SELECT_FILE_BUTTON}
          id="SyndicationModal-select-button"
          type="button"
          variant="primary"
          onClick={() => fileSelector.click()}
          disabled={!!selectedFile || !!selectedImageId}
        >
          {strings.SELECT_FILE}
        </Button>
        {selectedFile && (
          <div className="SyndicationModal-upload-file">
            {truncateEndOfStringByLength(selectedFile.name, 23)}
            <button
              className="SyndicationModal-upload-file-delete-button"
              type="button"
              onClick={() => setSelectedFile(undefined)}
            >
              <Delete className="SyndicationModal-upload-file-delete-button-icon" />
            </button>
          </div>
        )}
      </div>
      <div
        data-vas-testing={testIds.SYNDICATION_MODAL_TEXT}
        className="SyndicationModal-text"
      >
        {strings.SYNDICATION_MODAL_SUBTITLE}
      </div>
      <div
        className={`SyndicationModal-gallery ${selectedFile ? 'disabled' : ''}`}
      >
        {images.map((image: VehicleImage) => {
          const className = isUndefined(selectedImageId)
            ? ''
            : selectedImageId === image.id
            ? 'active'
            : 'inactive';
          return (
            <button
              className="SyndicationModal-gallery-button"
              type="button"
              onClick={() => {
                if (selectedImageId === image.id) {
                  setSelectedImageId(undefined);
                  return;
                }
                setSelectedImageId(image.id);
              }}
              disabled={!!selectedFile}
            >
              <VehicleImageView
                className={className}
                imageUrl={image.uri}
                size="square"
              />
            </button>
          );
        })}
      </div>
    </Modal>
  );
};

export default SyndicationModal;
