import { Link, RouteComponentProps } from '@reach/router';

type Props = {
  children: React.ReactNode;
} & RouteComponentProps;

const PaidInvoices = ({ children, navigate }: Props) => {
  return (
    <div className="paid-invoice">
      <div>
        <Link to="invoice/1">Invoice 1</Link>
      </div>
      <div>
        <Link to="invoice/2">Invoice 2</Link>
      </div>
      <div>
        <Link to="invoice/3">Invoice 3</Link>
      </div>
      <div>
        <button
          onClick={() => {
            navigate?.('./invoice/4');
          }}
        >
          Invoice 4
        </button>
      </div>
      {children}
    </div>
  );
};

export default PaidInvoices;
