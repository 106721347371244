import React, { useEffect, useReducer } from 'react';
import { Card } from 'react-bootstrap';

import { useEngagements, useEngagementSearchFacets } from 'api';
import { TabProps } from 'components/shared/Tabs/Tabs';

import {
  getCanGoToNextPage,
  getCanGoToPreviousPage,
  getStartIndex,
  initialState,
  reducer,
} from './EngagementsTable/EngagementsFilterReducer';
import EngagementsTable from './EngagementsTable/EngagementsTable';

import 'components/pages/VelocityEngage/VelocityEngageEngagementsTab/EngagementsHeader/EngagementsHeader.scss';

const EngageTab: React.FC<TabProps> = () => {
  const [state, reduce] = useReducer(reducer, initialState);
  const {
    query: { data: { data: facets } = {} },
  } = useEngagementSearchFacets();
  const {
    data: { data } = {},
    isLoading,
    error,
    isError,
    isIdle,
  } = useEngagements({
    startIndex: getStartIndex(state),
    pageSize: state.pageSize,
    sortBy: state.sortBy,
    sortDirection: state.sortDirection,
    groupBy: state.groupBy,
    filterBy: state.filterBy,
    facets,
  });
  useEffect(() => {
    if (data?.totalItems !== undefined) {
      reduce({ type: 'setTotal', total: data?.totalItems });
    }
  }, [data?.totalItems]);
  return (
    <Card style={{ margin: '24px 0', boxShadow: '3px 3px 5px #e6e7e8' }}>
      <EngagementsTable
        canGoToNextPage={getCanGoToNextPage(state)}
        canGoToPreviousPage={getCanGoToPreviousPage(state)}
        filterBy={state.filterBy}
        groupBy={state.groupBy}
        onNextPage={() => reduce({ type: 'nextPage' })}
        onPreviousPage={() => reduce({ type: 'previousPage' })}
        onSwitchOrderBy={(attribute) =>
          reduce({ type: 'switchOrderBy', attribute })
        }
        currentPage={state.page}
        pageSize={state.pageSize}
        setFilterBy={(filterBy) => {
          reduce({ type: 'setFilterBy', filterBy });
        }}
        resetFilters={() => {
          reduce({ type: 'reset' });
        }}
        setPageSize={(size: number) => {
          reduce({ type: 'setPageSize', size });
        }}
        sortBy={state.sortBy}
        sortDirection={state.sortDirection}
        setGroupBy={(groupBy) => reduce({ type: 'setGroupBy', groupBy })}
        facets={facets}
        data={data}
        totalItems={state.totalItems || (data?.totalItems ?? null)}
        isError={isError || !!error}
        isLoading={isLoading}
        isLoadingFacets={isIdle}
      />
    </Card>
  );
};

export default EngageTab;
