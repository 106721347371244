import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { useVehicleNeedsAttention } from 'api';
import strings from 'common/strings';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { TabProps } from 'components/shared/Tabs/Tabs';
import { InventoryNeedingAttentionItem } from 'models';

import VehicleNeedsAttentionCard from '../VehicleNeedsAttentionCard/VehicleNeedsAttentionCard';

const NeedsAttentionTab: React.FC<TabProps> = () => {
  const { data: { data } = {}, isLoading, error } = useVehicleNeedsAttention();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || !data?.items?.length) {
    return (
      <Col xs={12} className="Dashboard-no-notices-col">
        <div className="Dashboard-no-notices-title">
          {String(strings.NO_VEHICLES_NEED_ATTENTION).toUpperCase()}
        </div>
        <div className="Dashboard-no-notices-message">
          {strings.NO_VEHICLES_NEED_ATTENTION_MESSAGE}
        </div>
      </Col>
    );
  }

  return (
    <div className="vehicle-attention-grid">
      <Row>
        {data?.items.map((item: InventoryNeedingAttentionItem, idx: number) => (
          <Col
            key={`${idx.toString()}`}
            xl={3}
            md={6}
            className="VehicleNeedsAttentionCol"
          >
            <VehicleNeedsAttentionCard inventoryNeedingAttentionItem={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default NeedsAttentionTab;
