import { useQuery } from 'react-query';

import { APIResponse, defaultMetaQueryFn, useDownload } from 'api';
import { DateRange } from 'common/DateRange';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import { EngagementHeatmapData } from 'models';
import VelocityEngageReportPayload from 'models/reports/VelocityEngageCorporateReportPayload';
import { getHyphenatedApiDateStringFromDate } from 'utils/time';

const requestPath: string = '/reports/engage/corporate';
interface VelocityEngageCorporateReportRequestParameters {
  dateRange: DateRange;
  tags: string[];
  condition: string;
}
export default function useVelocityEngageCorporateReport({
  condition,
  tags,
  dateRange: { end, start },
}: VelocityEngageCorporateReportRequestParameters) {
  const params = `startDate=${getHyphenatedApiDateStringFromDate(
    start
  )}&endDate=${getHyphenatedApiDateStringFromDate(end)}&tagIds=${tags.join(
    ','
  )}&condition=${condition}`;
  const url = `${requestPath}?${params}`;
  const key = [
    requestPath,
    getHyphenatedApiDateStringFromDate(start),
    getHyphenatedApiDateStringFromDate(end),
    tags.join(','),
    condition,
  ];

  return useQuery<APIResponse<VelocityEngageReportPayload>>(key, () =>
    defaultMetaQueryFn(url)
  );
}

export function useVelocityEngageCorporateReportDetails(
  tenantId: string,
  { start, end }: DateRange,
  condition: string
) {
  const params = `startDate=${getHyphenatedApiDateStringFromDate(
    start
  )}&endDate=${getHyphenatedApiDateStringFromDate(end)}&condition=${condition}`;
  const url = `${requestPath}/${tenantId}?${params}`;
  const key = [
    tenantId,
    requestPath,
    getHyphenatedApiDateStringFromDate(start),
    getHyphenatedApiDateStringFromDate(end),
    condition,
  ];

  return useQuery<APIResponse<EngagementHeatmapData>>(key, () =>
    defaultMetaQueryFn(url)
  );
}

const getDownloadUrl = (
  exportType: ReportType,
  dateRange: DateRange,
  condition: string,
  tagIds?: string[]
) => {
  const url = '/reports/export/engage/corporate';
  const urlSearchParams = new URLSearchParams();

  const params = {
    startDate: getHyphenatedApiDateStringFromDate(dateRange.start),
    endDate: getHyphenatedApiDateStringFromDate(dateRange.end),
    condition,
    exportType,
  };

  Object.entries(params).forEach(([key, value]) => {
    urlSearchParams.append(key, value);
  });

  if (tagIds && tagIds.length > 0) {
    const tagIdParams = tagIds.join(',');
    urlSearchParams.append('tagIds', tagIdParams);
  }
  return `${url}?${urlSearchParams.toString()}`;
};

export function useVelocityEngageReportDownloadLink(
  reportType: ReportType | null,
  dateRange: DateRange,
  vehicleConditionFilter: string,
  tagIds?: string[]
) {
  const downloadUrl =
    reportType !== null
      ? getDownloadUrl(reportType, dateRange, vehicleConditionFilter, tagIds)
      : '';
  return useDownload({
    enabled: reportType !== null,
    fileName: 'EngageCorporateReport.xlsx',
    url: downloadUrl,
  });
}
