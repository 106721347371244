import React, { ChangeEvent } from 'react';

import './TextArea.scss';

interface TextAreaProps {
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;

  className?: string;
  containerClassName?: string;
  placeholderClassName?: string;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  required?: boolean;
}

const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    className = '',
    containerClassName = '',
    placeholderClassName = '',
    disabled,
    id,
    onChange,
    onBlur,
    placeholder,
    value,
    required = true,
  } = props;

  const disabledClassName = disabled ? 'TextArea-disabled' : '';
  return (
    <div className={`TextArea ${disabledClassName} ${containerClassName}`}>
      <textarea
        className={`TextArea-input ${className}`}
        disabled={disabled}
        id={id}
        onChange={onChange}
        value={value}
        required={required}
        onBlur={onBlur}
      />
      <span
        className={`floating-label ${
          disabled && value ? 'floating-label-disabled' : ''
        } ${placeholderClassName}`}
      >
        {placeholder}
      </span>
    </div>
  );
};

export default TextArea;
