import React, { useState } from 'react';
import Sidebar from 'react-sidebar';

import strings from 'common/strings';
import Alert from 'components/shared/Alert';

import NotificationCenterContent from './NotificationCenterContent';

const zIndex = 1001;

const sidebarStyles = {
  sidebar: { background: 'white', width: '320px', zIndex: zIndex.toString() },
  overlay: { top: '60px', zIndex: (zIndex - 1).toString() },
};

export interface NotificationCenterProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotificationCenter: React.FC<NotificationCenterProps> = ({
  isOpen,
  onClose,
}) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const sidebar = isOpen ? (
    <NotificationCenterContent
      onClose={onClose}
      onError={() => setShowErrorAlert(true)}
    />
  ) : (
    <></>
  );

  return (
    <Sidebar open={isOpen} pullRight sidebar={sidebar} styles={sidebarStyles}>
      <Alert
        open={showErrorAlert}
        contentProps={{
          message: strings.API_MESSAGE,
          onClose: () => setShowErrorAlert(false),
          variant: 'error',
        }}
      />
    </Sidebar>
  );
};

export default NotificationCenter;
