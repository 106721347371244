import { Button as MUIButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { RouteComponentProps, WindowLocation } from '@reach/router';
import { useCallback } from 'react';

import useGetInvoiceQuery from 'api/invoicing/useGetInvoiceQuery';
import ApiError from 'components/shared/ApiError';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import { Invoice } from '../../Invoice';

import './ViewInvoice.scss';

type Props = {
  location?: WindowLocation<{
    invoiceId?: string;
  }>;
} & RouteComponentProps;

const ViewInvoice = ({ navigate, location }: Props) => {
  const invoiceId = location?.state?.invoiceId; // Look to see existing invoice
  const getInvoiceQuery = useGetInvoiceQuery(invoiceId);

  const handleBack = useCallback(() => {
    navigate?.('../');
  }, [navigate]);

  let content = null;
  if (getInvoiceQuery.isLoading) {
    content = <LoadingIndicator />;
  } else if (getInvoiceQuery.isSuccess) {
    content = <Invoice invoice={getInvoiceQuery.data?.data} />;
  }

  return (
    <div className="vendor-view-invoice">
      <header className="vendor-view-invoice-header">
        <MUIButton
          variant="text"
          color="secondary"
          style={{ fontWeight: 'bold' }}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
        >
          Back
        </MUIButton>
      </header>
      <main className="vendor-view-invoice-content">{content}</main>
      <footer className="vendor-view-invoice-footer"></footer>
      {getInvoiceQuery.error && <ApiError error={getInvoiceQuery.error} />}
    </div>
  );
};

export default ViewInvoice;
