import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useCallback, useState } from 'react';

import { strings } from 'common';
import VehicleSearch from 'components/pages/VehicleCollectionBrowser/VehicleSearch/VehicleSearch';
import { getLastQueryParamWithName } from 'navigation/util/ParamHelpers';

import './Header.scss';

const Header = () => {
  const [searchInputValue, setSearchInputValue] = useState(
    getLastQueryParamWithName('s') || ''
  );
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus?.();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: { target: any }) => {
    if (anchorRef.current?.contains?.(event.target)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback(
    (event: { key: string; preventDefault: () => void }) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    },
    []
  );

  return (
    <div className="vendor-header">
      <VehicleSearch
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
      />
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="small"
        >
          <FilterListIcon color="secondary" />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClose}>
                      {strings.PENDING_INVOICES}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      {strings.PAID_INVOICES}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Header;
