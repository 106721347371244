import React from 'react';

var CustomDropdownToggle = React.forwardRef((props: any, ref) => {
  const handleClick: any = (event: MouseEvent) => {
    event.preventDefault();
    props.onClick(event);
  };
  return (
    <div role="listbox" tabIndex={-1} onClick={handleClick}>
      {props.children}
    </div>
  );
});

export default CustomDropdownToggle;
