import { FC, useEffect, useState } from 'react';

import { useUsers } from 'api';
import { useAssignUserToStep } from 'api/inventory/workflows';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Dropdown from 'components/shared/Dropdown';
import { usePermissions } from 'hooks';
import { getUsersFullName } from 'utils/user';

import { StepItemSubset } from './types';
import { getUserList } from './utils';

import './UsersDropdown.scss';

interface UsersDropdownProps {
  loaderType?: 'srp' | 'default' | 'srpGradient';
  currentStep?: StepItemSubset | null;
  inventoryId?: string;
}

const UsersDropdown: FC<UsersDropdownProps> = ({
  loaderType,
  currentStep,
  inventoryId,
}) => {
  const { data: { data: users = [] } = { data: [] } } = useUsers();
  const assignToStepMutation = useAssignUserToStep(inventoryId);

  const { assignedTo } = currentStep || {};
  const [selectedUser, setSelectedUser] = useState({
    id: 'not-assigned',
    value: '',
  });
  const { hasPermission } = usePermissions();

  // TODO: if we get complaints we can do optimistic update when setting dropdown
  useEffect(() => {
    if (selectedUser.id !== assignedTo?.id) {
      setSelectedUser({
        id: assignedTo?.id ?? 'not-assigned',
        value: getUsersFullName(strings.NOT_ASSIGNED, assignedTo),
      });
    }
  }, [assignedTo, selectedUser.id]);

  const setUser = async (userId: string) => {
    const user = users.find((listUser) => listUser.id === userId);
    assignToStepMutation.mutateAsync(userId);
    setSelectedUser({
      id: user?.id || 'not-assigned',
      value: getUsersFullName(strings.NOT_ASSIGNED, user),
    });
  };

  if (hasPermission(permissions.RECON_VDP_WORKFLOW_STEP_UPDATE)) {
    return (
      <Dropdown
        optionContainerId={testIds.ASSIGNEE_DROPDOWN}
        options={getUserList(users, currentStep)}
        selectedOption={selectedUser}
        onSelect={setUser}
        disabled={assignToStepMutation.isLoading}
        containerClassName="UsersDropdown"
        userCanChange
        loaderType={loaderType}
      />
    );
  }
  if (hasPermission(permissions.RECON_ASSIGNMENTS_VIEW)) {
    return (
      <div className="UsersDropdown-no-permission">
        <div className="assigneeName">{selectedUser.value}</div>
      </div>
    );
  }
  return null;
};

export default UsersDropdown;
