import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { navigate } from '@reach/router';

import permissions from 'common/permissions';
import strings from 'common/strings';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { usePermissions } from 'hooks';
import { DealerStepReportVehicle } from 'models';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { currencyFormatter } from 'utils/formatter';
import { getFormattedDurationFromSeconds } from 'utils/time';

import { compareItemOverage, ratings } from '../ReportsState';

interface StepReportTableRowProps {
  item: DealerStepReportVehicle;
  data?: {
    [key: string]: { reconTimeInStep: number; retailTimeInStep: number };
  };
  isLoading: boolean;
}

export const StepReportTableRow = ({
  item,
  data,
  isLoading,
}: StepReportTableRowProps) => {
  const { hasPermission } = usePermissions();
  const userHasReconInfoPermission = hasPermission(
    permissions.INVENTORY_VDP_RECON_INFO_VIEW
  );

  const openVDPScreenTabForVehicleItem = (item: DealerStepReportVehicle) => {
    const vehicleId = item?.vehicle?.id;
    if (!vehicleId) return;
    navigate(`${vehicleDetailsBuilder(vehicleId)}/overview`);
  };

  const renderItemOverage = () => {
    if (!userHasReconInfoPermission) {
      return <>{strings.EMPTY_VALUE}</>;
    }

    return (
      <>
        {compareItemOverage(item) === ratings.GOOD ? (
          <ArrowUpward />
        ) : (
          <ArrowDownward />
        )}
        {`${
          item?.overage?.amount
            ? currencyFormatter(Math.abs(item?.overage?.amount) || 0)
            : strings.EMPTY_VALUE
        }`}
      </>
    );
  };

  const timeInStep = data?.[item?.vehicle?.id] ?? {
    reconTimeInStep: 0,
    retailTimeInStep: 0,
  };

  const itemOverageClassName =
    'VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight ' +
    (userHasReconInfoPermission
      ? `VehicleStepReportModal-${compareItemOverage(item)}`
      : '');

  return (
    <tr className="VehicleStepReportModal-tableDataRow">
      <td className="VehicleStepReportModal-stockNumber">
        <div role="none" onClick={() => openVDPScreenTabForVehicleItem(item)}>
          {item?.vehicle?.stockNumber || ''}
        </div>
      </td>
      <td className="VehicleStepReportModal-alignLeft">
        {`${item?.vehicle?.year || ''} ${item?.vehicle?.make || ''} ${
          item?.vehicle?.model || ''
        }`}
      </td>
      <td className="VehicleStepReportModal-darkGreyCell VehicleStepReportModal-alignCenter">
        {isLoading ? (
          <LoadingIndicator size={10} />
        ) : (
          getFormattedDurationFromSeconds(timeInStep.retailTimeInStep)
        )}
      </td>
      <td className="VehicleStepReportModal-darkGreyCell VehicleStepReportModal-alignCenter">
        {isLoading ? (
          <LoadingIndicator size={10} />
        ) : (
          getFormattedDurationFromSeconds(timeInStep.reconTimeInStep)
        )}
      </td>
      <td className="VehicleStepReportModal-lightGreyCell VehicleStepReportModal-alignCenter">
        {getFormattedDurationFromSeconds(item?.retailReadyTimeAsSeconds) || ''}
      </td>
      <td className="VehicleStepReportModal-lightGreyCell VehicleStepReportModal-alignCenter">
        {getFormattedDurationFromSeconds(item?.reconTimeAsSeconds) || ''}
      </td>
      <td className="VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight">
        {item?.estimatedRecon && userHasReconInfoPermission
          ? currencyFormatter(item?.estimatedRecon?.amount || 0)
          : strings.EMPTY_VALUE}
      </td>
      <td className="VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight">
        {item?.actualRecon && userHasReconInfoPermission
          ? currencyFormatter(item?.actualRecon?.amount || 0)
          : strings.EMPTY_VALUE}
      </td>
      <td className={itemOverageClassName}>{renderItemOverage()}</td>
    </tr>
  );
};
