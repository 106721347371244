import { FC } from 'react';
import { Table } from 'react-bootstrap';

import strings from 'common/strings';
import { DealerStepReportVehicleV2 } from 'models';

import { StepReportTableRow } from './StepReportTableRow';

interface StepReportTableProps {
  selectedVehicles: DealerStepReportVehicleV2[];
}

export const StepReportTable: FC<StepReportTableProps> = ({
  selectedVehicles,
}) => (
  <div className="VehicleStepReportModal-scroller">
    <Table>
      <thead>
        <tr className="VehicleStepReportModal-titleRow">
          <th className="VehicleStepReportModal-vehicleInfoTitle" colSpan={2}>
            {strings.VEHICLE_INFO}
          </th>
          <th
            className="VehicleStepReportModal-reconditionEffortTitle"
            colSpan={4}
          >
            {strings.RECONDITION_EFFORT}
          </th>
          <th
            className="VehicleStepReportModal-reconditionCostsTitle"
            colSpan={3}
          >
            {strings.RECONDITION_COSTS}
          </th>
        </tr>
        <tr>
          <th>Stock #</th>
          <th className="VehicleStepReportModal-alignLeft">
            {strings.VEHICLE}
          </th>
          <th className="VehicleStepReportModal-darkGreyCell VehicleStepReportModal-alignCenter">
            {strings.RETAIL_READY_TIME_IN_STEP}
          </th>
          <th className="VehicleStepReportModal-darkGreyCell VehicleStepReportModal-alignCenter">
            {strings.RECON_TIME_IN_STEP}
          </th>
          <th className="VehicleStepReportModal-lightGreyCell VehicleStepReportModal-alignCenter">
            {strings.RETAIL_READY_TIME}
          </th>
          <th className="VehicleStepReportModal-lightGreyCell VehicleStepReportModal-alignCenter">
            {strings.RECON_TIME}
          </th>
          <th className="VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight">
            {strings.ESTIMATED}
          </th>
          <th className="VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight">
            {strings.ACTUAL}
          </th>
          <th className="VehicleStepReportModal-blueTableCell VehicleStepReportModal-alignRight">
            {strings.OVERAGE}
          </th>
        </tr>
      </thead>
      <tbody>
        {selectedVehicles.length > 0 ? (
          selectedVehicles.map((item, index) => (
            <StepReportTableRow
              item={item}
              key={`step-report-table-row-${index}`}
            />
          ))
        ) : (
          <tr className="VehicleStepReportModal-tableDataRow">
            <td className="VehicleStepReportModal-alignCenter p-5" colSpan={9}>
              {strings.NO_COMPLETED_DATA}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
