import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { useVehicleStepReport } from 'api';
import { DateRange } from 'common/DateRange';
import strings from 'common/strings';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { DealerStepReportSummaryItem } from 'models';

import { StepReportTable } from './StepReportTable';

import './VehicleStepReportModal.scss';

export interface VehicleStepReportModalProps {
  showModal: boolean;
  onClose: any;
  dateRange: DateRange;
  step: DealerStepReportSummaryItem;
  tenantId?: string;
}

const VehicleStepReportModal: FC<VehicleStepReportModalProps> = ({
  onClose,
  showModal,
  dateRange,
  step,
  tenantId,
}) => {
  const { data, isLoading } = useVehicleStepReport({
    dateRange,
    stepId: step.stepId,
    tenantId,
  });

  function closeModal() {
    onClose();
  }

  const allVehicles = data?.data ?? [];

  const selectedVehicles =
    step === null
      ? allVehicles
      : allVehicles.filter((v) => step.vehicleIds?.includes(v.vehicle.id));

  return (
    <>
      <Modal
        centered
        size="xl"
        show={showModal}
        onHide={closeModal}
        className="VehicleStepReportModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {step === null ? strings.TOTAL_COMPLETED : step.stepName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <StepReportTable selectedVehicles={selectedVehicles} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={closeModal}
            className="white-button modal-footer-button-base"
          >
            {strings.CLOSE}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VehicleStepReportModal;
