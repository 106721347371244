import React from 'react';

import FleetButton from './FleetButton';

import './VelocityLocate.scss';

const VelocityLocate: React.FC = () => (
  <div className="VelocityLocate">
    <div className="border rounded justify-content-between align-items-middle d-flex px-4 VelocityLocate-banner">
      <div className="d-flex align-self-center">
        <div className="Velocity-locate-logo" />
      </div>
      <FleetButton />
    </div>
  </div>
);

export default VelocityLocate;
