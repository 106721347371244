import React, { MouseEvent } from 'react';
import { Button as BSButton, Spinner } from 'react-bootstrap';

import './Button.scss';

export type Variant =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'danger'
  | 'info'
  | 'dark'
  | 'success'
  | 'link';

type ButtonType = 'button' | 'submit' | 'reset' | undefined;

interface ButtonProps {
  id?: string;
  type: ButtonType;
  variant: Variant;
  className?: string;
  onClick: (event: MouseEvent) => void;
  disabled?: boolean;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  id,
  type,
  variant,
  className,
  onClick,
  disabled,
  loading,
  children,
  ...rest
}) => {
  const buttonPaddingClass = loading ? 'spinner-padding-adjustment' : '';

  const buttonContent = () => {
    if (loading) {
      let spinnerVariant: Variant = 'primary';

      if (variant === 'primary' && !disabled) {
        spinnerVariant = 'secondary';
      }

      return (
        <Spinner
          role="status"
          aria-hidden="true"
          as="span"
          animation="border"
          variant={spinnerVariant}
        />
      );
    }
    return <div>{children}</div>;
  };

  return (
    <div className="Button">
      <BSButton
        id={id}
        type={type}
        variant={variant}
        className={`${buttonPaddingClass} ${className || ''} `}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {buttonContent()}
      </BSButton>
    </div>
  );
};

export default Button;
