import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import DropdownButton from 'react-bootstrap/esm/DropdownButton';

import { strings } from 'common/strings';

interface PaginationProps {
  onPageSizeChange: (size: number) => void;
  totalItems: number | null;
  page: number;
  pageSize: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  canGoToPreviousPage: boolean;
  canGoToNextPage: boolean;
}
export function getPaginationText({
  page,
  pageSize,
  totalItems,
}: {
  totalItems: number | null;
  page: number;
  pageSize: number;
}): string {
  const startIndex = page * pageSize;
  return `${startIndex + 1} - ${
    totalItems
      ? Math.min(startIndex + pageSize, totalItems)
      : startIndex + pageSize
  } of ${totalItems || strings.EMPTY_VALUE}`;
}
const Pagination: React.FC<PaginationProps> = ({
  onPageSizeChange,
  page,
  pageSize,
  totalItems,
  canGoToNextPage,
  canGoToPreviousPage,
  onNextPage,
  onPreviousPage,
}) => (
  <>
    <DropdownButton
      onSelect={(key: string | null) => onPageSizeChange(key ? +key : 10)}
      id="pageSizeSelect"
      size="sm"
      variant="link"
      title={getPaginationText({ totalItems, page, pageSize })}
    >
      {[20, 50, 100].map((c) => (
        <Dropdown.Item key={`${c}`} eventKey={`${c}`}>
          {`${c} per page`}
        </Dropdown.Item>
      ))}
    </DropdownButton>
    {[
      {
        key: 'chev-left',
        label: <ChevronLeft />,
        onClick: onPreviousPage,
        disabled: !canGoToPreviousPage,
      },
      {
        key: 'chev-right',
        label: <ChevronRight />,
        onClick: onNextPage,
        disabled: !canGoToNextPage,
      },
    ].map((props) => (
      <a
        type="button"
        {...props}
        style={
          props.disabled
            ? { color: '#ddd', cursor: 'not-allowed' }
            : { color: 'inherit' }
        }
      >
        {props.label}
      </a>
    ))}
  </>
);
export default Pagination;
