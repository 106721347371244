import { StepItem } from './stepItem';
import VehicleSummary from './vehicleSummary';

export enum VehicleType {
  INVENTORY_VEHICLE,
  RECON_VEHICLE,
}

// TODO should this be the same as VehicleSummary
export default interface InventoryVehicleSummary extends VehicleSummary {
  vehicleType: VehicleType;
  retailReadyTimeAsSeconds: number;
  reconTimeAsSeconds: number;
  stepItem: StepItem;
}
