import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import { FC, useMemo, useState } from 'react';

import Button from 'components/shared/Button';
import { Select } from 'components/shared/Inputs';
import { Option } from 'components/shared/Inputs/Select/types';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import MultipleSearchSelection, {
  Option as MultiSelectOption,
} from 'components/shared/MultipleSearchSelection';
import { useWindowSize } from 'hooks';
import { useCarfax } from 'hooks/useCarfax';
import {
  CarfaxOption,
  CarfaxValues,
  HistoryBasedValue as IHistoryBasedValue,
} from 'models/carfax';
import { currencyFormatter } from 'utils/formatter';

import Arrows from './Arrows';

import './HistoryBasedValue.scss';

export interface HistoryBasedValueProps {
  vin: string;
  zipCode: string;
}

type TypeOfSale = 'retail' | 'wholesale' | 'certified';
type Condition =
  | 'excellentConditionValues'
  | 'goodConditionValues'
  | 'fairConditionValues';

const HistoryBasedValue: FC<HistoryBasedValueProps> = ({ vin, zipCode }) => {
  const { useHistoryBasedValue, useUpdateHistoryBasedValue } = useCarfax();
  const isMobile = useWindowSize().isMobileViewport();
  const formStyleString = isMobile ? 'controls-mobile' : 'controls-desktop';

  const { isLoading: isGetHbvLoading, data: getHbvData } = useHistoryBasedValue(
    { vin, zipCode },
    {
      onSuccess: (data: IHistoryBasedValue) => {
        console.log(data);
        setValues({
          excellentConditionValues: data.excellentConditionValues,
          goodConditionValues: data.goodConditionValues,
          fairConditionValues: data.fairConditionValues,
        });
        setColors(data.colors);
        setTrims(data.trims);
        setOptions(data.options);
      },
    }
  );
  const { isLoading: isUpdateHbvLoading, mutate: updateHbv } =
    useUpdateHistoryBasedValue({
      onSuccess: (data: CarfaxValues) => {
        setValues(data);
      },
    });
  const [zip, setZip] = useState<string>(zipCode);
  const [values, setValues] = useState({
    excellentConditionValues: {
      retail: 0,
      wholesale: 0,
      certified: 0,
    },
    goodConditionValues: {
      retail: 0,
      wholesale: 0,
      certified: 0,
    },
    fairConditionValues: {
      retail: 0,
      wholesale: 0,
      certified: 0,
    },
  });
  const [typeOfSale, setTypeOfSale] = useState<TypeOfSale>('retail');
  const [condition, setCondition] = useState<Condition>(
    'excellentConditionValues'
  );
  const [colors, setColors] = useState<CarfaxOption[]>([]);
  const [trims, setTrims] = useState<CarfaxOption[]>([]);
  const [options, setOptions] = useState<CarfaxOption[]>([]);

  const displayedValue = useMemo<string>(
    () => currencyFormatter(values[condition][typeOfSale]),
    [values, condition, typeOfSale]
  );

  const colorOptions = useMemo<Option[]>(() => {
    return (
      colors.map(({ name }, index) => ({
        label: name,
        value: String(index),
      })) ?? []
    );
  }, [colors]);
  const trimOptions = useMemo<Option[]>(() => {
    return (
      trims.map(({ name }, index) => ({
        label: name,
        value: String(index),
      })) ?? []
    );
  }, [trims]);
  const optionOptions = useMemo<MultiSelectOption[]>(() => {
    return (
      options.map(({ name, selected }, index) => ({
        id: String(index),
        value: name,
        checked: selected,
      })) ?? []
    );
  }, [options]);

  const selectedColorIndex = useMemo<number>(
    () => colors.findIndex(({ selected }) => selected === true),
    [colors]
  );
  const selectedTrimIndex = useMemo<number>(
    () => trims.findIndex(({ selected }) => selected === true),
    [trims]
  );

  const handleTrimChange = (option: Option) => {
    const index = Number(option.value);

    const newTrims = Array.from(trims);
    const oldSelectedTrim = trims[selectedTrimIndex];

    // Mark previously selected trim as no longer selected
    newTrims.splice(selectedTrimIndex, 1, {
      ...oldSelectedTrim,
      selected: false,
    });

    // Mark newly selected trim as selected
    newTrims.splice(index, 1, {
      ...newTrims[index],
      selected: true,
    });

    setTrims(newTrims);
  };
  const handleColorChange = (option: Option) => {
    const index = Number(option.value);

    const newColors = Array.from(colors);
    const oldSelectedColor = colors[selectedColorIndex];
    const newSelectedColor = colors[index];

    // Mark previously selected color as no longer selected
    newColors.splice(selectedColorIndex, 1, {
      ...oldSelectedColor,
      selected: false,
    });

    // Mark newly selected color as selected
    newColors.splice(index, 1, {
      ...newSelectedColor,
      selected: true,
    });

    setColors(newColors);
  };
  const handleOptionChange = (id: string) => {
    const index = Number(id);

    const newOptions = Array.from(options);
    const option = newOptions[index];

    newOptions.splice(index, 1, {
      ...option,
      selected: !option.selected,
    });

    setOptions(newOptions);
  };
  const handleUpdateAssumptions = () => {
    updateHbv({
      vin: vin,
      zipCode: zip,
      mobile: true,
      odometer: getHbvData?.odometer ?? '',
      colors,
      options,
      trim: trims[selectedTrimIndex],
    });
  };

  if (isGetHbvLoading) {
    return <LoadingIndicator />;
  }

  if (getHbvData) {
    return (
      <div className="HistoryBasedValue">
        <div className="value-display">
          <Arrows arrows={getHbvData.arrows} />
          <div className="value">
            <div className="amount">
              {isUpdateHbvLoading ? <LoadingIndicator /> : displayedValue}
            </div>
            <div className="text">
              CARFAX History Based {capitalize(typeOfSale)} Value
            </div>
          </div>
        </div>
        <div className={formStyleString}>
          <div className={`${formStyleString}-values`}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sale Type</FormLabel>
              <RadioGroup
                aria-label="typeOfSale"
                name="typeOfSale"
                value={typeOfSale}
                onChange={(e) => setTypeOfSale(e.target.value as TypeOfSale)}
              >
                <FormControlLabel
                  value="retail"
                  control={<Radio checked={typeOfSale === 'retail'} />}
                  label="Retail"
                />
                <FormControlLabel
                  value="wholesale"
                  control={<Radio checked={typeOfSale === 'wholesale'} />}
                  label="Wholesale"
                />
                <FormControlLabel
                  value="certified"
                  control={<Radio checked={typeOfSale === 'certified'} />}
                  label="Certified"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel component="legend">Condition</FormLabel>
              <RadioGroup
                aria-label="condition"
                name="condition"
                value={condition}
                onChange={(e) => setCondition(e.target.value as Condition)}
              >
                <FormControlLabel
                  value="excellentConditionValues"
                  control={
                    <Radio checked={condition === 'excellentConditionValues'} />
                  }
                  label="Excellent"
                />
                <FormControlLabel
                  value="goodConditionValues"
                  control={
                    <Radio checked={condition === 'goodConditionValues'} />
                  }
                  label="Good"
                />
                <FormControlLabel
                  value="fairConditionValues"
                  control={
                    <Radio checked={condition === 'fairConditionValues'} />
                  }
                  label="Fair"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={`${formStyleString}-options`}>
            <FormControl className="trim">
              <FormLabel>Trim</FormLabel>
              <Select
                options={trimOptions}
                value={trimOptions[selectedTrimIndex]}
                onChange={handleTrimChange}
              />
            </FormControl>
            <FormControl className="color">
              <FormLabel>Color</FormLabel>
              <Select
                options={colorOptions}
                value={colorOptions[selectedColorIndex]}
                onChange={handleColorChange}
              />
            </FormControl>
            <FormControl className="zipCode">
              <FormLabel>Zip Code</FormLabel>
              <Input
                type="text"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </FormControl>
            <FormControl className="options">
              <FormLabel>Options</FormLabel>
              <MultipleSearchSelection
                filterId="hbv-options"
                options={optionOptions}
                onSelect={handleOptionChange}
              />
            </FormControl>
            <div className="submit">
              <Button
                variant="primary"
                type="button"
                className="button"
                loading={isUpdateHbvLoading}
                onClick={handleUpdateAssumptions}
              >
                Update Assumptions
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="message-container">
      Must be a CARFAX Advantage dealer to use History Based Value.
    </div>
  );
};

export default HistoryBasedValue;
