import { FC } from 'react';

import Chevron from 'components/shared/icons/Chevron';
import { VehicleRepairOrderLine } from 'models';

import Price from './Price';

export interface RepairOrderLineItemProps {
  repairOrderLine: VehicleRepairOrderLine;
  hasParts?: boolean;
  isOpen?: boolean;
}

const RepairOrderLineItem: FC<RepairOrderLineItemProps> = ({
  repairOrderLine,
  hasParts = false,
  isOpen = false,
}) => {
  const { opCode, opCodeDescription, laborSale, partsSale, miscSale, total } =
    repairOrderLine;

  const PRICES = [laborSale, miscSale, partsSale, total];

  return (
    <div className={`RepairOrderLine ${hasParts ? '' : 'pad-right'}`}>
      <div className="OpCode-and-OpCodeDescription">
        <div className="OpCode">{opCode}</div>
        <div className="OpCodeDescription">{opCodeDescription}</div>
      </div>
      <div className="prices">
        {PRICES.map((amount, index) => (
          <Price amount={amount} key={index} />
        ))}
      </div>
      {hasParts && (
        <div className="parts-icon">
          <Chevron isOpen={isOpen} />
        </div>
      )}
    </div>
  );
};

export default RepairOrderLineItem;
