interface FitImageProps {
  fitWidth?: string;
  fitHeight?: string;
  className?: string;
  imageUrl?: string;
  alt?: string;
}

var FitImage = ({
  imageUrl,
  alt,
  className,
  fitWidth,
  fitHeight,
}: FitImageProps) => {
  return (
    <img
      src={imageUrl}
      className={className}
      alt={alt}
      style={{ width: fitWidth, height: fitHeight, objectFit: 'contain' }}
    />
  );
};

export default FitImage;
