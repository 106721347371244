import { useQuery } from 'react-query';

import { APIResponse } from 'api/useAPI';
import { VehicleSummary } from 'models';

/**
 * @package api/inventory/invoice-summary
 * @description - Get summaries for vehicles with invoices
 */
export function useGetDealerInvoiceInventoryQuery() {
  const path = '/inventory/invoice-summary';
  return useQuery<APIResponse<VehicleSummary[]>>(path);
}

export default useGetDealerInvoiceInventoryQuery;
