import { FC } from 'react';

import { PartUsed as IPartUsed } from 'models';

import PartUsed from './PartUsed';

export interface RepairOrderLinePartsUsedProps {
  partsUsed: IPartUsed[];
}

const RepairOrderLinePartsUsed: FC<RepairOrderLinePartsUsedProps> = ({
  partsUsed,
}) => {
  return (
    <div className="PartsUsed">
      <div className="PartsUsed-Table">
        <div className="PartsUsed-Table-row header">
          <div className="details">
            <div className="partNumber">Part #</div>
            <div className="description">Description</div>
          </div>
          <div className="breakdown">
            <div>Quantity</div>
            <div>Unit Price</div>
            <div>Total</div>
            <div className="placeholder" />
          </div>
        </div>
        {partsUsed.map((partUsed) => (
          <PartUsed key={partUsed.part.id} partUsed={partUsed} />
        ))}
      </div>
    </div>
  );
};

export default RepairOrderLinePartsUsed;
