import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { InventoryNeedingAttentionItem } from 'models';

import { noticeTitleAndIconMap, ReconIcon } from '../NoticeTitleAndIcons';

interface VehicleNeedsAttentionIconsProps {
  inventoryNeedingAttentionItem: InventoryNeedingAttentionItem;
}

var NeedsAttentionIcon = ({ icon, title, message }: any) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 450 }}
      overlay={
        <Tooltip id="tooltip-top">
          <span className="font-weight-bold">{title}</span>
          <span className="mr-1">:</span>
          <span>{message}</span>
        </Tooltip>
      }
    >
      <div className="VehicleNeedsAttentionCard-icon-hover">{icon}</div>
    </OverlayTrigger>
  );
};

var VehicleNeedsAttentionIcons = ({
  inventoryNeedingAttentionItem,
}: VehicleNeedsAttentionIconsProps) => {
  const { itemType, message } = inventoryNeedingAttentionItem;

  const { title, Icon } = noticeTitleAndIconMap[itemType] || {
    title: 'Recon Issue',
    Icon: <ReconIcon />,
  };
  return (
    <NeedsAttentionIcon
      key={itemType}
      title={title}
      icon={Icon}
      message={message}
    />
  );
};

export default VehicleNeedsAttentionIcons;
