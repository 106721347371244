import { ArrowDropDown, Check } from '@material-ui/icons';
import { components } from 'react-select';

import { Task } from 'models';

export var CustomOption = (props: any): any => {
  const {
    isSelected,
    isFocused,
    theme: { colors },
  } = props;
  const backgroundColor = isFocused ? colors.primary25 : 'transparent';
  return (
    <div className="FlatTask-option" style={{ backgroundColor }}>
      {isSelected ? (
        <Check className="FlatTask-option-icon" />
      ) : (
        <div className="FlatTask-option-icon" />
      )}
      <components.Option {...props} />
    </div>
  );
};

export var CustomMenu = (props: any): any => {
  const { customRef, onClick } = props;
  return (
    <div ref={customRef} onClick={onClick} role="none">
      <components.Menu {...props} />
    </div>
  );
};

export const getDropdownTheme = (baseTheme: any) => ({
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    primary: '#DDDDDD',
    primary25: '#EFF1F1',
    primary50: '#C7C7C7',
  },
});

export const customDropdownStyles = (isMobileViewport: boolean) => ({
  container: (base: any) => ({
    ...base,
    width: '100%',
  }),
  singleValue: (base: any) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: isMobileViewport ? '13px' : '14px',
    lineHeight: 20,
    letterSpacing: 0.1,
    color: '#263238',
  }),
  control: (provided: any, selectState: any) => ({
    ...provided,
    minHeight: '30px',
    height: '30px',
    boxShadow: 'none',
    border: selectState.menuIsOpen ? '1px solid #007cd7 !important' : 'none',
    borderRadius: 'none',
    backgroundColor: 'transparent',
    cursor: selectState.isDisabled ? 'no-drop' : '',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: '30px',
    padding: '0 0',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '30px',
    marginRight: isMobileViewport ? '10px' : 'unset',
  }),
  option: (provided: any, selectState: any) => ({
    ...provided,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: isMobileViewport ? '13px' : '16px',
    lineHeight: '24px',
    letterSpacing: '0.3px',
    color: '#263238',
    backgroundColor: selectState.isFocused
      ? selectState.theme.colors.primary25
      : 'transparent',
  }),
  menu: (provided: any) => ({
    ...provided,
    width: 'auto',
    whiteSpace: 'nowrap',
  }),
});

export const customDropdownComponents = (onMenuClick: () => void) => ({
  IndicatorSeparator: () => null,
  DropdownIndicator: () => <ArrowDropDown className="FlatTask-caret" />,
  Option: CustomOption,
  Menu: (props: any) => <CustomMenu onClick={onMenuClick} {...props} />,
});

export const totalTaskPrice = (task: Task): number => {
  return (task?.partsPrice?.amount || 0) + (task?.laborPrice?.amount || 0);
};
