interface SelectPlaceholderProps {
  defaultValue: any;
  labelClassName: any;
  disabled: any;
  selectedValue: any;
  value: any;
  placeholderClassName: any;
  placeholder: any;
}

var SelectPlaceholder = ({
  defaultValue,
  labelClassName,
  disabled,
  selectedValue,
  value,
  placeholderClassName,
  placeholder,
}: SelectPlaceholderProps) => {
  if (defaultValue) {
    return null;
  }

  return (
    <span
      className={`
          floating-label 
          ${labelClassName} 
          ${
            disabled && (selectedValue || value)
              ? 'floating-label-disabled'
              : ''
          } 
          ${placeholderClassName}
        `}
    >
      {placeholder}
    </span>
  );
};

export default SelectPlaceholder;
