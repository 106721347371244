import { FC } from 'react';

import { TabProps } from 'components/shared/Tabs/Tabs';

interface DealerStepReportV3Props extends TabProps {}

const DealerStepReportV3: FC<DealerStepReportV3Props> = () => {
  return (
    <>
      <p>Dealer Step Report V3</p>
    </>
  );
};

export default DealerStepReportV3;
