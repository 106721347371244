import { AddCircle } from '@material-ui/icons';
import React from 'react';

import { useWindowSize } from 'hooks';

import './EngageMediaPlaceholder.scss';

interface EngageMediaPlaceholderProps {
  onClick: () => void;
  title: string;
}

const EngageMediaPlaceholder: React.FC<EngageMediaPlaceholderProps> = ({
  onClick,
  title,
}) => {
  const windowSize = useWindowSize();

  var LargeMediaPlaceholder = () => {
    return (
      <div
        className="EngageMediaPlaceholder item"
        role="none"
        onClick={onClick}
      >
        <div className="item-content">
          <div className="EngageMediaPlaceholder-content">
            <AddCircle className="EngageMediaPlaceholder-icon" />
            <span className="EngageMediaPlaceholder-title">{title}</span>
          </div>
        </div>
      </div>
    );
  };

  var SmallMediaPlaceholder = () => {
    return (
      <div
        className="EngageMediaPlaceholder-small item"
        role="none"
        onClick={onClick}
      >
        <div className="item-content">
          <div className="EngageMediaPlaceholder-content">
            <AddCircle className="EngageMediaPlaceholder-icon" />
          </div>
        </div>
      </div>
    );
  };

  return windowSize.isMobileViewport() ? (
    <SmallMediaPlaceholder />
  ) : (
    <LargeMediaPlaceholder />
  );
};

export default EngageMediaPlaceholder;
