export const extensionStrings = {
  EXTENSION_EVENT_NEW_HOVER_TARGET: 'new_hover_target',
  EXTENSION_EVENT_CACHE_VEHICLES: 'cache_vehicles',
  EXTENSION_EVENT_CARD_IN_BACKGROUND: 'card_in_background',
};
export const DEBOUNCE_DELAY = 500;
export const PAGE_SIZE = 50;
export const TAG_COLOR_OPTIONS = [
  {
    value: '#EEEFEF',
    label: 'Silver',
  },
  {
    value: '#FFDFDD',
    label: 'Red',
  },
  {
    value: '#FFEAD0',
    label: 'Orange',
  },
  {
    value: '#FFF7CC',
    label: 'Yellow',
  },
  {
    value: '#E2F9DA',
    label: 'Green',
  },
  {
    value: '#D5F6EE',
    label: 'Aqua',
  },
  {
    value: '#DEF1F9',
    label: 'Light Blue',
  },
  {
    value: '#DBE8FF',
    label: 'Dark Blue',
  },
  {
    value: '#EFE6FD',
    label: 'Purple',
  },
  {
    value: '#FCE3F6',
    label: 'Pink',
  },
];
export const RECON_STEP_STATE = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  MANUALLY_REMOVED: 'MANUALLY_REMOVED',
  NOT_STARTED: 'NOT_STARTED',
};
