import { FC, useEffect, useMemo, useState } from 'react';

import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Dropdown from 'components/shared/Dropdown';
import { StepItem, Workflow } from 'models';
import { recurseChildSteps } from 'utils/workflow';

import './BaseStepDropdown.scss';

type Step = Pick<StepItem, 'id' | 'name'>;

interface BaseStepDropdownProps {
  containerClassName?: string;
  loaderType?: 'srp' | 'default' | 'srpGradient';
  workflow?: Workflow;
  currentStep?: Step;
  onStepChange?: (stepId: string) => Promise<void>;
  isLoading?: boolean;
}

const BaseStepDropdown: FC<BaseStepDropdownProps> = ({
  containerClassName,
  loaderType,
  workflow,
  currentStep,
  onStepChange,
  isLoading,
}) => {
  const [selectedStep, setSelectedStep] = useState({
    id: currentStep?.id || 'no-step',
    value: currentStep?.name || strings.EMPTY_VALUE,
  });

  useEffect(() => {
    setSelectedStep({
      id: currentStep?.id || 'no-step',
      value: currentStep?.name || strings.EMPTY_VALUE,
    });
  }, [currentStep?.id, currentStep?.name]);

  const flattenedSteps = useMemo(
    () => (workflow ? recurseChildSteps(workflow) : []),
    [workflow]
  );

  const handleStepSelect = async (newSelectedStepId: string) => {
    try {
      if (currentStep?.id !== newSelectedStepId) {
        const newStepDefinition = flattenedSteps.find(
          (listStep) => listStep.id === newSelectedStepId
        );
        setSelectedStep({
          id: newStepDefinition?.id || 'no-step',
          value: newStepDefinition?.name || strings.EMPTY_VALUE,
        });
        await onStepChange?.(newSelectedStepId);
      }
    } catch (e) {
      setSelectedStep({
        id: currentStep?.id || 'no-step',
        value: currentStep?.name || strings.EMPTY_VALUE,
      });
    }
  };

  return (
    <Dropdown
      optionContainerId={testIds.STEP_DROPDOWN}
      options={flattenedSteps.map((step: StepItem) => ({
        id: step.id || '',
        testId: step.name,
        value: step.name,
        level: step.level,
        isHeader: step?.childSteps && step?.childSteps?.length > 0,
      }))}
      selectedOption={selectedStep}
      onSelect={handleStepSelect}
      disabled={isLoading}
      isSubmitting={isLoading}
      containerClassName={containerClassName}
      loaderType={loaderType}
    />
  );
};

export default BaseStepDropdown;
