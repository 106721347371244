import React, { useCallback, useMemo, useState } from 'react';

import { useVehicleList } from 'api';
import { permissions } from 'common';
import PermissionsGate from 'components/shared/PermissionsGate';
import VelocityLocate from 'components/shared/VelocityLocate';
import { usePermissions, useWindowSize } from 'hooks';
import { VehicleSummary } from 'models';

import Banner from './Banner';
import Sidebar from './Sidebar';
import VehicleList from './VehicleList';
import VehicleListHeader from './VehicleListHeader';

import './VehicleCollectionBrowser.scss';

interface VehicleCollectionBrowserProps {
  default: boolean;
}

const VehicleCollectionBrowser: React.FC<
  VehicleCollectionBrowserProps
> = () => {
  const { hasPermission } = usePermissions();
  const windowSize = useWindowSize();
  const { data: vehicleListData } = useVehicleList();
  const viewShowroom = useMemo(
    () => hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW_SHOWROOM),
    [hasPermission]
  );
  const [isShowroomMode, setIsShowroomMode] = useState(viewShowroom);
  const [engageSendToShopperVehicles, setEngageSendToShopperVehicles] =
    useState<VehicleSummary[]>([]);
  const [isSendVehicleModalDisplayed, setIsSendVehicleModalDisplayed] =
    useState(false);
  const [isOverviewFiltersModalOpen, setIsOverviewFiltersModalOpen] =
    useState(false);

  const toggleShowRoom = useCallback(() => {
    setIsShowroomMode((prevState: boolean) => !prevState);
  }, []);

  const isOnboarding =
    !!vehicleListData?.pages?.[0]?.meta?.show_onboarding_banner;

  return (
    <div className="VehicleCollectionBrowser flex-columns page grey-background">
      <Sidebar
        isShowroomMode={isShowroomMode}
        isOverviewFiltersModalOpen={isOverviewFiltersModalOpen}
        setIsOverviewFiltersModalOpen={setIsOverviewFiltersModalOpen}
      />
      <div className="SRP flex-grow flex-rows no-padding overflow-hidden">
        {!windowSize.isMobileViewport() && (
          <PermissionsGate
            permissions={[permissions.PLUGIN_VELOCITYLOCATE_VIEW]}
          >
            <VelocityLocate />
          </PermissionsGate>
        )}
        {isOnboarding && <Banner />}
        <VehicleListHeader
          isShowroomMode={isShowroomMode}
          toggleShowroom={toggleShowRoom}
          engageSendToShopperVehicles={engageSendToShopperVehicles}
          isSendVehicleModalDisplayed={isSendVehicleModalDisplayed}
          setIsSendVehicleModalDisplayed={setIsSendVehicleModalDisplayed}
          setIsOverviewFiltersModalOpen={setIsOverviewFiltersModalOpen}
        />
        <VehicleList
          isShowroomMode={isShowroomMode}
          setEngageSendToShopperVehicles={setEngageSendToShopperVehicles}
          setIsSendVehicleModalDisplayed={setIsSendVehicleModalDisplayed}
        />
      </div>
    </div>
  );
};

export default VehicleCollectionBrowser;
